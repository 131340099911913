import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Select, Input } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import {
  Content,
  Bloco,
  Grid,
  BForm,
  GridT,
  ButtonView,
  ContentCnae,
} from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Cnae() {
  const { idCliente } = useParams();
  const [cnaes, setCnaes] = useState([]);
  const [cliente, setCliente] = useState('');
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;
        localStorage.setItem('cliente', JSON.stringify(response.data));

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/cnaes`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setCnaes(percorre);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const simOp = [
    { id: 'SIM', title: 'Sim' },
    { id: 'NÃO', title: 'Não' },
  ];

  async function handleSubmit({ code, cnae, atividade }) {
    try {
      setLoading(true);
      await api.post(`clientes/${idCliente}/cnaes`, {
        code,
        cnae,
        atividade,
      });
      setLoading(false);
      toast.success('Atualizado com sucesso.');

      const percorreData = await api.get(`clientes/${idCliente}/cnaes`);

      setCnaes(
        percorreData.data.data.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.updated_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }))
      );
    } catch (err) {
      setLoading(false);
      toast.error('Erro na atualização.');
    }
  }

  async function handleUpdate({ id, code, cnae }) {
    const valAtiv = document.querySelector(`.form_ativ${id}`).value;
    try {
      await api.put(`clientes/${idCliente}/cnaes?id=${id}`, {
        code,
        cnae,
        atividade: valAtiv,
      });

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Erro na atualização.');
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`delete/${idCliente}/cnaes?id=${id}`);

      toast.success('Atualizado com sucesso.');
      setCnaes(cnaes.filter(item => item.id !== id));
    } catch (err) {
      toast.error('Erro no delete.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="cnae"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Código</label>
                <Input type="text" name="code" />
              </span>
              <span>
                <label>CNAE do Estabelecimento - Conforme Cartão CNPJ</label>
                <Input type="text" name="cnae" />
              </span>
              <span>
                <label>Atividade Principal?</label>
                <Select name="atividade" options={simOp} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <ContentCnae>
          <h4>CNAE Principal</h4>
        </ContentCnae>

        {cnaes.map(
          item =>
            item.atividade === 'SIM' && (
              <GridT key={item.id}>
                <Form onSubmit={handleUpdate} initialData={item}>
                  <Input type="hidden" name="id" />
                  <BForm>
                    <span>
                      <label>Código</label>
                      <Input type="text" name="code" />
                    </span>
                    <span>
                      <label>
                        CNAE do Estabelecimento - Conforme Cartão CNPJ
                      </label>
                      <Input type="text" name="cnae" />
                    </span>
                  </BForm>
                  <BForm>
                    <span>
                      <p>
                        Adicionado em {item.dateFormatted} por{' '}
                        <strong>
                          {item.user ? item.user.name : 'Anônimo'}
                        </strong>
                      </p>
                    </span>
                  </BForm>
                  <BForm>
                    <span>
                      <ButtonView>
                        <button type="submit" className="btn-save">
                          <MdSave size={16} color="#fff" /> Salvar
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}
                          className="btn-delete"
                        >
                          <MdDelete size={16} color="#fff" /> Deletar
                        </button>
                      </ButtonView>
                    </span>
                  </BForm>
                </Form>
              </GridT>
            )
        )}

        <ContentCnae>
          <h4>CNAES Secundário</h4>
        </ContentCnae>

        {cnaes.map(
          item =>
            item.atividade === 'NÃO' && (
              <GridT key={item.id}>
                <Form onSubmit={handleUpdate} initialData={item}>
                  <Input type="hidden" name="id" />
                  <BForm>
                    <span>
                      <label>Código</label>
                      <Input type="text" name="code" />
                    </span>
                    <span>
                      <label>
                        CNAE do Estabelecimento - Conforme Cartão CNPJ
                      </label>
                      <Input type="text" name="cnae" />
                    </span>
                  </BForm>
                  <BForm>
                    <span>
                      <p>
                        Adicionado em {item.dateFormatted} por{' '}
                        <strong>
                          {item.user ? item.user.name : 'Anônimo'}
                        </strong>
                      </p>
                    </span>
                  </BForm>
                  <BForm>
                    <span>
                      <ButtonView>
                        <button type="submit" className="btn-save">
                          <MdSave size={16} color="#fff" /> Salvar
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(item.id)}
                          className="btn-delete"
                        >
                          <MdDelete size={16} color="#fff" /> Deletar
                        </button>
                      </ButtonView>
                    </span>
                  </BForm>
                </Form>
              </GridT>
            )
        )}

        <Footer />
      </Container>
    </>
  );
}
