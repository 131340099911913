import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
    margin:0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }

  a {
  text-decoration: none;
  transition-duration: 0.5s;
}

  html, body, #root {
    min-height: 100%;
  }

  body {
    background: #eee;
    -webkit-font-smoothing: antialiased !important;
    font-family: Roboto, sans-serif;
    height: 100%;
    min-width: 1260px;
  }

  button, input, textarea {
    font-family: Roboto, sans-serif;
  }

  button {
    cursor: pointer;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }

  table {
    border-collapse: collapse;
    flex: 1;
  }

  select option {
    font-size: 16px;
  }

  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    font-size: 12px;
    width: 100%;
  }

  thead {
    font-weight: bold;
    color: #0085B2;
    font-size: 14px;
  }

  thead tr td {
    padding: 10px;
  }

  tbody tr td {
    padding: 10px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
`;
