import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Footer from '~/components/Footer';
import Title from '~/components/Title';
import Notify from '~/components/Modal/Notification';

import { Content, Bloco, Card, ContentModal } from './styles';

export default function View() {
  const profile = useSelector(state => state.user.profile);
  const { id } = useParams();
  const [title, setTitle] = useState('');

  useEffect(() => {
    async function loadTitle() {
      const response = await api.get(`notifications/${id}`);

      if (response.data.user_id !== profile.id) {
        history.push('/dashboard');
      }

      setTitle(response.data.title);
    }

    loadTitle();
  }, [id, profile.id]);

  return (
    <>
      <Nav page="notifications" />
      <Container>
        <User />

        <Content>
          <Title title={`Notificação: ${title}`} />
          <Bloco>
            <div>
              <h2>Notificação: {title}</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <ContentModal>
            <Notify cliente={id} ativo />
          </ContentModal>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
