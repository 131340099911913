import React, { useState } from 'react';

import { MdSave } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import Title from '~/components/Title';
import Carregando from '~/components/Carregando/Button';
import Footer from '~/components/Footer';
import history from '~/services/history';

import api from '~/services/api';

import User from '~/components/User';

import { Container, Content, Bloco, Card, Editar } from './styles';
import Nav from '~/components/Navigation';

export default function TbEconomicoAdd() {
  const [loading, setLoading] = useState(false);

  async function handleSubmit({ title }) {
    try {
      setLoading(true);
      await api.post('tb/grupoeconomico', {
        title,
      });

      setLoading(false);
      toast.success('Cadastrado com sucesso.');
      history.push(`/show/grupoeconomico`);
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado. Tente mais tarde.');
    }
  }

  return (
    <>
      <Nav page="itens" />
      <Container>
        <User />

        <Content>
          <Title title="Itens / Tabelas / Adicionar" />
          <Bloco>
            <div>
              <h2>Adicionar / Tabela / Grupo Econômico</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <Editar>
            <Form onSubmit={handleSubmit}>
              <Input name="title" placeholder="Adicionar title" />
              <button type="submit">
                {loading ? (
                  <Carregando />
                ) : (
                  <>
                    <MdSave size={18} color="#fff" /> Salvar
                  </>
                )}
              </button>
            </Form>
          </Editar>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
