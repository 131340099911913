import React, { useState, useEffect } from 'react';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative } from 'date-fns';
import Skeleton from '@material-ui/lab/Skeleton';

import api from '~/services/api';
import { urlFile, urlRh } from '~/utils/url.json';

import {
  Container,
  Grid,
  Avatar,
  Discription,
  Name,
  Email,
  Acesso,
} from './styles';

import SemAvatar from '~/assets/no-avatar.png';

const arrayloading = Array.from(String('1234567890'), Number);

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('users?onlyOld=true');

      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.updated_at), new Date(), {
          locale: pt,
        }),
      }));

      setLoading(false);
      setUsers(percorre);
    }

    loadUsers();
  }, []);

  return (
    <Container>
      {loading ? (
        <>
          {arrayloading.map(item => (
            <Grid key={item}>
              <Avatar>
                <span />
              </Avatar>
              <Discription>
                <div className="loading">
                  <Skeleton />
                </div>
                <div className="loading">
                  <Skeleton />
                </div>
                <div className="loading">
                  <Skeleton />
                </div>
              </Discription>
            </Grid>
          ))}
        </>
      ) : (
        <>
          {users.map(item => (
            <Grid key={item.id}>
              <Avatar>
                {item.file ? (
                  <img src={`${urlFile}${item.file.file}`} alt={item.name} />
                ) : (
                  <img
                    src={item.avatar ? `${urlRh}${item.avatar}` : SemAvatar}
                    alt={item.name}
                  />
                )}
              </Avatar>
              <Discription>
                <Name>{item.name}</Name>
                <Email>{item.email}</Email>
                <Acesso>Último acesso: {item.dateFormatted}</Acesso>
              </Discription>
            </Grid>
          ))}
        </>
      )}
    </Container>
  );
}
