import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Tributacao({ dados }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.data_virg
      ? format(parseISO(item.data_virg), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid>
      <h4>Particularidades Tributárias:</h4>
      {data.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>Regime aco..</strong> {item.regime}
            </div>
            <div>
              <strong>Período: </strong> {item.dateFormatted}
            </div>
            <div>
              <strong>Federal: </strong>
              {item.federal}
            </div>
            <div>
              <strong>Estadual: </strong>
              {item.estadual}
            </div>
            <div>
              <strong>Municipal: </strong>
              {item.municipal}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Previdenciário: </strong> {item.previ}
            </div>
            <div>
              <strong>Alíquota s/ Faturamento: </strong> {item.aliq}
            </div>
            <div>
              <strong>RAT: </strong> {item.rat}
            </div>
            <div>
              <strong>FAP: </strong> {item.fap}
            </div>
            <div>
              <strong>Outras Entidades: </strong> {item.entidades}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Tributacao.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Tributacao.defaultProps = {
  dados: [],
};
