import React, { useState, useCallback, useEffect } from 'react';
import Pusher from 'pusher-js'; //eslint-disable-line
import Echo from 'laravel-echo';

import { useSelector, useDispatch } from 'react-redux';

import renderHTML from 'react-render-html';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import api from '~/services/api';

import Loading from '~/components/Carregando/MaterialUi/loading';

import { updateNotificationRequest } from '~/store/modules/user/actions';

const echo = new Echo({
  broadcaster: 'pusher',
  key: '6286eb0eb3e4bed0551a',
  cluster: 'us2',
  forceTLS: true,
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PopUp() {
  const dispatch = useDispatch();
  const { profile, notification } = useSelector(state => state.user);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [totalNot] = useState(() => {
    const total = localStorage.getItem('@secran:notification');

    if (total) {
      return Number(total);
    }

    return 0;
  });

  const handleUpdateNotification = useCallback(() => {
    if (totalNot === notification) {
      const soma = notification + 1;
      dispatch(updateNotificationRequest(soma));
    }
  }, [dispatch, notification, totalNot]);

  echo
    .channel(`channel-notify-${profile && profile.id}`)
    .listen(`.my-notify-${profile && profile.id}`, event => {
      setData(event);
      setOpen(true);
      handleUpdateNotification();
    });

  echo
    .channel(`channel-snack-${profile && profile.id}`)
    .listen(`.my-snack-${profile && profile.id}`, event => {
      setData(event);
      setOpenSnack(true);
      handleUpdateNotification();
    });

  const handleConfirm = useCallback(
    async event => {
      setLoading(true);
      await api.put(`notifications/${event}`, {
        confirmed: true,
      });

      const contagem = notification - 1;

      dispatch(updateNotificationRequest(contagem));

      setOpen(false);
      setLoading(false);
    },
    [dispatch, notification]
  );

  useEffect(() => {
    api.get('comunicados?reading=true').then(response => {
      if (response.data) {
        setOpen(true);
        setData(response.data);
      }
    });
  }, []);

  return (
    <>
      {data && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {loading ? '' : `Novo comunicado: ${data.subject}!`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {loading ? (
                <div
                  style={{
                    width: 180,
                    height: 80,
                  }}
                >
                  <Loading />
                </div>
              ) : (
                renderHTML(data.message)
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!loading && (
              <Button
                onClick={() => handleConfirm(data.idnotification)}
                color="primary"
              >
                Marcar como lido
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={10000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert onClose={() => setOpenSnack(false)} severity="success">
          {data ? data.message : 'Você tem uma nova notificação.'}
        </Alert>
      </Snackbar>
    </>
  );
}
