import React, { useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';
import {
  MdGavel,
  MdLibraryBooks,
  MdWork,
  MdLock,
  MdPermContactCalendar,
  MdContactPhone,
  MdInfo,
  MdGroup,
} from 'react-icons/md';

import { FaHandsHelping, FaIdCard } from 'react-icons/fa';

import { HiDocumentRemove } from 'react-icons/hi';

import { RiFileSearchFill, RiMoneyDollarBoxFill } from 'react-icons/ri';

import ReactTooltip from 'react-tooltip';
import { Menus, Tabela } from './styles';

import api from '~/services/api';

import Tributacao from '~/pages/Clientes/Show/Tributacao';
import Fiscais from '~/pages/Clientes/Show/IncentivosFiscais';
import Cnaes from '~/pages/Clientes/Show/Cnae';
import Socios from '~/pages/Clientes/Show/Socios';
import Atos from '~/pages/Clientes/Show/AtosSocietarios';
import Negativas from '~/pages/Clientes/Show/CertNegativos';
import Procuracoes from '~/pages/Clientes/Show/Procuracoes';
import Digitais from '~/pages/Clientes/Show/CertDigitais';
import Licencas from '~/pages/Clientes/Show/Licencas';
import Contatos from '~/pages/Clientes/Show/Contatos';
import Primeiro from '~/pages/Clientes/Show/PrimeiroContato';
import Info from '~/pages/Clientes/Show/InforAdicionais';
import Executores from '~/components/Modal/Executores';

export default function NavClienteFooter({
  id,
  cnpj,
  tributacoes,
  fiscais,
  cnaes,
  socios,
  atos,
  negativas,
  procuracoes,
  digitais,
  licencas,
  contatos,
  info,
}) {
  const [tipo, setTipo] = useState('');
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function loadUser() {
      const response = await api.get('users?role=5&limit=200'); // Não pega cliente

      setUsers(response.data.data);
    }

    loadUser();
  }, []);

  const handleMenu = useCallback(event => {
    setTipo(event.tipo);
    setTitle(event.title);
    setOpen(true);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(!open);
    setTipo('');
    setTitle('');
  }, [open]);

  return (
    <>
      <Tabela>
        <Menus>
          <ul>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({ tipo: 'executores', title: 'Executores' })
                }
                data-tip="Executores"
              >
                <MdGroup />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({ tipo: 'tributacoes', title: 'Tributações' })
                }
                data-tip="Tributações"
              >
                <MdGavel />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'incentivofiscais',
                    title: 'Incentivo Fiscais',
                  })
                }
                data-tip="Incentivo Fiscais"
              >
                <RiMoneyDollarBoxFill />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'cnaes',
                    title: 'Cnaes',
                  })
                }
                data-tip="Cnaes"
              >
                <MdLibraryBooks />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'socios',
                    title: 'Sócios',
                  })
                }
                data-tip="Sócios"
              >
                <FaHandsHelping />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'atossocietarios',
                    title: 'Atos Societários',
                  })
                }
                data-tip="Atos Societários"
              >
                <MdWork />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'certidoesnegativas',
                    title: 'Certidoes Negativas',
                  })
                }
                data-tip="Certidoes Negativas"
              >
                <HiDocumentRemove />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'procuracoes',
                    title: 'Procurações',
                  })
                }
                data-tip="Procurações"
              >
                <RiFileSearchFill />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'certificadodigitais',
                    title: 'Certificado Digitais',
                  })
                }
                data-tip="Certificado Digitais"
              >
                <MdLock />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'licencas',
                    title: 'Licenças',
                  })
                }
                data-tip="Licenças"
              >
                <FaIdCard />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'contatos',
                    title: 'Contatos',
                  })
                }
                data-tip="Contatos"
              >
                <MdContactPhone />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'primeirocontato',
                    title: 'Primeiro Contato',
                  })
                }
                data-tip="Primeiro Contato"
              >
                <MdPermContactCalendar />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() =>
                  handleMenu({
                    tipo: 'infoadicionais',
                    title: 'Informações Adicionais',
                  })
                }
                data-tip="Informações Adicionais"
              >
                <MdInfo />
              </button>
            </li>
          </ul>
        </Menus>
      </Tabela>

      <ReactTooltip />

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {tipo === 'executores' && <Executores cnpj={cnpj} id={id} />}
            {tipo === 'tributacoes' && <Tributacao dados={tributacoes} />}
            {tipo === 'incentivofiscais' && <Fiscais dados={fiscais} />}
            {tipo === 'cnaes' && <Cnaes dados={cnaes} />}
            {tipo === 'socios' && <Socios dados={socios} />}
            {tipo === 'atossocietarios' && <Atos dados={atos} />}
            {tipo === 'certidoesnegativas' && <Negativas dados={negativas} />}
            {tipo === 'procuracoes' && <Procuracoes dados={procuracoes} />}
            {tipo === 'certificadodigitais' && <Digitais dados={digitais} />}
            {tipo === 'licencas' && <Licencas dados={licencas} />}
            {tipo === 'contatos' && <Contatos dados={contatos} />}
            {tipo === 'primeirocontato' && <Primeiro id={id} />}
            {tipo === 'infoadicionais' && <Info dados={info} users={users} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

NavClienteFooter.propTypes = {
  id: PropTypes.number.isRequired,
  cnpj: PropTypes.string.isRequired,
  tributacoes: PropTypes.array, // eslint-disable-line
  fiscais: PropTypes.array, // eslint-disable-line
  cnaes: PropTypes.array, // eslint-disable-line
  socios: PropTypes.array, // eslint-disable-line
  atos: PropTypes.array, // eslint-disable-line
  negativas: PropTypes.array, // eslint-disable-line
  procuracoes: PropTypes.array, // eslint-disable-line
  digitais: PropTypes.array, // eslint-disable-line
  licencas: PropTypes.array, // eslint-disable-line
  contatos: PropTypes.array, // eslint-disable-line
  info: PropTypes.array, // eslint-disable-line
};

NavClienteFooter.defaultProps = {
  tributacoes: [],
  fiscais: [],
  cnaes: [],
  socios: [],
  atos: [],
  negativas: [],
  procuracoes: [],
  digitais: [],
  licencas: [],
  contatos: [],
  info: [],
};
