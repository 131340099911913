import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import { useParams } from 'react-router-dom';

import { MdExitToApp } from 'react-icons/md';

import { Content, Card, Loading, Submenu } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import MenuSub from '~/components/Navigation/Obrigacoes';

import api from '~/services/api';
import history from '~/services/history';

import img from '~/assets/loading-process.svg';

export default function Calendar() {
  const { id } = useParams();
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [, setDelFuncao] = useState(!getStorageFuncao);
  const [obrs, setObr] = useState([]);
  const [editObrs, setEditObrs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [funcao] = useState(
    getStorageFuncao && profile.cargo.subtitle === 'diretor'
      ? JSON.parse(getStorageFuncao)
      : profile.cargo.subtitle
  );
  const dateYear = format(new Date(), 'yyyy');
  const dateMonth = format(new Date(), 'MM');
  const { admGestaoPermission } = usePermission();

  useEffect(() => {
    admGestaoPermission({ role_id: profile.role_id });

    async function loadObrigacoes() {
      const responseMonth = await api.get(`obrigacoes/calendar?post_id=${id}`);

      setObr(responseMonth.data);
    }

    loadObrigacoes();

    api.get().then(response => {
      setObr(response.data);
    });
  }, [admGestaoPermission, dateMonth, dateYear, funcao, id, profile.role_id]);

  const handleSetor = useCallback(e => {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
  }, []);

  const handleInput = useCallback(
    ({ event, date_id }) => {
      const data = {
        id: date_id,
        vencimento: event,
      };

      setEditObrs([data, ...editObrs]);
    },
    [editObrs]
  );

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true);
      await api.put('obrigacoes/calendar', {
        obrigacoes: editObrs,
      });

      setLoading(false);
      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }, [editObrs]);

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="edit-calendar" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title="Usuários" />
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Titulo</td>
                <td>Tipo</td>
                <td>Vencimento</td>
                <td>Vencimento Empresa</td>
              </tr>
            </thead>
            <tbody>
              {obrs.map(item => (
                <tr key={item.id}>
                  <td>{item.obrigacoes.title}</td>
                  <td>{item.obrigacoes.tipo}</td>
                  <td>
                    <input
                      type="date"
                      className={`vencimento_legal${item.obr_id}`}
                      defaultValue={item.vencimento}
                      disabled
                    />
                    Atualizado: {item.vencimento}
                  </td>
                  <td>
                    <input
                      type="date"
                      className={`vencimento_emp${item.obr_id}`}
                      defaultValue={item.vencimento_emp}
                      onChange={e =>
                        handleInput({
                          event: e.target.value,
                          date_id: item.id,
                        })
                      }
                    />{' '}
                    Atualizado: {item.vencimento_emp}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="button" className="btn-green" onClick={handleUpdate}>
            Atualizar Obrigações
          </button>
        </Card>

        <Footer />
      </Container>
      {loading && (
        <Loading>
          <img src={img} alt="Secran Loading" />
        </Loading>
      )}
    </>
  );
}
