import React, { useState, useEffect } from 'react';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import PropTypes from 'prop-types';

import { Grid } from './styles';
import Carregando from '~/components/Carregando/Button';

import api from '~/services/api';

export default function TabNotification({ cliente, ativo }) {
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(true);

  const [certificado, setCertificado] = useState([]);
  const [orgaos, setOrgao] = useState([]);
  const [outorgados, setOutorgados] = useState([]);
  const [tbnegativas, setTbNegativas] = useState([]);
  const [tabfiscais, setFiscais] = useState([]);
  const [tablicenca, setLicenca] = useState([]);
  const [tabquali, setSociQuali] = useState([]);
  const [tabpais, setSociPais] = useState([]);

  useEffect(() => {
    if (!ativo) {
      setLoading(true);
      return;
    }

    async function loadNotification() {
      const response = await api.get(`notifications/${cliente}`);

      const percorre = response.data && {
        ...response.data,
        procDataProtocolo: response.data.procuracao
          ? format(
              parseISO(response.data.procuracao.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        negDataProtocolo: response.data.negativa
          ? format(
              parseISO(response.data.negativa.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        digDataProtocolo: response.data.digital
          ? format(
              parseISO(response.data.digital.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        licDataProtocolo: response.data.licenca
          ? format(
              parseISO(response.data.licenca.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        fisDataProtocolo: response.data.fiscais
          ? format(
              parseISO(response.data.fiscais.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        socDataProtocolo: response.data.socios
          ? format(
              parseISO(response.data.socios.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
      };

      if (percorre.procuracao) {
        const tabDigital = await api.get('tb/geral?tb=cert_digitais');
        const tabVirg = await api.get('tb/geral?tb=virg_orgaos');
        const tabOut = await api.get('tb/geral?tb=virg_outorgados');

        setCertificado(tabDigital.data);
        setOrgao(tabVirg.data);
        setOutorgados(tabOut.data);
      }

      if (percorre.socios) {
        const tabQuali = await api.get('tb/qualificacao?limit=100');
        const tabPais = await api.get('tb/pais?limit=150');

        setSociQuali(tabQuali.data.data);
        setSociPais(tabPais.data.data);
      }

      if (percorre.negativa) {
        const tabNegativa = await api.get('tb/certidoes-negativas');

        setTbNegativas(tabNegativa.data.data);
      }

      if (percorre.digital) {
        const tabDigital = await api.get('tb/geral?tb=cert_digitais');

        setCertificado(tabDigital.data);
      }

      if (percorre.fiscais) {
        const tabResFiscais = await api.get('tb/geral?tb=ince_fiscais');

        setFiscais(tabResFiscais.data);
      }

      if (percorre.licenca) {
        const tabLicencaRes = await api.get('tb/geral?tb=lincencas');

        setLicenca(tabLicencaRes.data);
      }

      setNotification(percorre);

      setLoading(false);
    }

    loadNotification();
  }, [ativo, cliente]);

  return (
    <>
      <Grid>
        {loading ? (
          <Carregando />
        ) : (
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Informações</td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-hover">
                <td>
                  <strong>Título:</strong> {notification.title}
                </td>
              </tr>
              {notification.procuracao && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Procuração:</strong>{' '}
                      {certificado.map(
                        item =>
                          item.id === notification.procuracao.certificado &&
                          item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Outorgado:</strong>{' '}
                      {outorgados.map(
                        item =>
                          item.id === notification.procuracao.outorgado &&
                          item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Vigência Orgão:</strong>{' '}
                      {orgaos.map(
                        item =>
                          item.id === notification.procuracao.orgao &&
                          item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Titular:</strong>{' '}
                      {notification.procuracao.titular}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Vigência data:</strong>{' '}
                      {notification.procuracao.vigencia}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.procDataProtocolo}
                    </td>
                  </tr>
                </>
              )}
              {notification.socios && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Nome:</strong> {notification.socios.nome}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data de Nascimento:</strong>{' '}
                      {notification.socios.data_nas}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>CPF:</strong> {notification.socios.cpf_cnpj}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>País de residência:</strong>{' '}
                      {tabpais.map(
                        item =>
                          item.id === notification.socios.pais && item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Qualificação:</strong>{' '}
                      {tabquali.map(
                        item =>
                          item.id === notification.socios.quali && item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data de Ingresso:</strong>{' '}
                      {notification.socios.data_ingre}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data de Saída:</strong>{' '}
                      {notification.socios.data_saida}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Representante Legal?:</strong>{' '}
                      {notification.socios.repre_legal}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Início Mandato:</strong>{' '}
                      {notification.socios.first_mand}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Fim Mandato:</strong>{' '}
                      {notification.socios.end_mand}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.socDataProtocolo}
                    </td>
                  </tr>
                </>
              )}
              {notification.negativa && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Certidão Negativa:</strong>{' '}
                      {tbnegativas.map(
                        item =>
                          item.id === notification.negativa.tb_id && item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Vigência data:</strong>{' '}
                      {notification.negativa.vigencia}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.negDataProtocolo}
                    </td>
                  </tr>
                </>
              )}
              {notification.digital && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Certificado Digital:</strong>{' '}
                      {certificado.map(
                        item =>
                          item.id === notification.digital.tb_id && item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Titular:</strong> {notification.digital.titular}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Senha:</strong> {notification.digital.senha}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Vigência:</strong> {notification.digital.vigencia}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.digDataProtocolo}
                    </td>
                  </tr>
                </>
              )}
              {notification.fiscais && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Orgão:</strong>{' '}
                      {tabfiscais.map(
                        item =>
                          item.id === notification.fiscais.orgaos && item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Número de Acordo:</strong>{' '}
                      {notification.fiscais.n_acordo}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Vigência data:</strong>{' '}
                      {notification.fiscais.fim_vig}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.fisDataProtocolo}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Nº do Protocolo:</strong>{' '}
                      {notification.fiscais.n_proto}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Observação:</strong> {notification.fiscais.obs}
                    </td>
                  </tr>
                </>
              )}
              {notification.licenca && (
                <>
                  <tr className="table-hover">
                    <td>
                      <strong>Nº do Documento:</strong>{' '}
                      {notification.licenca.n_doc}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Nº do Protocolo:</strong>{' '}
                      {notification.licenca.n_proto}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Tipo da funcionalidade:</strong>{' '}
                      {tablicenca.map(
                        item =>
                          item.id === notification.licenca.tb_all_id &&
                          item.title
                      )}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Vencimento:</strong>{' '}
                      {notification.licenca.data_venc}
                    </td>
                  </tr>
                  <tr className="table-hover">
                    <td>
                      <strong>Data Protocolo:</strong>{' '}
                      {notification.licDataProtocolo}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )}
      </Grid>
    </>
  );
}

TabNotification.propTypes = {
  cliente: PropTypes.number.isRequired,
  ativo: PropTypes.bool,
};

TabNotification.defaultProps = {
  ativo: false,
};
