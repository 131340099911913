import React, { useState, useEffect, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  MdSearch,
  MdRemoveRedEye,
  MdControlPoint,
  MdCheckBox,
  MdWarning,
  MdCancel,
  MdPeople,
  MdCloudUpload,
} from 'react-icons/md';
import { Input, Form } from '@rocketseat/unform';
import InputMask from 'react-input-mask';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando/Tabela';
import Executores from '~/components/Modal/Executores';
import Pagination from '~/components/Pagination';

import User from '~/components/User';
import api from '~/services/api';
import history from '~/services/history';

import { Content, Bloco, Card, Pesquisa } from './styles';
import Nav from '~/components/Navigation';

export default function Clientes() {
  const [openExecutores, setOpenExecutores] = useState(false);
  const [clienteExe, setClienteExe] = useState({
    id: 0,
    cnpj: '',
    razao: '',
  });
  const [clientes, setClientes] = useState([]);
  const [search, setSearch] = useState('razao');
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitView, setLimitView] = useState(20);
  const [pageView, setPageView] = useState(1);
  const [statusView, setStatusView] = useState('');
  const [tipo, setTipo] = useState('');
  const url = new URL(window.location);

  useEffect(() => {
    const params = new URLSearchParams(url.search);
    const limit = params.get('limit') || 20;
    const page = params.get('page') || 1;
    const status = params.get('status') || '';
    const type = params.get('tipo') || '';

    setLoading(true);
    api
      .get(`clientes?limit=${limit}&tipo=${type}&page=${page}&status=${status}`)
      .then(response => {
        try {
          setLoading(false);

          setLimitView(limit);
          setPageView(Number(page));
          setStatusView(status);
          setTipo(type);

          setClientes(response.data.data);
          setTotal(response.data.last_page);
        } catch (err) {
          toast.warning(err.message, {
            position: 'top-center',
          });
        }
      });
  }, [url.search]);

  const handleLimit = useCallback(
    e => {
      setLoading(true);
      const limit = e.target.value;

      setLimitView(limit);
      setPageView(1);
      history.push(
        `/clientes?limit=${limit}&tipo=${statusView}&page=1&status=${statusView}`
      );
    },
    [statusView]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = pageView === 1 ? 1 : pageView - 1;
        setPageView(ptotal);
        setLimitView(limitView);
        history.push(
          `/clientes?limit=${limitView}&tipo=${statusView}&page=${ptotal}&status=${statusView}`
        );
        return;
      }
      setLoading(true);
      const ptotal = Number(pageView) + 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(
        `/clientes?limit=${limitView}&tipo=${tipo}&page=${ptotal}&status=${statusView}`
      );
    },
    [limitView, pageView, statusView, tipo]
  );

  const handlePageSelect = useCallback(
    ({ page }) => {
      setLoading(true);
      setPageView(page);
      setLimitView(limitView);
      history.push(
        `/clientes?limit=${limitView}&tipo=${tipo}&page=${page}&status=${statusView}`
      );
    },
    [limitView, statusView, tipo]
  );

  const handleStatus = useCallback(
    e => {
      const status = e.target.value;
      setLoading(true);

      if (
        status === 'matriz' ||
        status === 'filial' ||
        status === 'scp' ||
        status === 'cpf' ||
        status === 'domestica'
      ) {
        history.push(
          `/clientes?limit=${limitView}&tipo=${status}&page=${pageView}&status=`
        );
        setTipo(status);
        return;
      }

      history.push(
        `/clientes?limit=${limitView}&tipo=${tipo}&page=${pageView}&status=${status}`
      );
    },
    [limitView, pageView, tipo]
  );

  const handleSearch = useCallback(
    async ({ searchGo }) => {
      setLoading(true);
      const resSearch = await api.get(
        `search/clientes?search_type=${search}&search=${searchGo}`
      );

      setTotal(resSearch.data.last_page);

      setClientes(resSearch.data.data);
      setLoading(false);
    },
    [search]
  );

  const handleExecutores = useCallback(event => {
    setOpenExecutores(true);
    setClienteExe({ id: event.id, cnpj: event.cnpj, razao: event.razao });
  }, []);

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title="Clientes" />
          <Bloco>
            <div>
              <h2>Clientes</h2>
            </div>
            <div className="links">
              <Link to="/adicionar/cliente">
                <MdControlPoint size={16} color="#fff" /> Adicionar nova empresa
              </Link>
              <Link to="/#">
                <MdPeople size={18} color="#fff" /> Editar Executores Geral
              </Link>
              <Link to="/uploads/clientes">
                <MdCloudUpload size={18} color="#fff" /> Envio de documentos
                para a SECRAN
              </Link>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <select onChange={handleStatus}>
                <option value="">Todos</option>
                <option
                  value="Ativo"
                  selected={statusView === 'Ativo' || false}
                >
                  Ativos
                </option>
                <option
                  value="Suspenso"
                  selected={statusView === 'Suspenso' || false}
                >
                  Suspensos
                </option>
                <option
                  value="Rescindido"
                  selected={statusView === 'Rescindido' || false}
                >
                  Rescindidos
                </option>
                <option
                  value="Baixada"
                  selected={statusView === 'Baixada' || false}
                >
                  Baixada
                </option>
                <option value="matriz">MATRIZ</option>
                <option value="filial">FILIAL</option>
                <option value="scp">SCP</option>
                <option value="cpf">CPF</option>
                <option value="domestica">DOMÉSTICA</option>
              </select>

              <div className="search">
                <span>Pesquisar por:</span>
                <select onChange={e => setSearch(e.target.value)}>
                  <option value="razao">Razão Social</option>
                  <option value="fantasia">Nome Fantasia</option>
                  <option value="cnpj">CNPJ</option>
                  <option value="insc_mun">Inscrição Municipal</option>
                  <option value="insc_est">Inscrição Estadual</option>
                </select>
                <Form onSubmit={handleSearch}>
                  <Input name="searchGo" placeholder="Pesquisar" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          {loading ? (
            <Carregando />
          ) : (
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>Razão Social</td>
                  <td>Status</td>
                  <td>Unidade</td>
                  <td>CNPJ / CPF</td>
                  <td>Cidade/UF</td>
                  <td>Detalhar</td>
                </tr>
              </thead>
              <tbody>
                {clientes.map(item => (
                  <>
                    <tr
                      key={item.id}
                      className="table-hover"
                      id={
                        (item.status === 'Suspenso' && 'orange') ||
                        (item.status === 'Rescindido' && 'red') ||
                        (item.ativoUser && 'exe')
                      }
                    >
                      <td>{item.razao}</td>
                      <td className="contrato">
                        {item.status === 'Ativo' && (
                          <>
                            <MdCheckBox size={20} color="#0FAF69" /> Ativo
                          </>
                        )}
                        {item.status === 'Rescindido' && (
                          <>
                            <MdCancel size={20} color="#FF2626" /> Rescindido
                          </>
                        )}
                        {item.status === 'Baixada' && (
                          <>
                            <MdCancel size={20} color="#FF2626" /> Baixada
                          </>
                        )}
                        {item.status === 'Suspenso' && (
                          <>
                            <MdWarning size={20} color="#FF7F00" /> Suspenso
                          </>
                        )}
                      </td>
                      <td>{item.tipo}</td>
                      <td>
                        {item.cnpj.length === 14 ? (
                          <InputMask
                            mask="99.999.999/9999-99"
                            value={item.cnpj.replace('x', '1')}
                            disabled
                          />
                        ) : (
                          <InputMask
                            mask="99.999.999-99"
                            value={item.cnpj}
                            disabled
                          />
                        )}
                      </td>
                      <td>
                        {item.cidade}/{item.state}
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            title="Visualizar executor"
                            className="executor"
                            onClick={() =>
                              handleExecutores({
                                id: item.id,
                                cnpj: item.cnpj,
                                razao: item.razao,
                              })
                            }
                          >
                            <MdPeople size={20} color="#fff" />
                          </button>
                          <Link
                            to={`/cliente/${item.cnpj}`}
                            alt="Visualizar"
                            title="Visualizar"
                            className="visualizar"
                          >
                            <MdRemoveRedEye size={20} color="#fff" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          )}
        </Card>

        <Pagination
          total={total}
          limit={limitView}
          page={pageView}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Footer />

        <Dialog
          fullWidth
          maxWidth="lg"
          open={openExecutores}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Cliente: {clienteExe.razao} / Cnpj: {clienteExe.cnpj}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Executores cnpj={clienteExe.cnpj} id={clienteExe.id} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenExecutores(false);
                setClienteExe({ id: 0, cnpj: '', razao: '' });
              }}
              color="primary"
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
