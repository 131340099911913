import styled from 'styled-components';

export const Menus = styled.div`
  display: flex;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;

  ul {
    list-style: none;
    display: grid;
    /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
    grid-template-columns: repeat(8, 1fr);
    /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
    grid-auto-rows: auto;
    /* espaço entre um elemento interno e outro */
    grid-gap: 5px 8px;
    /* margem interna que afasta os elementos da borda do grid */
    text-align: center;
    margin-bottom: 12px;
    width: 100%;
  }

  ul li a {
    font-size: 11px;
    flex: 1;
    height: 36px;
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0f73af;

    &:hover {
      background: #2a9fe5;
    }
  }

  .active {
    background: #2a9fe5;
  }
`;
