import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Form } from '@rocketseat/unform';
import {
  MdSave,
  MdDelete,
  MdEdit,
  MdEmail,
  MdPhoneInTalk,
  MdPerson,
} from 'react-icons/md';

import * as Yup from 'yup';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { urlFile, urlRh } from '~/utils/url.json';

import {
  Content,
  Bloco,
  Grid,
  ButtonBtn,
  BForm,
  Tabela,
  Image,
  Icon,
  Description,
  GridDir,
  GridExe,
  Titulo,
} from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

import SemAvatar from '~/assets/no-avatar.png';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const options = [
  { id: 'diretor', title: 'DIRETOR' },
  { id: 'contabil', title: 'CONTÁBIL' },
  { id: 'fiscal', title: 'FISCAL' },
  { id: 'pessoal', title: 'PESSOAL' },
  { id: 'tributos', title: 'TRIBUTOS' },
];

export default function Executores() {
  const classes = useStyles();
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState('');
  const [loading, setLoading] = useState(false);
  const [carrega, setCarrega] = useState(false);
  const [loadpage, setLoadPage] = useState(false);
  const [executor, setExecutor] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [users, setUsers] = useState([]);
  const [carregaUser, setCarregaUser] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDelete] = useState(0);
  const [userUpdate, setUserUpdate] = useState([]);
  const [state, setState] = useState({
    id: 0,
    idexe: 0,
  });
  const { validarPermission } = usePermission();

  const profile = useSelector(prof => prof.user.profile);

  useEffect(() => {
    async function loadCliente() {
      const resStorage = localStorage.getItem('cliente');
      if (resStorage) {
        const dadosStorage = JSON.parse(resStorage);
        const verify = JSON.parse(resStorage).ativoUser;

        validarPermission({ role_id: profile.role_id, ativo: verify });

        setCliente(dadosStorage);

        const response = await api.get(
          `clientes/${idCliente}/executores?cnpj=${dadosStorage.cnpj}`
        );
        setUsers(response.data);
      } else {
        const resCli = await api.get(`clientes/${idCliente}`);

        const { ativoUser } = resCli.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(resCli.data));
        setCliente(resCli.data);

        const response = await api.get(
          `clientes/${idCliente}/executores?cnpj=${resCli.data.cnpj}`
        );

        setUsers(response.data);
      }
    }

    loadCliente();
  }, [idCliente, profile.role_id, validarPermission]);

  const handleClickOpen = useCallback(
    async event => {
      setEdit(true);
      const { data } = await api.get(
        `users?cargo=${event.cargo}&userid=${event.id}&executor=true`
      );
      setUserUpdate(data);
      setState({ ...state, idexe: event.idexe });
    },
    [state]
  );

  const handleClose = useCallback(() => {
    setEdit(false);
  }, []);

  const handleUpdate = useCallback(async () => {
    setLoadPage(true);
    const { data } = await api.put(`clientes/${state.idexe}/executores`, {
      user: state.id,
    });

    if (data.error) {
      toast.warning(data.error);
      setLoadPage(false);
      return;
    }

    toast.success('Atualizado com sucesso');

    const percorre = users.filter(item => item.id !== state.idexe);

    setUsers([...percorre, data]);
    setState({ id: 0, idexe: 0 });
    setEdit(false);
    setLoadPage(false);
  }, [state.id, state.idexe, users]);

  const handleSubmit = useCallback(async () => {
    const funcao = document.querySelector(`.funcao`).value;
    const cargo_id = document.querySelector(`.cargo_id`).value;
    const user_id = document.querySelector(`.user_id`).value;
    const date_inicio = document.querySelector(`.date_inicio`).value;

    try {
      const schema = Yup.object().shape({
        user_id: Yup.string().required('Por favor! Selecione o usuário!'),
        cargo_id: Yup.string().required('Por favor! Selecione o cargo!'),
        funcao: Yup.string().required('Por favor! Selecione o setor!'),
      });

      await schema.validate({
        funcao,
        cargo_id,
        user_id,
      });

      setLoading(true);

      const show = await api.post(`clientes/${idCliente}/executores`, {
        funcao,
        cargo_id,
        user_id,
        date_inicio,
        cnpj: cliente.cnpj,
      });

      if (show.data.error) {
        toast.warning(show.data.error);
        setLoading(false);
        document.getElementById('form_reset').reset();
        return;
      }

      document.getElementById('form_reset').reset();

      setUsers(show.data);

      toast.success('Executores Atualizado com sucesso.');
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      toast.error(err.message);
    }
  }, [cliente.cnpj, idCliente]);

  const handleCargo = useCallback(async e => {
    const searchvalor = e.target.value;
    setCarrega(true);

    const resCargo = await api.get(`cargos?search=%${searchvalor}%&limit=50`);

    setCargos(resCargo.data.data);
    setExecutor([]);
    setCarrega(false);
  }, []);

  const handleUser = useCallback(async e => {
    const usersearch = e.target.value;
    setCarregaUser(true);

    const resUser = await api.get(`users?setor=${usersearch}&limit=200`);

    setExecutor(resUser.data.data);
    setCarregaUser(false);
  }, []);

  const handleDelete = useCallback(
    async e => {
      try {
        const { data } = await api.delete(
          `delete/${idCliente}/executores?id=${e}`
        );

        if (data.error) {
          toast.warning(data.error);
          return;
        }

        toast.success('Deletado com sucesso.');
        setUsers(users.filter(item => item.id !== e));
        setDelete(0);
      } catch (err) {
        toast.error('Algo deu errado no delete.');
      }
    },
    [idCliente, users]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="executores"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <Grid>
              <BForm>
                <h3>Selecionar</h3>
              </BForm>
              <BForm>
                <span>
                  <label>
                    <strong>Selecione o Setor</strong>
                  </label>
                  <select className="funcao" onChange={handleCargo}>
                    <option value="">...</option>
                    {options.map(item => (
                      <option value={item.id}>{item.title}</option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>
                    <strong>Selecione o Cargo</strong>
                  </label>
                  <select className="cargo_id" onChange={handleUser}>
                    {carrega ? (
                      <option value="">carregando...</option>
                    ) : (
                      <>
                        <option value="">...</option>
                        {cargos.map(item => (
                          <option value={item.id}>{item.title}</option>
                        ))}
                      </>
                    )}
                  </select>
                </span>
                <span>
                  <label>
                    <strong>Selecione o Usuário</strong>
                  </label>
                  <select className="user_id">
                    {carregaUser ? (
                      <option value="">carregando...</option>
                    ) : (
                      <>
                        <option value="">...</option>
                        {executor.map(item => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </>
                    )}
                  </select>
                </span>
                <span>
                  <label>Data Início</label>
                  <input type="date" className="date_inicio" />
                </span>
              </BForm>
              <ButtonBtn>
                <button type="submit">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </ButtonBtn>
            </Grid>
          </Form>

          <Tabela>
            <Titulo>Diretor</Titulo>
            <GridDir>
              {users.map(
                user =>
                  user.funcao === 'diretor' && (
                    <div key={user.id} className="grid-4">
                      <span>
                        <button
                          type="button"
                          onClick={() => setDelete(user.id)}
                          title="Deletar"
                        >
                          <MdDelete size={12} color="#fff" />
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            handleClickOpen({
                              cargo: user.cargo_id,
                              id: user.user_id,
                              idexe: user.id,
                            })
                          }
                          title="Editar"
                        >
                          <MdEdit size={12} color="#fff" />
                        </button>
                      </span>
                      <Image>
                        {user.imagem ? (
                          <img
                            src={`${urlFile}${user.imagem}`}
                            alt={user.name}
                          />
                        ) : (
                          <>
                            {user.avatar ? (
                              <img
                                src={`${urlRh}${user.avatar}`}
                                alt={user.name}
                              />
                            ) : (
                              <img src={SemAvatar} alt={user.name} />
                            )}
                          </>
                        )}
                      </Image>
                      <Description>
                        <Icon>
                          <strong>Diretor Responsável</strong>
                        </Icon>
                        <Icon>
                          <MdPerson size={12} color="#000" />
                          {user.name}
                        </Icon>
                        <Icon>
                          <MdPhoneInTalk size={12} color="#000" />
                          Ramal {user.ramal}
                        </Icon>
                        <Icon>
                          <MdEmail size={12} color="#000" />
                          {user.email}
                        </Icon>
                      </Description>
                    </div>
                  )
              )}
            </GridDir>

            <Titulo>Contábil</Titulo>
            <GridExe>
              {users.map(
                user =>
                  user.funcao === 'contabil' && (
                    <div key={user.id} className="grid-4">
                      <span>
                        <button
                          type="button"
                          onClick={() => setDelete(user.id)}
                        >
                          <MdDelete size={12} color="#fff" />
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            handleClickOpen({
                              cargo: user.cargo_id,
                              id: user.user_id,
                              idexe: user.id,
                            })
                          }
                          title="Editar"
                        >
                          <MdEdit size={12} color="#fff" />
                        </button>
                      </span>
                      <Image>
                        {user.imagem ? (
                          <img
                            src={`${urlFile}${user.imagem}`}
                            alt={user.name}
                          />
                        ) : (
                          <>
                            {user.avatar ? (
                              <img
                                src={`${urlRh}${user.avatar}`}
                                alt={user.name}
                              />
                            ) : (
                              <img src={SemAvatar} alt={user.name} />
                            )}
                          </>
                        )}
                      </Image>
                      <Description>
                        <Icon>
                          <strong>
                            {(user.tipo === 'coord' && 'Coordernação') ||
                              (user.tipo === 'supervisao' && 'Supervisão') ||
                              (user.tipo === 'analista' && 'Analista') ||
                              (user.tipo === 'executor' && 'Executor')}
                          </strong>
                        </Icon>
                        <Icon>
                          <MdPerson size={12} color="#000" />
                          {user.name}
                        </Icon>
                        <Icon>
                          <MdPhoneInTalk size={12} color="#000" />
                          Ramal {user.ramal}
                        </Icon>
                        <Icon>
                          <MdEmail size={12} color="#000" />
                          {user.email}
                        </Icon>
                      </Description>
                    </div>
                  )
              )}
            </GridExe>

            <Titulo>Fiscal</Titulo>
            <GridExe>
              {users.map(
                user =>
                  user.funcao === 'fiscal' && (
                    <div key={user.id} className="grid-4">
                      <span>
                        <button
                          type="button"
                          onClick={() => setDelete(user.id)}
                        >
                          <MdDelete size={12} color="#fff" />
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            handleClickOpen({
                              cargo: user.cargo_id,
                              id: user.user_id,
                              idexe: user.id,
                            })
                          }
                          title="Editar"
                        >
                          <MdEdit size={12} color="#fff" />
                        </button>
                      </span>
                      <Image>
                        {user.imagem ? (
                          <img
                            src={`${urlFile}${user.imagem}`}
                            alt={user.name}
                          />
                        ) : (
                          <>
                            {user.avatar ? (
                              <img
                                src={`${urlRh}${user.avatar}`}
                                alt={user.name}
                              />
                            ) : (
                              <img src={SemAvatar} alt={user.name} />
                            )}
                          </>
                        )}
                      </Image>
                      <Description>
                        <Icon>
                          <strong>
                            {(user.tipo === 'coord' && 'Coordernação') ||
                              (user.tipo === 'supervisao' && 'Supervisão') ||
                              (user.tipo === 'analista' && 'Analista') ||
                              (user.tipo === 'executor' && 'Executor')}
                          </strong>
                        </Icon>
                        <Icon>
                          <MdPerson size={12} color="#000" />
                          {user.name}
                        </Icon>
                        <Icon>
                          <MdPhoneInTalk size={12} color="#000" />
                          Ramal {user.ramal}
                        </Icon>
                        <Icon>
                          <MdEmail size={12} color="#000" />
                          {user.email}
                        </Icon>
                      </Description>
                    </div>
                  )
              )}
            </GridExe>

            <Titulo>Pessoal</Titulo>
            <GridExe>
              {users.map(
                user =>
                  user.funcao === 'pessoal' && (
                    <div key={user.id} className="grid-4">
                      <span>
                        <button
                          type="button"
                          onClick={() => setDelete(user.id)}
                        >
                          <MdDelete size={12} color="#fff" />
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            handleClickOpen({
                              cargo: user.cargo_id,
                              id: user.user_id,
                              idexe: user.id,
                            })
                          }
                          title="Editar"
                        >
                          <MdEdit size={12} color="#fff" />
                        </button>
                      </span>
                      <Image>
                        {user.imagem ? (
                          <img
                            src={`${urlFile}${user.imagem}`}
                            alt={user.name}
                          />
                        ) : (
                          <>
                            {user.avatar ? (
                              <img
                                src={`${urlRh}${user.avatar}`}
                                alt={user.name}
                              />
                            ) : (
                              <img src={SemAvatar} alt={user.name} />
                            )}
                          </>
                        )}
                      </Image>
                      <Description>
                        <Icon>
                          <strong>
                            {(user.tipo === 'coord' && 'Coordernação') ||
                              (user.tipo === 'supervisao' && 'Supervisão') ||
                              (user.tipo === 'analista' && 'Analista') ||
                              (user.tipo === 'executor' && 'Executor')}
                          </strong>
                        </Icon>
                        <Icon>
                          <MdPerson size={12} color="#000" />
                          {user.name}
                        </Icon>
                        <Icon>
                          <MdPhoneInTalk size={12} color="#000" />
                          Ramal {user.ramal}
                        </Icon>
                        <Icon>
                          <MdEmail size={12} color="#000" />
                          {user.email}
                        </Icon>
                      </Description>
                    </div>
                  )
              )}
            </GridExe>

            <Titulo>Tributos</Titulo>
            <GridExe>
              {users.map(
                user =>
                  user.funcao === 'tributos' && (
                    <div key={user.id} className="grid-4">
                      <span>
                        <button
                          type="button"
                          onClick={() => setDelete(user.id)}
                        >
                          <MdDelete size={12} color="#fff" />
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          onClick={() =>
                            handleClickOpen({
                              cargo: user.cargo_id,
                              id: user.user_id,
                              idexe: user.id,
                            })
                          }
                          title="Editar"
                        >
                          <MdEdit size={12} color="#fff" />
                        </button>
                      </span>
                      <Image>
                        {user.imagem ? (
                          <img
                            src={`${urlFile}${user.imagem}`}
                            alt={user.name}
                          />
                        ) : (
                          <>
                            {user.avatar ? (
                              <img
                                src={`${urlRh}${user.avatar}`}
                                alt={user.name}
                              />
                            ) : (
                              <img src={SemAvatar} alt={user.name} />
                            )}
                          </>
                        )}
                      </Image>
                      <Description>
                        <Icon>
                          <strong>
                            {(user.tipo === 'coord' && 'Coordernação') ||
                              (user.tipo === 'supervisao' && 'Supervisão') ||
                              (user.tipo === 'analista' && 'Analista') ||
                              (user.tipo === 'executor' && 'Executor')}
                          </strong>
                        </Icon>
                        <Icon>
                          <MdPerson size={12} color="#000" />
                          {user.name}
                        </Icon>
                        <Icon>
                          <MdPhoneInTalk size={12} color="#000" />
                          Ramal {user.ramal}
                        </Icon>
                        <Icon>
                          <MdEmail size={12} color="#000" />
                          {user.email}
                        </Icon>
                      </Description>
                    </div>
                  )
              )}
            </GridExe>
          </Tabela>
        </Grid>

        <Footer />
      </Container>

      <Dialog
        open={edit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Escolha o usuário abaixo.
        </DialogTitle>
        <DialogContent>
          {loadpage ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 30,
              }}
            >
              <CircularProgress size={24} />
            </div>
          ) : (
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">
                Usuários para Selecionar
              </InputLabel>
              <Select
                native
                value={state.id}
                onChange={e => setState({ ...state, id: e.target.value })}
                inputProps={{
                  name: 'user',
                }}
              >
                <option aria-label="None" value="" />
                {userUpdate.map(item => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        {!loadpage && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Salvar
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={del}
        onClose={() => setDelete(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Você tem certeza que deseja deletar?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDelete(0)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDelete(del)} color="primary">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
