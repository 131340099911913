import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Link } from 'react-router-dom';

import { MdExitToApp } from 'react-icons/md';

import api from '~/services/api';
import xml from '~/services/xmlApi';

import { Content, Bloco, Grid, Submenu } from './styles';

import Modal from '~/components/Modal/Dialog';
import Carregando from '~/components/Carregando/MaterialUi/loading';
import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import MenuSub from '~/components/Navigation/Obrigacoes';
import history from '~/services/history';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function Periodica() {
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [, setDelFuncao] = useState(!getStorageFuncao);
  const [periodicas, setPeriodicas] = useState([]);
  const [loadFile, setLoadFile] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadPeriodicas() {
      setLoading(true);
      const response = await api.get('periodicaTabs');

      setLoading(false);
      setPeriodicas(response.data.data);
    }

    loadPeriodicas();
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.xlsx, .xls',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleFile = useCallback(
    async e => {
      setLoadFile(true);

      const dados = new FormData();

      dados.append('file', e.target.files[0]);

      try {
        const response = await xml.post(
          `periodica/import?user_id=${profile.id}`,
          dados
        );

        const { title, tipo, token, created_at } = response.data.tabs;

        const objectPeriodica = {
          title,
          tipo,
          permission: [],
          token,
          user: {
            id: profile.id,
            name: profile.name,
          },
          created_at,
        };

        setPeriodicas([objectPeriodica, ...periodicas]);

        setLoadFile(false);
        setModal(!modal);
        toast.success('Obrigação Periódica Criada com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado..');
        setLoadFile(false);
      }
    },
    [modal, periodicas, profile.id, profile.name]
  );

  const handleSetor = useCallback(async e => {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
  }, []);

  const handleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="obrigacoes-periodicas" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title="Obrigações / Periódicas" />

          <Bloco>
            <div>
              <h2>Obrigações / Periódicas</h2>
            </div>
            <div>
              <Link to="/create/periodica">Criar Planilha</Link>
              <button type="button" onClick={handleModal}>
                Criar Planilha através de importação Excel
              </button>
            </div>
          </Bloco>
        </Content>

        <Grid>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {periodicas.map(item => (
                <Link to={`/periodica/${item.token}`} className="bloco">
                  <small>Planilha Digital</small>
                  <h4>{item.title}</h4>
                  <p>
                    {item.tipo === 'users' && (
                      <>
                        Permissão: (
                        {item.permission.map(
                          permission => `- ${permission.name} -`
                        )}
                        )
                      </>
                    )}

                    {item.tipo === 'public' && <>Permissão: Público</>}

                    {item.tipo === 'eu' && (
                      <>Permissão: Somente item.user.name</>
                    )}

                    {item.tipo === 'cargos' && (
                      <>
                        Permissão: (
                        {item.permission.map(
                          permission => `- ${permission.title} -`
                        )}
                        )
                      </>
                    )}
                  </p>
                  <p>Data da Criação: {item.created_at}</p>
                </Link>
              ))}
              {profile.cargo.subtitle === 'pessoal' && (
                <Link to="/periodica-acordo" className="bloco">
                  <small>Planilha Digital</small>
                  <h4>MP 936 - CONTRATO DE TRABALHO</h4>
                  <p>Permissão: (Setor Pessoal / Diretoria / Setor TI)</p>
                  <p>Data da Criação: abril / 2020</p>
                </Link>
              )}
              {profile.cargo.subtitle === 'diretor' && (
                <Link to="/periodica-acordo" className="bloco">
                  <small>Planilha Digital</small>
                  <h4>MP 936 - CONTRATO DE TRABALHO</h4>
                  <p>Permissão: (Setor Pessoal / Diretoria / Setor TI)</p>
                  <p>Data da Criação: abril / 2020</p>
                </Link>
              )}
            </>
          )}
        </Grid>

        <Footer />
      </Container>

      <Modal
        open={modal}
        width={false}
        title="Importar arquivo em formato de excel para criar obrigação."
        handleCancel={handleModal}
      >
        <div className="container">
          {loadFile ? (
            <Carregando />
          ) : (
            <>
              <div {...getRootProps({ style })}>
                <input
                  {...getInputProps()}
                  multiple={false}
                  onChange={handleFile}
                />
                <p>
                  Arraste e solte algum arquivo aqui ou clique para selecionar
                  um arquivo
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
