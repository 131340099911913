import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { MdSearch, MdDelete, MdControlPoint, MdEdit } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import Title from '~/components/Title';
import Footer from '~/components/Footer';

import User from '~/components/User';

import api from '~/services/api';

import {
  Container,
  Content,
  Bloco,
  Card,
  Pesquisa,
  Pagination,
  Qtd,
  Modal,
  ContentModal,
} from './styles';
import Nav from '~/components/Navigation';

export default function Certidoes() {
  const [tabs, setTabs] = useState([]);
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(null);

  useEffect(() => {
    async function loadTabs() {
      const response = await api.get(`tb/certidoes-negativas`);

      setTabs(response.data.data);
    }

    loadTabs();
  }, []);

  function handleSubmit() {}

  function handleModal(e) {
    if (e) {
      setModal(true);
      setIdDelete(e);
      return;
    }
    setModal(false);
  }

  async function handleDelete(event) {
    try {
      await api.delete(`tb/certidoes-negativas/${event}`);

      setModal(false);
      toast.success('Deletado com sucesso!');
      setTabs(tabs.filter(item => item.id !== event));
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  return (
    <>
      <Nav page="itens" />
      <Container>
        <User />

        <Content>
          <Title title="Itens / Certidões Negativas" />
          <Bloco>
            <div>
              <h2>Tabela</h2>
            </div>
            <Link to="/adicionar/certidoes-negativas">
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSubmit}>
                  <Input name="search" placeholder="Search" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Title</td>
                <td>Cargo</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {tabs.map(item => (
                <tr className="table-hover">
                  <td>{item.title}</td>
                  <td>{item.cargo ? item.cargo.title : ''}</td>
                  <td>
                    <div>
                      <Link to={`/edit/certidoes-negativas/${item.id}`}>
                        <MdEdit size={20} color="#fff" />
                      </Link>
                      <button
                        type="button"
                        onClick={() => handleModal(item.id)}
                      >
                        <MdDelete size={20} color="#fff" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <Qtd>
            <span>Página de:</span>
            <select onChange={() => {}}>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select>
          </Qtd>
        </Pagination>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          <h4>Aviso!</h4>
          <p>Você deseja raelmente deletar?</p>
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModal(null)}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-save"
              onClick={() => handleDelete(idDelete)}
            >
              Sim
            </button>
          </div>
        </ContentModal>
      </Modal>
    </>
  );
}
