import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Negativas({ dados }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.data_protocolo
      ? format(
          parseISO(item.data_protocolo),
          "dd 'de' MMMM' de' yyyy ', às ' HH:mm'h'",
          {
            locale: pt,
          }
        )
      : null,
  }));

  return (
    <Grid on>
      {data.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>Órgão: </strong>
              {item.orgao}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Data limite protocolado pela Secran:</strong>{' '}
              {item.dateFormatted}
            </div>
            <div>
              <strong>Atualizado por: </strong>
              {item.user}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Negativas.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Negativas.defaultProps = {
  dados: [],
};
