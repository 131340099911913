import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  width: 100%;
  display: block;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;
  margin-top: ${Props => (Props.marginTop ? '15px' : 0)};
  align-items: center;

  .contentTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;

      button + button {
        margin-left: 4px;
      }
    }

    span {
      display: flex;
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 2px;
      padding-bottom: 2px;
      border: none;
      align-items: center;
      justify-content: center;
      background: ${Props => (Props.tipo ? '#FF8040' : '#008080')};
      color: #fff;
      font-size: 12px;
    }
  }

  #title {
    font-size: 12px;
  }

  .columtext {
    div {
      width: 100%;
    }
  }

  h4 {
    padding: 4px 8px;
    border: 1px solid #999;
    color: #999;
  }

  .notifybtn {
    background: #00ae57;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
  }

  .notifyedit {
    background: #ff8040;
    color: #fff;
    padding: 4px 8px;
    border-radius: 5px;
    border: none;
  }

  .formtable {
    width: 100%;
    display: flex;
    flex-direction: column;

    div {
      width: 100%;
    }
  }

  .bloco-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: row;

      button + button {
        margin-left: 4px;
      }
    }
  }

  .subprocesso {
    width: auto;
    display: block;
    background: #00b259;
    color: #fff;
    padding: 4px 6px;
  }

  .subprocessoN {
    width: auto;
    display: block;
    background: #007efd;
    color: #fff;
    padding: 4px 6px;
  }

  .btncliente {
    display: flex;
    background: #ffb973;
    color: #000;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 5px;
  }

  .btnprovisorio {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: none;
    background: #007efd;
    color: #fff;

    &:hover {
      background: #0057ae;
    }
  }

  .btnsolicitante {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    border: none;
    background: #f97c00;
    color: #fff;

    &:hover {
      background: #f97c00;
    }
  }

  .carregando {
    display: flex;
    align-items: center;
  }

  .btn-activity {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button + button {
      margin-left: 4px;
    }
  }

  .atualizado {
    display: flex;
    background: #0faf69;
    padding: 6px;
    border-radius: 4px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 6px;

      p {
        font-size: 12px;
        text-align: left;
        color: #fff;
      }

      small {
        font-size: 11px;
        color: #eee;
      }
    }
  }

  .setor {
    display: flex;
    align-items: center;
    background: #999;
    border-radius: 4px;
    padding: 6px 8px;
    color: #fff;
    text-align: center;
  }

  .expirado {
    display: flex;
    align-items: center;
    border: 1px solid #ff4d4d;
    border-radius: 4px;
    padding: 6px 8px;
    color: #000;
    text-align: center;
  }

  .faltaDays {
    display: flex;
    align-items: center;
    border: 1px solid #0faf69;
    border-radius: 4px;
    padding: 6px 8px;
    color: #000;
    text-align: center;
  }

  .sigiloClosed {
    display: flex;
    align-items: center;
    background: #ff8040;
    border-radius: 4px;
    padding: 6px 8px;
    color: #fff;
    text-align: center;
  }

  .sigiloOpen {
    display: flex;
    align-items: center;
    background: #0faf69;
    border-radius: 4px;
    padding: 6px 8px;
    color: #fff;
    text-align: center;
  }

  .usuario {
    display: flex;
    align-items: center;
    background: #379bff;
    border-radius: 4px;
    padding: 6px 8px;
    color: #fff;
  }

  .usuarioVc {
    display: flex;
    align-items: center;
    background: #008080;
    border-radius: 4px;
    padding: 6px 8px;
    color: #fff;
  }

  .successTr {
    color: #0faf69;
  }

  .btn-success {
    background: #0faf69;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#0faf69')};
    }
  }

  .btn-warning {
    background: #ff8040;
    color: #fff;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#FF8040')};
    }
  }

  .alto {
    border: 1px solid #00a6a6;
    padding: 6px 8px;
    border-radius: 4px;
    align-items: center;
  }

  .media {
    border: 1px solid #ff661c;
    padding: 6px 8px;
    border-radius: 4px;
    align-items: center;
  }

  .baixa {
    border: 1px solid #9a9a9a;
    padding: 6px 8px;
    border-radius: 4px;
    align-items: center;
  }

  span {
    display: flex;
    flex-direction: column;

    select {
      margin-top: 5px;
      font-size: 12px;

      option {
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      color: #999;
    }
  }

  .form-text {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding-left: 8px;
  }

  .btn-delete {
    color: #fff;
    background: #ff4d4d;
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    padding: 4px 10px 4px 10px;

    &:hover {
      background: #ff2828;
    }
  }

  #red {
    color: #ff4d4d;
  }
  #green {
    color: #00b259;
  }
  .btn-deletecat {
    border: none;
    background: none;
  }
  .verificao {
    display: flex;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    color: #eee;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
  }
  div {
    display: flex;
    flex-direction: row;
  }
  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }
  .btn-blue {
    background: #00468c;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00468C')};
    }
  }
  .btn-gray {
    background: #999;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#999')};
    }
  }
`;

export const Messenger = styled.div`
  width: 100%;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  position: relative;

  .comments {
    display: flex;
    flex: 1;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    background: #fff;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    span {
      padding: 8px;
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      flex: 1;
      font-size: 12px;

      div {
        border-radius: 6px;
        padding: 6px;
        display: flex;
        margin-top: 0px;
        margin-bottom: 0px;
        flex: 1;
      }

      p {
        font-size: 10px;
        margin-top: 0px;
        margin-top: 4px;
        color: #999;
      }
    }
  }

  h3 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .inputAnexo {
    display: flex;
    flex: 1;
    position: relative;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input[type='file'] {
        display: none;
      }
    }
  }

  input {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    padding: 8px;
    border: 0px;
    margin-bottom: 8px;
  }

  .btn-blue {
    background: #004080;
    border: 0;
    width: 100%;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#004080')};
    }
  }

  .btn-gray {
    background: #999;
    border: 0;
    width: 100%;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#999')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .nivel {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    padding-right: 22px;

    h4 {
      font-size: 14px;
      margin-bottom: 4px;
    }

    select {
      width: 100%;
      height: 42px;
      margin-bottom: 12px;
      border-radius: 6px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .blocodiv {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 12px;
    width: 100%;

    .exportar {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0px;
      background: #00b259;
      color: #fff;
      height: 26px;
      padding-left: 5px;
      padding-right: 5px;

      &:hover {
        background: #008040;
      }

      img {
        width: 14px;
        margin-right: 4px;
      }
    }

    span {
      background: #fff;
      font-size: 14px;
      padding: 4px 10px;
      border-radius: 4px;
    }

    div {
      width: 100%;
      background: ${Props => (Props.demanda ? Props.demanda : '')};
    }
  }

  .form {
    display: flex;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;

    div {
      width: 100%;
    }
  }

  .conteudo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      background: #00b259;
      color: #fff;
      border-radius: 5px;
      margin-right: 22px;
      padding: none;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;

      &:hover {
        background: ${darken(0.03, '#0FAF69')};
      }
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 25px 25px 25px;

  .bloco {
    display: flex;
    margin-top: 14px;
  }

  h2 {
    color: #0059b2;
    font-size: 26px;
    margin-top: 12px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  .bloco {
    display: flex;
    flex: 1;
    flex-direction: row;

    .form-input {
      display: flex;
      flex: 1;

      div {
        width: 100%;
      }
    }
  }

  .bloco-btn {
    display: block;
    padding-left: 8px;
    padding-right: 8px;

    button {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;
      background: #003366;
      border: 0;
      height: 46px;
      color: #fff;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#003366')};
      }
    }
  }

  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .btn-green {
    background: #00b259;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    width: 140px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#00b259')};
    }
  }
  .btn-red {
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }
  .btn-warning {
    background: #ff8040;
    color: #fff;
    border-radius: 5px;
    margin-right: 22px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8040')};
    }
  }
  .btn-blue {
    background: #0f73af;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#0f73af')};
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  ul {
    display: flex;
    list-style: none;
  }

  ul li {
    a {
      border-radius: 6px;
      background: ${Props => (Props.cores ? '#ff7f00' : '#00468c')};
      color: ${Props => (Props.cores ? '#ff7f00' : '#00468c')};
      color: #fff;
      padding: 8px 10px;
      font-size: 12px;

      &:hover {
        background: #ff7f00;
      }
    }
  }

  .bloco-finalizar {
    display: flex;

    button {
      margin-left: 6px;
      border: 0;
      background: #0f73af;
      color: #fff;
      padding-left: 6px;
      padding-right: 6px;

      &:hover {
        opacity: 0.8;
      }
    }

    .encerrado {
      background: #ff4d4d;
      border-radius: 4px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
    }

    .entregue {
      background: #00468c;
      border-radius: 4px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
    }

    .ativo {
      background: #008c69;
      border-radius: 4px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
    }
  }

  .bloco-one {
    display: flex;
    flex-direction: column;

    .divbtn {
      display: flex;
      background: none;
      flex-direction: row;
      justify-content: flex-end;

      .form {
        background: none;
        padding-bottom: 22px;

        div {
          background: none;
        }
      }

      button + button {
        margin-right: none;
        margin-left: 4px;
      }
    }

    li + li {
      margin-left: 4px;
    }

    .ativo {
      background: #ff7f00;
    }

    div {
      background: #fff;
      height: 35px;
      border-radius: 4px;
      padding-left: 6px;
      padding-right: 6px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    p {
      display: flex;
      font-size: 11px;
      align-items: center;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }

  .bloco-search {
    display: flex;
    flex-direction: row;

    select {
      height: 32px;
    }
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        background: #008c69;
        align-items: center;
        font-size: 12px;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;

  .showUsuario {
    display: flex;
    flex-direction: row;

    .usuarioShow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    button {
      padding: 4px 6px;
      border: none;
      background: #008c69;
      color: #fff;
      transition: background-color 0.2s;
      margin-left: 4px;

      &:hover {
        background: ${shade(0.3, '#008c69')};
      }
    }
  }

  .formbtn {
    display: block;
    position: relative;
    width: 100%;

    button {
      position: absolute;
      z-index: 9;
      right: 5px;
      top: 12px;
      border: none;
      background: #05a960;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .form-input {
    display: flex;
    position: relative;
    flex: 1;

    .moreclick {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 30px;
      top: 13px;
      border: none;
      z-index: 999;
      background: #05a960;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      color: #fff;
      font-weight: bold;
      font-size: 20px;

      &:hover {
        background: #006231;
      }
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    input {
      width: 100%;
    }

    select {
      width: 100%;

      option {
        font-size: 14px;
        color: #999;
      }

      .title {
        color: #222222;
      }
    }
  }

  .bloco-conteudo {
    display: block;
    width: 100%;
  }

  .colunaUser {
    display: flex;
    flex-direction: row;
  }

  .usuarioSec {
    display: block;
    margin-top: 12px;

    h4 {
      font-size: 16px;
      margin-bottom: 12px;
    }

    img {
      width: 30px;
      border-radius: 15px;
    }
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    .reaberto {
      display: block;

      span {
        background: #eee;
        padding: 12px 6px;
        border-radius: 4px;
        color: #999;
      }
    }

    h5 {
      margin-bottom: 6px;
      color: #999;
    }

    div {
      display: flex;
      padding-top: 5px;
      align-items: center;

      img {
        width: 40px;
        border-radius: 20px;
      }

      span {
        margin-left: 4px;

        p {
          font-size: 12px;
          margin-bottom: 0px;
        }

        small {
          font-size: 11px;
          color: #999;
        }
      }
    }

    p {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }

  span + span {
    border-top: 1px solid #ddd;
  }

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  .form-input {
    display: flex;
    flex-direction: column;
  }

  .form-input + .form-input {
    margin-left: 8px;
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  max-width: 480px;

  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  div {
    display: flex;
    button + button {
      margin-left: 4px;
    }
  }
  .inputCheck {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 11px;
    color: #ff4d4d;

    input {
      margin-right: 4px;
    }

    input + input {
      margin-left: 6px;
    }
  }
  .viewEmail {
    display: flex;
    flex-direction: column;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      padding-bottom: 4px;
      padding-top: 2px;
      border-bottom: 1px solid #999;
    }
  }

  .inputForm {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;

    form {
      display: flex;
      flex: 1;
    }

    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }

    .notify {
      background: #0075ea;
      color: #fff;
    }

    .white {
      background: #eee;
      color: #000;
    }

    select {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;

      option {
        font-size: 12px;
      }
    }
  }
  .vencimento {
    font-size: 32px;
    background: #eee;
    padding: 12px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  input[type='checkbox'] {
    height: 18px;
    width: 18px;
    margin-left: 12px;
  }

  p {
    font-size: 12px;
    margin-left: 4px;
    color: #000;
  }
`;

export const Pagination = styled.div`
  display: block;
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
  }

  select {
    border: 0;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
  }
`;

export const PageVer = styled.div`
  display: flex;
  align-items: center;

  form span {
    font-size: 12px;
    margin-right: 6px;
  }

  input {
    border: 0;
    width: 45px;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 8px;
  }

  button {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    border-radius: 6px;
    background: #00b259;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }

  button + button {
    margin-left: 6px;
  }

  .hidden {
    display: none;
  }

  button.desativa {
    background: #999;
  }
`;

export const FormInter = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;

  .interacao {
    border: none;
    height: 18px;
    width: 18px;
    position: absolute;
    padding: none;
    background: none;
    z-index: 6;
    top: 0px;
    right: 0px;
  }

  div {
    display: flex;
    flex: 1;
  }

  div + div {
    margin-top: 10px;
    padding-top: 8px;
    border-top: 1px dotted #ccc;
  }
`;

export const Perfil = styled.div`
  display: flex;
  align-items: center;

  input {
    font-size: 12px;
  }

  .mensagem {
    background: #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-right: 40px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: #eee;
    margin-right: 6px;
  }

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const Encerrado = styled.div`
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
`;

export const FormList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;

  h4 {
    margin-top: 12px;
  }

  .orange {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    background: #003f7f;
    color: #fff;
    margin-top: 5px;

    &:hover {
      opacity: 0.6;
    }
  }

  button {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    background: #00b259;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const AddSub = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    background: #00b259;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const SearchDate = styled.div`
  display: flex;
  padding-right: 20px;
  margin-top: 12px;

  .bloco {
    display: flex;
    flex-direction: row;

    .form + .form {
      margin-left: 4px;
    }
  }

  .form {
    display: flex;
    font-size: 12px;
    align-items: center;

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
    }
  }
`;

export const Grid = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  background: ${Props => (Props.active ? '#f3f3f3' : '#fff')};
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  border: none;
  transition: transform 0.2s;

  &:hover {
    transform: translateX(10px);
  }

  .total {
    display: block;
    text-align: left;

    .percentage {
      font-size: 18px;
      font-weight: bold;

      .red {
        color: #ff3e3e;
      }

      .black {
        color: #222222;
      }

      .green {
        color: #00b285;
      }

      .orange {
        color: #ff7f00;
      }

      .blue {
        color: #00468c;
      }
    }

    p {
      font-size: 12px;
    }

    span {
      font-size: 34px;
      font-weight: bold;
    }
  }

  .blue {
    color: #00468c;
  }

  .green {
    color: #36d900;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff3e3e;
  }
`;

export const BtnSave = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 12px;
  flex: 1;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;

  button {
    background: ${Props => (Props.loading ? '#ccc' : '#008c69')};
    color: #fff;
    height: 48px;
    border-radius: 8px;
    font-size: 18px;
    border: none;

    &:hover {
      background: ${Props =>
        Props.loading ? '#ccc' : `${darken(0.06, '#008c69')}`};
    }
  }
`;

export const Files = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;
