import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 22px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a + a {
    background: #b22d00;
    margin-left: 10px;
  }

  a:hover {
    opacity: 0.5;
  }

  .btn {
    display: flex;
    align-items: center;
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 5px;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const Grid = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px;
  background: #fff;
  display: block;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .loading {
    display: flex;
    flex-direction: row;
  }
`;
