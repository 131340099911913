import React, { useEffect, useState, useCallback } from 'react';

import pt from 'date-fns/locale/pt';
import { setMonth, format, setYear } from 'date-fns';

import { IoMdArrowDropright, IoMdArrowDropleft } from 'react-icons/io';

import { Container, Head, Calendar } from './styles';

const CalendarMonth = ({ handleClick, valueMonth, valueYear }) => {
  const [months, setMonths] = useState([]);
  const [year, setAddYear] = useState(Number(format(new Date(), 'yyyy')));
  const [modifidYearValue, setModifidYearValue] = useState(Number(valueYear));

  useEffect(() => {
    const monthFormatted = [];

    for (let i = 0; i < 12; i++) {
      const month = format(setMonth(new Date(), i), 'MMMM', {
        locale: pt,
      });
      const object = {
        name: month,
        id: i,
      };
      monthFormatted.push(object);
    }

    setMonths(monthFormatted);
  }, []);

  const handlePrevios = useCallback(() => {
    const countYear = modifidYearValue ? modifidYearValue + 1 : year - 1;
    const yearAdd = format(setYear(new Date(), countYear), 'yyyy');

    setModifidYearValue(0);
    setAddYear(Number(yearAdd));
  }, [modifidYearValue, year]);

  const handleNext = useCallback(() => {
    const countYear = modifidYearValue ? modifidYearValue + 1 : year + 1;
    const yearAdd = format(setYear(new Date(), countYear), 'yyyy');

    setModifidYearValue(0);
    setAddYear(Number(yearAdd));
  }, [modifidYearValue, year]);

  return (
    <Container>
      <Head>
        <button type="button" onClick={handlePrevios}>
          <IoMdArrowDropleft size={15} color="#fff" />
        </button>
        Calendário Ano {modifidYearValue || year}
        <button type="button" onClick={handleNext}>
          <IoMdArrowDropright size={15} color="#fff" />
        </button>
      </Head>
      <Calendar>
        {months.map(month => (
          <button
            type="button"
            key={month.id}
            className={`grid ${valueMonth - 1 === month.id ? 'active' : ''}`}
            onClick={() =>
              handleClick({
                month: month.id,
                year,
              })
            }
          >
            {month.name}
          </button>
        ))}
      </Calendar>
    </Container>
  );
};

export default CalendarMonth;
