import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Atos({ dados }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.data_regi
      ? format(parseISO(item.data_regi), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid>
      {data.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>Ato: </strong> {item.atos}
            </div>
            <div>
              <strong>Data Registro: </strong> {item.dateFormatted}
            </div>
            <div>
              <strong>Órgão de Registro: </strong>
              {item.orgao_regi}
            </div>
            <div>
              <strong>Resumo: </strong>
              {item.resumo}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Atos.propTypes = {
  dados: PropTypes.array, // eslint-disable-line
};

Atos.defaultProps = {
  dados: [],
};
