import React, { useContext, useState, useCallback, createContext } from 'react';
import { addMonths, subMonths, format } from 'date-fns';

const CalendarContext = createContext({});

export const useCalendar = () => useContext(CalendarContext);

export const CalendarProvider = ({ children, events, weekStartsOn }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [getData, setGetData] = useState({
    title: '',
    date: '',
    open: false,
    info: [],
  });

  const prevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const nextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const handleOpen = useCallback(event => {
    const title = `Dia do calendário: ${format(
      new Date(event.date),
      'dd-MM-yyy'
    )}`;

    setGetData({
      title,
      open: true,
      info: event.data,
    });
  }, []);

  const handleClose = useCallback(() => {
    setGetData({
      title: '',
      open: false,
      info: [],
    });
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        events,
        weekStartsOn,
        currentMonth,
        setCurrentMonth,
        selectedDate,
        setSelectedDate,
        prevMonth,
        nextMonth,
        handleOpen,
        handleClose,
        getData,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
