import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  color: #00468c;
  padding-left: 6px;
  padding-right: 6px;

  button {
    border: 0;
    background: #00468c;
    padding-left: 3px;
    padding-right: 3px;
    align-items: center;

    &:hover {
      background: ${shade(0.2, '#00468c')};
    }
  }
`;

export const Calendar = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #fff;
  padding: 10px;

  .grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #eeeeee;
    border: 1px solid #fff;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: #00468c;
      color: #fff;
    }
  }

  .active {
    background: #00468c;
    color: #fff;
  }
`;
