import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function AtosSocietarios() {
  const { idCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [cliente, setCliente] = useState('');
  const [atoss, setAtos] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/atos-societarios`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setAtos(percorre);
    });

    api.get('tb/geral?tb=reg_nire').then(response => {
      setTabs(response.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  async function handleSubmit({ valoratos, data_regi, orgao_regi, resumo }) {
    if (!valoratos) {
      toast.warning('Por favor! Preencha o campo Ato');
      return;
    }

    if (!orgao_regi) {
      toast.warning('Por favor! Preencha o campo Órgão de Registro');
      return;
    }

    setLoading(true);

    try {
      const { data } = await api.post(
        `clientes/${idCliente}/atos-societarios`,
        {
          atos: valoratos,
          data_regi,
          orgao_regi,
          resumo,
        }
      );

      document.getElementById('form_reset').reset();
      setLoading(false);
      toast.success('Ato Societário cadastrado com sucesso.');

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(atoss);

      setAtos(
        dadosArray.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.updated_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }))
      );
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro no cadastro.');
    }
  }

  async function handleUpdate({ id, atos, data_regi, orgao_regi, resumo }) {
    try {
      await api.put(`clientes/${idCliente}/atos-societarios?id=${id}`, {
        atos,
        data_regi,
        orgao_regi,
        resumo,
      });

      toast.success('Ato atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`delete/${idCliente}/atos-societarios?id=${id}`);

      toast.success('Ato deletado com sucesso.');
      setAtos(atoss.filter(item => item.id !== id));
    } catch (err) {
      toast.error('Algo deu errado no delete');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="atos-societarios"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Ato *</label>
                <Input type="text" name="valoratos" />
              </span>
              <span>
                <label>Data Registro</label>
                <Input type="date" name="data_regi" />
              </span>
              <span>
                <label>Órgão de Registro *</label>
                <Select name="orgao_regi" options={tabs} />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Resumo</label>
                <Input type="text" name="resumo" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {atoss.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Ato</label>
                  <Input type="text" name="atos" />
                </span>
                <span>
                  <label>Data Registro</label>
                  <Input type="date" name="data_regi" />
                </span>
                <span>
                  <label>Órgão de Registro</label>
                  <Select name="orgao_regi" options={tabs} />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Resumo</label>
                  <Input type="text" name="resumo" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
