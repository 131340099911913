import React, { useState, useEffect } from 'react';

// import OneSignal from 'react-onesignal';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import pt from 'date-fns/locale/pt-BR';
import { parseISO, formatRelative, format, addDays } from 'date-fns';

import { MdAdd, MdRemove } from 'react-icons/md';
import InputMask from 'react-input-mask';
import api from '~/services/api';
import history from '~/services/history';

import {
  Content,
  Contagem,
  Conteudo,
  Bloco,
  Title,
  Total,
  Icon,
  Grid,
  Titulo,
  ContentGrid,
  TotalClientes,
} from './styles';

import Container from '~/components/Container';

import Calendar from '~/components/Calendar';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Users from './UsersOnline';
import Footer from '~/components/Footer';
import Skeleton from '~/components/Carregando/Skeleton/Div';

import Comments from './Comments';
import Notifications from './Notifications';

import IconCoffe from '~/assets/icons/dashboard/coffee-cup.svg';

import { signOut } from '../../store/modules/auth/actions';
import { updateProfilePageRequest } from '~/store/modules/user/actions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 12,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const id = profile ? profile.id : null;
  const dispatch = useDispatch();

  const [contagem, setContagem] = useState('');
  const [loading, setLoading] = useState(true);

  const [loadCliente, setLoadCliente] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [clientesRes, setClientesRes] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const dateYear = format(new Date(), 'yyy');

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     OneSignal.initialize('9445096a-842e-4493-b344-db94cd6626a3');

  //     OneSignal.sendTag('email', profile.email);
  //   }
  // }, [profile]);

  useEffect(() => {
    async function loadObrigacoes() {
      const calendarHolidays = await api.get(`calendar/holiday`);
      const responseBirthdays = await api.get('birthday/users');

      const obrigacoesCalendar = await api.get('calendar/obrigacoes');

      const percorredateday = calendarHolidays.data.map(item => ({
        ...item,
        date: parseISO(item.date),
      }));

      const percorreBirthdays = responseBirthdays.data.map(item => ({
        ...item,
        date: addDays(new Date(item.date), 1),
      }));

      const perccoreObrigacoesCalendar = obrigacoesCalendar.data.map(item => ({
        ...item,
        date: addDays(new Date(item.date), 1),
      }));

      setCalendar([
        ...percorredateday,
        ...perccoreObrigacoesCalendar,
        ...percorreBirthdays,
      ]);
    }

    loadObrigacoes();
  }, [dateYear, profile.email, profile.role.title]);

  useEffect(() => {
    api.get(`users/${id}`).then(response => {
      dispatch(updateProfilePageRequest(response.data));

      const user = response.data;

      if (!user) {
        dispatch(signOut());
      }

      if (user.status === 'Inativo') {
        dispatch(signOut());
      }
    });

    api.get('contagens').then(response => {
      setContagem(response.data);
      setLoading(false);
    });

    api.get(`home/cliente?status=ativo`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.created_at), new Date(), {
          locale: pt,
        }),
        data_servico: item.data_servico
          ? format(parseISO(item.data_servico), 'dd-MM-yyy')
          : null,
      }));
      setClientes(percorre);
    });

    api.get(`home/cliente?status=Rescindido`).then(response => {
      const rescindidoRes = response.data.data.map(item => ({
        ...item,
        dateFormatted: formatRelative(parseISO(item.updated_at), new Date(), {
          locale: pt,
        }),
        data_servico: item.data_servico
          ? format(parseISO(item.data_servico), 'dd-MM-yyy')
          : null,
      }));

      setLoadCliente(false);
      setClientesRes(rescindidoRes);
    });
  }, [dispatch, id]);

  return (
    <>
      <Nav page="dashboard" />
      <Container>
        <User />

        <Content>
          {profile && !profile.birthday && (
            <div className={classes.root}>
              <Alert
                variant="filled"
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => history.push('/profile')}
                  >
                    Clique aqui
                  </Button>
                }
              >
                Por favor! Preencha a sua data de aniversário no profile.
              </Alert>
            </div>
          )}
          {profile && !profile.ramal && (
            <div className={classes.root}>
              <Alert
                variant="filled"
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => history.push('/profile')}
                  >
                    Clique aqui
                  </Button>
                }
              >
                Por favor! Preencha o seu ramal no profile.
              </Alert>
            </div>
          )}

          <TotalClientes>
            Total de {contagem.total || 0} Clientes.
          </TotalClientes>
          <Contagem>
            <Bloco className="bg-blue">
              <span>
                <Title>MATRIZ</Title>
                <Total>
                  {loading ? (
                    <Link to="/clientes?limit=10&tipo=matriz&page=1&status=">
                      0
                    </Link>
                  ) : (
                    <>
                      <Link to="/clientes?limit=10&tipo=matriz&page=1&status=">
                        {contagem.matriz}
                      </Link>
                    </>
                  )}
                </Total>
              </span>
              <Icon>
                <img src={IconCoffe} alt="Clientes Matriz" />
              </Icon>
            </Bloco>
            <Bloco>
              <span>
                <Title>FILIAL</Title>
                <Total>
                  {loading ? (
                    <Link to="/clientes?limit=10&tipo=filial&page=1&status=">
                      0
                    </Link>
                  ) : (
                    <>
                      <Link to="/clientes?limit=10&tipo=filial&page=1&status=">
                        {contagem.filiais}
                      </Link>
                    </>
                  )}
                </Total>
              </span>
              <Icon>
                <img src={IconCoffe} alt="Clientes Filial" />
              </Icon>
            </Bloco>
            <Bloco className="bg-blue">
              <span>
                <Title>SCP</Title>
                <Total>
                  {loading ? (
                    <Link to="/clientes?limit=10&tipo=scp&page=1&status=">
                      0
                    </Link>
                  ) : (
                    <>
                      <Link to="/clientes?limit=10&tipo=scp&page=1&status=">
                        {contagem.scp}
                      </Link>
                    </>
                  )}
                </Total>
              </span>
              <Icon>
                <img src={IconCoffe} alt="Clientes Scp" />
              </Icon>
            </Bloco>
            <Bloco>
              <span>
                <Title>CPF</Title>
                <Total>
                  {loading ? (
                    <Link to="/clientes?limit=10&tipo=cpf&page=1&status=">
                      0
                    </Link>
                  ) : (
                    <>
                      <Link to="/clientes?limit=10&tipo=cpf&page=1&status=">
                        {contagem.cpf}
                      </Link>
                    </>
                  )}
                </Total>
              </span>
              <Icon>
                <img src={IconCoffe} alt="Clientes CPF" />
              </Icon>
            </Bloco>
            <Bloco className="bg-blue">
              <span>
                <Title>DOMÉSTICA</Title>
                <Total>
                  {loading ? (
                    <Link to="/clientes?limit=10&tipo=domestica&page=1&status=">
                      0
                    </Link>
                  ) : (
                    <>
                      <Link to="/clientes?limit=10&tipo=domestica&page=1&status=">
                        {contagem.domestica}
                      </Link>
                    </>
                  )}
                </Total>
              </span>
              <Icon>
                <img src={IconCoffe} alt="Clientes Doméstica" />
              </Icon>
            </Bloco>
          </Contagem>
        </Content>

        <Content>
          <Users />
        </Content>

        <Content>
          <Notifications />
        </Content>

        <Content>
          <Conteudo>
            <Grid>
              <Titulo className="blue">
                <MdAdd size={16} color="#fff" /> Novos Clientes
              </Titulo>
              <ContentGrid>
                {loadCliente ? (
                  <Skeleton number={8} />
                ) : (
                  <>
                    {clientes.map(item => (
                      <Link
                        key={item.id}
                        to={`/cliente/${item.cnpj}`}
                        className="bloco"
                      >
                        <div className="perfil">
                          <div>
                            <strong>{item.razao}</strong>
                            <span className="subtitle">
                              CNPJ:{' '}
                              {item.cnpj.length === 14 ? (
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  value={item.cnpj.replace('x', '1')}
                                  disabled
                                />
                              ) : (
                                <InputMask
                                  mask="99.999.999-99"
                                  value={item.cnpj}
                                  disabled
                                />
                              )}
                              <br />
                              por {item.user.name} / Atualizado:{' '}
                              {item.dateFormatted}
                            </span>
                          </div>
                        </div>
                        <div className="date_service">
                          Data início: {item.data_servico}
                        </div>
                      </Link>
                    ))}
                  </>
                )}
                <Link to="/relatorio/clientes">Veja mais</Link>
              </ContentGrid>
            </Grid>

            <Grid>
              <Titulo className="red">
                <MdRemove size={16} color="#fff" /> Clientes Rescindidos /
                Suspenso
              </Titulo>
              <ContentGrid>
                {loadCliente ? (
                  <Skeleton number={8} />
                ) : (
                  <>
                    {clientesRes.map(item => (
                      <Link
                        key={item.id}
                        to={`/cliente/${item.cnpj}`}
                        className="bloco"
                      >
                        <div className="perfil">
                          <div>
                            <strong>{item.razao}</strong>
                            <span className="subtitle">
                              CNPJ:{' '}
                              {item.cnpj.length === 14 ? (
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  value={item.cnpj.replace('x', '1')}
                                  disabled
                                />
                              ) : (
                                <InputMask
                                  mask="99.999.999-99"
                                  value={item.cnpj}
                                  disabled
                                />
                              )}
                              <br />
                              por {item.user.name} / Atualizado:{' '}
                              {item.dateFormatted}
                            </span>
                          </div>
                        </div>
                        <div className="date_service_red">
                          Data início: {item.data_servico}
                        </div>
                      </Link>
                    ))}
                  </>
                )}
                <Link to="/relatorio/clientes?status=Rescindido">
                  Veja mais
                </Link>
              </ContentGrid>
            </Grid>
          </Conteudo>
          <Conteudo>
            <Comments />

            <Calendar events={calendar} />
          </Conteudo>
        </Content>

        <Footer />
      </Container>
    </>
  );
}
