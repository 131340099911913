import React, { useState, useEffect, useCallback } from 'react';

import { Link, useParams } from 'react-router-dom';
import { MdSearch, MdRemoveRedEye, MdControlPoint } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando';
import Pagination from '~/components/Pagination';

import User from '~/components/User';
import api from '~/services/api';
import history from '~/services/history';

import { Content, Bloco, Card, Pesquisa } from './styles';
import Nav from '~/components/Navigation';

export default function Clientes() {
  const { id } = useParams();
  const [clientes, setClientes] = useState([]);
  const [matriz, setMatriz] = useState('');
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitView, setLimitView] = useState(10);
  const [pageView, setPageView] = useState(1);
  const [statusView, setStatusView] = useState('');
  const url = new URL(window.location);

  useEffect(() => {
    async function loadClientes() {
      const params = new URLSearchParams(url.search);
      const limit = params.get('limit') || 10;
      const page = params.get('page') || 1;
      const status = params.get('status') || '';

      const matrizCliente = localStorage.getItem('matriz_name');

      if (matrizCliente) {
        setMatriz(JSON.parse(matrizCliente));
      } else {
        const resMatriz = await api.get(`filial/${id}/clientes/show`);
        localStorage.setItem(
          'matriz_name',
          JSON.stringify(resMatriz.data.razao)
        );
      }

      const response = await api.get(
        `filial/${id}/clientes?limit=${limit}&page=${page}&status=${status}`
      );

      setLoading(false);

      setLimitView(limit);
      setPageView(Number(page));
      setStatusView(status);

      setClientes(response.data.data);
      setTotal(response.data.last_page);
    }

    loadClientes();
  }, [id, url.search]);

  const handleLimit = useCallback(
    e => {
      const limit = e.target.value;

      setLimitView(limit);
      setPageView(1);
      history.push(
        `/filial/${id}/clientes?limit=${limit}&page=1&status=${statusView}`
      );
    },
    [id, statusView]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        const ptotal = pageView === 1 ? 1 : pageView - 1;
        setPageView(ptotal);
        setLimitView(limitView);
        history.push(
          `/filial/${id}/clientes?limit=${limitView}&page=${ptotal}&status=${statusView}`
        );
        return;
      }
      const ptotal = Number(pageView) + 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(
        `/filial/${id}/clientes?limit=${limitView}&page=${ptotal}&status=${statusView}`
      );
    },
    [id, limitView, pageView, statusView]
  );

  const handlePageSelect = useCallback(
    ({ page }) => {
      setPageView(page);
      setLimitView(limitView);
      history.push(
        `/filial/${id}/clientes?limit=${limitView}&page=${page}&status=${statusView}`
      );
    },
    [id, limitView, statusView]
  );

  const handleStatus = useCallback(
    e => {
      const status = e.target.value;
      setStatusView(status);
      history.push(
        `/filial/${id}/clientes?limit=${limitView}&page=${pageView}&status=${status}`
      );
    },
    [id, limitView, pageView]
  );

  function handleSearch() {}

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title={`Clientes/${matriz}`} />
          <Bloco>
            <div>
              <h2>{matriz} / Filiais</h2>
            </div>
            <Link to={`/adicionar/filial/${id}`}>
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <select onChange={handleStatus}>
                <option value="">Todos</option>
                <option
                  value="ativo"
                  selected={statusView === 'ativo' || false}
                >
                  Ativos
                </option>
                <option
                  value="suspenso"
                  selected={statusView === 'suspenso' || false}
                >
                  Suspensos
                </option>
                <option
                  value="rescindido"
                  selected={statusView === 'rescindido' || false}
                >
                  Rescindidos
                </option>
              </select>

              <div className="search">
                <span>Pesquisar por:</span>
                <select onChange={() => {}}>
                  <option value="">Razão Social</option>
                  <option value="">Nome Fantasia</option>
                  <option value="">CNPJ</option>
                  <option value="">Inscrição Municipal</option>
                  <option value="">Inscrição Estadual</option>
                </select>
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Pesquisar" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Razão Social</td>
                <td>Unidade</td>
                <td>CNPJ</td>
                <td>CNAE Principal</td>
                <td>Cidade/UF</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {clientes.map(item => (
                    <>
                      <tr key={item.id} className="table-hover">
                        <td>{item.razao}</td>
                        <td>{item.tipo}</td>
                        <td>{item.cnpj}</td>
                        <td>{item.cnae}</td>
                        <td>
                          {item.cidade}/{item.state}
                        </td>
                        <td>
                          <div>
                            <Link
                              to={`/cliente/${item.cnpj}`}
                              alt="Visualizar"
                              title="Visualizar"
                            >
                              <MdRemoveRedEye size={20} color="#fff" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limitView}
          page={pageView}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Footer />
      </Container>
    </>
  );
}
