import React, { useState, useEffect, useCallback } from 'react';

import { MdClose } from 'react-icons/md';

import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import CheckboxGroup from 'react-checkbox-group';

import {
  format,
  setDate,
  isAfter,
  addMonths,
  addYears,
  addDays,
} from 'date-fns';

import { toast } from 'react-toastify';

import {
  Content,
  Bloco,
  Card,
  BForm,
  Pesquisa,
  Modal,
  ContentModal,
  CheckboxDiv,
  BtnSave,
} from './styles';

import Container from '~/components/Container';
import ModalDialog from '~/components/Modal/Dialog';
import Autocomplete from '~/components/FormElement/AutoloadInput';
import Input from '~/components/FormElement/Input';
import Select from '~/components/FormElement/Select';
import Button from '~/components/FormElement/Button';

import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import history from '~/services/history';
import LoadingButton from '~/components/Carregando/MaterialUi/button';

import api from '~/services/api';

const dadosSetor = [
  { id: 'diretor', title: 'Diretoria' },
  { id: 'paralegal', title: 'Paralegal' },
  { id: 'contabil', title: 'Contábil' },
  { id: 'fiscal', title: 'Fiscal' },
  { id: 'tributos', title: 'Tributos' },
  { id: 'pessoal', title: 'Pessoal' },
  { id: 'consultoria', title: 'Consultoria' },
  { id: 'ti', title: 'Tecnologia da Informação' },
];

export default function Create() {
  const profile = useSelector(state => state.user.profile);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [, setLoadingUser] = useState(false);
  const [, setLoadBtn] = useState(false);
  const [loadContent, setLoadContent] = useState(false);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkSeg, setCheckSeg] = useState(false);
  const [loadActivity, setLoadActivity] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [activity, setActivity] = useState([]);
  const [trueActivity, setTrueActivity] = useState([]);
  const [check, setCheck] = useState([]);
  const [seguidores, setSeguidores] = useState([]);
  const loading = open && clientes.length === 0;
  const [state, setState] = useState({
    provisorio: false,
    solicitante: false,
    typeuser: false,
    typewrite: false,
  });

  const [setor, setSetor] = useState('');
  const [valUser, setValUser] = useState('');
  const [valActivity, setValActivity] = useState('');
  const [valNivel, setNivel] = useState('');
  const [checkBox, setCheckBox] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [date, setDateAtual] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    closed: false,
    notify: '',
    prazoday: false,
  });

  useEffect(() => {
    if (!loading) {
      return;
    }
    async function loadClientes() {
      const response = await api.get(`/simples-clientes`);

      setClientes(response.data);
    }

    loadClientes();
  }, [loading]);

  const handleSubmit = useCallback(async () => {
    setLoadBtn(true);
    const user = state.typeuser
      ? users.find(
          item => item.name === document.querySelector('#userauto').value
        ).id
      : valUser;

    const cliente_id = document.querySelector('#cliente').value;
    const clienteProvisorio = document.querySelector('#clienteProvisorio')
      .value;
    const dateVerify = document.querySelector('#date').value;
    const messeger = document.querySelector('#messeger').value;

    const idCliente = clientes.find(elem => elem.razao === cliente_id);

    if (!idCliente && !clienteProvisorio) {
      toast.error('Por favor! Preencha todos os dados');
      setLoadBtn(false);
      return;
    }

    if (
      !user ||
      !dateVerify ||
      !messeger ||
      !valActivity ||
      !setor ||
      !valNivel
    ) {
      toast.error('Por favor! Preencha todos os dados');
      setLoadBtn(false);
      return;
    }

    const dados = {
      cliente_id: idCliente ? idCliente.id : null,
      cliente_sec: clienteProvisorio || null,
      usuario: state.solicitante ? profile.id : user,
      usuario_write: state.solicitante ? user : '',
      date: dateVerify,
      messeger,
      activity: valActivity,
      setor,
      nivel: valNivel,
      subcat: check,
      seguidores,
      sigilo: checkBox,
      repeat: date.closed,
    };

    try {
      setLoadingTicket(true);
      await api.post('ticket', dados);

      setLoadBtn(false);
      setLoadContent(!loadContent);
      setLoadingTicket(false);
      toast.success('Ticket atualizado com sucesso.');

      history.push('/tickets?btn=true');
    } catch (err) {
      toast.error('Algo deu errado!');
      setLoadingTicket(false);
      setLoadBtn(false);
    }
  }, [
    check,
    checkBox,
    clientes,
    date.closed,
    loadContent,
    profile.id,
    seguidores,
    setor,
    state.solicitante,
    state.typeuser,
    users,
    valActivity,
    valNivel,
    valUser,
  ]);

  const handleUser = useCallback(
    async e => {
      setSetor(e.target.value);
      if (!state.solicitante) {
        const res = await api.get(`users?funcao=${e.target.value}`);

        const percorreUser = res.data.map(item => ({
          ...item,
          title: item.name,
        }));

        setUsers(percorreUser);
      }

      const tipo = await api.get(
        `tb/geral?tb=demanda&funcao=${e.target.value}`
      );

      setActivity(tipo.data);
      setLoadingUser(false);
    },
    [state.solicitante]
  );

  const handleModal = useCallback(
    async e => {
      if (e.tipo === 'sub') {
        setValActivity(e.value);

        const findTabela = activity.find(item => item.id === Number(e.value));

        const comandoParseFindActivity = JSON.parse(findTabela.comando);
        const numberPrazoDay = comandoParseFindActivity.prazodays;

        if (numberPrazoDay) {
          const dateformatted = format(
            addDays(new Date(), Number(numberPrazoDay)),
            'yyyy-MM-dd'
          );
          setDateAtual({
            ...date,
            date: dateformatted,
            closed: true,
            prazoday: true,
          });
        }

        const dadosTabela = await api.get(`tb/geral/${e.value}?tb_name=ticket`);

        const { comando } = dadosTabela.data;

        const toString = comando.id.toString();

        if (!comando.notify && !numberPrazoDay) {
          setDateAtual({
            date: format(new Date(), 'yyyy-MM-dd'),
            closed: false,
            notify: '',
          });
        }

        if (comando.notify === 'mensal' && !numberPrazoDay) {
          const datecomando = setDate(new Date(), Number(comando.day));
          const verifydate = isAfter(new Date(datecomando), new Date());
          const dateFinaly = verifydate
            ? format(new Date(datecomando), 'yyyy-MM-dd')
            : format(addMonths(new Date(datecomando), 1), 'yyyy-MM-dd');
          setDateAtual({
            date: dateFinaly,
            closed: true,
            notify: comando.notify,
          });
        }

        if (comando.notify === 'anual' && !numberPrazoDay) {
          const datemes = addMonths(new Date(), Number(comando.month));
          const dateAddDay = setDate(new Date(datemes), Number(comando.day));
          const verifyDate = isAfter(new Date(dateAddDay), new Date());
          const dateFinaly = verifyDate
            ? format(new Date(dateAddDay), 'yyyy-MM-dd')
            : format(addYears(new Date(dateAddDay), 1), 'yyyy-MM-dd');
          setDateAtual({
            date: dateFinaly,
            closed: true,
            notify: comando.notify,
          });
        }

        const trueRes = await api.get(
          `tb/geral?tb=ticket&status=true&whereIn=${toString}`
        );

        if (trueRes.data.length > 0) {
          setTrueActivity(trueRes.data);
          setModal(true);
          return;
        }

        setCheck([]);
        setTrueActivity([]);
        setModal(false);
        return;
      }

      if (e.tipo === 'seguidores') {
        setCheckSeg(true);
        setModal(true);

        return;
      }

      if (e.tipo === 'fechar') {
        setCheck([]);
        setCheckSeg(false);
        setModal(false);
        return;
      }

      setCheckSeg(false);
      setModal(false);
    },
    [activity, date]
  );

  const handleSeguidores = useCallback(
    async e => {
      const email = document.querySelector('.email').value;

      const usuario = email.substring(0, email.indexOf('@'));
      const dominio = email.substring(email.indexOf('@') + 1, email.length);

      const verificaEmail = seguidores.find(item => item === email);

      if (verificaEmail) {
        toast.error('Ops! Email já existe');
        return;
      }

      if (
        usuario.length >= 1 &&
        dominio.length >= 3 &&
        usuario.search('@') === -1 &&
        dominio.search('@') === -1 &&
        usuario.search(' ') === -1 &&
        dominio.search(' ') === -1 &&
        dominio.search('.') !== -1 &&
        dominio.indexOf('.') >= 1 &&
        dominio.lastIndexOf('.') < dominio.length - 1
      ) {
        const object = { email };

        const convert = Object.values(object);

        const valor = seguidores.concat(convert);

        setSeguidores(valor);

        document.getElementById('form_reset').reset();

        return;
      }

      if (e.tipo === 'remove') {
        setSeguidores(seguidores.filter(elem => elem !== e.email));
        return;
      }

      toast.error('Ops! Email não corresponde!');
    },
    [seguidores]
  );

  const handleGetUsers = useCallback(async () => {
    const response = await api.get(`/users?limit=1000`);

    setUsers(response.data.data);
  }, []);

  const handleActivity = useCallback(async () => {
    const title = document.querySelector(`#title`).value;

    if (!title) {
      toast.warning('Preencha o campo atividade');
      return;
    }

    setLoadActivity(true);
    try {
      const { data } = await api.post('tb/geral', {
        title,
        tb_name: 'ticket',
        comando: true,
        idSub: true,
        type: setor,
        notify: '',
        month: '',
        day: '',
        daynotify: '',
        prazodays: '',
      });

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = activity.concat(convert);

      setActivity(dadosArray);
      setOpenActivity(false);
      setLoadActivity(false);
      toast.success('Atividade atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado no servidor');
      setOpenActivity(false);
      setLoadActivity(false);
    }
  }, [activity, setor]);

  const handleState = useCallback(
    event => {
      if (event === 'solicitante') {
        setState({
          ...state,
          solicitante: !state.solicitante,
          typeuser: false,
          typewrite: false,
        });
        setValUser('');
        setSetor('');
        setUsers([]);
        return;
      }

      setState({ ...state, provisorio: !state.provisorio });
      document.getElementById('form_reset').reset();
    },
    [state]
  );

  const handleNivel = useCallback(
    event => {
      const { value } = event.target;
      setNivel(value);

      if (!date.notify && !date.prazoday) {
        if (value === 'baixa') {
          const dateformatted = format(addDays(new Date(), 2), 'yyyy-MM-dd');
          setDateAtual({ ...date, date: dateformatted });
          return;
        }

        if (value === 'media') {
          const dateformatted = format(addDays(new Date(), 1), 'yyyy-MM-dd');
          setDateAtual({ ...date, date: dateformatted });
          return;
        }

        const dateformatted = format(new Date(), 'yyyy-MM-dd');
        setDateAtual({ ...date, date: dateformatted });
      }
    },
    [date]
  );

  return (
    <>
      <Nav page="tickets" />
      <Container>
        <User />

        <Content>
          <Title title="Tickets / Adicionar" />

          <Bloco>
            <div>
              <h2>Tickets / Adicionar</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-atividade">
              <button
                type="button"
                className="btn-green"
                onClick={() => history.push('/ticket/activitys')}
              >
                Ver Atividades
              </button>
            </div>
          </Pesquisa>
        </Content>

        <Card tipo={date.closed}>
          <div className="contentTop">
            <div>
              <button
                type="button"
                className="btnprovisorio"
                onClick={() => handleState('cliente')}
              >
                {state.provisorio
                  ? '- Alterar para o Cliente atual'
                  : '+ Alterar como Cliente Provisório'}
              </button>

              <button
                type="button"
                className={
                  state.solicitante ? 'btnsolicitante' : 'btnprovisorio'
                }
                onClick={() => handleState('solicitante')}
              >
                {state.solicitante
                  ? 'Eu vou ser o solicitante'
                  : 'Quero adicionar um solicitante'}
              </button>
            </div>
            <span>
              {date.closed ? `Tipo: Repete (${date.notify})` : 'Tipo: Normal'}
            </span>
          </div>
          <form id="form_reset">
            <BForm>
              {state.provisorio ? (
                <div className="form-input">
                  <input type="hidden" id="cliente" value="" />
                  <Input
                    id="clienteProvisorio"
                    label="Cliente"
                    name="cliente"
                    variant="outlined"
                  />
                </div>
              ) : (
                <div className="form-input">
                  <input type="hidden" id="clienteProvisorio" value="" />
                  <Autocomplete
                    id="cliente"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    getOptionSelected={(option, value) =>
                      option.razao === value.razao
                    }
                    getOptionLabel={option => option.razao}
                    options={clientes}
                    loading={loading}
                    renderInput={params => (
                      <Input
                        {...params}
                        label="Clientes"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              )}
              <div className="form-input">
                <Select
                  id="setor"
                  value={setor}
                  onChange={handleUser}
                  label="Departamento"
                  variant="outlined"
                >
                  {dadosSetor.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="form-input">
                {state.solicitante ? (
                  <>
                    {!state.typeuser && !state.typewrite ? (
                      <Select
                        onChange={e => {
                          setState({
                            ...state,
                            typeuser: e.target.value === 'usuario',
                            typewrite: e.target.value === 'digitar',
                          });
                          if (e.target.value === 'usuario') {
                            handleGetUsers();
                          }
                        }}
                        label="Tipo do Solicitante"
                        variant="outlined"
                      >
                        <MenuItem value="usuario">Usuário cadastrado</MenuItem>
                        <MenuItem value="digitar">Vou digitar</MenuItem>
                      </Select>
                    ) : (
                      <>
                        {state.typeuser && (
                          <Autocomplete
                            id="userauto"
                            options={users}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <Input
                                {...params}
                                label="Escolha o solicitante"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        {state.typewrite && (
                          <Input
                            label="Digite o solicitante"
                            variant="outlined"
                            onChange={e => setValUser(e.target.value)}
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Select
                    id="usuario"
                    value={valUser}
                    onChange={e => setValUser(e.target.value)}
                    label="Usuário da demanda"
                    variant="outlined"
                  >
                    {users.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </BForm>
            <BForm>
              <div className="form-input">
                <Input
                  id="date"
                  label="Data da Demanda"
                  type="date"
                  value={date.date}
                  variant="outlined"
                  disabled={date.closed}
                  onChange={e =>
                    setDateAtual({ ...date, date: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="form-input">
                {profile.role_id === 1 && activity.length > 0 && (
                  <button
                    type="button"
                    className="moreclick"
                    title="Adicionar Atividade"
                    onClick={() => setOpenActivity(true)}
                  >
                    +
                  </button>
                )}
                {profile.role_id !== 1 && profile.cargo.subtitle === setor && (
                  <button
                    type="button"
                    className="moreclick"
                    title="Adicionar Atividade"
                    onClick={() => setOpenActivity(true)}
                  >
                    +
                  </button>
                )}
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="activity"
                  value={valActivity}
                  onChange={e =>
                    handleModal({
                      tipo: 'sub',
                      value: e.target.value,
                    })
                  }
                  label="Tipo de Demanda"
                  variant="outlined"
                >
                  {activity.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                      {item.notify
                        ? ` - (Tipo: ${item.notify})`
                        : `(Subprocesso: ${item.idSub.length})`}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="form-input">
                <Select
                  id="nivel"
                  value={valNivel}
                  onChange={handleNivel}
                  label="Nível do Chamado"
                  variant="outlined"
                >
                  <MenuItem value="baixa">
                    <em>Baixa</em>
                  </MenuItem>
                  <MenuItem value="media">
                    <em>Média</em>
                  </MenuItem>
                  <MenuItem value="alto">
                    <em>Alto</em>
                  </MenuItem>
                </Select>
              </div>
            </BForm>
            <BForm>
              <div className="form-input">
                <Input id="messeger" label="Mensagem" variant="outlined" />
              </div>
            </BForm>
            <BForm>
              <p>
                <input
                  type="checkbox"
                  name="sigilo"
                  onClick={() => setCheckBox(!checkBox)}
                />{' '}
                Deixar o ticket sigiloso
              </p>
            </BForm>
            <BForm>
              <span className="bloco-btn">
                <span>
                  {check.length > 0 && (
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => setModal(true)}
                    >
                      + Ver Categoria ({check.length}) (Tipo de Demanda)
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleModal({ tipo: 'seguidores', value: null })
                    }
                  >
                    + Adicionar Seguidores ({seguidores.length})
                  </Button>
                </span>
              </span>
            </BForm>
          </form>
        </Card>

        <BtnSave loading={loadingTicket}>
          <button type="button" onClick={handleSubmit}>
            {!loadingTicket && 'ABRIR CHAMADO'}
          </button>
          {loadingTicket && <LoadingButton size={24} />}
        </BtnSave>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          {checkSeg ? (
            <>
              <h4>Adicionar Email para seguidor</h4>
              <div className="viewEmail">
                {seguidores.map(item => (
                  <p>
                    {item}{' '}
                    <button
                      type="button"
                      onClick={() =>
                        handleSeguidores({ tipo: 'remove', email: item })
                      }
                    >
                      <MdClose size={12} color="#FF5555" />
                    </button>
                  </p>
                ))}
              </div>
              <div className="inputForm">
                <form id="form_reset">
                  <input
                    type="email"
                    className="email"
                    placeholder="Adicionar Email"
                  />
                </form>
              </div>
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={() => handleSeguidores({ tipo: 'add', email: null })}
                >
                  Salvar Email
                </button>
                <button
                  type="button"
                  className="btn-save"
                  onClick={() => handleModal({ tipo: 'fechar', value: null })}
                >
                  Concluir
                </button>
              </div>
            </>
          ) : (
            <>
              <h4>Adicionar Subprocesso</h4>
              <div className="inputCheck">
                <CheckboxGroup name="check" value={check} onChange={setCheck}>
                  {Checkbox => (
                    <>
                      {trueActivity.map(item => (
                        <CheckboxDiv key={item.id}>
                          <Checkbox value={item.id} />
                          <p>{item.title}</p>
                        </CheckboxDiv>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={() => handleModal({ tipo: 'salvar', value: null })}
                >
                  Salvar
                </button>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => handleModal({ tipo: 'fechar', value: null })}
                >
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ContentModal>
      </Modal>

      <ModalDialog
        open={openActivity}
        close={() => setOpenActivity(false)}
        handleSubmit={handleActivity}
        handleCancel={() => setOpenActivity(false)}
        title="Adicionar Atividade"
      >
        {loadActivity ? (
          <LoadingButton />
        ) : (
          <Input
            autoFocus
            margin="dense"
            id="title"
            label="Adicionar Atividade"
            type="atividade"
            fullWidth
            style={{ width: 280 }}
          />
        )}
      </ModalDialog>
    </>
  );
}
