import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { MdSearch } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando';
import Pagination from '~/components/Pagination';

import { Container, Content, Bloco, Card, Pesquisa } from './styles';

export default function Auditorias() {
  const [auditorias, setAuditorias] = useState([]);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitView, setLimitView] = useState(10);
  const [pageView, setPageView] = useState(1);
  const url = new URL(window.location);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    if (profile.role_id > 1) {
      toast.warning('Opa! Você não tem permissão.');
      history.goBack();
    }

    async function loadAuditorias() {
      const params = new URLSearchParams(url.search);
      const limit = params.get('limit') || 10;
      const page = params.get('page') || 1;

      const response = await api.get(`auditorias?limit=${limit}&page=${page}`);

      setLoading(false);

      setLimitView(limit);
      setPageView(Number(page));

      setAuditorias(response.data.data);

      setTotal(response.data.last_page);
    }

    loadAuditorias();
  }, [profile.role_id, url.search]);

  function handleSearch() {}

  const handleLimit = useCallback(e => {
    const limit = e.target.value;

    setLimitView(limit);
    setPageView(1);
    history.push(`/auditorias?limit=${limit}&page=1`);
  }, []);

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        const ptotal = pageView === 1 ? 1 : pageView - 1;
        setPageView(ptotal);
        setLimitView(limitView);
        history.push(`/auditorias?limit=${limitView}&page=${ptotal}`);
        return;
      }
      const ptotal = Number(pageView) + 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(`/auditorias?limit=${limitView}&page=${ptotal}`);
    },
    [limitView, pageView]
  );

  const handlePageSelect = useCallback(
    ({ page }) => {
      setPageView(page);
      setLimitView(limitView);
      history.push(`/auditorias?limit=${limitView}&page=${page}`);
    },
    [limitView]
  );

  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Title title="Administrador / Auditorias" />
          <Bloco>
            <div>
              <h2>Auditorias</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Search" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Ação</td>
                <td>Usuário</td>
                <td>Cliente</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {auditorias.map(item => (
                    <tr className="table-hover">
                      <td>{item.acao}</td>
                      <td>{item.user.name}</td>
                      <td>{item.cliente && item.cliente.razao}</td>
                      <td>{item.updated_at}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limitView}
          page={pageView}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Footer />
      </Container>
    </>
  );
}
