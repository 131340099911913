import styled from 'styled-components';
import { darken } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;

  .autoload {
    display: flex;
    width: 100%;
    position: relative;

    label {
      font-size: 12px;
      color: #000;
    }

    button {
      position: absolute;
      top: 0px;
      right: 18px;
      width: auto;

      & + button {
        right: 0;
      }
    }

    input {
      width: 100%;
    }

    div {
      width: 100%;
    }
  }

  .az {
    border: 0;
    background: none;
    margin-top: 4px;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  textarea {
    background: none;
    width: 100%;
  }

  input {
    font-size: 12px;
  }

  select {
    font-size: 12px;
    width: 100%;

    option {
      max-width: 220px;
    }
  }

  .paragrafo {
    font-size: 9px;
    color: #999;
  }

  td {
    font-size: 12px;

    p {
      font-size: 9px;
    }

    div {
      display: flex;
      flex-direction: column;
      font-size: 12px;

      .margin {
        margin-top: 10px;
      }

      select {
        option {
          font-size: 11px;
        }
      }
    }
  }

  .btnEdit {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }
  }

  div {
    width: 100%;
  }

  span {
    display: flex;
    flex-direction: row;
  }

  .btnDelete {
    background: #ff4d4d;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }

  #red {
    color: #ff4d4d;
  }
  #green {
    color: #00b259;
  }
  .verificao {
    display: flex;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    color: #eee;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
  }
  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }
  .btn-gray {
    background: #bbbbbb;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#BBBBBB')};
    }
  }
  .btn-orange {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const GridTab = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  .btn-info {
    display: flex;

    button + button {
      margin-left: 4px;
    }
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 0;
    background: #ff4242;
    color: #fff;

    &:hover {
      background: ${darken(0.06, '#ff4242')};
    }
  }

  .editar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 0;
    background: #00b300;
    color: #fff;

    &:hover {
      background: ${darken(0.06, '#00B300')};
    }
  }

  .bloco {
    display: flex;
    flex: 1;

    div + div {
      margin-left: 8px;
    }
  }

  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  p {
    padding-top: 8px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  .hidden {
    display: none;
  }

  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
    width: 200px;
    margin-right: 8px;
  }

  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }

  .btn-cancela {
    background: #ccc;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ccc')};
    }
  }
  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  .bloco-one {
    display: flex;
    align-items: center;

    .exportar {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0px;
      background: #00b259;
      color: #fff;
      padding: 6px 8px;

      &:hover {
        background: #008040;
      }

      img {
        width: 14px;
        margin-right: 4px;
      }
    }

    .pesquisa {
      background: #ff7735;
      border: none;
      border-radius: 4px;
      color: #fff;
      padding: 8px 10px;
    }

    p {
      display: flex;
      font-size: 11px;
      align-items: center;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  div {
    display: flex;
    button + button {
      margin-left: 4px;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .inputForm {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .vencimento {
    font-size: 32px;
    background: #eee;
    padding: 12px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        background: #008c69;
        align-items: center;
        font-size: 12px;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;

  .total {
    display: block;

    .percentage {
      font-size: 18px;
      font-weight: bold;

      .red {
        color: #ff3e3e;
      }

      .black {
        color: #222222;
      }

      .green {
        color: #00b285;
      }

      .orange {
        color: #ff7f00;
      }

      .blue {
        color: #00468c;
      }
    }

    p {
      font-size: 12px;
    }

    span {
      font-size: 34px;
      font-weight: bold;
    }
  }

  .blue {
    color: #00468c;
  }

  .green {
    color: #36d900;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff3e3e;
  }
`;
