import React from 'react';
import PropTypes from 'prop-types';
import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function BrlCurrencyComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <IntlCurrencyInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      currency="BRL"
      config={currencyConfig}
    />
  );
}

BrlCurrencyComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default BrlCurrencyComponent;
