import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 8,
  },
});

export default function AnimationsDiv({ number }) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const arrayTotal = [];
    const numberTotal = Number(number);

    for (let i = 0; i < numberTotal; i++) {
      arrayTotal.push(i + 1);
    }

    setData(arrayTotal);
  }, [number]);

  return (
    <>
      {data.map(item => {
        return (
          <div key={item} className={classes.root}>
            <Skeleton height={14} />
          </div>
        );
      })}
    </>
  );
}

AnimationsDiv.propTypes = {
  number: PropTypes.string.isRequired,
};
