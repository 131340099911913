import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Licencas({ dados }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.data_protocolo
      ? format(parseISO(item.data_protocolo), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
    dateVencimento: item.data_venc
      ? format(parseISO(item.data_venc), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid>
      {data.map(item => (
        <div key={item.id} className="content">
          <h4>{item.tb_title}</h4>
          <div className="tabelas">
            <div>
              <strong>Nº Documento: </strong> {item.n_doc}
            </div>
            <div>
              <strong>Data Vencimento: </strong> {item.dateVencimento}
            </div>
            <div>
              <strong>Data limite protocolado pela Secran: </strong>{' '}
              {item.dateFormatted}
            </div>
            <div>
              <strong>Nº Protocolo: </strong> {item.n_proto}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Data Pedido: </strong> {item.created_at}
            </div>
            <div>
              <strong>Protocolado por: </strong>
              {item.user}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Licencas.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Licencas.defaultProps = {
  dados: [],
};
