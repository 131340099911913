import React from 'react';

import { Form, Input } from '@rocketseat/unform';

import { Pagination, Qtd, PageVer } from './styles';

const PaginationConst = ({
  limit,
  total,
  page,
  handleLimit,
  handlePage,
  handlePrev,
  handleNext,
}) => {
  return (
    <Pagination>
      <div className="pagination">
        <Qtd>
          <span>Página de:</span>
          <select onChange={handleLimit}>
            <option value="10" selected={limit === '10' || false}>
              10
            </option>
            <option value="20" selected={limit === '20' || false}>
              20
            </option>
            <option value="40" selected={limit === '40' || false}>
              40
            </option>
            <option value="60" selected={limit === '60' || false}>
              60
            </option>
            <option value="80" selected={limit === '80' || false}>
              80
            </option>
            <option value="100" selected={limit === '100' || false}>
              100
            </option>
          </select>
        </Qtd>
        <PageVer>
          {total > 1 && (
            <>
              <Form onSubmit={handlePage}>
                <span>
                  Página de {page} para {total}
                </span>
                <Input name="page" />
                <button type="submit" className="hidden">
                  s
                </button>
              </Form>
              <button
                type="button"
                onClick={handlePrev}
                disabled={page === '1' || false}
              >
                Anterior
              </button>
              <button
                type="button"
                onClick={handleNext}
                disabled={page === total || false}
              >
                Próximo
              </button>
            </>
          )}
        </PageVer>
      </div>
    </Pagination>
  );
};

export default PaginationConst;
