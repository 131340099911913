import styled from 'styled-components';
import { darken } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  .contrato {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }

  #red {
    color: #c9321f;
  }

  #green {
    color: #0faf69;
  }

  #orange {
    color: #ff7f00;
  }

  .perfil {
    display: flex;
    align-items: center;
    background: #0faf69;
    border-radius: 4px;
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    justify-content: flex-start;

    p {
      font-size: 12px;
      padding-left: 4px;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }

  .grid {
    display: flex;
    flex: 1;
  }

  .bloco {
    display: flex;
    justify-content: space-between;
    background: #0059b2;
    flex: 1;
    border-radius: 5px;
    padding: 16px 10px;
    color: #fff;

    .icon {
      padding-top: 8px;
    }

    h2 {
      color: #fff;
      font-size: 36px;
    }

    & + .bloco {
      background: #ff4d4d;
      margin-left: 12px;
    }
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  .bloco_form {
    display: flex;
    flex: 1;
    align-items: center;

    span {
      margin-right: 4px;
      font-size: 11px;
    }
  }

  .bloco_form + .bloco_form {
    margin-left: 4px;
  }

  select {
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 15px;
  }

  select + select {
    margin-left: 6px;
  }

  input {
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
    width: 100%;
  }

  button {
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
    background: #0f73af;
    color: #fff;
    margin-left: 5px;
  }

  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    select {
      background: #999;
      color: #fff;
      width: 100%;
    }

    span {
      margin-right: 5px;
    }
  }

  .bloco-one {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .bloco-one + .bloco-one {
    margin-top: 8px;
  }

  .bloco-two {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      margin-right: 5px;
    }

    button {
      border: 0;
      background: #0faf69;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;

      &:hover {
        background: #0faf69;
      }
    }

    button + button {
      margin-left: 5px;
    }
  }
`;
