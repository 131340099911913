import React, { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import parse from 'react-html-parser';

import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import { MdSend, MdAttachFile } from 'react-icons/md';

import { Input, Form } from '@rocketseat/unform';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import User from '~/components/User';
import Nav from '~/components/Navigation';

import iconPdf from '~/assets/annotations/pdf.svg';
import iconPicture from '~/assets/annotations/picture.svg';
import iconTxt from '~/assets/annotations/approve.svg';
import iconDocx from '~/assets/annotations/text.svg';
import iconExcel from '~/assets/annotations/microsoft-excel.svg';

import { Content, Card, Bloco, Text, Files, Messenger, BForm } from './styles';

import SemAvatar from '~/assets/no-avatar.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Show() {
  const classes = useStyles();
  const { id } = useParams();
  const user = useSelector(state => state.user.profile);
  const [annotation, setAnnotation] = useState('');
  const [messengers, setMessengers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadFiles, setLoadFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [state, setState] = useState({
    razao: '',
    cargo: '',
    user: {},
    files: [],
  });
  const url = 'https://apic.secran.digital/storage/';
  const urlRH = 'https://rh.secran.digital/storage/';

  const handleMessenger = useCallback(
    async e => {
      const percorreFiles = files.map(item => item.id);
      try {
        setLoading(true);
        const { data } = await api.post('annotations?comment=true', {
          title: e.title,
          id_annotation: e.id,
          executor: e.userID,
          files: percorreFiles,
        });

        document.getElementById('form_reset').reset();

        setMessengers([data, ...messengers]);
        setLoading(false);
      } catch (err) {
        toast.error('Ops! Algo deu errado!');
        setLoading(false);
      }
    },
    [files, messengers]
  );

  const handleFile = useCallback(
    async e => {
      setLoadFiles(true);

      const dados = new FormData();

      dados.append('file', e.target.files[0]);

      try {
        const { data } = await api.post('files?annotation=true', dados);

        const object = { data };

        const convert = Object.values(object); // Transform object to array

        const filesRes = convert.concat(files);

        setFiles(filesRes);
        setLoadFiles(false);
      } catch (err) {
        toast.error('Algo deu errado..');
        setLoadFiles(false);
      }
    },
    [files]
  );

  const handleDelete = useCallback(
    async e => {
      setFiles(files.filter(item => item.id !== e));

      await api.delete(`files/${e}`);
    },
    [files]
  );

  const showMessengers = useCallback(async () => {
    const { data } = await api.get(`annotations?id_annotation=${id}`);

    const percorre = data.map(item => ({
      ...item,
      dateFormatted: format(
        parseISO(item.created_at),
        "dd 'de' MMMM 'de' yyyy",
        {
          locale: pt,
        }
      ),
    }));

    setMessengers(percorre);
  }, [id]);

  useEffect(() => {
    api.get(`annotations/${id}`).then(response => {
      if (
        response.data.sigilo &&
        response.data.user_id !== user.id &&
        response.data.cargo_id !== user.cargo_id
      ) {
        toast.warning('Ops! Você não tem permissão.');
        history.push('/annotations');
        return;
      }

      const percorre = {
        ...response.data,
        dateFormatted: format(
          parseISO(response.data.created_at),
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: pt,
          }
        ),
      };

      setAnnotation(percorre);
      setState({
        razao: response.data.cliente.razao,
        cargo: response.data.cargo.title,
        user: response.data.user,
        files: response.data.files,
      });

      showMessengers();
    });
  }, [id]); // eslint-disable-line

  return (
    <>
      <Nav page="historico" />
      <Container flexDirection="column">
        <User />

        <Content>
          <Title title={`Históricos / ${annotation.title}`} />

          <Bloco>
            <div>
              <h2>Assunto: {annotation.title}</h2>
            </div>
          </Bloco>
          <Bloco>
            <div className="autor">
              <div className="avatar">
                {state.user.file_id ? (
                  <img src={`${url}${state.user.file}`} alt={state.user.name} />
                ) : (
                  <img
                    src={`${urlRH}${state.user.avatar}`}
                    alt={state.user.name}
                  />
                )}
              </div>
              <div className="user">
                <strong>{state.user.name}</strong>
                <p>{state.user.email}</p>
                <small>Criado: {annotation.dateFormatted}</small>
              </div>
            </div>
          </Bloco>
        </Content>

        <Card>
          <h4>{state.files.length > 1 ? 'Arquivos' : 'Arquivo'}</h4>
          <Files>
            {state.files.map(file => (
              <li key={file.id}>
                <div className="iconText">
                  <div>
                    {file.type === 'pdf' && (
                      <img src={iconPdf} alt={file.name} />
                    )}
                    {file.type === 'xlsx' && (
                      <img src={iconExcel} alt={file.name} />
                    )}
                    {file.type === 'docx' && (
                      <img src={iconDocx} alt={file.name} />
                    )}
                    {file.type === 'txt' && (
                      <img src={iconTxt} alt={file.name} />
                    )}
                    {file.type === 'xml' && (
                      <img src={iconTxt} alt={file.name} />
                    )}
                    {file.type === 'jpeg' && (
                      <img src={iconPicture} alt={file.name} />
                    )}
                    {file.type === 'jpg' && (
                      <img src={iconPicture} alt={file.name} />
                    )}
                  </div>
                  <p>{file.name}</p>
                </div>
                <div className="btn">
                  <a
                    href={`${url}${file.file}`}
                    className="linkDownload"
                    rel="noopener noreferrer"
                    target="_blank"
                    download
                  >
                    Visualizar ou Download
                  </a>
                </div>
              </li>
            ))}
          </Files>
          <Alert icon={false} severity="success">
            Cliente: {state.razao}
          </Alert>
          <Text>{parse(annotation.text)}</Text>
        </Card>

        <Messenger>
          <div className="messengers">
            {messengers.map(item => (
              <span key={item.id} className="messenger">
                <div className="avatar">
                  {item.user.file ? (
                    <img src={`${url}${item.user.file}`} alt={item.user.name} />
                  ) : (
                    <img
                      src={
                        item.user.avatar
                          ? `${urlRH}${item.user.avatar}`
                          : SemAvatar
                      }
                      alt={item.user.name}
                    />
                  )}
                </div>
                <div className="buble">
                  {item.files.length > 0 && (
                    <span>
                      {item.files.map(file => (
                        <a
                          href={`${url}${file.file}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <MdAttachFile size={12} color="#000" /> {file.name}
                        </a>
                      ))}
                    </span>
                  )}
                  <p>{item.title}</p>
                  <small>
                    de {item.user.name} - Publicado: {item.dateFormatted}
                  </small>
                </div>
              </span>
            ))}
          </div>
          <div className="inputMessenger">
            <Form onSubmit={handleMessenger} id="form_reset">
              <Input type="hidden" name="id" value={annotation.id} />
              <Input type="hidden" name="userID" value={annotation.user_id} />
              <span className="separa">
                <label>
                  <MdAttachFile size={42} color="#585858" />

                  <input
                    type="file"
                    id="arquivos"
                    accept=".jpeg, .jpg, .png, .pdf, .docx, .txt, .xlsx, .xml"
                    onChange={handleFile}
                  />
                </label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Responder o assunto"
                />
              </span>
              <button type="submit">
                <MdSend size={42} color="#585858" />
              </button>
            </Form>
          </div>
          {loadFiles && (
            <BForm>
              <div className={classes.root}>
                <LinearProgress />
              </div>
            </BForm>
          )}
          <BForm>
            {files.map(file => (
              <Chip
                key={file.id}
                label={file.name}
                onDelete={() => handleDelete(file.id)}
              />
            ))}
          </BForm>
        </Messenger>
      </Container>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Footer />
    </>
  );
}
