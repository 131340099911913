import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  padding: 12px;
  position: relative;
  flex: 1;
  flex-direction: column;
  margin-top: 190px;

  .tableRelative {
    position: relative;
  }

  .vencimentoAlterado {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00468c;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 5px;
    margin-bottom: 8px;
    color: #ffc926;
  }

  #red {
    color: #ff4a4a;
  }

  #black {
    color: #000;
  }

  .btn-obr {
    border: none;
    background: #eee;
    border-radius: 4px;
    padding: 4px 6px;
    color: #000;

    &:hover {
      background: #97cbff;
      color: #fff;
    }
  }

  .btn-obractive {
    border: none;
    background: #97cbff;
    color: #fff;
    border-radius: 4px;
    padding: 4px 6px;

    &:hover {
      background: #00468c;
      color: #fff;
    }
  }

  button.btn-circle {
    border: 0;
    background: none;
  }

  .trib {
    font-size: 10px;
  }

  td .div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    span {
      margin-left: 8px;
    }

    span img {
      width: 14px;
      height: 14px;
    }
  }

  .barraFixed {
    display: block;
    position: fixed;
    width: 100%;
  }

  .txtRazao {
    font-size: 10px;

    input {
      background: transparent;
      border: none;
      font-size: 10px;
    }
  }

  th,
  td {
    border: 1px solid #ddd;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
  }

  table {
    border-collapse: collapse;

    .btn-Ativo {
      display: flex;
      align-items: center;
    }

    .btn-Retificadora {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .circlo {
      border-radius: 9px;
      width: 18px;
      height: 18px;
      background: transparent;
      border: none;
      margin-left: 3px;
    }

    .circlo + .circlo {
      margin-right: 5px;
    }

    .txtMove {
      font-size: 10px;
      padding-bottom: 5px;
      display: block;
      color: #ff5c26;
    }
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .txt_user {
    text-align: left;
    padding-left: 5px;

    p {
      font-size: 12px;
      color: #fff;
      font-weight: 200;
    }
  }

  .txt_retificao {
    text-align: left;
    padding-left: 5px;

    p {
      font-size: 11px;
      color: #000;
      font-weight: 200;
    }
  }

  #red {
    color: #ff4d4d;
  }
  #green {
    color: #00b259;
  }

  .bloco {
    display: block;
    overflow: auto;
    white-space: nowrap;
    width: 99%;
    height: 74%;
    position: fixed;
    overflow-y: scroll;
    background: #fff;

    .porcima {
      display: flex;
      position: absolute;
      z-index: 99;
      background: rgba(0, 0, 0, 0.6);
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      span {
        background: #2693ff;
        font-size: 32px;
        color: #fff;
        padding: 8px;
      }
    }

    .black {
      color: #000;
      margin-top: 8px;
    }

    .porcentagem {
      display: block;
      width: 100%;
      padding-top: 8px;

      a {
        color: #fff;
        font-size: 12px;
        font-weight: normal;

        &:hover {
          background: #0055aa;
        }
      }

      a + a {
        margin-top: 4px;
      }

      .green {
        background: #00b259;
        color: #fff;
        padding: 4px;
      }

      .red {
        background: #ff4d4d;
        color: #fff;
        padding: 4px;
      }

      .orange {
        background: #d96d00;
        color: #fff;
        padding: 4px;
      }

      .blue {
        background: #2a9fe5;
        color: #fff;
        padding: 4px;
      }

      .gray {
        background: #373737;
        color: #fff;
        padding: 4px;
      }

      p {
        font-size: 12px;
      }

      p + p {
        margin-top: 4px;
      }
    }

    span {
      font-size: 12px;
      color: #000;

      #red {
        color: #ff4d4d;
      }
      #green {
        color: #00b259;
      }
    }
  }

  .scrollbar {
    margin: none;
    padding: none;
    display: block;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }

  .btn-yellow {
    background: #ffbf00;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    flex: 1;
    margin-top: 4px;
  }

  .btn-red {
    background: #ff4d4d;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF4D4D')};
    }
  }

  .btn-gray {
    background: #bbbbbb;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#BBBBBB')};
    }
  }
  .btn-orange {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }
  }
  .btn-black {
    background: #222222;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#222222')};
    }
  }
  .btn-disable {
    background: #999;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#999')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  background: #eeeeee;
  z-index: 99;

  .logo {
    display: block;
    position: absolute;
    z-index: 9;
    top: 10px;
    right: 14px;

    img {
      width: 120px;
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 40px;

  .bloco {
    display: flex;
    flex-direction: row;
  }

  .form + .form {
    margin-left: 4px;
  }

  .form {
    display: flex;
    align-items: center;
    font-size: 12px;

    .btnModalDay {
      background: #00468c;
      color: #fff;
      padding: 8px 6px;
      border: 0;
      border-radius: 4px;
      margin-left: 4px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.3, '#00468c')};
      }
    }

    .btnModalMonthYear {
      background: #008c46;
      color: #fff;
      padding: 8px 6px;
      border: 0;
      border-radius: 4px;
      margin-left: 4px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.3, '#008C46')};
      }
    }

    .btnanalista {
      display: flex;
      align-items: center;
      border: 0;
      background: #00468c;
      color: #fff;
      border-radius: 4px;
      padding: 8px 6px;

      &:hover {
        background: ${shade(0.3, '#00468c')};
      }
    }

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
    }
  }

  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
  }
  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  .bloco-one {
    display: flex;
    align-items: center;
    p {
      display: flex;
      font-size: 11px;
      align-items: center;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  div {
    display: flex;
    button + button {
      margin-left: 4px;
    }
  }
  .carregandoModal {
    width: 450px;
    height: 70px;
    position: relative;
  }
  .title {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .inputCheck {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 11px;
    color: #ff4d4d;

    input {
      margin-right: 4px;
    }

    input + input {
      margin-left: 6px;
    }
  }
  .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;

    .dateMovimento {
      margin-top: 6px;
    }

    select {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }

    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .vencimento {
    font-size: 32px;
    background: #eee;
    padding: 12px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  z-index: 99;
  flex: 1;
  background: #eee;
  height: 100%;
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  display: block;
  font-size: 12px;
  position: fixed;
  bottom: 15px;
  right: 15px;

  a {
    color: #999;
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        font-size: 14px;
        background: #008c69;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;

export const ModalDialogDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;

  select {
    width: 100%;
    height: 38px;
    border-radius: 4px;

    & + select {
      margin-top: 4px;
    }
  }
`;
