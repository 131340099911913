import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Form, Input } from '@rocketseat/unform';
import InputMask from 'react-input-mask';

import { MdDelete } from 'react-icons/md';
import { Grid, Col } from './styles';

import api from '~/services/api';

import Carregando from '~/components/Carregando/Button';

export default function Cno({ cliente, ativo }) {
  const [cnos, setCnos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnload, setBtnLoad] = useState(false);

  useEffect(() => {
    if (!ativo) {
      setLoading(false);
      return;
    }

    async function loadCnos() {
      const response = await api.get(`clientes/${cliente}/cno`);

      setCnos(response.data.data);
      setLoading(false);
    }

    loadCnos();
  }, [ativo, cliente]);

  async function handleSubmit({ data }) {
    setBtnLoad(true);
    const insc = document.querySelector('.insc').value;
    await api.post(`clientes/${cliente}/cno`, {
      data,
      insc,
    });

    const dados = await api.get(`clientes/${cliente}/cno`);

    setBtnLoad(false);
    setCnos(dados.data.data);
  }

  async function handleDelete(id) {
    await api.delete(`delete/${cliente}/cno?id=${id}`);

    setCnos(cnos.filter(item => item.id !== id));
  }

  return (
    <>
      <Grid>
        <table className="tab table-striped">
          <thead>
            <tr>
              <td>Inscrição Nº</td>
              <td>Data</td>
              <td>Ação</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Carregando />
            ) : (
              <>
                {cnos.map(item => (
                  <>
                    <tr key={item.id} className="table-hover">
                      <td>{item.insc}</td>
                      <td>{item.date}</td>
                      <td>
                        <div>
                          <button
                            type="button"
                            onClick={() => handleDelete(item.id)}
                          >
                            <MdDelete size={20} color="#fff" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            )}
          </tbody>
        </table>

        <Col>
          <Form onSubmit={handleSubmit}>
            <span>
              <InputMask type="text" mask="99.999.99999/99" className="insc" />
            </span>
            <span>
              <Input type="date" name="date" />
            </span>
            <span>
              <button type="submit" className="btn-save">
                {btnload ? <Carregando /> : 'Salvar'}
              </button>
            </span>
          </Form>
        </Col>
      </Grid>
    </>
  );
}

Cno.propTypes = {
  cliente: PropTypes.number.isRequired,
  ativo: PropTypes.bool,
};

Cno.defaultProps = {
  ativo: false,
};
