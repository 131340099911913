import React, { useEffect, useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function LoadSkeleton({ number }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const array = Array.from(String(number), Number);
    setData(array);
  }, [number]);

  return (
    <>
      <Container>
        <table>
          {data.map(item => (
            <tr key={item}>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          ))}
        </table>
      </Container>
    </>
  );
}

LoadSkeleton.propTypes = {
  number: PropTypes.string.isRequired,
};
