import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
// import Backdrop from '@material-ui/core/Backdrop';

import { toast } from 'react-toastify';

import CheckboxGroup from 'react-checkbox-group';

import InputMask from 'react-input-mask';

import { usePermission } from '~/hooks/permission';

import { urlFile, urlRh } from '~/utils/url.json';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando/Tabela';

import User from '~/components/User';
import api from '~/services/api';

import { Content, Card, Pesquisa, Box } from './styles';
import Nav from '~/components/Navigation';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function ExecutoresClientes() {
  const profile = useSelector(state => state.user.profile);
  const classes = useStyles();
  const [clientes, setClientes] = useState([]);
  const [clientesSec, setClientesSec] = useState([]);
  const [checkClientesPri, setCheckClientesPri] = useState([]);
  const [checkClientesSec, setCheckClientesSec] = useState([]);
  const [executores, setExecutores] = useState([]);
  const [executoresSec, setExecutoresSec] = useState([]);
  const [userPri, setUserPri] = useState('');
  const [userSec, setUserSec] = useState('');
  const [loadingPri, setLoadingPri] = useState(false);
  const [loadingSec, setLoadingSec] = useState(false);
  const [input, setInput] = useState('');
  const { admGestaoPermission } = usePermission();

  useEffect(() => {
    admGestaoPermission({ role_id: profile.role_id });
  }, [admGestaoPermission, profile.role_id]);

  const handleChange = useCallback(
    event => {
      setInput({ ...input, [event.target.name]: event.target.value });
    },
    [input]
  );

  const handleChangeExecutor = useCallback(
    async e => {
      try {
        setClientes([]);
        setClientesSec([]);
        setExecutoresSec([]);
        setUserPri('');
        setUserSec('');
        setCheckClientesPri([]);
        setCheckClientesSec([]);

        const response = await api.get(`users?funcao=${e.target.value}`);

        setExecutores(response.data);
        setInput({ ...input, executor: true });
      } catch (err) {
        toast.error(err.message);
      }
    },
    [input]
  );

  const handleClientes = useCallback(
    async event => {
      try {
        const user_id = event.target.value;

        const findExecutor = executores.find(
          user => user.id === Number(user_id)
        );

        const filterExecutores = executores.filter(
          exe => exe.cargo.tipo === findExecutor.cargo.tipo
        );

        setLoadingPri(true);

        const response = await api.get(
          `update/executores/list?user_id=${user_id}`
        );

        setClientes(response.data);
        setExecutoresSec(
          filterExecutores.filter(exe => exe.id !== Number(user_id))
        );
        setUserPri(findExecutor);
        setLoadingPri(false);
      } catch (err) {
        toast.error(err.message);
        setLoadingPri(false);
      }
    },
    [executores]
  );

  const handleExecutorToExecutor = useCallback(
    async event => {
      try {
        const user_id = event.target.value;

        const findExecutor = executores.find(exe => exe.id === Number(user_id));

        setLoadingSec(true);

        const response = await api.get(
          `update/executores/list?user_id=${user_id}`
        );

        setUserSec(findExecutor);
        setClientesSec(response.data);
        setLoadingSec(false);
      } catch (err) {
        toast.error(err.message);
        setLoadingSec(false);
      }
    },
    [executores]
  );

  const handleCheckPriAll = useCallback(
    event => {
      const checkEvent = event.target.checked;

      let allsIdsClientes = [];

      if (checkEvent) {
        allsIdsClientes = clientes.map(
          cliente => cliente.countobr === 0 && cliente.id
        );
      }

      setCheckClientesPri(allsIdsClientes);
    },
    [clientes]
  );

  const handleCheckSecAll = useCallback(
    event => {
      const checkedEvent = event.target.checked;

      let allsIdsClientes = [];

      if (checkedEvent) {
        allsIdsClientes = clientesSec.map(
          cliente => cliente.countobr === 0 && cliente.id
        );
      }

      setCheckClientesSec(allsIdsClientes);
    },
    [clientesSec]
  );

  const handleAddExecutor = useCallback(() => {
    const mapClientes = clientes.filter(cliente =>
      checkClientesPri.includes(cliente.id)
    );

    const mapRemoveClientes = clientes.filter(
      cliente => !checkClientesPri.includes(cliente.id)
    );

    const clientesSecMap = [...clientesSec, ...mapClientes];

    setClientesSec(clientesSecMap);
    setClientes(mapRemoveClientes);
  }, [checkClientesPri, clientes, clientesSec]);

  const handleUpdateExecutores = useCallback(async () => {
    try {
      const filterCnpjsClientes = clientesSec.filter(cliente =>
        checkClientesPri.includes(cliente.id)
      );

      const mapCnpjsClientesPri = filterCnpjsClientes.map(
        cliente => cliente.cnpj
      );

      // const response = await api.post('update/executores/list', {
      //   clientes: mapCnpjsClientesPri,
      //   user_id: userPri.id,
      // });
    } catch (err) {
      toast.error(err.message);
    }
  }, [checkClientesPri, clientesSec]);

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title="Cliuentes / Edit / Executores" />

          <Pesquisa>
            <div className="bloco">
              <div className="form-input">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Selecionar por tipo da execução</InputLabel>
                  <Select
                    native
                    onChange={handleChange}
                    name="tipo"
                    label="Selecionar por tipo da execução"
                  >
                    <option aria-label="None" value="" />
                    <option value="executor">
                      Substituir executor para outro
                    </option>
                    <option value="tipo">
                      Alterar o tipo do executor para outro
                    </option>
                    <option value="add">
                      Adicionar Executor de uma lista para outro executor
                    </option>
                  </Select>
                </FormControl>
              </div>
              {input.tipo === 'executor' && (
                <div className="blocorow">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Selecionar o setor</InputLabel>
                      <Select
                        native
                        onChange={handleChangeExecutor}
                        label="Selecionar o setor"
                      >
                        <option aria-label="None" value="" />
                        <option value="contabil">Contábil</option>
                        <option value="fiscal">Fiscal</option>
                        <option value="tributos">Tributos</option>
                        <option value="pessoal">Pessoal</option>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              {input.executor && (
                <div className="blocorow">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Selecione o Executor Atual</InputLabel>
                      <Select
                        native
                        onChange={handleClientes}
                        label="Selecione o Executor Atual"
                      >
                        <option aria-label="None" value="" />
                        {executores.map(executor => (
                          <option key={executor.id} value={executor.id}>
                            {executor.name} - ({executor.cargo.tipo})
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Selecione o Executor para troca</InputLabel>
                      <Select
                        native
                        onChange={handleExecutorToExecutor}
                        label="Selecione o Executor para troca"
                      >
                        <option aria-label="None" value="" />
                        {executoresSec.map(executor => (
                          <option key={executor.id} value={executor.id}>
                            {executor.name} - ({executor.cargo.tipo})
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
          </Pesquisa>
        </Content>

        <Box>
          <div className="bloco">
            {userPri ? (
              <div className="avatar">
                <div className="user">
                  {userPri.file ? (
                    <img
                      src={`${urlFile}${userPri.file.file}`}
                      alt={userPri.name}
                    />
                  ) : (
                    <>
                      {userPri.avatar ? (
                        <img
                          src={`${urlRh}${userPri.avatar}`}
                          alt={userPri.name}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <div className="name">{userPri.name}</div>
              </div>
            ) : (
              <div className="avatar">
                <div className="user" />
                <div className="name">Nome Executor</div>
              </div>
            )}
            <Card>
              {loadingPri ? (
                <Carregando />
              ) : (
                <table className="tab table-striped">
                  <thead>
                    <tr>
                      <td width="10%">
                        <input type="checkbox" onChange={handleCheckPriAll} />
                      </td>
                      <td>Razão Social</td>
                      <td>CNPJ / CPF</td>
                      <td>Obrigaões Feitas no mês</td>
                    </tr>
                  </thead>
                  <tbody>
                    <CheckboxGroup
                      name="check"
                      value={checkClientesPri}
                      onChange={setCheckClientesPri}
                    >
                      {Checkbox => (
                        <>
                          {clientes.map(item => (
                            <>
                              <tr
                                key={item.id}
                                className="table-hover"
                                id={item.countobr > 0 ? 'red' : ''}
                              >
                                <td>
                                  <Checkbox
                                    value={item.id}
                                    disabled={item.countobr > 0}
                                  />
                                </td>
                                <td>{item.razao}</td>
                                <td>
                                  {item.cnpj.length === 14 ? (
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      value={item.cnpj.replace('x', '1')}
                                      disabled
                                    />
                                  ) : (
                                    <InputMask
                                      mask="99.999.999-99"
                                      value={item.cnpj}
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>{item.countobr}</td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                    </CheckboxGroup>
                  </tbody>
                </table>
              )}
              {userSec ? (
                <>
                  {clientes.length > 0 ? (
                    <button type="button" onClick={handleAddExecutor}>
                      ENVIAR O SELECIONADO PARA O USUÁRIO: {userSec.name}
                    </button>
                  ) : (
                    <button type="button" disabled>
                      ENVIAR O SELECIONADO PARA O USUÁRIO: {userSec.name}
                    </button>
                  )}
                </>
              ) : (
                <button type="button" disabled>
                  ENVIAR O SELECIONADO PARA O USUÁRIO
                </button>
              )}
            </Card>
          </div>

          <div className="bloco">
            {userSec ? (
              <div className="avatar">
                <div className="user">
                  {userSec.file ? (
                    <img
                      src={`${urlFile}${userSec.file.file}`}
                      alt={userSec.name}
                    />
                  ) : (
                    <>
                      {userSec.avatar ? (
                        <img
                          src={`${urlRh}${userSec.avatar}`}
                          alt={userSec.name}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <div className="name">{userSec.name}</div>
              </div>
            ) : (
              <div className="avatar">
                <div className="user" />
                <div className="name">Nome Executor</div>
              </div>
            )}
            <Card>
              {loadingSec ? (
                <Carregando />
              ) : (
                <table className="tab table-striped">
                  <thead>
                    <tr>
                      <td width="10%">
                        <input type="checkbox" onChange={handleCheckSecAll} />
                      </td>
                      <td>Razão Social</td>
                      <td>CNPJ / CPF</td>
                      <td>Obrigaões Feitas no mês</td>
                    </tr>
                  </thead>
                  <tbody>
                    <CheckboxGroup
                      name="check"
                      value={checkClientesSec}
                      onChange={setCheckClientesSec}
                    >
                      {Checkbox => (
                        <>
                          {clientesSec.map(item => (
                            <>
                              <tr
                                key={item.id}
                                className="table-hover"
                                id={item.countobr > 0 ? 'red' : ''}
                              >
                                <td>
                                  <Checkbox
                                    value={item.id}
                                    disabled={item.countobr > 0}
                                  />
                                </td>
                                <td>{item.razao}</td>
                                <td>
                                  {item.cnpj.length === 14 ? (
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      value={item.cnpj.replace('x', '1')}
                                      disabled
                                    />
                                  ) : (
                                    <InputMask
                                      mask="99.999.999-99"
                                      value={item.cnpj}
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>{item.countobr}</td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                    </CheckboxGroup>
                  </tbody>
                </table>
              )}
              {checkClientesSec.length > 0 ? (
                <button type="button" onClick={handleUpdateExecutores}>
                  ATUALIZAR EXECUÇÃO SELECIONADO
                </button>
              ) : (
                <button type="button" disabled>
                  ATUALIZAR EXECUÇÃO SELECIONADO
                </button>
              )}
            </Card>
          </div>
        </Box>

        <Footer />
      </Container>
    </>
  );
}
