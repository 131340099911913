import React from 'react';

import { Link } from 'react-router-dom';

import { Container, Logo, Content } from './styles';
import logo from '~/assets/logo.svg';
import cpf from '~/assets/icons/cliente-cpf.svg';
import cnpj from '~/assets/icons/cliente-cnpj.svg';
import scp from '~/assets/icons/cliente-scp.svg';
import domestica from '~/assets/icons/cliente-domestica.svg';

export default function Adicionar() {
  return (
    <>
      <Logo>
        <img src={logo} alt="Secran" />
      </Logo>
      <Container>
        <h3>Escolha uma das opções para o cadastro.</h3>
        <Content>
          <Link to="/adicionar/cnpj/cliente">
            <img src={cnpj} alt="CNPJ" />
            <span>CNPJ</span>
          </Link>
          <Link to="/adicionar/scp/cliente">
            <img src={scp} alt="SCP" />
            <span>SCP</span>
          </Link>
          <Link to="/adicionar/cpf/cliente">
            <img src={cpf} alt="CPF" />
            <span>CPF</span>
          </Link>
          <Link to="/adicionar/domestica/cliente">
            <img src={domestica} alt="DOMÉSTICA" />
            <span>DOMÉSTICA</span>
          </Link>
        </Content>
      </Container>
    </>
  );
}
