import React, { useCallback, useEffect, useState } from 'react';

import { MdSearch, MdFolder, MdVpnKey } from 'react-icons/md';

import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import api from '~/services/api';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Pagination from '~/components/Pagination';

import { Content, Bloco, Card, Pesquisa } from './styles';

const UploadsArchives = () => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(1);
  const history = useHistory();

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const protocol = params.get('protocol') || '';
  const cliente_id = params.get('cliente_id') || '';
  const limit = params.get('limit') || '';
  const page = params.get('page') || '1';

  useEffect(() => {
    async function loadFolders() {
      setLoading(true);
      try {
        const response = await api.get(
          `clientes-uploads?protocol=${protocol}&cliente_id=${cliente_id}&limit=${limit}&page=${page}`
        );

        setFolders(response.data.data);
        setTotal(response.data.last_page);
        setLoading(false);
      } catch (err) {
        toast.warning(err.message, {
          position: 'top-center',
        });
      }
    }

    loadFolders();
  }, [cliente_id, limit, page, protocol]);

  const handleSearch = useCallback(
    event => {
      event.preventDefault();
      const client_protocol = document.querySelector('.client_protocol').value;
      history.push(
        `/uploads/clientes?protocol=${client_protocol}&cliente_id=${cliente_id}&limit=${limit}&page=${page}`
      );
    },
    [cliente_id, history, limit, page]
  );

  const handlePage = useCallback(
    event => {
      history.push(`/uploads/cliente/${event}`);
    },
    [history]
  );

  const handleLimit = useCallback(
    event => {
      const limitEvent = event.target.value;
      history.push(
        `/uploads/clientes?protocol=${protocol}&cliente_id=${cliente_id}&limit=${limitEvent}&page=${page}`
      );
    },
    [cliente_id, history, page, protocol]
  );

  const handlePaginate = useCallback(
    ({ paginate }) => {
      history.push(
        `/uploads/clientes?protocol=${protocol}&cliente_id=${cliente_id}&limit=${limit}&page=${paginate}`
      );
    },
    [cliente_id, history, limit, protocol]
  );

  const handlePageNextPrev = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = Number(page) === 1 ? 1 : Number(page) - 1;
        history.push(
          `/uploads/clientes?protocol=${protocol}&cliente_id=${cliente_id}&limit=${limit}&page=${ptotal}`
        );
        return;
      }
      setLoading(true);
      const ptotal = Number(page) + 1;
      history.push(
        `/uploads/clientes?protocol=${protocol}&cliente_id=${cliente_id}&limit=${limit}&page=${ptotal}`
      );
    },
    [cliente_id, history, limit, page, protocol]
  );

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title="Clientes / Upload" />
          <Bloco>
            <div>
              <h2>Clientes / Upload</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <form onSubmit={handleSearch}>
              <label>
                <input
                  type="text"
                  name="protocol"
                  className="client_protocol"
                  placeholder="Pesquisar por protocolo"
                />
                <button type="submit">
                  <MdSearch size={18} color="#000" />
                </button>
              </label>
            </form>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Razão Social</td>
                <td>Unidade</td>
                <td>CNPJ / CPF</td>
                <td>nome da Pasta</td>
                <td>Protocolo</td>
                <td>Setor</td>
                <td>Data da Postagem</td>
                <td>Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {folders.map(folder => {
                    return (
                      <tr key={folder.id}>
                        <td>{folder.razao}</td>
                        <td>{folder.tipo}</td>
                        <td>{folder.cnpj}</td>
                        <td>
                          <div>
                            <MdFolder size={14} color="#0059B2" />
                            {folder.name} - ({folder.countfiles})
                          </div>
                        </td>
                        <td>
                          <div>
                            <MdVpnKey size={14} color="#008C69" />
                            {folder.protocol}
                          </div>
                        </td>
                        <td>
                          <div className={`sector ${folder.sector}`}>
                            {folder.sectorString}
                          </div>
                        </td>
                        <td>{folder.dateFormattedCreated}</td>
                        <td align="right">
                          <button
                            type="button"
                            onClick={() =>
                              handlePage(`protocol/${folder.protocol}`)
                            }
                          >
                            Visualizar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limit}
          page={page}
          handleLimit={handleLimit}
          handlePage={handlePaginate}
          handlePrev={() => handlePageNextPrev('prev')}
          handleNext={() => handlePageNextPrev('next')}
        />
      </Container>

      <Footer />
    </>
  );
};

export default UploadsArchives;
