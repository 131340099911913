import React, { createContext, useContext, useCallback } from 'react';

import { toast } from 'react-toastify';
import history from '~/services/history';

const PermissionContext = createContext({});

const PermissionProvider = ({ children }) => {
  const validarPermission = useCallback(({ role_id, ativo }) => {
    if (role_id === 4 && !ativo) {
      history.goBack();
      toast.warning('Ops! Você não tem permissão.');
      return;
    }
    if (role_id === 6 && !ativo) {
      history.goBack();
      toast.warning('Ops! Você não tem permissão.');
    }
  }, []);

  const administradorPermission = useCallback(({ role_id }) => {
    if (role_id > 4) {
      history.goBack();
      toast.warning('Ops! Você não tem permissão.');
    }
  }, []);

  const adminAcessoPermission = useCallback(({ role_id }) => {
    if (role_id > 1) {
      history.goBack();
      toast.warning('Ops! Você não tem permissão.');
    }
  }, []);

  const admGestaoPermission = useCallback(({ role_id }) => {
    if (role_id === 1 || role_id === 3) {
      return;
    }

    history.goBack();
    toast.warning('Ops! Você não tem permissão.');
  }, []);

  const cargoPermission = useCallback(({ cargo }) => {
    if (
      cargo !== 'financeiro' ||
      cargo !== 'consultoria' ||
      cargo !== 'arquivo'
    ) {
      return;
    }

    history.goBack();
    toast.warning('Ops! Você não tem permissão.');
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        validarPermission,
        administradorPermission,
        adminAcessoPermission,
        admGestaoPermission,
        cargoPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

function usePermission() {
  const context = useContext(PermissionContext);

  if (!context) {
    throw new Error('usePermission must be used whithin a Permission');
  }

  return context;
}

export { PermissionProvider, usePermission };
