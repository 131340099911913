import React from 'react';

import PropTypes from 'prop-types';

import { Grid } from './styles';

export default function Contatos({ dados }) {
  return (
    <Grid>
      {dados.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>Nome: </strong> {item.nome}
            </div>
            <div>
              <strong>E-mail: </strong> {item.email}
            </div>
            <div>
              <strong>Telefone: </strong> {item.telefone}
            </div>
            <div>
              <strong>Cargo/Setor: </strong> {item.cargo}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Contatos.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Contatos.defaultProps = {
  dados: [],
};
