import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  flex-direction: column;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  .table-hover {
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .contrato {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }

  #red {
    color: #c9321f;
  }

  #red-background {
    background: #ffdfdf;
  }

  #green {
    color: #0faf69;
  }

  #orange {
    color: #ff7f00;
  }

  #btn-nao-lido {
    background: #0faf69;
    color: #fff;
    margin-left: 8px;

    &:hover {
      background: ${darken(0.06, '#0faf69')};
    }
  }

  a.btn-detalhes {
    background: #ff7f00;
    color: #fff;

    &:hover {
      background: ${darken(0.06, '#ff7f00')};
    }
  }

  a.btn-notifications {
    background: #333333;
    color: #fff;
    width: 120px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#333333')};
    }
  }

  #btn-lido {
    background: #8888;
    color: #fff;
    margin-left: 8px;

    &:hover {
      background: ${darken(0.06, '#8888')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;
