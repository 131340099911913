import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  h4 {
    margin-bottom: 6px;
  }
`;

export const Messenger = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;

  .messengers {
    display: block;
    width: 100%;
    padding-bottom: 20px;

    .messenger + .messenger {
      margin-top: 14px;
      padding-top: 14px;
      border-top: 1px dotted #999;
    }

    .messenger {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;

      .avatar {
        width: 40px;
        height: 40px;
        background: #ccc;
        border-radius: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }
      }

      .buble {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 5px;
          margin-bottom: 6px;

          a + a {
            margin-left: 7px;
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 11px;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        p {
          display: block;
          margin-left: 5px;
          background: #ccc;
          border-radius: 6px;
          padding: 4px 6px;
        }

        small {
          display: block;
          padding-left: 6px;
          font-size: 11px;
        }
      }
    }
  }

  .inputMessenger {
    display: flex;
    flex: 1;
    background: #fff;
    border-radius: 4px;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input[type='file'] {
        display: none;
      }
    }

    form {
      display: flex;
      flex: 1;
      justify-content: space-between;

      .separa {
        display: flex;
        width: 80%;
        flex-direction: row;
      }

      input {
        background: none;
        width: 100%;
        height: 68px;
        border: none;
        padding-left: 8px;
      }

      button {
        border: none;
        background: none;
        margin-right: 10px;
      }
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const Files = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 12px;

  li + li {
    margin-top: 4px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: 4px;
    border: 1px solid #eee;
    padding: 4px;

    p {
      font-size: 14px;
    }

    .btn {
      display: flex;
      flex-direction: row;

      a + a {
        margin-left: 4px;
      }

      .linkView {
        background: #00468c;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 12px;
        color: #fff;

        &:hover {
          background: ${darken(0.08, '#00468c')};
        }
      }

      .linkDownload {
        background: #009d9d;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 12px;
        color: #fff;

        &:hover {
          background: ${darken(0.08, '#009d9d')};
        }
      }
    }

    .iconText {
      display: flex;
      flex-direction: row;

      div {
        width: 25px;
        padding-right: 4px;
      }
    }

    img {
      width: 100%;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex: 1;
  background: #eee;
  border-radius: 4px;
  font-size: 14px;
  padding: 18px;
  margin-top: 10px;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 8px;

  .autor {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
      border-radius: 35px;
    }
  }

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background: #999;
  }

  .user {
    font-size: 14px;
    padding-left: 6px;

    p {
      font-size: 13px;
    }

    small {
      font-size: 12px;
      color: #999;
    }
  }

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 22px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a + a {
    background: #b22d00;
    margin-left: 10px;
  }

  a:hover {
    opacity: 0.5;
  }

  .btn {
    display: flex;
    align-items: center;
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 5px;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;
