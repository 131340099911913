import React, { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import { format, parseISO } from 'date-fns';

import CheckboxGroup from 'react-checkbox-group';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Content,
  Bloco,
  Card,
  Modal,
  ContentModal,
  Pesquisa,
  FormList,
} from './styles';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando/Button';
import LoadPage from '~/components/Carregando/Tabela';

const useStyles = makeStyles({
  list: {
    width: 550,
  },
  fullList: {
    width: 'auto',
  },
});

const tabelas = [
  { id: 'diretor', title: 'Diretoria' },
  { id: 'paralegal', title: 'Paralegal' },
  { id: 'contabil', title: 'Contábil' },
  { id: 'fiscal', title: 'Fiscal' },
  { id: 'tributos', title: 'Tributos' },
  { id: 'pessoal', title: 'Pessoal' },
  { id: 'consultoria', title: 'Consultoria' },
  { id: 'ti', title: 'Tecnologia da Informação' },
];

const monthArray = [
  { id: '1', title: 'Janeiro' },
  { id: '2', title: 'Fevereiro' },
  { id: '3', title: 'Março' },
  { id: '4', title: 'Abril' },
  { id: '5', title: 'Maio' },
  { id: '6', title: 'Junho' },
  { id: '7', title: 'Julho' },
  { id: '8', title: 'Agosto' },
  { id: '9', title: 'Setembro' },
  { id: '10', title: 'Outubro' },
  { id: '11', title: 'Novembro' },
  { id: '12', title: 'Dezembro' },
];

const mesArray = [
  { id: '1', title: '1 mês antes' },
  { id: '2', title: '2 mês antes' },
  { id: '3', title: '3 mês antes' },
  { id: '4', title: '4 mês antes' },
  { id: '5', title: '5 mês antes' },
  { id: '6', title: '6 mês antes' },
];

export default function Activity() {
  const profile = useSelector(state => state.user.profile);
  const [activity, setActivity] = useState([]);
  const [trueActivity, setTrueActivity] = useState([]);
  const [idUpdate, setIdUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadpage, setLoadPage] = useState(false);
  const [check, setCheck] = useState([]);
  const [edit, setEdit] = useState(0);
  const [selectAll, setSelectAll] = useState([]);
  const [checkType, setCheckType] = useState(false);
  const [openNotify, setOpenNotify] = useState('');

  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    delete: false,
    clean: false,
    type: '',
    notify: '',
    month: '',
    day: '',
    daynotify: '',
    prazodays: '',
  });
  const [titulo, setTitle] = useState('');
  const [prazoday, setPrazoDay] = useState('');
  // const [prazo, setPrazo] = useState(0);
  const [titleCat, setTitleCat] = useState('');

  useEffect(() => {
    setLoading(true);
    api.get('tb/geral?tb=ticket&status=false').then(response => {
      const percorre = response.data.map(item => ({
        ...item,
        created_at: format(parseISO(item.created_at), 'dd-MM-yyyy'),
      }));

      setActivity(percorre);
      setLoading(false);
    });
  }, [loadpage]);

  const handleTicket = useCallback(async e => {
    const { data } = await api.get(
      `tb/geral?tb=ticket&status=true&funcao=${e}`
    );

    const percorreCheck = data.map(item => item.id);
    setSelectAll(percorreCheck);
    setTrueActivity(data);
  }, []);

  const handleModal = useCallback(e => {
    if (e.open) {
      setModal(true);
      return;
    }

    setModal(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    const title = document.querySelector(`.title`).value;
    const { role_id } = profile;
    const cargo = profile.cargo.subtitle;

    if (!state.notify) {
      toast.warning('Ops! Preencha o campo Deseja repetir o ticket?');
      return;
    }

    if (state.notify === 'mensal') {
      if (!state.day) {
        toast.warning('Ops! Preencha o campo Todo o dia.');
        return;
      }

      if (!state.daynotify) {
        toast.warning('Ops! Preencha o campo Notificar com antencedência.');
        return;
      }
    }

    if (state.notify === 'anual') {
      if (!state.month) {
        toast.warning('Ops! Preencha o campo Mês.');
        return;
      }

      if (!state.day) {
        toast.warning('Ops! Preencha o campo Todo o dia.');
        return;
      }

      if (!state.daynotify) {
        toast.warning('Ops! Preencha o campo Notificar com antencedência.');
        return;
      }
    }

    if (role_id === 1 && !state.type) {
      toast.warning('Ops! Selecione o tipo do setor.');
      return;
    }

    setLoading(true);
    try {
      await api.post('tb/geral', {
        title,
        tb_name: 'ticket',
        comando: true,
        idSub: true,
        type: role_id === 1 ? state.type : cargo,
        notify: state.notify === 'nao' ? '' : state.notify,
        month: state.notify === 'nao' ? '' : state.month,
        day: state.notify === 'nao' ? '' : state.day,
        daynotify: state.notify === 'nao' ? '' : state.daynotify,
      });

      toast.success('Atividade adicionado com sucesso!');

      setLoading(false);
      setLoadPage(!loadpage);
      setModal(false);
      setState({
        ...state,
        type: '',
        notify: '',
        month: '',
        day: '',
        daynotify: '',
        clean: false,
      });
      setOpenNotify('');
    } catch (err) {
      toast.error('Algo deu errado.');
      setLoading(false);
    }
  }, [loadpage, profile, state]);

  const handleDelete = useCallback(
    async e => {
      await api.delete(`tb/geral/${e}`);

      toast.success('Deletado com sucesso!');
      setLoadPage(!loadpage);
    },
    [loadpage]
  );

  async function handleUpdate(e) {
    if (e === 'add') {
      const findTicket = activity.find(item => item.id === idUpdate);

      if (!titulo) {
        toast.warning('Por Favor! Preencha o campo titulo');
        return;
      }

      // if (!prazo) {
      //   toast.warning('Por Favor! Preencha o campo prazo');
      //   return;
      // }

      setLoading(true);
      try {
        const { data } = await api.post(`tb/geral`, {
          title: titulo,
          tb_name: 'ticket',
          type: findTicket.type,
          subticket: true,
        });

        setTrueActivity([...trueActivity, data]);
        setLoading(false);
        return;
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
        return;
      }
    }

    if (e.tipo === 'categoria') {
      try {
        setBtnLoading(true);
        const find = activity.find(item => item.id === e.id);

        const dadosTitulo = titulo || find.title;
        const dadosNotify = state.notify || find.notify;
        const dadosMonth = state.month || find.month;
        const dadosDay = state.day || find.day;
        const dadosDayNotify = state.daynotify || find.daynotify;
        const dadosPrazoDays = prazoday || state.prazodays;

        await api.put(`tb/geral/${e.id}`, {
          title: dadosTitulo,
          type: find.type,
          idSub: find.idSub,
          notify: state.clean ? '' : dadosNotify,
          month: state.clean ? '' : dadosMonth,
          day: state.clean ? '' : dadosDay,
          daynotify: state.clean ? '' : dadosDayNotify,
          prazodays: state.clean ? '' : dadosPrazoDays,
        });

        if (state.clean) {
          const percorre = activity.map(item => ({
            ...item,
            title: item.id === e.id ? '' : item.title,
            notify: item.id === e.id ? '' : item.notify,
            month: item.id === e.id ? '' : item.month,
            day: item.id === e.id ? '' : item.day,
            daynotify: item.id === e.id ? '' : item.daynotify,
            prazodays: item.id === e.id ? '' : item.prazodays,
          }));

          setActivity(percorre);
        } else {
          const percorre = activity.map(item => ({
            ...item,
            title: item.id === e.id ? dadosTitulo : item.title,
            notify: item.id === e.id ? dadosNotify : item.notify,
            month: item.id === e.id ? dadosMonth : item.month,
            day: item.id === e.id ? dadosDay : item.day,
            daynotify: item.id === e.id ? dadosDayNotify : item.daynotify,
            prazodays: item.id === e.id ? dadosPrazoDays : item.prazodays,
          }));

          setActivity(percorre);
        }

        setPrazoDay('');
        setTitle('');
        setEdit(0);
        setBtnLoading(false);
        setState({
          ...state,
          notify: '',
          month: '',
          day: '',
          daynotify: '',
          clean: false,
        });
        setOpenNotify('');

        toast.success('Atualizado com sucesso');

        return;
      } catch (err) {
        toast.error('Algo deu Errado!');
        return;
      }
    }

    try {
      const { type, notify, month, day } = activity.find(
        item => item.id === idUpdate
      );
      const { data } = await api.put(`tb/geral/${idUpdate}`, {
        type,
        idSub: check,
        notify,
        month,
        day,
      });

      const percorre = activity.map(item => ({
        ...item,
        idSub: item.id === idUpdate ? data.idSub : item.idSub,
      }));

      setActivity(percorre);

      setState({ ...state, right: false });
      setCheck([]);

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  function handleChangeCategory(event) {
    setTitle(event.target.value);
  }

  const toggleDrawer = anchor => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setCheck(anchor.idSub);

    if (!anchor.open) {
      setCheck([]);
    }

    const { idTicket, title } = anchor;

    if (idTicket) {
      setIdUpdate(anchor.idTicket);
    }

    if (title) {
      setTitleCat(anchor.title);
    }

    setState({ ...state, right: anchor.open });
    handleTicket(anchor.funcao);
  };

  const list = anchor => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]:
            anchor.anchor === 'top' || anchor.anchor === 'bottom',
        })}
        role="presentation"
        onKeyDown={toggleDrawer({
          anchor: 'right',
          open: true,
          title: '',
          idSub: [],
          idTicket: 0,
        })}
      >
        <FormList>
          <h4>
            <input
              type="checkbox"
              checked={checkType}
              onClick={() => {
                if (checkType) {
                  setCheckType(false);
                  setCheck([]);
                  return;
                }
                setCheckType(true);
                setCheck(selectAll);
              }}
            />{' '}
            Categoria: {titleCat}
          </h4>
        </FormList>
        {trueActivity.length > 0 && (
          <>
            <CheckboxGroup name="check" value={check} onChange={setCheck}>
              {Checkbox => (
                <>
                  {trueActivity.map(item => (
                    <ListItem key={item.id}>
                      <ListItemIcon>
                        <Checkbox value={item.id} />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </>
              )}
            </CheckboxGroup>
            <FormList>
              <button type="button" onClick={() => handleUpdate('update')}>
                ADICIONAR LISTA
              </button>
            </FormList>
          </>
        )}

        <Divider />

        <FormList>
          <h4>Adicionar categoria</h4>
          <TextField
            id="standard-basic"
            label="Titulo"
            onChange={handleChangeCategory}
          />
          {/* <TextField
            id="standard-basic"
            label="Prazo em dia"
            type="number"
            maxlength="2"
            size="4"
            onChange={e =>
              handleChangeCategory({ event: e.target.value, type: 'prazo' })
            }
          /> */}
          <button
            type="button"
            className="orange"
            onClick={() => handleUpdate('add')}
          >
            {loading ? 'CARREGANDO...' : 'ATUALIZAR LISTA'}
          </button>
        </FormList>
      </div>
    </>
  );

  return (
    <>
      <Nav page="tickets" />
      <Container>
        <User />

        <Content>
          <Title title="Tickets / Adicionar / Atividade" />

          <Bloco>
            <div>
              <h2>Tickets / Adicionar / Atividade</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <button
              type="button"
              className="btn-green"
              onClick={() => handleModal({ open: true, id: 0 })}
            >
              Adicionar
            </button>
          </Pesquisa>
        </Content>

        <Card>
          {loading ? (
            <div className="carregando">
              <LoadPage />
            </div>
          ) : (
            <>
              {profile.role_id === 1 ? (
                <>
                  {tabelas.map(tab => (
                    <>
                      {activity.find(item => item.type === tab.id) && (
                        <>
                          <h4 style={{ marginTop: 10, marginBottom: 10 }}>
                            {tab.title}
                          </h4>
                          <table className="tab table-striped">
                            <thead>
                              <tr>
                                <td width="8%">Prazo Dias</td>
                                <td width="24%">Titulo</td>
                                <td width="8%">SubProcesso</td>
                                <td width="8%">QTD Tickets</td>
                                <td width="23%">
                                  Repetir Obrigação Mensal / Anual
                                </td>
                                <td>Created At</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {activity
                                .filter(item => item.type === tab.id)
                                .map(item => (
                                  <tr>
                                    <td>
                                      {edit === item.id ? (
                                        <input
                                          type="text"
                                          onChange={e =>
                                            setPrazoDay(e.target.value)
                                          }
                                          defaultValue={item.prazodays}
                                        />
                                      ) : (
                                        <>
                                          {item.prazodays
                                            ? `${item.prazodays} dias`
                                            : 'Sem prazo'}
                                        </>
                                      )}
                                    </td>
                                    <td className="columtext">
                                      {edit === item.id ? (
                                        <TextField
                                          id="title"
                                          defaultValue={item.title}
                                          onChange={e =>
                                            setTitle(e.target.value)
                                          }
                                        />
                                      ) : (
                                        item.title
                                      )}
                                    </td>
                                    <td>
                                      {item.idSub.length > 0 ? (
                                        <span className="subprocesso">
                                          Sim (Subprocesso: {item.idSub.length})
                                        </span>
                                      ) : (
                                        <span className="subprocessoN">
                                          Não
                                        </span>
                                      )}
                                    </td>
                                    <td>{item.countTicket}</td>
                                    <td>
                                      {edit === item.id ? (
                                        <div className="formtable">
                                          {!state.notify && item.notify ? (
                                            <>
                                              {state.clean ? (
                                                'Você limpou! Clique em salvar para apagar.'
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="notifybtn"
                                                  onClick={() => {
                                                    setOpenNotify(item);
                                                    setState({
                                                      ...state,
                                                      notify: item.notify,
                                                      month: item.month,
                                                      day: item.day,
                                                      daynotify: item.daynotify,
                                                      prazodays: item.prazodays,
                                                      delete: true,
                                                    });
                                                  }}
                                                >
                                                  {item.notify === 'mensal' && (
                                                    <>
                                                      (<strong>Tipo:</strong>{' '}
                                                      Mensal) - (Todo o dia{' '}
                                                      {item.day}) - (Notificar
                                                      com antecedência{' '}
                                                      {item.daynotify} dias)
                                                    </>
                                                  )}
                                                  {item.notify === 'anual' && (
                                                    <>
                                                      (<strong>Tipo:</strong>{' '}
                                                      Anual) - (
                                                      <strong>Mês:</strong>{' '}
                                                      {item.month} -{' '}
                                                      <strong>Dia:</strong>{' '}
                                                      {item.day}) - (Notificar
                                                      com antecedência{' '}
                                                      {item.daynotify}{' '}
                                                      {item.daynotify > '1'
                                                        ? 'meses'
                                                        : 'mês'}
                                                      )
                                                    </>
                                                  )}
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {state.notify ? (
                                                <button
                                                  type="button"
                                                  className="notifyedit"
                                                  onClick={() =>
                                                    setOpenNotify({
                                                      notify: state.notify,
                                                      month: state.month,
                                                      day: state.day,
                                                      daynotify:
                                                        state.daynotify,
                                                    })
                                                  }
                                                >
                                                  {state.notify ===
                                                    'mensal' && (
                                                    <>
                                                      (<strong>Tipo:</strong>{' '}
                                                      Mensal) - (Todo o dia{' '}
                                                      {state.day}) - (Notificar
                                                      com antecedência{' '}
                                                      {state.daynotify} dias)
                                                    </>
                                                  )}
                                                  {state.notify === 'anual' && (
                                                    <>
                                                      (<strong>Tipo:</strong>{' '}
                                                      Anual) - (
                                                      <strong>Mês:</strong>{' '}
                                                      {state.month} -{' '}
                                                      <strong>Dia:</strong>{' '}
                                                      {state.day}) - (Notificar
                                                      com antecedência{' '}
                                                      {state.daynotify}{' '}
                                                      {state.daynotify > '1'
                                                        ? 'meses'
                                                        : 'mês'}
                                                      )
                                                    </>
                                                  )}{' '}
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="notifybtn"
                                                  onClick={() =>
                                                    setOpenNotify(item)
                                                  }
                                                >
                                                  Cadastrar envio automático
                                                  ticket
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          {item.notify ? (
                                            <>
                                              {item.notify === 'mensal' && (
                                                <>
                                                  (<strong>Tipo:</strong>{' '}
                                                  Mensal) - (Todo o dia{' '}
                                                  {item.day}) - (Notificar com
                                                  antecedência {item.daynotify}{' '}
                                                  dias)
                                                </>
                                              )}
                                              {item.notify === 'anual' && (
                                                <>
                                                  (<strong>Tipo:</strong> Anual)
                                                  - (<strong>Mês:</strong>{' '}
                                                  {item.month} -{' '}
                                                  <strong>Dia:</strong>{' '}
                                                  {item.day}) - (Notificar com
                                                  antecedência {item.daynotify}{' '}
                                                  {item.daynotify > '1'
                                                    ? 'meses'
                                                    : 'mês'}
                                                  )
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            'Não Existe'
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>{item.created_at}</td>
                                    <td align="right">
                                      <div className="btn-activity">
                                        <button
                                          type="button"
                                          className="btn-green"
                                          onClick={toggleDrawer({
                                            anchor: 'right',
                                            title: item.title,
                                            idSub: item.idSub,
                                            open: true,
                                            idTicket: item.id,
                                            funcao: item.type,
                                          })}
                                        >
                                          Atualizar ou Adicionar Subprocesso
                                        </button>
                                        {edit === item.id ? (
                                          <button
                                            type="button"
                                            className="btn-blue"
                                            onClick={() =>
                                              handleUpdate({
                                                tipo: 'categoria',
                                                id: item.id,
                                              })
                                            }
                                          >
                                            {btnLoading ? (
                                              <Carregando />
                                            ) : (
                                              'Salvar'
                                            )}
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-warning"
                                            onClick={() => setEdit(item.id)}
                                          >
                                            Editar
                                          </button>
                                        )}
                                        {edit === item.id ? (
                                          <button
                                            type="button"
                                            className="btn-delete"
                                            onClick={() => {
                                              setEdit(0);
                                              setOpenNotify('');
                                              setState({
                                                ...state,
                                                notify: '',
                                                month: '',
                                                day: '',
                                                daynotify: '',
                                                prazodays: '',
                                                clean: false,
                                              });
                                            }}
                                          >
                                            Cancelar
                                          </button>
                                        ) : (
                                          <>
                                            {item.countTicket > 0 ? (
                                              <button
                                                type="button"
                                                className="btn-delete"
                                                onClick={() => {
                                                  toast.warning(
                                                    `Você não pode deletar pois tem tickets registrado (${item.countTicket})`
                                                  );
                                                }}
                                              >
                                                Deletar
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn-delete"
                                                onClick={() =>
                                                  handleDelete(item.id)
                                                }
                                              >
                                                Deletar
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <table className="tab table-striped">
                  <thead>
                    <tr>
                      <td width="16%">Titulo</td>
                      <td width="12%">SubProcesso</td>
                      <td width="8%">QTD Tickets</td>
                      <td width="27%">Repetir Obrigação Mensal / Anual</td>
                      <td>Created At</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {activity.map(item => (
                      <tr>
                        <td className="columtext">
                          {edit === item.id ? (
                            <TextField
                              id="title"
                              defaultValue={item.title}
                              onChange={e => setTitle(e.target.value)}
                            />
                          ) : (
                            item.title
                          )}
                        </td>
                        <td>
                          {item.idSub.length > 0 ? (
                            <span className="subprocesso">
                              Sim (Subprocesso: {item.idSub.length})
                            </span>
                          ) : (
                            <span className="subprocessoN">Não</span>
                          )}
                        </td>
                        <td>{item.countTicket}</td>
                        <td>
                          {edit === item.id ? (
                            <div className="formtable">
                              {!state.notify && item.notify ? (
                                <>
                                  {state.clean ? (
                                    'Você limpou! Clique em salvar para apagar.'
                                  ) : (
                                    <button
                                      type="button"
                                      className="notifybtn"
                                      onClick={() => {
                                        setOpenNotify(item);
                                        setState({
                                          ...state,
                                          notify: item.notify,
                                          month: item.month,
                                          day: item.day,
                                          daynotify: item.daynotify,
                                          prazodays: item.prazodays,
                                          delete: true,
                                        });
                                      }}
                                    >
                                      {item.notify === 'mensal' && (
                                        <>
                                          (<strong>Tipo:</strong> Mensal) -
                                          (Todo o dia {item.day}) - (Notificar
                                          com antecedência {item.daynotify}{' '}
                                          dias)
                                        </>
                                      )}
                                      {item.notify === 'anual' && (
                                        <>
                                          (<strong>Tipo:</strong> Anual) - (
                                          <strong>Mês:</strong> {item.month} -{' '}
                                          <strong>Dia:</strong> {item.day}) -
                                          (Notificar com antecedência{' '}
                                          {item.daynotify}{' '}
                                          {item.daynotify > '1'
                                            ? 'meses'
                                            : 'mês'}
                                          )
                                        </>
                                      )}
                                    </button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {state.notify ? (
                                    <button
                                      type="button"
                                      className="notifyedit"
                                      onClick={() =>
                                        setOpenNotify({
                                          notify: state.notify,
                                          month: state.month,
                                          day: state.day,
                                          daynotify: state.daynotify,
                                        })
                                      }
                                    >
                                      {state.notify === 'mensal' && (
                                        <>
                                          (<strong>Tipo:</strong> Mensal) -
                                          (Todo o dia {state.day}) - (Notificar
                                          com antecedência {state.daynotify}{' '}
                                          dias)
                                        </>
                                      )}
                                      {state.notify === 'anual' && (
                                        <>
                                          (<strong>Tipo:</strong> Anual) - (
                                          <strong>Mês:</strong> {state.month} -{' '}
                                          <strong>Dia:</strong> {state.day}) -
                                          (Notificar com antecedência{' '}
                                          {state.daynotify}{' '}
                                          {state.daynotify > '1'
                                            ? 'meses'
                                            : 'mês'}
                                          )
                                        </>
                                      )}{' '}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="notifybtn"
                                      onClick={() => setOpenNotify(item)}
                                    >
                                      Cadastrar envio automático ticket
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              {item.notify ? (
                                <>
                                  {item.notify === 'mensal' && (
                                    <>
                                      (<strong>Tipo:</strong> Mensal) - (Todo o
                                      dia {item.day}) - (Notificar com
                                      antecedência {item.daynotify} dias)
                                    </>
                                  )}
                                  {item.notify === 'anual' && (
                                    <>
                                      (<strong>Tipo:</strong> Anual) - (
                                      <strong>Mês:</strong> {item.month} -{' '}
                                      <strong>Dia:</strong> {item.day}) -
                                      (Notificar com antecedência{' '}
                                      {item.daynotify}{' '}
                                      {item.daynotify > '1' ? 'meses' : 'mês'})
                                    </>
                                  )}
                                </>
                              ) : (
                                'Não Existe'
                              )}
                            </>
                          )}
                        </td>
                        <td>{item.created_at}</td>
                        <td align="right">
                          <div className="btn-activity">
                            <button
                              type="button"
                              className="btn-green"
                              onClick={toggleDrawer({
                                anchor: 'right',
                                title: item.title,
                                idSub: item.idSub,
                                open: true,
                                idTicket: item.id,
                                funcao: item.type,
                              })}
                            >
                              Atualizar ou Adicionar Subprocesso
                            </button>
                            {edit === item.id ? (
                              <button
                                type="button"
                                className="btn-blue"
                                onClick={() =>
                                  handleUpdate({
                                    tipo: 'categoria',
                                    id: item.id,
                                  })
                                }
                              >
                                {btnLoading ? <Carregando /> : 'Salvar'}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-warning"
                                onClick={() => setEdit(item.id)}
                              >
                                Editar
                              </button>
                            )}
                            {edit === item.id ? (
                              <button
                                type="button"
                                className="btn-delete"
                                onClick={() => {
                                  setEdit(0);
                                  setOpenNotify('');
                                  setState({
                                    ...state,
                                    notify: '',
                                    month: '',
                                    day: '',
                                    daynotify: '',
                                    prazodays: '',
                                    clean: false,
                                  });
                                }}
                              >
                                Cancelar
                              </button>
                            ) : (
                              <>
                                {item.countTicket > 0 ? (
                                  <button
                                    type="button"
                                    className="btn-delete"
                                    onClick={() => {
                                      toast.warning(
                                        `Você não pode deletar pois tem tickets registrado (${item.countTicket})`
                                      );
                                    }}
                                  >
                                    Deletar
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn-delete"
                                    onClick={() => handleDelete(item.id)}
                                  >
                                    Deletar
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </Card>
      </Container>

      <Footer />

      <Modal modal={modal}>
        <ContentModal>
          {loading ? (
            <Carregando />
          ) : (
            <>
              <h4>Adicionar Atividade</h4>
              <div className="inputForm">
                <select
                  className="notify"
                  onChange={e => setState({ ...state, notify: e.target.value })}
                >
                  <option value="">Deseja repetir o ticket?</option>
                  <option value="nao">Não</option>
                  <option value="mensal">Sim (Mensal)</option>
                  <option value="anual">Sim (Anual)</option>
                </select>
              </div>
              {state.notify === 'anual' && (
                <>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, month: e.target.value })
                      }
                    >
                      <option value="">Selecione o mês</option>
                      {monthArray.map(mon => (
                        <option key={mon.id} value={mon.id}>
                          {mon.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="inputForm">
                    <input
                      type="number"
                      className="white"
                      placeholder="Todo o dia"
                      onChange={e =>
                        setState({ ...state, day: e.target.value })
                      }
                    />
                  </div>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, daynotify: e.target.value })
                      }
                    >
                      <option value="">
                        Notificar com quantos meses de antecedência?
                      </option>
                      {mesArray.map(mes => (
                        <option
                          value={mes.id}
                          selected={openNotify.daynotify === mes.id}
                        >
                          {mes.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {state.notify === 'mensal' && (
                <>
                  <div className="inputForm">
                    <input
                      type="number"
                      placeholder="Todo o dia"
                      onChange={e =>
                        setState({ ...state, day: e.target.value })
                      }
                    />
                  </div>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, daynotify: e.target.value })
                      }
                    >
                      <option value="">
                        Notificar com quantos dias de antecedência?
                      </option>
                      <option value="5">5 dias antes</option>
                      <option value="10">10 dias antes</option>
                      <option value="15">15 dias antes</option>
                      <option value="30">30 dias antes</option>
                    </select>
                  </div>
                </>
              )}
              {profile.role_id === 1 && (
                <div className="inputForm">
                  <select
                    className="type"
                    onChange={e => setState({ ...state, type: e.target.value })}
                  >
                    <option value="">Selecione o setor</option>
                    {tabelas.map(tab => (
                      <option value={tab.id}>{tab.title}</option>
                    ))}
                  </select>
                </div>
              )}
              <div className="inputForm">
                <input type="text" placeholder="Título" className="title" />
              </div>
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={handleSubmit}
                >
                  Adicionar
                </button>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => {
                    handleModal({ open: false, id: 0 });
                    setState({
                      ...state,
                      notify: '',
                      month: '',
                      day: '',
                      daynotify: '',
                    });
                  }}
                >
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ContentModal>
      </Modal>

      <Modal modal={openNotify}>
        <ContentModal>
          {loading ? (
            <Carregando />
          ) : (
            <>
              <h4>Repetir Ticket</h4>
              <div className="inputForm">
                <select
                  className="notify"
                  onChange={e => setState({ ...state, notify: e.target.value })}
                >
                  <option value="">Escolha tipo de repetição</option>
                  <option
                    value="mensal"
                    selected={openNotify.notify === 'mensal'}
                  >
                    Repetir Mensal
                  </option>
                  <option
                    value="anual"
                    selected={openNotify.notify === 'anual'}
                  >
                    Repetir Anual
                  </option>
                </select>
              </div>
              {state.notify === 'anual' && (
                <>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, month: e.target.value })
                      }
                    >
                      <option value="">Selecione o mês</option>
                      {monthArray.map(mon => (
                        <option
                          key={mon.id}
                          value={mon.id}
                          selected={openNotify.month === mon.id}
                        >
                          {mon.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="inputForm">
                    <input
                      type="number"
                      className="white"
                      placeholder="Todo o dia"
                      defaultValue={openNotify.day}
                      onChange={e =>
                        setState({ ...state, day: e.target.value })
                      }
                    />
                  </div>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, daynotify: e.target.value })
                      }
                    >
                      <option value="">
                        Notificar com quantos meses de antecedência?
                      </option>
                      {mesArray.map(mes => (
                        <option
                          value={mes.id}
                          selected={openNotify.daynotify === mes.id}
                        >
                          {mes.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              {state.notify === 'mensal' && (
                <>
                  <div className="inputForm">
                    <input
                      type="number"
                      placeholder="Todo o dia"
                      defaultValue={openNotify.day}
                      onChange={e =>
                        setState({ ...state, day: e.target.value })
                      }
                    />
                  </div>
                  <div className="inputForm">
                    <select
                      className="white"
                      onChange={e =>
                        setState({ ...state, daynotify: e.target.value })
                      }
                    >
                      <option value="">
                        Notificar com quantos dias de antecedência?
                      </option>
                      <option value="5" selected={openNotify.daynotify === '5'}>
                        5 dias antes
                      </option>
                      <option
                        value="10"
                        selected={openNotify.daynotify === '10'}
                      >
                        10 dias antes
                      </option>
                      <option
                        value="15"
                        selected={openNotify.daynotify === '15'}
                      >
                        15 dias antes
                      </option>
                      <option
                        value="30"
                        selected={openNotify.daynotify === '30'}
                      >
                        30 dias antes
                      </option>
                    </select>
                  </div>
                </>
              )}
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={() => setOpenNotify('')}
                >
                  Atualizar
                </button>
                {state.delete ? (
                  <button
                    type="button"
                    className="btn-delete"
                    onClick={() => {
                      setOpenNotify('');
                      setState({
                        ...state,
                        notify: '',
                        month: '',
                        day: '',
                        daynotify: '',
                        delete: false,
                        clean: true,
                      });
                    }}
                  >
                    Excluir Repetição
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-delete"
                    onClick={() => {
                      setOpenNotify('');
                      setState({
                        ...state,
                        notify: '',
                        month: '',
                        day: '',
                        daynotify: '',
                      });
                    }}
                  >
                    Cancelar
                  </button>
                )}
              </div>
            </>
          )}
        </ContentModal>
      </Modal>

      <div>
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </div>
    </>
  );
}
