import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding-left: 17.5px;
  padding-right: 17.5px;
  position: relative;
`;

export const Grid = styled.div`
  display: flex;
  background: #fff;
  border-radius: 4px;
  padding: 6px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  margin-left: 2.5px;
  margin-right: 2.5px;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3px;

  span {
    width: 30px;
    height: 30px;
    background: #eeeeee;
    border-radius: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  img {
    width: 30px;
    height: 30px;
    background: #eeeeee;
    border-radius: 15px;
  }
`;

export const Discription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .loading {
    display: block;
    width: 100%;
    flex: 1;
    font-size: 4px;

    + .loading {
      margin-top: 4px;
    }
  }
`;

export const Name = styled.div`
  display: block;
  font-size: 11px;
  color: #000;
`;

export const Email = styled.p`
  font-size: 9px;
  color: #999;
`;

export const Acesso = styled.p`
  font-size: 9px;
  color: #008080;
  margin-top: 3px;
`;
