import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import { useSelector } from 'react-redux';

import { MdExitToApp } from 'react-icons/md';
import { IoIosTrendingUp, IoMdPodium } from 'react-icons/io';

import { format, parseISO } from 'date-fns';

import { toast } from 'react-toastify';

import 'react-month-picker-input/dist/react-month-picker-input.css';

import { Form, Input } from '@rocketseat/unform';
import api from '~/services/api';

import {
  Content,
  Bloco,
  Card,
  Submenu,
  Pesquisa,
  Pagination,
  Qtd,
  PageVer,
  Grid,
} from './styles';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando/Button';
import MenuSub from '~/components/Navigation/Obrigacoes';
import history from '~/services/history';

export default function Periodica() {
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [, setDelFuncao] = useState(!getStorageFuncao);
  const [loadPage, setLoadPage] = useState(true);
  const [ativaTr, setTr] = useState(false);
  const [acordos, setAcordos] = useState([]);
  const [total, setTotal] = useState(1);
  const [loadSave, setLoadSave] = useState(false);
  const [edit, setEdit] = useState(0);

  const [faturou, setFaturou] = useState('');
  const [exeexterno, setExeExterno] = useState('');
  const [contrato, setContrato] = useState('');
  const [acordo, setAcordo] = useState('');
  const [userTitle, setUserTitle] = useState(false);
  const [userAcordo, setUserAcordo] = useState(false);
  const [userInicio, setUserInicio] = useState(false);
  const [count, setCount] = useState({
    total: 0,
    qtd: 0,
  });

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const limit = params.get('limit') || '1000';
  const page = params.get('page') || 1;
  const state = params.get('status') || 'pendentes';
  const sExe = params.get('exe') || '';
  const sCon = params.get('con') || '';
  const search = params.get('search') || '';

  useEffect(() => {
    async function loadAcordos() {
      setLoadPage(true);
      const response = await api.get(
        `acordo?status=${state}&limit=${limit}&page=${page}&search=${search}&exe=${sExe}&con=${sCon}`
      );

      const percorre = response.data.data.map(item => ({
        ...item,
        formattedAcordo: item.date_acordo
          ? format(parseISO(item.date_acordo), 'dd-MM-yyyy')
          : '',
        formattedStatus: item.status
          ? format(parseISO(item.status), 'dd-MM-yyyy')
          : '',
        formattedComunication: item.comunication
          ? format(parseISO(item.comunication), 'dd-MM-yyyy')
          : '',
        formattedInicio: item.inicio_vig
          ? format(parseISO(item.inicio_vig), 'dd-MM-yyyy')
          : '',
      }));

      const qtdArray = percorre.map(item => (item.qtd ? item.qtd : 0));

      const reduce = qtdArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      setCount({ total: response.data.total, qtd: reduce });
      setLoadPage(false);
      setAcordos(percorre);
      setTotal(response.data.last_page);
    }

    loadAcordos();
  }, [limit, page, sCon, sExe, search, state]); //eslint-disable-line

  function handleSetor(e) {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
  }

  async function handleSave() {
    const title = document.querySelector('#title').value;
    const qtd = document.querySelector('#qtd').value;
    const date_acordo = document.querySelector('#date_acordo').value;
    const inicio_vig = document.querySelector('#inicio_vig').value;
    const sindicato = document.querySelector('#sindicato').value;
    const status = document.querySelector('#status').value;
    const comunication = document.querySelector('#comunication').value;

    setLoadSave(true);

    try {
      const { data } = await api.post('acordo', {
        title,
        exe_externo: exeexterno,
        contrato,
        faturou,
        tipo: acordo,
        qtd,
        date_acordo,
        inicio_vig,
        sindicato,
        status,
        comunication,
      });

      const DadosPercorre = {
        ...data,
        formattedAcordo: data.date_acordo
          ? format(parseISO(data.date_acordo), 'dd-MM-yyyy')
          : '',
        formattedStatus: data.status
          ? format(parseISO(data.status), 'dd-MM-yyyy')
          : '',
        formattedComunication: data.comunication
          ? format(parseISO(data.comunication), 'dd-MM-yyyy')
          : '',
        formattedInicio: data.inicio_vig
          ? format(parseISO(data.inicio_vig), 'dd-MM-yyyy')
          : '',
      };

      const object = { DadosPercorre };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(acordos);

      setAcordos(dadosArray);

      setTr(false);

      toast.success('Adicionado com sucesso.');
      setLoadSave(false);

      setAcordo('');
      setFaturou('');
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
      setLoadSave(false);
      setAcordo('');
      setFaturou('');
    }
  }

  async function handleDelete(e) {
    await api.delete(`acordo/${e}`);

    setEdit(0);

    setAcordos(acordos.filter(item => item.id !== e));

    toast.success('Deletado com sucesso!');
  }

  async function handleUpdate(e) {
    const title = document.querySelector('#title').value;
    const qtd = document.querySelector('#qtd').value;
    const date_acordo = document.querySelector('#date_acordo').value;
    const inicio_vig = document.querySelector('#inicio_vig').value;
    const sindicato = document.querySelector('#sindicato').value;
    const status = document.querySelector('#status').value;
    const comunication = document.querySelector('#comunication').value;

    const itemfaturou = faturou || e.itemFaturou;
    const tipo = acordo || e.itemAcordo;
    const itemContrato = contrato || e.itemContrato;
    const itemExe = exeexterno || e.itemExeExterno;

    try {
      setLoadSave(true);
      await api.put(`acordo/${e.id}`, {
        title,
        user_emp: userTitle ? profile.name : null,
        exe_externo: itemExe,
        contrato: itemContrato,
        faturou: itemfaturou,
        tipo,
        user_tipo: acordo ? profile.name : null,
        qtd,
        date_acordo,
        user_acordo: userAcordo ? profile.name : null,
        inicio_vig,
        user_inicio: userInicio ? profile.name : null,
        sindicato,
        status,
        comunication,
      });

      const formatAcordo = date_acordo
        ? format(parseISO(date_acordo), 'dd-MM-yyyy')
        : '';

      const formatStatus = status ? format(parseISO(status), 'dd-MM-yyyy') : '';

      const formatComunication = comunication
        ? format(parseISO(comunication), 'dd-MM-yyyy')
        : '';

      const formatInicio = inicio_vig
        ? format(parseISO(inicio_vig), 'dd-MM-yyyy')
        : '';

      const dados = acordos.map(item => ({
        ...item,
        title: item.id === e.id ? title : item.title,
        exe_externo: item.id === e.id ? itemExe : item.exe_externo,
        contrato: item.id === e.id ? itemContrato : item.contrato,
        faturou: item.id === e.id ? itemfaturou : item.faturou,
        tipo: item.id === e.id ? tipo : item.tipo,
        qtd: item.id === e.id ? qtd : item.qtd,
        date_acordo: item.id === e.id ? date_acordo : item.date_acordo,
        formattedAcordo: item.id === e.id ? formatAcordo : item.formattedAcordo,
        sindicato: item.id === e.id ? sindicato : item.sindicato,
        status: item.id === e.id ? status : item.status,
        formattedStatus: item.id === e.id ? formatStatus : item.formattedStatus,
        formattedInicio: item.id === e.id ? formatInicio : item.formattedInicio,
        comunication: item.id === e.id ? comunication : item.comunication,
        formattedComunication:
          item.id === e.id ? formatComunication : item.formattedComunication,
      }));

      setEdit(0);
      setAcordos(dados);
      setAcordo('');
      setFaturou('');

      setLoadSave(false);
      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo Deu Errado!');
      setEdit(0);
      setAcordo('');
      setFaturou('');
      setLoadSave(false);
    }
  }

  function handleCancela(e) {
    if (e) {
      setTr(!ativaTr);
      setEdit(0);
      setAcordo('');
      setFaturou('');
      return;
    }
    setEdit(0);
    setTr(false);
    setAcordo('');
    setFaturou('');
  }

  function handlePage(e) {
    if (e === 'prev') {
      const ptotal = page === 1 ? 1 : page - 1;
      history.push(
        `/periodica-acordo?status=${state}&page=${ptotal}&limit=${limit}&search=${search}&exe=${sExe}&con=${sCon}`
      );
      return;
    }
    const ptotal = Number(page) + 1;
    history.push(
      `/periodica-acordo?status=${state}&page=${ptotal}&limit=${limit}&search=${search}&exe=${sExe}&con=${sCon}`
    );
  }

  function handlePageSelect({ paginate }) {
    history.push(
      `/periodica-acordo?status=${state}&page=${paginate}&limit=${limit}&search=${search}&exe=${sExe}&con=${sCon}`
    );
  }

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="obrigacoes-periodicas" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title="Obrigações / Periódicas / MP 936 - CONTRATO DE TRABALHO" />

          <Bloco>
            <div>
              <h2>Obrigações / Periódicas / MP 936 - CONTRATO DE TRABALHO</h2>
              <p>
                <strong>ATENÇÃO:</strong> PARA CADA TIPO DE DEMANDA (SUSPENSÃO,
                REDUÇÃO 25%, RED. 50%, RED. 70%) INCLUIR UMA LINHA SEPARADA! SE
                A MESMA EMPRESA (CNPJ) FIZER OS 4 TIPOS DE ACORDO, ELA APARECERÁ
                EM 4 LINHAS DIFERENTES! DA MESMA FORMA, SE A MESMA EMPRESA FIZER
                INCLUSÃO DE UM GRUPO DE FUNCIONÁRIOS EM UMA DATA, E DEPOIS PEDIR
                OUTROS FUNCIONÁRIOS (OUTRA DATA) DEVE SER CRIADA UMA OUTRA LINHA
                COM ESSE NOVO PEDIDO!
              </p>
            </div>
          </Bloco>

          <Bloco>
            <div className="bloco">
              <Grid>
                <div className="total">
                  <p>Total</p>
                  {loadPage ? (
                    'Carregando...'
                  ) : (
                    <>
                      <span className="blue">{count.total}</span>
                    </>
                  )}
                </div>
                <div className="icon">
                  <IoMdPodium size={68} color="#00468c" />
                </div>
              </Grid>

              <Grid>
                <div className="total">
                  <p>Quantidade de Acordos</p>
                  {loadPage ? (
                    'Carregando...'
                  ) : (
                    <>
                      <span className="green">{count.qtd}</span>
                    </>
                  )}
                </div>
                <div className="icon">
                  <IoIosTrendingUp size={68} color="#36d900" />
                </div>
              </Grid>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <button
                type="button"
                className={ativaTr ? 'btn-cancela' : 'btn-green'}
                onClick={() => handleCancela(true)}
              >
                {ativaTr ? 'Cancelar' : 'Adicionar'}
              </button>
            </div>
            <div className="bloco-one">
              <select
                onChange={e =>
                  history.push(
                    `periodica-acordo?status=${e.target.value}&page=${page}&limit=${limit}&search=${search}&exe=${sExe}&con=${sCon}`
                  )
                }
              >
                <option value="todos" selected={state === 'todos'}>
                  Exibir Todos
                </option>
                <option value="pendentes" selected={state === 'pendentes'}>
                  Exibir Pendentes
                </option>
                <option value="concluidos" selected={state === 'concluidos'}>
                  Exibir Concluídos
                </option>
              </select>
              <Form
                onSubmit={({ s }) =>
                  history.push(
                    `periodica-acordo?status=${state}&page=${page}&limit=${limit}&search=${s}&exe=${sExe}&con=${sCon}`
                  )
                }
              >
                <Input name="s" placeholder="Pesquisar..." />
                <button type="submit" className="hidden">
                  s
                </button>
              </Form>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td width="15%">Empresa</td>
                <td width="7.72%">
                  <div>
                    Executor Externo
                    <select
                      className="margin"
                      onChange={e =>
                        history.push(
                          `periodica-acordo?status=${state}&page=${page}&limit=${limit}&search=${search}&exe=${e.target.value}&con=${sCon}`
                        )
                      }
                    >
                      <option value="">...</option>
                      <option value="sim" selected={sExe === 'sim'}>
                        Sim
                      </option>
                      <option value="não" selected={sExe === 'nao'}>
                        Não
                      </option>
                    </select>
                  </div>
                </td>
                <td width="7.72%">
                  <div>
                    Status Contrato / Acordo
                    <select
                      className="margin"
                      onChange={e =>
                        history.push(
                          `periodica-acordo?status=${state}&page=${page}&limit=${limit}&search=${search}&exe=${sExe}&con=${e.target.value}`
                        )
                      }
                    >
                      <option value="">...</option>
                      <option value="feito" selected={sCon === 'feito'}>
                        Feito
                      </option>
                      <option value="pendente" selected={sCon === 'pendente'}>
                        Pendente
                      </option>
                    </select>
                  </div>
                </td>
                <td width="7.72%">Empresa faturou mais de R$ 4,8mi em 2019?</td>
                <td width="7.72%">Tipo de acordo (clique e selecione)</td>
                <td width="7.72%">Quantidade de Acordo</td>
                <td width="7.72%">Data de Acordo</td>
                <td width="7.72%">Início da Vigência</td>
                <td width="7.72%">Sindicato</td>
                <td width="7.72%">
                  Status / Data Comunicação Sindicato / FEDER. / CONFED.
                </td>
                <td width="7.72%">Comunicação no ministério da economia</td>
                <td width="7.72%" />
              </tr>
            </thead>
            <tbody>
              {loadPage ? (
                'Carregando...'
              ) : (
                <>
                  {ativaTr && (
                    <tr>
                      <td>
                        <TextField id="title" label="" />
                      </td>
                      <td>
                        <Select
                          native
                          value={exeexterno}
                          id="exeexterno"
                          onChange={e => setExeExterno(e.target.value)}
                          inputProps={{
                            name: 'exeexterno',
                            id: 'exeexterno',
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="SIM">SIM</option>
                          <option value="NÃO">NÃO</option>
                        </Select>
                      </td>
                      <td>
                        <Select
                          native
                          value={contrato}
                          onChange={e => setContrato(e.target.value)}
                          inputProps={{
                            name: 'contrato',
                            id: 'contrato',
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="FEITO">FEITO</option>
                          <option value="PENDENTE">PENDENTE</option>
                        </Select>
                      </td>
                      <td>
                        <Select
                          native
                          value={faturou}
                          id="faturou"
                          onChange={e => setFaturou(e.target.value)}
                          inputProps={{
                            name: 'faturou',
                            id: 'age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="SIM">SIM</option>
                          <option value="NÃO">NÃO</option>
                        </Select>
                      </td>
                      <td>
                        <Select
                          native
                          value={acordo}
                          id="acordo"
                          onChange={e => setAcordo(e.target.value)}
                          inputProps={{
                            name: 'acordo',
                            id: 'age-native-simple',
                          }}
                        >
                          <option aria-label="None" value="" />
                          <option value="SUSPENSÃO">SUSPENSÃO</option>
                          <option value="REDUÇÃO 25%">REDUÇÃO 25%</option>
                          <option value="REDUÇÃO 50%">REDUÇÃO 50%</option>
                          <option value="REDUÇÃO 70%">REDUÇÃO 70%</option>
                        </Select>
                      </td>
                      <td>
                        <TextField
                          id="qtd"
                          label=""
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </td>
                      <td>
                        <TextField
                          id="date_acordo"
                          label=""
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </td>
                      <td>
                        <TextField
                          id="inicio_vig"
                          label=""
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </td>
                      <td>
                        <TextField id="sindicato" label="" />
                      </td>
                      <td>
                        <TextField id="status" type="date" />
                      </td>
                      <td>
                        <TextField id="comunication" type="date" />
                      </td>
                      <td>
                        <span>
                          <buton
                            type="button"
                            className="btn-green"
                            onClick={handleSave}
                          >
                            {loadSave ? <Carregando /> : 'Salvar'}
                          </buton>
                          <buton
                            type="button"
                            className="btnDelete"
                            onClick={() => handleCancela(false)}
                          >
                            Cancelar
                          </buton>
                        </span>
                      </td>
                    </tr>
                  )}
                  {acordos.map(item => (
                    <>
                      {edit === item.id ? (
                        <tr>
                          <td>
                            <TextField
                              id="title"
                              defaultValue={item.title}
                              onChange={() => setUserTitle(true)}
                            />
                          </td>
                          <td>
                            <Select
                              native
                              value={exeexterno || item.exe_externo}
                              id="exeexterno"
                              onChange={e => setExeExterno(e.target.value)}
                              inputProps={{
                                name: 'exe_externo',
                                id: 'exeexterno',
                              }}
                            >
                              <option value="SIM">SIM</option>
                              <option value="NÃO">NÃO</option>
                            </Select>
                          </td>
                          <td>
                            <Select
                              native
                              value={contrato || item.contrato}
                              onChange={e => setContrato(e.target.value)}
                              inputProps={{
                                name: 'contrato',
                                id: 'contrato',
                              }}
                            >
                              <option value="FEITO">FEITO</option>
                              <option value="PENDENTE">PENDENTE</option>
                            </Select>
                          </td>
                          <td>
                            <Select
                              native
                              value={faturou || item.faturou}
                              id="faturou"
                              onChange={e => setFaturou(e.target.value)}
                              inputProps={{
                                name: 'faturou',
                                id: 'age-native-simple',
                              }}
                            >
                              <option value="SIM">SIM</option>
                              <option value="NÃO">NÃO</option>
                            </Select>
                          </td>
                          <td>
                            <Select
                              native
                              value={acordo || item.tipo}
                              id="acordo"
                              onChange={e => setAcordo(e.target.value)}
                              inputProps={{
                                name: 'acordo',
                                id: 'age-native-simple',
                              }}
                            >
                              <option value="SUSPENSÃO">SUSPENSÃO</option>
                              <option value="REDUÇÃO 25%">REDUÇÃO 25%</option>
                              <option value="REDUÇÃO 50%">REDUÇÃO 50%</option>
                              <option value="REDUÇÃO 70%">REDUÇÃO 70%</option>
                            </Select>
                          </td>
                          <td>
                            <TextField
                              id="qtd"
                              label=""
                              defaultValue={item.qtd}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id="date_acordo"
                              label=""
                              type="date"
                              onChange={() => setUserAcordo(true)}
                              defaultValue={item.date_acordo}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id="inicio_vig"
                              label=""
                              type="date"
                              onChange={() => setUserInicio(true)}
                              defaultValue={item.inicio_vig}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id="sindicato"
                              defaultValue={item.sindicato}
                              label=""
                            />
                          </td>
                          <td>
                            <TextField
                              id="status"
                              defaultValue={item.status}
                              type="date"
                            />
                          </td>
                          <td>
                            <TextField
                              id="comunication"
                              defaultValue={item.comunication}
                              type="date"
                            />
                          </td>
                          <td>
                            <span>
                              <buton
                                type="button"
                                className="btn-green"
                                onClick={() =>
                                  handleUpdate({
                                    id: item.id,
                                    itemFaturou: item.faturou,
                                    itemContrato: item.contrato,
                                    itemExeExterno: item.exe_externo,
                                    itemAcordo: item.tipo,
                                  })
                                }
                              >
                                {loadSave ? <Carregando /> : 'Salvar'}
                              </buton>
                              <buton
                                type="button"
                                className="btnDelete"
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </buton>
                            </span>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            {item.title}
                            <p className="paragrafo">
                              {item.users && `Edit: ${item.users.user_emp}`}
                            </p>
                          </td>
                          <td>{item.exe_externo}</td>
                          <td>{item.contrato}</td>
                          <td>{item.faturou}</td>
                          <td>
                            {item.tipo}
                            <p className="paragrafo">
                              {item.users && `Edit: ${item.users.user_tipo}`}
                            </p>
                          </td>
                          <td>{item.qtd}</td>
                          <td>
                            {item.formattedAcordo}
                            <p className="paragrafo">
                              {item.users && `Edit: ${item.users.user_acordo}`}
                            </p>
                          </td>
                          <td>
                            {item.formattedInicio}
                            <p className="paragrafo">
                              {item.users && `Edit: ${item.users.user_inicio}`}
                            </p>
                          </td>
                          <td>{item.sindicato}</td>
                          <td>{item.formattedStatus}</td>
                          <td>{item.formattedComunication}</td>
                          <td>
                            <div>
                              <buton
                                type="button"
                                className="btnEdit"
                                onClick={() => setEdit(item.id)}
                              >
                                Editar
                              </buton>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <div className="pagination">
            <Qtd>
              <span>Página de:</span>
              <select
                onChange={e =>
                  history.push(
                    `periodica-acordo?status=${state}&page=${page}&limit=${e.target.value}&search=${search}&exe=${sExe}&con=${sCon}`
                  )
                }
              >
                <option value="10" selected={limit === '10' || false}>
                  10
                </option>
                <option value="30" selected={limit === '30' || false}>
                  30
                </option>
                <option value="40" selected={limit === '50' || false}>
                  50
                </option>
                <option value="70" selected={limit === '70' || false}>
                  70
                </option>
                <option value="90" selected={limit === '90' || false}>
                  90
                </option>
                <option value="100" selected={limit === '100' || false}>
                  100
                </option>
                <option value="500" selected={limit === '500' || false}>
                  500
                </option>
                <option value="1000" selected={limit === '1000' || false}>
                  1000
                </option>
              </select>
            </Qtd>
            <PageVer>
              {total > 1 && (
                <>
                  <Form onSubmit={handlePageSelect}>
                    <span>
                      Página de {page} para {total}
                    </span>
                    <Input name="page" />
                    <button type="submit" className="hidden">
                      s
                    </button>
                  </Form>
                  <button
                    type="button"
                    onClick={() => handlePage('prev')}
                    disabled={page === 1 || false}
                  >
                    Anterior
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePage('next')}
                    disabled={page === total || false}
                  >
                    Próximo
                  </button>
                </>
              )}
            </PageVer>
          </div>
        </Pagination>

        <Footer />
      </Container>
    </>
  );
}
