import styled from 'styled-components';

export const Grid = styled.div`
  display: ${Props => (Props.on ? 'flex' : 'none')};
  flex-direction: column;
  flex: 1;

  .content {
    display: block;
    width: 100%;
    margin-top: 12px;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  }

  .content + .content {
    margin-top: 12px;
  }

  .tabelas {
    display: flex;
    flex: 1;

    div {
      background: #ccc;
      border-radius: 5px;
      height: 32px;
      color: #000;
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
      flex: 1;
      font-size: 12px;

      strong {
        margin-right: 4px;
      }
    }

    div + div {
      margin-left: 7px;
    }
  }

  .tabelas + .tabelas {
    margin-top: 10px;
  }
`;
