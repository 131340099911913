import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

function SelectOption({ children, label, ...rest }) {
  return (
    <FormControl variant={rest.variant}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select label={label} {...rest}>
        <option aria-label="None" value="" />
        {children}
      </Select>
    </FormControl>
  );
}

export default SelectOption;
