import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
  margin-top: 22px;

  .date {
    flex: auto;
    background: #0059b2;
    padding: 4px 8px 4px 8px;
    color: #fff;
  }

  input {
    height: auto;
    background: none;
    border: 0;
  }
  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #fff;
    &:hover {
      background: #0faf69;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .conteudo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      padding-top: 8px;
    }

    div {
      display: flex;
    }

    a {
      background: #00b259;
      color: #fff;
      border-radius: 5px;
      margin-right: 22px;
      padding: none;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        background: ${darken(0.03, '#0FAF69')};
      }
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;
  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;

  .total {
    display: block;

    p {
      font-size: 12px;
      color: #000;
    }

    span {
      font-size: 42px;
      font-weight: bold;
    }
  }

  .blue {
    color: #00468c;
  }

  .green {
    color: #36d900;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff3e3e;
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;

    + select {
      margin-left: 5px;
    }
  }
  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  .bloco-one {
    display: flex;
    align-items: center;
    justify-items: center;

    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }

    .btn-green {
      background: #00b285;
      border: 0;
      border-radius: 4px;
      padding: 4px 12px 4px 12px;
      color: #fff;
      font-size: 16px;
    }

    .btn-ok {
      background: #aaaaaa;
      border: 0;
      border-radius: 4px;
      padding: 4px 12px 4px 12px;
      color: #fff;
      font-size: 16px;
    }

    .btn-red {
      background: #ff4d4d;
      border: 0;
      border-radius: 4px;
      padding: 4px 12px 4px 12px;
      color: #fff;
      font-size: 16px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }
`;

export const Pagination = styled.div`
  display: block;
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
  }
  select {
    border: 0;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
  }
`;

export const Loading = styled.div`
  position: fixed;
  display: flex;
  z-index: 9;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;

  img {
    width: 70px;
    height: 70px;
    animation: ${rotate} 1s linear infinite;
  }
`;
