import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 8;
  width: 24px;
  right: 210px;
  top: 20px;

  img {
    width: 24px;
  }

  button {
    border: 0;
    background: transparent;
  }
`;

export const Notification = styled.div`
  position: absolute;
  z-index: 5;
  padding: none;
  background: #fff;
  width: 380px;
  left: -360px;
  top: 30px;
  border-radius: 5px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .scrollbar {
    z-index: 999;
    margin: none;
    padding: none;
  }

  .bloco {
    display: block;
    flex: 1;
    padding-left: 0px;

    div + div {
      border-top: 1px dotted #ccc;
    }

    .red {
      color: #0059b2;
    }

    .green {
      color: #008c69;
    }
  }

  span {
    display: flex;
    flex-direction: column;

    p {
      font-size: 10px;
    }
  }

  div {
    display: flex;
    margin-left: 0;
  }

  .lido {
    background: #999;
    padding: 4px;
    font-size: 10px;
    color: #000;
    border-radius: 4px;
  }

  button {
    display: block;
    background: #00b259;
    padding: 4px;
    font-size: 10px;
    color: #fff;
    border: 0;
    border-radius: 4px;

    &:hover {
      background: #008a45;
    }
  }
`;

export const IconCount = styled.div`
  background: #ff4d4d;
  color: #fff;
  font-size: 10px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 1px;
  padding-top: 1px;
  border-radius: 4px;
  position: absolute;
  top: -10px;
  right: 0px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  color: #000;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;

  a {
    color: #000;
  }

  &:hover {
    background: #eee;
  }
`;

export const Load = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
`;
