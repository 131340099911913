import React, { useState, useEffect, useCallback } from 'react';

import { uuid } from 'uuidv4';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { useSelector } from 'react-redux';

import { MdExitToApp } from 'react-icons/md';

import api from '~/services/api';

import { Content, Bloco, Card, Submenu, BForm, BtnSave } from './styles';

import history from '~/services/history';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import MenuSub from '~/components/Navigation/Obrigacoes';
import LoadingButton from '~/components/Carregando/MaterialUi/button';
import FormLoad from '~/components/Carregando/Skeleton/Forms';

export default function Create() {
  const { token } = useParams();
  const profile = useSelector(state => state.user.profile);
  const [loadform, setloadForm] = useState(true);
  const [addfield, setAddField] = useState({
    title: '',
    type: '',
    width: '',
    format: false,
    validation: false,
  });
  const [fields, setFields] = useState([]);
  const [forms, setForms] = useState({
    id: 0,
    title: '',
    description: '',
    viewcliente: '',
    tipo: '',
  });
  const [permissions, setPermissions] = useState([]);
  const [autoloads, setAutoLoads] = useState([]);
  const [autooption, setAutoOption] = useState([]);
  const [valPermission, setValPermission] = useState('');
  const [editBtn, setEditBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadplanilha, setLoadPlanilha] = useState(false);
  const [onOption, setOnOption] = useState(false);

  function handleSetor(e) {
    if (e === 'open') {
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
  }

  const handleChange = useCallback(
    event => {
      event.preventDefault();

      if (event.target.value === 'select') {
        setOnOption(true);
      }

      if (event.target.value === 'text') {
        setOnOption(false);
        setAutoOption([]);
      }

      if (event.target.value === 'number') {
        setOnOption(false);
        setAutoOption([]);
      }

      if (event.target.value === 'textarea') {
        setOnOption(false);
        setAutoOption([]);
      }

      setAddField({
        ...addfield,
        [event.target.name]: event.target.value,
      });
    },
    [addfield]
  );

  const handleField = useCallback(() => {
    if (!addfield.title) {
      toast.warning('Por favor! Preencha o campo titulo');
      return;
    }

    if (!addfield.type) {
      toast.warning('Por favor! Preencha o campo tipo');
      return;
    }

    if (addfield.type === 'select' && autooption.length === 0) {
      toast.warning('Por favor! Adicione o campo option');
      return;
    }

    const replaceUuid = uuid();

    const iduuid = replaceUuid.replace(/[^a-z]+/g, '');

    const data = {
      ...addfield,
      name: iduuid,
      width: addfield.width ? addfield.width : false,
      validation: addfield.validation === 'sim',
      option: autooption.length > 0 ? autooption : false,
    };

    setFields([...fields, data]);
  }, [addfield, autooption, fields]);

  const handleRemove = name => {
    const filter = fields.filter(field => field.name !== name);

    setFields(filter);
  };

  const handlePermission = async e => {
    setValPermission(e);

    if (e === 'users') {
      setLoading(true);
      const response = await api.get('users?usersGeral=true');

      setAutoLoads(response.data);
      setEditBtn(true);
      setPermissions([]);
      setLoading(false);
      setForms({ ...forms, tipo: e });
      return;
    }

    if (e === 'cargos') {
      setLoading(true);
      const response = await api.get('cargos?geral=true');

      setAutoLoads(response.data);
      setLoading(false);
      setPermissions([]);
      setEditBtn(true);
      setForms({ ...forms, tipo: e });
      return;
    }

    if (e === 'public') {
      setForms({ ...forms, tipo: e });
      setPermissions([]);
      setEditBtn(false);
      return;
    }

    setForms({ ...forms, tipo: 'eu' });
    setPermissions([]);
    setEditBtn(false);
  };

  const handleUpdated = ({ id, event, inputName }) => {
    const roam = fields.map(field => ({
      name: field.name,
      title: field.name === id && inputName === 'title' ? event : field.title,
      type: field.name === id && inputName === 'type' ? event : field.type,
      width: field.name === id && inputName === 'width' ? event : field.width,
      format:
        field.name === id && inputName === 'format' ? event : field.format,
      validation:
        field.name === id && inputName === 'validation'
          ? event
          : field.validation,
      option: field.name === id && inputName === 'type' ? [] : field.option,
    }));

    setFields(roam);
  };

  const handleOption = ({ value, name }) => {
    const roam = fields.map(field => ({
      ...field,
      option: field.name === name ? value : field.option,
    }));

    setFields(roam);
  };

  const handlePlanilha = async () => {
    const percorrePermission = permissions.map(permission => permission.id);
    try {
      setLoadPlanilha(true);
      const { data } = await api.put(`/periodicaTabs/${forms.id}`, {
        title: forms.title,
        description: forms.description,
        fields: fields.length > 0 ? fields : '',
        tipo: forms.tipo,
        permission: percorrePermission.length > 0 ? percorrePermission : '',
      });

      if (data.error) {
        toast.warning(data.error);
        setLoadPlanilha(false);
        return;
      }

      toast.success('Sucesso! Planilha digital atualizada.');
      setLoadPlanilha(false);
      history.push(`/periodica/${token}`);
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
      setLoadPlanilha(false);
    }
  };

  useEffect(() => {
    async function loadTab() {
      const response = await api.get(`/periodicaTabs/${token}?edit=true`);

      if (response.data.error) {
        history.goBack();
        toast.warning(response.data.error);
        return;
      }

      if (response.data.tipo === 'users') {
        const users = await api.get('users?usersGeral=true');
        setPermissions(response.data.permission);
        setAutoLoads(users.data);
        setEditBtn(true);
      }

      if (response.data.tipo === 'cargos') {
        const cargos = await api.get('cargos?geral=true');
        setPermissions(response.data.permission);
        setAutoLoads(cargos.data);
        setEditBtn(true);
      }

      setValPermission(response.data.tipo);
      setFields(response.data.fields);
      setForms({
        id: response.data.id,
        title: response.data.title,
        description: response.data.description,
        viewcliente: response.data.viewcliente,
        tipo: response.data.tipo,
      });
      setloadForm(false);
    }

    loadTab();
  }, [token]);

  return (
    <>
      <Nav page="obrigacoes" />
      <Container flexDirection="column">
        <User />

        <Submenu>
          <MenuSub page="obrigacoes-periodicas" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title={`Obrigações / Periódicas / Editar / ${forms.title}`} />

          <Bloco>
            <div>
              <h2>Obrigações / Periódicas / Editar / {forms.title}</h2>
            </div>
          </Bloco>
        </Content>

        {loadform ? (
          <Card>
            <FormLoad />
          </Card>
        ) : (
          <>
            <Card>
              <BForm>
                <div className="form-input">
                  <TextField
                    id="outlined-read-only-input"
                    label="Título"
                    name="title"
                    value={forms.title}
                    onChange={e =>
                      setForms({ ...forms, title: e.target.value })
                    }
                    variant="outlined"
                  />
                </div>
              </BForm>
              <BForm>
                <div className="form-input">
                  <TextareaAutosize
                    aria-label="minimum height"
                    rowsMin={8}
                    value={forms.description}
                    name="description"
                    onChange={e =>
                      setForms({ ...forms, description: e.target.value })
                    }
                    placeholder="Digite a descrição do post."
                  />
                </div>
              </BForm>
            </Card>

            <Card>
              <BForm>
                <div>
                  <h4>Tipo de visualização na planilha</h4>
                  <p>
                    Obs* Escolha esssa opção para ativar o campo clientes como
                    autoload e título deve conter o nome *CLIENTES*
                  </p>
                </div>
              </BForm>
              <BForm>
                <div className="form-input">
                  <FormControl variant="outlined" className="select">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Escolha o tipo
                    </InputLabel>
                    <Select
                      native
                      value={forms.viewcliente}
                      label="Escolher o tipo da permissão"
                      onChange={e =>
                        setForms({ ...forms, viewcliente: e.target.value })
                      }
                      name="viewcliente"
                    >
                      <option aria-label="None" value="" />
                      <option value="inputclientes">
                        Quero ativar lista dos clientes
                      </option>
                    </Select>
                  </FormControl>
                </div>
              </BForm>
              <BForm>
                <h4>Permissão</h4>
              </BForm>
              <BForm>
                <div className="form-input">
                  <FormControl variant="outlined" className="select">
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Escolher o tipo da permissão
                    </InputLabel>
                    <Select
                      native
                      value={valPermission}
                      label="Escolher o tipo da permissão"
                      onChange={e => handlePermission(e.target.value)}
                      name="type"
                    >
                      <option aria-label="None" value="" />
                      <option value="users">por usuários</option>
                      <option value="cargos">por cargos</option>
                      <option value="eu">somente eu</option>
                      <option value="public">público</option>
                    </Select>
                  </FormControl>
                </div>
              </BForm>
              {editBtn && (
                <>
                  {loading ? (
                    'Carregando...'
                  ) : (
                    <div className="form-input">
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        onChange={(event, newValue) => {
                          setPermissions(newValue);
                        }}
                        defaultValue={permissions}
                        options={autoloads}
                        getOptionLabel={option => option.title}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Escolher permissão"
                            placeholder="Escolher Permissão"
                          />
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </Card>

            <Card>
              <BForm>
                <h4>Adicionar campo</h4>
              </BForm>
              <BForm>
                <div className="form-input">
                  <TextField
                    id="outlined-read-only-input"
                    label="Título"
                    name="title"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-input">
                  <FormControl variant="outlined">
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      native
                      value={addfield.type}
                      name="type"
                      label="Tipo"
                      onChange={handleChange}
                    >
                      <option aria-label="None" value="" />
                      <option value="text">Texto</option>
                      <option value="date">Data</option>
                      <option value="number">Somente Número</option>
                      <option value="textarea">Texto Longo</option>
                      <option value="select">Caixa de seleção</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-input">
                  <TextField
                    id="outlined-read-only-input"
                    label="Width"
                    name="width"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-input">
                  <FormControl variant="outlined">
                    <InputLabel>Formato</InputLabel>
                    <Select
                      native
                      value={addfield.format}
                      name="format"
                      label="Formato"
                      onChange={handleChange}
                      disabled={addfield.type !== 'text'}
                    >
                      <option aria-label="None" value="" />
                      <option value="cnpj">cnpj</option>
                      <option value="cpf">cpf</option>
                      <option value="cep">cep</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-input">
                  <FormControl variant="outlined">
                    <InputLabel>Validar Campo</InputLabel>
                    <Select
                      native
                      value={addfield.validation}
                      name="validation"
                      label="Validar Campo"
                      onChange={handleChange}
                    >
                      <option aria-label="None" value="" />
                      <option value="nao">Não</option>
                      <option value="sim">Sim</option>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-input">
                  <button
                    type="button"
                    className="btn-blue"
                    onClick={handleField}
                  >
                    ADICIONAR CAMPO
                  </button>
                </div>
              </BForm>
              {onOption && (
                <div className="form-input">
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    onChange={(event, newValue) => {
                      setAutoOption(newValue);
                    }}
                    options={autooption}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Adicionar Option"
                        placeholder="Digite aqui para adicionar"
                      />
                    )}
                  />
                </div>
              )}
            </Card>

            {fields.map(field => (
              <Card key={field.name} color>
                <BForm>
                  <div className="form-input">
                    <TextField
                      id="outlined-read-only-input"
                      label="Título"
                      name="title"
                      defaultValue={field.title}
                      variant="outlined"
                      onChange={e =>
                        handleUpdated({
                          id: field.name,
                          event: e.target.value,
                          inputName: e.target.name,
                        })
                      }
                    />
                  </div>
                  <div className="form-input">
                    <FormControl variant="outlined">
                      <InputLabel>Tipo</InputLabel>
                      <Select
                        native
                        value={field.type}
                        name="type"
                        label="Tipo"
                        onChange={e =>
                          handleUpdated({
                            id: field.name,
                            event: e.target.value,
                            inputName: e.target.name,
                          })
                        }
                      >
                        <option aria-label="None" value="" />
                        <option value="text">Texto</option>
                        <option value="date">Data</option>
                        <option value="number">Somente Número</option>
                        <option value="textarea">Texto Longo</option>
                        <option value="select">Caixa de seleção</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <TextField
                      id="outlined-read-only-input"
                      label="Width"
                      name="width"
                      defaultValue={field.width ? field.width : ''}
                      variant="outlined"
                      onChange={e =>
                        handleUpdated({
                          id: field.name,
                          event: e.target.value,
                          inputName: e.target.name,
                        })
                      }
                    />
                  </div>
                  <div className="form-input">
                    <FormControl variant="outlined">
                      <InputLabel>Formato</InputLabel>
                      <Select
                        native
                        value={field.format ? field.format : ''}
                        name="format"
                        label="Formato"
                        onChange={e =>
                          handleUpdated({
                            id: field.name,
                            event: e.target.value,
                            inputName: e.target.name,
                          })
                        }
                        disabled={field.type !== 'text'}
                      >
                        <option aria-label="None" value="" />
                        <option value="cnpj">cnpj</option>
                        <option value="cpf">cpf</option>
                        <option value="cep">cep</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <FormControl variant="outlined">
                      <InputLabel>Validar Campo</InputLabel>
                      <Select
                        native
                        value={field.validation}
                        name="validation"
                        label="Validar Campo"
                        onChange={e =>
                          handleUpdated({
                            id: field.name,
                            event: e.target.value,
                            inputName: e.target.name,
                          })
                        }
                      >
                        <option aria-label="None" value="" />
                        <option value="nao">Não</option>
                        <option value="sim">Sim</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <button
                      type="button"
                      className="btn-red"
                      onClick={() => handleRemove(field.name)}
                    >
                      REMOVER CAMPO
                    </button>
                  </div>
                </BForm>
                {field.option && (
                  <div className="form-input">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      name="option"
                      options={field.option.map(op => op)}
                      onChange={(event, newValue) =>
                        handleOption({ value: newValue, name: field.name })
                      }
                      defaultValue={field.option.map(op => op)}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Adicionar Option"
                          placeholder="Digite aqui para adicionar"
                        />
                      )}
                    />
                  </div>
                )}
              </Card>
            ))}

            <BtnSave loading={loadplanilha}>
              <button type="button" onClick={handlePlanilha}>
                {!loadplanilha && 'ATUALIZAR PLANILHA'}
              </button>
              {loadplanilha && <LoadingButton size={24} />}
            </BtnSave>
          </>
        )}
      </Container>

      <Footer />
    </>
  );
}
