import styled from 'styled-components';

export const TopoContent = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Voltar = styled.div`
  display: flex;
  align-items: center;

  button {
    border: 0;
    font-size: 14px;
    color: #0059b2;
    display: flex;
    align-items: center;
    background: none;
  }
`;

export const TitleNav = styled.div`
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #999;
  font-size: 12px;
`;
