import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  IoMdPodium,
  IoIosTrendingUp,
  IoIosTrendingDown,
  IoIosCafe,
} from 'react-icons/io';

import { FaChartArea } from 'react-icons/fa';

import SemAvatar from '~/assets/no-avatar.png';

import { Grid, GridButton } from './styles';

const ExecutorExe = ({ data, month, year }) => {
  const history = useHistory();

  const handlePage = useCallback(
    event => {
      history.push(`/obrigacoes/executores/relatorios?${event}`);
    },
    [history]
  );

  return (
    <>
      {data.map(item => (
        <Grid key={item.id}>
          <div className="blocoavatar">
            <span>
              {item.avatar ? (
                <img src={item.avatar} alt={item.name} />
              ) : (
                <img src={SemAvatar} alt={item.name} />
              )}
            </span>
            <p>{item.name}</p>
          </div>

          <div className="bloco">
            <div className="grid">
              <div className="divisao">
                <div className="total">
                  <p>Total de clientes</p>
                  <span className="blue">{item.total_clientes}</span>
                </div>
                <div className="totalprazo">
                  <p>Total de Obrigações</p>
                  <span className="red">{item.obr_total}</span>
                </div>
              </div>
              <div className="icon">
                <IoIosCafe size={48} color="#00468c" />
              </div>
            </div>

            <div className="grid">
              <div className="divisao">
                <div className="total">
                  <p>A Fazer</p>
                  <span className="black">
                    {Number(item.percAfazer.toFixed(2)) === 0
                      ? 0.0
                      : item.percAfazer.toFixed(2)}
                    %
                  </span>
                </div>
                <div className="totalprazo">
                  <p>Total de Obrigações a Fazer</p>
                  <span className="red">{item.obr_total - item.contagem}</span>
                </div>
              </div>
              <div className="icon">
                <IoMdPodium size={58} color="#222222" />
              </div>
            </div>

            <GridButton
              onClick={() =>
                handlePage(
                  `year=${year}&month=${month}&obr=obr_feito_nao&executor=${item.id}`
                )
              }
            >
              <div className="divisao">
                <div className="total">
                  <p>Entregue Fora do Prazo</p>
                  <span className="red">{item.percAtraso}%</span>
                </div>
                <div className="totalprazo">
                  <p>Total Preenchido</p>
                  <span className="red">{item.obr_nao_feitos}</span>
                </div>
              </div>
              <div className="icon">
                <IoIosTrendingDown size={58} color="#ff3e3e" />
              </div>
            </GridButton>

            <GridButton
              onClick={() =>
                handlePage(
                  `year=${year}&month=${month}&obr=obr_feito_nao&executor=${item.id}`
                )
              }
            >
              <div className="divisao">
                <div className="total">
                  <p>Entregue Fora do Prazo c/ Ressalva</p>
                  <span className="orange">{item.percAtrasoC}%</span>
                </div>
                <div className="totalprazo">
                  <p>Total Preenchido</p>
                  <span className="orange">{item.obr_nao_cliente}</span>
                </div>
              </div>
              <div className="icon">
                <IoIosTrendingDown size={58} color="#ff7f00" />
              </div>
            </GridButton>

            <GridButton
              onClick={() =>
                handlePage(
                  `year=${year}&month=${month}&obr=obr_feito_prazo&executor=${item.id}`
                )
              }
            >
              <div className="divisao">
                <div className="total">
                  <p>Dentro do Prazo</p>
                  <span className="green">{item.percFeito}%</span>
                </div>
                <div className="totalprazo">
                  <p>Total Preenchido</p>
                  <span className="green">{item.obr_feitos}</span>
                </div>
              </div>
              <div className="icon">
                <IoIosTrendingUp size={58} color="#36d900" />
              </div>
            </GridButton>

            <div className="grid">
              <div className="divisao">
                <div className="total">
                  <p>Resultado</p>
                  <span className="blue">{item.percResultado.toFixed(2)}%</span>
                </div>
              </div>
              <div className="icon">
                <FaChartArea size={40} color="#0059B2" />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
};

export default ExecutorExe;
