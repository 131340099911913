import React from 'react';
import { isSameDay, isSameMonth, isSunday, isToday, format } from 'date-fns';

import { useCalendar } from '~/hooks/calendar';
// import CalendarEvent from './CalendarEvent';

import { Button, Info } from './styles';

const CalendarCell = ({ date }) => {
  const {
    events,
    currentMonth,
    selectedDate,
    setSelectedDate,
    handleOpen,
  } = useCalendar();

  if (!currentMonth || !setSelectedDate) return null;

  const thisDate = date;

  let classes = 'calendar-cell';

  if (selectedDate && isSameDay(date, selectedDate)) {
    classes += ' calendar-cell--selected';
  }

  if (isToday(date)) {
    classes += ' calendar-cell--today';
  }

  if (!isSameMonth(date, currentMonth)) {
    classes += ' calendar-cell--disabled';
  }

  if (isSunday(new Date(date))) {
    classes += ' calendar-cell--sunday';
  }

  return (
    <>
      {events.filter(event => isSameDay(date, event.date)).length > 0 ? (
        <Button
          className={classes}
          onClick={() =>
            handleOpen({
              data: events.filter(event => isSameDay(date, event.date)),
              date: isSameMonth(thisDate, currentMonth) && thisDate,
            })
          }
        >
          <div className="calendar-cell__date">{format(date, 'd')}</div>

          <Info>
            {events.filter(
              item =>
                isSameDay(date, item.date) &&
                item.type !== 'obrigação' &&
                item.type !== 'aniversário' &&
                item.type !== 'anotação'
            ).length > 0 && <span className="date" />}
            {events.filter(
              item => isSameDay(date, item.date) && item.type === 'obrigação'
            ).length > 0 && <span className="obr" />}
            {events.filter(
              item => isSameDay(date, item.date) && item.type === 'aniversário'
            ).length > 0 && <span className="birthday" />}
            {events.filter(
              item => isSameDay(date, item.date) && item.type === 'anotação'
            ).length > 0 && <span className="reminder" />}
          </Info>
        </Button>
      ) : (
        <Button
          className={classes}
          onClick={() =>
            isSameMonth(thisDate, currentMonth) && setSelectedDate(thisDate)
          }
        >
          <div className="calendar-cell__date">{format(date, 'd')}</div>
        </Button>
      )}
    </>
  );
};

export default CalendarCell;
