import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Grid = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  display: block;
  width: 100%;

  form {
    display: flex;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 12px;
    margin-right: 12px;
    background: #fff;
    border-radius: 4px;
  }
`;

export const Tabela = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  flex-direction: column;
`;

export const GridDir = styled.div`
  width: 100%;
  display: grid;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(4, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* espaço entre um elemento interno e outro */
  grid-gap: 5px 8px;
  /* margem interna que afasta os elementos da borda do grid */

  .grid-4 {
    display: flex;
    flex: 1;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    padding: 12px;
    align-items: center;
    margin-bottom: 4px;
    position: relative;

    button {
      border: none;
      background: none;
    }

    span + span {
      top: 0px;
      right: 0px;
      background: #00d900;
    }

    span {
      display: block;
      position: absolute;
      z-index: 999;
      top: 0px;
      right: 22px;
      background: #ff4d4d;
      width: 18px;
      height: 18px;
      padding-top: 1px;
      border-radius: 9px;
      font-size: 11px;
      text-align: center;
      color: #fff;

      &:hover {
        opacity: 0.5;
      }
    }

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
`;

export const GridExe = styled.div`
  width: 100%;
  display: grid;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(4, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* espaço entre um elemento interno e outro */
  grid-gap: 5px 8px;
  /* margem interna que afasta os elementos da borda do grid */

  .grid-4 {
    display: flex;
    flex: 1;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    padding: 12px;
    align-items: center;
    position: relative;

    button {
      border: none;
      background: none;
    }

    span + span {
      top: 0px;
      right: 0px;
      background: #00d900;
    }

    span {
      display: block;
      position: absolute;
      z-index: 999;
      top: 0px;
      right: 22px;
      background: #ff4d4d;
      width: 18px;
      height: 18px;
      padding-top: 1px;
      border-radius: 9px;
      font-size: 11px;
      text-align: center;
      color: #fff;

      &:hover {
        opacity: 0.5;
      }
    }

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
`;

export const Image = styled.div``;

export const Description = styled.div`
  font-size: 12px;
  padding-left: 8px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
`;

export const Titulo = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
`;

export const BtnExe = styled.div`
  display: block;
  width: 100%;

  button {
    width: 100%;
    display: flex;
    color: #fff;
    background: #0f73af;
    border: 0;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    height: 34px;

    &:hover {
      background: #2a9fe5;
    }
  }

  button.red {
    background: #ff4d4d;

    &:hover {
      background: #ff1c1c;
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const ButtonBtn = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-end;
  padding-top: 6px;
  padding-bottom: 12px;

  button {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    border: 0;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;

  p {
    font-size: 12px;
  }

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  input[type='text'],
  input[type='date'] {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
  }

  .textarea {
    height: 80px;
    font-size: 14px;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  }

  select {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
  }

  select:disabled {
    background: #eaeae4;
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    label {
      padding-bottom: 5px;
      color: #333;
      font-size: 12px;
      padding-left: 4px;
    }

    .btn-editar {
      border: 0;
      background: #ccc;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #eee;
      }
    }

    .btn-save {
      border: 0;
      background: #0faf69;
      color: #fff;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: ${darken(0.03, '#0FAF69')};
      }
    }
  }

  span + span {
    margin-left: 6px;
  }
`;
