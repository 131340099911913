import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;

  textarea {
    width: 100%;
    height: 80px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    padding: 12px;
    font-size: 14px;
  }

  .container {
    width: 100%;
  }

  .form-input {
    display: flex;
    flex: 1;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    input {
      width: 100%;
    }

    select {
      width: 100%;
    }
  }

  .bloco-conteudo {
    display: block;
    width: 100%;
  }

  .usuarioShow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .colunaUser {
    display: flex;
    flex-direction: row;
  }

  .usuarioSec {
    display: block;
    margin-top: 12px;

    h4 {
      font-size: 16px;
      margin-bottom: 12px;
    }

    img {
      width: 30px;
      border-radius: 15px;
    }
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    h5 {
      margin-bottom: 6px;
      color: #999;
    }

    div {
      display: flex;
      padding-top: 5px;
      align-items: center;

      img {
        width: 40px;
        border-radius: 20px;
      }

      span {
        margin-left: 4px;

        p {
          font-size: 12px;
          margin-bottom: 0px;
        }

        small {
          font-size: 11px;
          color: #999;
        }
      }
    }

    p {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }

  span + span {
    border-top: 1px solid #ddd;
  }

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  .form-input {
    display: flex;
    flex-direction: column;
  }

  .form-input + .form-input {
    margin-left: 8px;
  }

  .btn-save {
    flex: 1;
    width: 100%;
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const BtnSave = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 12px;
  flex: 1;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;

  button {
    background: ${Props => (Props.loading ? '#ccc' : '#008c69')};
    color: #fff;
    height: 48px;
    border-radius: 8px;
    font-size: 18px;
    border: none;

    &:hover {
      background: ${Props =>
        Props.loading ? '#ccc' : `${darken(0.06, '#008c69')}`};
    }
  }
`;
