import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { MdSearch, MdDelete, MdControlPoint, MdEdit } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';

import {
  Container,
  Content,
  Bloco,
  Card,
  Pagination,
  Pesquisa,
  Qtd,
  Modal,
  ContentModal,
  PageVer,
} from './styles';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando';

export default function Usuarios() {
  const [usuario, setUser] = useState([]);
  const [modal, setModal] = useState(false);
  const [idUser, setIdUser] = useState('');

  const [total, setTotal] = useState(1);
  const [limitView, setLimitView] = useState(10);
  const [pageView, setPageView] = useState(1);

  const [loading, setLoading] = useState(true);
  const profile = useSelector(state => state.user.profile);

  const url = new URL(window.location);

  useEffect(() => {
    async function loadUser() {
      const params = new URLSearchParams(url.search);
      const limit = params.get('limit') || 10;
      const page = params.get('page') || 1;

      const response = await api.get(`users?limit=${limit}&page=${page}`);

      setLoading(false);
      setUser(response.data.data);
      setLimitView(limit);
      setPageView(Number(page));

      setTotal(response.data.last_page);
    }

    loadUser();
  }, [profile.role_id, url.search]);

  function handleLimit(e) {
    const limit = e.target.value;

    setLimitView(limit);
    setPageView(1);
    history.push(`/usuarios?limit=${limit}&page=1`);
  }

  function handlePage(e) {
    if (e === 'prev') {
      const ptotal = pageView === 1 ? 1 : pageView - 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(`/usuarios?limit=${limitView}&page=${ptotal}`);
      return;
    }
    const ptotal = Number(pageView) + 1;
    setPageView(ptotal);
    setLimitView(limitView);
    history.push(`/usuarios?limit=${limitView}&page=${ptotal}`);
  }

  function handlePageSelect({ page }) {
    setPageView(page);
    setLimitView(limitView);
    history.push(`/usuarios?limit=${limitView}&page=${page}`);
  }

  async function handleSearch({ search }) {
    const ressearch = await api.get(`users?search=${search}`);
    setUser(ressearch.data.data);
  }

  function handleModal(e) {
    if (e) {
      setModal(true);
      setIdUser(e);
      return;
    }
    setModal(false);
    setIdUser('');
  }

  async function handleDelete(id) {
    try {
      await api.delete(`users/${id}`);

      toast.success('Deletado com sucesso.');
      setModal(false);
      setUser(usuario.filter(item => item.id !== id));
    } catch (err) {
      setModal(false);
      toast.error('Algo deu errado no delete.');
    }
  }

  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Title title="Administrador / Usuários" />
          <Bloco>
            <div>
              <h2>Usuários</h2>
            </div>
            <Link to="/adicionar/usuario">
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Pesquisar" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Usuário</td>
                <td>Setor</td>
                <td>Permissão</td>
                <td>Status</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {usuario.map(user => (
                    <tr className="table-hover">
                      <td>{user.name}</td>
                      <td>{user.cargo.title}</td>
                      <td>{user.role.title}</td>
                      <td
                        className={
                          user.status === 'Ativo' ? 'ativo' : 'inativo'
                        }
                      >
                        {user.status}
                      </td>
                      <td>
                        <div>
                          <Link to={`/edit/usuario/${user.id}`}>
                            <MdEdit size={20} color="#fff" />
                          </Link>
                          <button
                            type="button"
                            onClick={() => handleModal(user)}
                          >
                            <MdDelete size={20} color="#fff" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <div className="pagination">
            <Qtd>
              <span>Página de:</span>
              <select onChange={handleLimit}>
                <option value="10" selected={limitView === '10' || false}>
                  10
                </option>
                <option value="20" selected={limitView === '20' || false}>
                  20
                </option>
                <option value="40" selected={limitView === '40' || false}>
                  40
                </option>
                <option value="60" selected={limitView === '60' || false}>
                  60
                </option>
                <option value="80" selected={limitView === '80' || false}>
                  80
                </option>
                <option value="100" selected={limitView === '100' || false}>
                  100
                </option>
              </select>
            </Qtd>
            <PageVer>
              {total > 1 && (
                <>
                  <Form onSubmit={handlePageSelect}>
                    <span>
                      Página de {pageView} para {total}
                    </span>
                    <Input name="page" value={pageView} />
                    <button type="submit" className="hidden">
                      s
                    </button>
                  </Form>
                  <button
                    type="button"
                    onClick={() => handlePage('prev')}
                    disabled={pageView === 1 || false}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePage('next')}
                    disabled={pageView === total || false}
                  >
                    Next
                  </button>
                </>
              )}
            </PageVer>
          </div>
        </Pagination>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          <h4>Aviso!</h4>
          <p>
            Você deseja raelmente deletar
            <br />o usuário: <strong>{idUser.name}?</strong>
          </p>
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModal()}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-save"
              onClick={() => handleDelete(idUser.id)}
            >
              Sim
            </button>
          </div>
        </ContentModal>
      </Modal>
    </>
  );
}
