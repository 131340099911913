import styled from 'styled-components';

export const Pagination = styled.div`
  display: block;
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
  }

  select {
    border: 0;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
  }
`;

export const PageVer = styled.div`
  display: flex;
  align-items: center;

  form span {
    font-size: 12px;
    margin-right: 6px;
  }

  input {
    border: 0;
    width: 45px;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 8px;
  }

  button {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    border-radius: 6px;
    background: #00b259;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }

  button + button {
    margin-left: 6px;
  }

  .hidden {
    display: none;
  }

  button.desativa {
    background: #999;
  }
`;
