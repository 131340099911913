import React from 'react';

import pt from 'date-fns/locale/pt-BR';
import { startOfWeek, format, addDays } from 'date-fns';
import { useCalendar } from '~/hooks/calendar';

import { Days } from './styles';

const CalendarDays = () => {
  const { currentMonth, weekStartsOn } = useCalendar();

  if (!currentMonth) return null;

  const weekStart = startOfWeek(currentMonth, {
    weekStartsOn,
  });
  const days = [];

  for (let i = 0; i < 7; i++) {
    days.push(
      <li key={i}>{format(addDays(weekStart, i), 'EEEE', { locale: pt })}</li>
    );
  }

  return <Days>{days}</Days>;
};

export default CalendarDays;
