import styled from 'styled-components';
import { darken } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: ${Props => (Props.color ? '#F7F7F7' : '#fff')};
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;

  .select {
    width: 100%;
  }

  + div {
    margin-top: 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  textarea {
    background: none;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 6px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  .paragrafo {
    font-size: 9px;
    color: #999;
  }

  .btnEdit {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }
  }

  .btnDelete {
    background: #ff4d4d;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }

  #red {
    color: #ff4d4d;
  }
  #green {
    color: #00b259;
  }
  .verificao {
    display: flex;
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.6);
    color: #eee;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
  }
  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }
  .btn-gray {
    background: #bbbbbb;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#BBBBBB')};
    }
  }
  .btn-orange {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }
  }
`;

export const BtnSave = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  position: relative;

  button {
    background: ${Props => (Props.loading ? '#ccc' : '#008c69')};
    color: #fff;
    height: 48px;
    border-radius: 8px;
    font-size: 18px;
    border: none;

    &:hover {
      background: ${Props =>
        Props.loading ? '#ccc' : `${darken(0.06, '#008c69')}`};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  .bloco {
    display: flex;
    flex: 1;

    div + div {
      margin-left: 8px;
    }
  }

  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  p {
    padding-top: 8px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  .hidden {
    display: none;
  }

  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
    width: 200px;
    margin-right: 8px;
  }

  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }

  .btn-cancela {
    background: #ccc;
    border: 0;
    border-radius: 4px;
    color: #000;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ccc')};
    }
  }
  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  .bloco-one {
    display: flex;
    align-items: center;
    p {
      display: flex;
      font-size: 11px;
      align-items: center;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  div {
    display: flex;
    button + button {
      margin-left: 4px;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .inputForm {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .vencimento {
    font-size: 32px;
    background: #eee;
    padding: 12px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;
export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        background: #008c69;
        align-items: center;
        font-size: 12px;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;

  p {
    font-size: 12px;
    padding-top: 12px;
    display: block;
  }

  .form-input {
    display: flex;
    flex: 1;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    input {
      width: 100%;
    }

    select {
      width: 100%;
    }
  }

  .bloco-conteudo {
    display: block;
    width: 100%;
  }

  .usuarioShow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .colunaUser {
    display: flex;
    flex-direction: row;
  }

  .usuarioSec {
    display: block;
    margin-top: 12px;

    h4 {
      font-size: 16px;
      margin-bottom: 12px;
    }

    img {
      width: 30px;
      border-radius: 15px;
    }
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    h5 {
      margin-bottom: 6px;
      color: #999;
    }

    div {
      display: flex;
      padding-top: 5px;
      align-items: center;

      img {
        width: 40px;
        border-radius: 20px;
      }

      span {
        margin-left: 4px;

        p {
          font-size: 12px;
          margin-bottom: 0px;
        }

        small {
          font-size: 11px;
          color: #999;
        }
      }
    }

    p {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }

  span + span {
    border-top: 1px solid #ddd;
  }

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  .form-input {
    display: flex;
    flex-direction: column;
  }

  .form-input + .form-input {
    margin-left: 8px;
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  .btn-blue {
    border: 0;
    background: #004080;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#004080')};
    }
  }

  .btn-red {
    border: 0;
    background: #ff5555;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#FF5555')};
    }
  }
`;
