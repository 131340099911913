import React from 'react';

import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="99.999.999/9999-99"
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default TextMaskCustom;
