import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';
import { MdSave } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container, Content, Bloco, Grid, BForm } from './styles';

import { usePermission } from '~/hooks/permission';

import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';

export default function Adicionar() {
  const [loading, setLoading] = useState(false);
  const [cargo, setCargo] = useState('');
  const { id } = useParams();
  const profile = useSelector(state => state.user.profile);
  const { administradorPermission } = usePermission();

  useEffect(() => {
    administradorPermission({ role_id: profile.role_id });
    async function loadEdit() {
      const response = await api.get(`cargos/${id}`);

      setCargo(response.data);
    }

    loadEdit();
  }, [administradorPermission, id, profile.role_id]);

  async function handleSubmit({ title }) {
    try {
      setLoading(true);
      await api.put(`cargos/${id}`, {
        title,
      });

      setLoading(false);
      toast.success('Setor atualizado com sucesso.');
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado na atualização.');
    }
  }

  return (
    <>
      <Nav page="administracao" />

      <Container>
        <User />

        <Content>
          <Title title="Administrador / Setores / Editar" />

          <Bloco>
            <div>
              <h2>Editar setor</h2>
            </div>
          </Bloco>
        </Content>

        <Grid>
          <Form onSubmit={handleSubmit} initialData={cargo}>
            <BForm>
              <span>
                <label>Setores</label>
                <Input type="text" name="title" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>
    </>
  );
}
