import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';

import { toast } from 'react-toastify';

import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import User from '~/components/User';
import Nav from '~/components/Navigation';
import LoadingButton from '~/components/Carregando/MaterialUi/button';

import { Content, Card, BForm, BtnSave } from './styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '60px',
  paddingBottom: '60px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function Create() {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [positions, setPositions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loadfile, setLoadFile] = useState(false);
  const loadCustomers = open && customers.length === 0;
  const [forms, setForms] = useState({
    title: '',
    cargo_id: 0,
    text: '',
    sigilo: false,
    notification: false,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.jpeg, .jpg, .png, .pdf, .docx, .txt, .xlsx, .xml',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleFile = useCallback(
    async e => {
      setLoadFile(true);

      const dados = new FormData();

      dados.append('file', e.target.files[0]);

      try {
        const { data } = await api.post('files?annotation=true', dados);

        setFiles([data, ...files]);
        setLoadFile(false);
      } catch (err) {
        toast.error('Algo deu errado..');
        setLoadFile(false);
      }
    },
    [files]
  );

  const handleDelete = useCallback(
    async e => {
      setFiles(files.filter(item => item.id !== e));

      await api.delete(`files/${e}`);
    },
    [files]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    const razao = document.querySelector('#customers').value;
    const cliente_id = customers.find(item => item.razao === razao);

    if (!cliente_id) {
      toast.warning('Por favor! Preencha o campo cliente');
      return;
    }

    if (!forms.title) {
      toast.warning('Por favor! Preencha o campo título');
      return;
    }

    if (!forms.cargo_id) {
      toast.warning('Por favor! Preencha o campo cargo');
      return;
    }

    if (!forms.text) {
      toast.warning('Por favor! Preencha o campo Mensagem.');
      return;
    }

    const percorreFiles = files.map(item => item.id);

    try {
      setOpenBackdrop(true);
      await api.post('annotations', {
        title: forms.title,
        cargo_id: forms.cargo_id,
        text: forms.text,
        sigilo: forms.sigilo,
        cliente_id: cliente_id.id,
        cliente_razao: razao,
        files: percorreFiles,
        notification: forms.notification,
      });

      setOpenBackdrop(false);
      setLoading(false);
      toast.success('Sucesso! Anotação adicionado.');
      history.push('/annotations');
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
      setOpenBackdrop(false);
      setLoading(false);
    }
  }, [
    customers,
    files,
    forms.cargo_id,
    forms.notification,
    forms.sigilo,
    forms.text,
    forms.title,
  ]);

  const handleTextArea = useCallback(
    event => {
      const textline = event.target.value;
      const replacetext1 = textline.replace('<', '');
      const replacetext2 = replacetext1.replace('>', '');

      const textbr = replacetext2.replace(/(?:\r\n|\r|\n)/g, '<br />');

      setForms({ ...forms, text: textbr });
    },
    [forms]
  );

  useEffect(() => {
    if (!loadCustomers) {
      return;
    }
    async function loadClientes() {
      const response = await api.get(`/simples-clientes`);

      setCustomers(response.data);
    }

    loadClientes();
  }, [loadCustomers]);

  useEffect(() => {
    async function loadCargos() {
      const response = await api.get(`cargos?search=%%&limit=50&tipo=&u`);

      setPositions(response.data.data);
    }

    loadCargos();
  }, []);

  return (
    <>
      <Nav page="historico" />
      <Container flexDirection="column">
        <User />

        <Content>
          <Title title="Históricos / Create" />
        </Content>

        <Card>
          <BForm>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input
                  {...getInputProps()}
                  multiple={false}
                  onChange={handleFile}
                />
                <p>
                  Arraste e solte algum arquivo aqui ou clique para selecionar
                  um arquivo
                </p>
              </div>
            </div>
          </BForm>
          {loadfile && (
            <BForm>
              <div className={classes.root}>
                <LinearProgress />
              </div>
            </BForm>
          )}
          <BForm>
            {files.map(item => (
              <Chip label={item.name} onDelete={() => handleDelete(item.id)} />
            ))}
          </BForm>

          <BForm>
            <div className="form-input">
              <Autocomplete
                id="customers"
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionSelected={(option, value) =>
                  option.razao === value.razao
                }
                getOptionLabel={option => option.razao}
                options={customers}
                loading={loadCustomers}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Clientes"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadCustomers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="form-input">
              <TextField
                id="text"
                label="Assunto"
                type="text"
                onChange={e => setForms({ ...forms, title: e.target.value })}
                variant="outlined"
              />
            </div>
            <div className="form-input">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Setor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="setor"
                  value={positions.find(item => item.id === forms.cargo_id)}
                  onChange={e =>
                    setForms({ ...forms, cargo_id: e.target.value })
                  }
                  label="setor"
                >
                  {positions.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </BForm>
          <BForm>
            <div className="form-input">
              <textarea
                placeholder="Mensagem da anotação"
                onChange={handleTextArea}
              />
            </div>
          </BForm>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={forms.sigilo}
                  onChange={() => setForms({ ...forms, sigilo: !forms.sigilo })}
                  name="sigilo"
                  color="primary"
                />
              }
              label="Deixar somente o setor escolhido visualizar"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={forms.notification}
                  onChange={() =>
                    setForms({ ...forms, notification: !forms.notification })
                  }
                  name="notification"
                  color="primary"
                />
              }
              label="Enviar notificações ao setor"
            />
          </FormGroup>
        </Card>

        <BtnSave loading={loading}>
          <button type="button" onClick={handleSubmit}>
            {!loading && 'CRIAR HISTÓRICO'}
          </button>
          {loading && <LoadingButton size={24} />}
        </BtnSave>
      </Container>

      <Footer />

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
