import styled from 'styled-components';

export const Tabela = styled.div`
  display: flex;
  justify-items: center;
  align-content: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  flex-direction: column;
`;

export const GridDir = styled.div`
  width: 100%;
  display: grid;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(4, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* espaço entre um elemento interno e outro */
  grid-gap: 5px 8px;
  /* margem interna que afasta os elementos da borda do grid */

  .grid-4 {
    display: flex;
    flex: 1;
    background: #eeeeee;
    color: #000;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    padding: 12px;
    align-items: center;
    margin-bottom: 4px;
    position: relative;

    button {
      border: none;
      background: none;
    }

    span + span {
      top: 0px;
      right: 0px;
      background: #00d900;
    }

    span {
      display: block;
      position: absolute;
      z-index: 999;
      top: 0px;
      right: 22px;
      background: #ff4d4d;
      width: 18px;
      height: 18px;
      padding-top: 1px;
      border-radius: 9px;
      font-size: 11px;
      text-align: center;
      color: #fff;

      &:hover {
        opacity: 0.5;
      }
    }

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
`;

export const GridExe = styled.div`
  width: 100%;
  display: grid;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(4, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* espaço entre um elemento interno e outro */
  grid-gap: 5px 8px;
  /* margem interna que afasta os elementos da borda do grid */

  .grid-4 {
    display: flex;
    flex: 1;
    background: #eeeeee;
    color: #000;
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    padding: 12px;
    align-items: center;
    position: relative;

    button {
      border: none;
      background: none;
    }

    span + span {
      top: 0px;
      right: 0px;
      background: #00d900;
    }

    span {
      display: block;
      position: absolute;
      z-index: 999;
      top: 0px;
      right: 22px;
      background: #ff4d4d;
      width: 18px;
      height: 18px;
      padding-top: 1px;
      border-radius: 9px;
      font-size: 11px;
      text-align: center;
      color: #fff;

      &:hover {
        opacity: 0.5;
      }
    }

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
  }
`;

export const Image = styled.div``;

export const Description = styled.div`
  font-size: 12px;
  padding-left: 8px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
`;

export const Titulo = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
`;
