import React from 'react';

import { Link } from 'react-router-dom';

import { Content, Bloco, Card } from './styles';

import Container from '~/components/Container';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Footer from '~/components/Footer';

export default function Itens() {
  return (
    <>
      <Nav page="itens" />
      <Container>
        <User />

        <Content>
          <Bloco>
            <div>
              <h2>Itens</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <ul>
            <li>
              <Link to="/show/itens?tb=geral">Geral - Estado (UF)</Link>
            </li>
            <li>
              <Link to="/">Geral - Cidade</Link>
            </li>
            <li>
              <Link to="/show/itens?tb=juridica">
                Geral - Natureza Jurídica
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=sind_patronal">
                Geral - Sindicato Patronal
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=sind_laboral">
                Geral - Sindicato Laboral
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=cnpj">Geral - Situação CNPJ</Link>
            </li>
            <li>
              <Link to="/show/itens?tb=ins_municipal">
                Geral - Situação Inscrição Municipal
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=ins_estadual">
                Geral - Situação Inscrição Estadual
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=reg_nire">
                Geral - Órgãos de Registro NIRE
              </Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to="/show/itens?tb=ince_fiscais">
                Termos de Acordo / Incentivos Fiscais - Órgão Firmado
              </Link>
            </li>
            <li>
              <Link to="/show/certidoes-negativas">
                Certidões Negativas - Órgão
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=cert_digitais">
                Controle de Certificados Digitais da Empresa - Tipo
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=pais_socios">
                Quadro Societário Vigente - País de Residência
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=contato_cliente">
                Primeiro Contato Realizado com o Cliente - Feito Via
              </Link>
            </li>
            <li>
              <Link to="/show/esocial">Fases do eSocial</Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to="/show/itens?tb=regras_tr_federal">
                Regras de Tributação - Federal
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=regras_tr_estadual">
                Regras de Tributação - Estadual
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=regras_tr_municipal">
                Regras de Tributação - Municipal
              </Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to="/show/itens?tb=virg_orgaos">
                Vigência Procurações para Secran - Órgão
              </Link>
            </li>
            <li>
              <Link to="/show/itens?tb=virg_outorgados">
                Vigência Procurações para Secran - Outorgados
              </Link>
            </li>
            <li>
              <Link to="/show/grupoeconomico">Grupo Econômico - Tabela</Link>
            </li>
          </ul>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
