import styled from 'styled-components';

import BackgroundB from '~/assets/bg-mural.jpg';

export const Grid = styled.div`
  margin-bottom: 20px;
  background: #fff;
  display: block;
  width: 49%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
`;

export const Titulo = styled.div`
  padding: 12px;
  background: #00468c;
  color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Messenger = styled.div`
  form {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    margin-top: 12px;

    button {
      font-size: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      border: none;
      background: #0080ff;
      color: #fff;
      border-radius: 5px;
      text-align: center;
    }

    textarea {
      margin-bottom: 8px;
      height: 80px;
      border-radius: 5px;
      background: #eeeeee;
      border: none;
      padding: 12px;
    }
  }
`;

export const ContentMessenger = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  background: url(${BackgroundB}) repeat;

  a {
    display: flex;
    background: #0080ff;
    color: #fff;
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 10px;

    &:hover {
      background: #005bb7;
    }
  }

  .bloco {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
    align-items: center;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;

    span {
      font-size: 10px;
    }
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .perfil {
    display: flex;
    align-items: center;

    div img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }

    div {
      display: flex;
      flex-direction: column;
    }
  }

  .messenger {
    background: #00bfff;
    border-radius: 5px;
    color: #fff;
    padding: 6px;
  }
`;
