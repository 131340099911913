import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Grid = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px;
  background: #fff;
  display: block;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
`;

export const GridT = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px;
  background: #f3f3f3;
  display: block;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Button = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-end;
  padding-top: 6px;
  padding-bottom: 12px;
  padding-left: 20px;

  button {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    border: 0;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const BForm = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  input[type='text'],
  input[type='date'],
  input[type='number'] {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
  }

  .textarea {
    height: 80px;
    font-size: 14px;
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  }

  select {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    p {
      font-size: 12px;
    }

    label {
      padding-bottom: 5px;
      color: #333;
      font-size: 12px;
      padding-left: 4px;
    }

    .btn-editar {
      border: 0;
      background: #ccc;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #eee;
      }
    }

    .btn-save {
      border: 0;
      background: #0faf69;
      color: #fff;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: ${darken(0.03, '#0FAF69')};
      }
    }

    .btn-warning {
      border: 0;
      background: #d16a0d;
      color: #fff;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: ${darken(0.03, '#d16a0d')};
      }
    }

    .btn-delete {
      border: 0;
      background: #ff4d4d;
      color: #fff;
      border-radius: 5px;
      width: 140px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: ${darken(0.03, '#FF4D4D')};
      }
    }
  }

  span + span {
    margin-left: 6px;
  }
`;

export const ButtonView = styled.div`
  display: flex;
  flex-direction: row;

  button + button {
    margin-left: 10px;
  }
`;
