import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { format, parseISO } from 'date-fns';
import { Container, Grid } from './styles';

import Carregando from '~/components/Carregando';

import api from '~/services/api';

export default function ClienteServ({ id, carrega }) {
  const [loading, setLoading] = useState(true);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    if (!carrega) {
      setServicos([]);
      setLoading(true);
      return;
    }
    async function loadServicos() {
      const response = await api.get(`clientes/${id}/servicos`);

      const formated = response.data.map(item => ({
        ...item,
        data: format(parseISO(item.data), 'dd-MM-yyyy'),
      }));

      setLoading(false);
      setServicos(formated);
    }

    loadServicos();
  }, [carrega, id]);

  return (
    <>
      <Container>
        <Grid>
          {loading ? (
            <Carregando />
          ) : (
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>Status</td>
                  <td>Período</td>
                </tr>
              </thead>
              <tbody>
                {servicos.map(item => (
                  <tr>
                    <td>{item.title}</td>
                    <td>{item.data}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Grid>
      </Container>
    </>
  );
}

ClienteServ.propTypes = {
  id: PropTypes.number,
  carrega: PropTypes.bool,
};

ClienteServ.defaultProps = {
  id: 0,
  carrega: false,
};
