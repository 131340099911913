import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import ReactTooltip from 'react-tooltip';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navigation } from './styles';
import Logo from '~/assets/logo.svg';

import IconDashboard from '~/assets/icons/dashboard.svg';
import IconCliente from '~/assets/icons/coffee.svg';
import IconRelatorio from '~/assets/icons/progress-report.svg';
import IconObrigacoes from '~/assets/icons/calendar.svg';
import IconNotification from '~/assets/icons/notification.svg';
import IconAdmin from '~/assets/icons/settings.svg';
import IconLogout from '~/assets/icons/logout.svg';
import IconHistorico from '~/assets/icons/edit.svg';
import IconMegaPhone from '~/assets/icons/megaphone.svg';
import IconTicket from '~/assets/icons/tickets.svg';

import { signOut } from '../../store/modules/auth/actions';
import history from '~/services/history';

export default function Navegacao({ page }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const cargo = profile.cargo.subtitle;
  const [closeNavigation, setCloseNavigation] = useState(() => {
    const checkParse = localStorage.getItem('@GFACIL:navigation');
    const check = JSON.parse(checkParse);
    return check || false;
  });

  function handleSignOut() {
    history.push('/dashboard');
    dispatch(signOut());
  }

  const handleNavigation = useCallback(() => {
    setCloseNavigation(!closeNavigation);
    localStorage.setItem(
      '@GFACIL:navigation',
      JSON.stringify(!closeNavigation)
    );
  }, [closeNavigation]);

  return (
    <>
      <Navigation width={closeNavigation ? '46px' : '180px'}>
        <div className="barraLateral">
          {closeNavigation ? (
            <button
              className="closed"
              title="Abrir navegação"
              onClick={handleNavigation}
            >
              <FaArrowRight size={14} color="#fff" />
            </button>
          ) : (
            <button
              className="closed"
              title="Fechar navegação"
              onClick={handleNavigation}
            >
              <FaArrowLeft size={14} color="#fff" />
            </button>
          )}

          <img src={Logo} className="logo" alt="GFÁCIL - Getão Contábil" />
          <ul>
            <li>
              <Link
                to="/"
                className={page === 'dashboard' ? 'active' : ''}
                data-tip="Dashboard"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconDashboard} alt="Dashboard" />{' '}
                {!closeNavigation && 'Dashboard'}
              </Link>
            </li>
            <li>
              <Link
                to="/clientes"
                className={page === 'clientes' ? 'active' : ''}
                data-tip="Clientes"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconCliente} alt="Clientes" />{' '}
                {!closeNavigation && 'Clientes'}
              </Link>
            </li>
            <li>
              <Link
                to="/historicos"
                className={page === 'historico' ? 'active' : ''}
                data-tip="Históricos"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconHistorico} alt="Históricos" />{' '}
                {!closeNavigation && 'Históricos'}
              </Link>
            </li>
            <li>
              <Link
                to="/comunicados"
                className={page === 'comunicado' ? 'active' : ''}
                data-tip="Comunicados"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconMegaPhone} alt="Comunicados" />{' '}
                {!closeNavigation && 'Comunicados'}
              </Link>
            </li>
            <li>
              <Link
                to="/relatorios"
                className={page === 'relatorios' ? 'active' : ''}
                data-tip="Relatórios"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconRelatorio} alt="Relatórios" />{' '}
                {!closeNavigation && 'Relatórios'}
              </Link>
            </li>
            <li>
              <Link
                to="/tickets"
                className={page === 'tickets' ? 'active' : ''}
                data-tip="Tickets"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconTicket} alt="Tickets" />{' '}
                {!closeNavigation && 'Tickets'}
              </Link>
            </li>
            <li>
              {cargo === 'financeiro' ||
              cargo === 'consultoria' ||
              cargo === 'arquivo' ? (
                <Link
                  to="/obrigacoes-periodicas"
                  className={page === 'obrigacoes' ? 'active' : ''}
                  data-tip="Obrigações"
                  data-tip-disable={!closeNavigation}
                >
                  <img src={IconObrigacoes} alt="Obrigações" />{' '}
                  {!closeNavigation && 'Obrigações'}
                </Link>
              ) : (
                <Link
                  to="/obrigacoes"
                  className={page === 'obrigacoes' ? 'active' : ''}
                  data-tip="Obrigações"
                  data-tip-disable={!closeNavigation}
                >
                  <img src={IconObrigacoes} alt="Obrigações" />{' '}
                  {!closeNavigation && 'Obrigações'}
                </Link>
              )}
            </li>
            <li>
              <Link
                to="/notifications"
                className={page === 'notifications' ? 'active' : ''}
                data-tip="Notificações"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconNotification} alt="Notifications" />{' '}
                {!closeNavigation && 'Notificações'}
              </Link>
            </li>
            <li>
              <Link
                to="/administracao"
                className={page === 'administracao' ? 'active' : ''}
                data-tip="Administrativo"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconAdmin} alt="Administração" />{' '}
                {!closeNavigation && 'Administrativo'}
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="logout"
                onClick={handleSignOut}
                data-tip="Sair do Sistema"
                data-tip-disable={!closeNavigation}
              >
                <img src={IconLogout} alt="Sair" /> {!closeNavigation && 'Sair'}
              </button>
            </li>
          </ul>
        </div>
      </Navigation>

      <ReactTooltip />
    </>
  );
}

Navegacao.propTypes = {
  page: PropTypes.string.isRequired,
};
