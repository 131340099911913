import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { MdToday, MdExitToApp } from 'react-icons/md';

import { toast } from 'react-toastify';

import MonthPickerInput from 'react-month-picker-input';
import api from '~/services/api';
import xml from '~/services/xmlApi';
import 'react-month-picker-input/dist/react-month-picker-input.css';
import imgXml from '~/assets/icons/xml.svg';

import { Content, Bloco, Card, Pesquisa, Submenu, LoadXml } from './styles';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
// import Carregando from '~/components/Carregando/Button';
import MenuSub from '~/components/Navigation/Obrigacoes';
import history from '~/services/history';

export default function Periodica() {
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [, setDelFuncao] = useState(!getStorageFuncao);
  const [loadingXml, setLoadingXml] = useState(false);
  const [files, setFiles] = useState([]);
  const [anoCalendar] = useState(Number(format(new Date(), 'yyyy')));
  const [mesCalendar] = useState(Number(format(new Date(), 'MM')));
  const [loadFile, setLoadFile] = useState(false);

  useEffect(() => {
    if (profile.role_id === 4) {
      history.goBack();
    }
    async function loadFiles() {
      const response = await api.get(
        `files?subtype=executores&name=${
          profile.role_id === 1
            ? JSON.parse(getStorageFuncao)
            : profile.cargo.subtitle
        }`
      );

      const percorre = response.data.data.map(item => ({
        ...item,
        created_at: format(
          parseISO(item.created_at),
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: pt,
          }
        ),
      }));

      setFiles(percorre);
    }

    loadFiles();
  }, [getStorageFuncao, profile.cargo.subtitle, profile.role_id]);

  function handleCalendar() {
    //
  }

  function handleSetor(e) {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
  }

  async function handleExport() {
    setLoadingXml(true);

    await xml
      .get(
        `executores/clientes?funcao=${
          profile.role_id === 1
            ? JSON.parse(getStorageFuncao)
            : profile.cargo.subtitle
        }`,
        {
          responseType: 'blob', // important
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const date = `${new Date()}.xlsx`;
        link.href = url;
        link.setAttribute('download', date); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    setLoadingXml(false);
  }

  async function handleFile(e) {
    const data = new FormData();

    setLoadFile(true);

    data.append('file', e.target.files[0]);

    await xml.delete(
      `cliente/delete?funcao=${
        profile.role_id === 1
          ? JSON.parse(getStorageFuncao)
          : profile.cargo.subtitle
      }`
    );

    const resFile = await xml.post(
      `cliente/import?funcao=${
        profile.role_id === 1
          ? JSON.parse(getStorageFuncao)
          : profile.cargo.subtitle
      }`,
      data
    );

    const object = resFile.data;

    const convert = Object.values(object); // Transforma objeto em array

    const dadosArray = convert.concat(files);

    setFiles(dadosArray);
    setLoadFile(false);

    toast.success('Sucesso executores atualizado com sucesso');
  }

  function handleDownload() {
    const next = files.values();
    const resFile = next.next().value;
    window.location.href = `http://xml.secran.digital/uploads/${resFile.file}`;
  }

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="obrigacoes-import-export" />
          {profile.cargo.subtitle === 'diretor' && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title="Obrigações / Import - Export" />

          <Bloco>
            <div>
              <h2>Obrigações / Import - Export</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <p>
                Mês de referência: <MdToday size={25} color="#0059B4" />
              </p>{' '}
              <MonthPickerInput
                year={Number(anoCalendar)}
                month={Number(mesCalendar) - 1}
                onChange={(maskedValue, selectedYear, selectedMonth) =>
                  handleCalendar({
                    value: maskedValue,
                    year: selectedYear,
                    month: selectedMonth,
                  })
                }
              />
            </div>
          </Pesquisa>
        </Content>

        <Content>
          <Card>
            <button type="button" id="bloco-export" onClick={handleExport}>
              <img src={imgXml} alt="exportar executores" />
              Export via Banco
            </button>
            <button type="button" id="bloco-export" onClick={handleDownload}>
              <img src={imgXml} alt="exportar executores" />
              Export via Servidor
            </button>
            <label id="bloco-import">
              <img src={imgXml} alt="exportar executores" />
              Importar Executores
              <input
                type="file"
                id="avatar"
                accept=".xlsx, .xls, .csv"
                onChange={handleFile}
              />
            </label>
          </Card>

          <Card className="marginTop">
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td>Documento</td>
                  <td>Data de criação</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {files.map(file => (
                  <tr key={file.id}>
                    <td>{file.file}</td>
                    <td>{file.created_at}</td>
                    <td>Action</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Content>
        <Footer />
      </Container>

      {loadingXml && <LoadXml>Processando...</LoadXml>}
      {loadFile && <LoadXml>Processando...</LoadXml>}
    </>
  );
}
