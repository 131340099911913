import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';

import { toast } from 'react-toastify';
import * as Yup from 'yup';

import pt from 'date-fns/locale/pt';
import { format, parseISO, addDays } from 'date-fns';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function CertDigitais() {
  const { idCliente } = useParams();
  const profile = useSelector(state => state.user.profile);
  const [cliente, setCliente] = useState('');
  const [certs, setCerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/certificados-digitais`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        DataAcrescimo: format(addDays(new Date(), item.aviso), 'yyy-MM-dd', {
          locale: pt,
        }),
      }));

      setCerts(percorre);
    });

    api.get('tb/geral?tb=cert_digitais').then(response => {
      setTabs(response.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const options = [
    { id: 1, title: '1 Dias' },
    { id: 2, title: '2 Dias' },
    { id: 3, title: '3 Dias' },
    { id: 4, title: '4 Dias' },
    { id: 5, title: '5 Dias' },
    { id: 10, title: '10 Dias' },
    { id: 15, title: '15 Dias' },
    { id: 30, title: '30 Dias' },
    { id: 45, title: '45 Dias' },
    { id: 60, title: '60 Dias' },
    { id: 90, title: '90 Dias' },
  ];

  const handleSubmit = useCallback(
    async ({
      tb_id,
      titular,
      senha,
      vigencia,
      emissao,
      data_protocolo,
      aviso,
    }) => {
      const forms = {
        tb_id,
        titular,
        senha,
        vigencia,
        emissao,
        data_protocolo,
        aviso,
      };

      const schema = Yup.object().shape({
        tb_id: Yup.string().required('Por favor! Preencha o campo Tipo'),
        senha: Yup.string().required('Por favor! Preencha o campo Senha'),
        data_protocolo: Yup.string().required(
          'Por favor! Preencha o campo Data limite protocolado'
        ),
        aviso: Yup.string().required('Por favor! Preencha o campo Avisar'),
        emissao: Yup.string().required('Por favor! Preencha o campo Emissão'),
      });

      await schema.validate(forms);

      setLoading(true);

      try {
        const { data } = await api.post(
          `clientes/${idCliente}/certificados-digitais`,
          {
            tb_id,
            titular,
            senha,
            vigencia,
            data_protocolo,
            aviso,
            emissao,
          }
        );

        setLoading(false);
        document.getElementById('form_reset').reset();
        toast.success('Certificado Digital cadastrado com sucesso.');

        const object = { data };

        const convert = Object.values(object); // Transforma objeto em array

        const dadosArray = convert.concat(certs);

        setCerts(
          dadosArray.map(item => ({
            ...item,
            dateFormatted: format(
              parseISO(item.updated_at),
              "dd 'de' MMMM', às ' HH:mm'h'",
              {
                locale: pt,
              }
            ),
          }))
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.warning(err.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [certs, idCliente]
  );

  const handleUpdate = useCallback(
    async ({ id, tb_id, titular, senha, vigencia, data_protocolo, aviso }) => {
      try {
        await api.put(`clientes/${idCliente}/certificados-digitais?id=${id}`, {
          tb_id,
          titular,
          senha,
          vigencia,
          data_protocolo,
          aviso,
        });

        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [idCliente]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`delete/${idCliente}/certificados-digitais?id=${id}`);

        toast.success('Deletado com sucesso.');
        setCerts(certs.filter(item => item.id !== id));
      } catch (err) {
        toast.error('Algo deu errado no delete');
      }
    },
    [certs, idCliente]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="certificados-digitais"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Tipo *</label>
                <Select name="tb_id" options={tabs} />
              </span>
              <span>
                <label>Titular</label>
                <Input type="text" name="titular" />
              </span>
              <span>
                <label>Senha *</label>
                <Input type="text" name="senha" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Emissão</label>
                <Input type="date" name="emissao" />
              </span>
              <span>
                <label>Vigência</label>
                <Input type="date" name="vigencia" />
              </span>
              <span>
                <label>Data limite protocolado pela Secran *</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Avisar em *</label>
                <Select name="aviso" options={options} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {certs.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Tipo</label>
                  <Select name="tb_id" options={tabs} />
                </span>
                <span>
                  <label>Titular</label>
                  <Input type="text" name="titular" />
                </span>
                <span>
                  <label>Senha</label>
                  <Input type="text" name="senha" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Emissão</label>
                  <Input type="date" name="emissao" />
                </span>
                <span>
                  <label>Vigência</label>
                  <Input type="date" name="vigencia" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <Select name="aviso" options={options} />
                </span>
              </BForm>
              <BForm>
                <span>
                  <p>
                    Adicionado em {item.dateFormatted} por{' '}
                    <strong>{item.user.name}</strong>
                  </p>
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    {item.vigencia <= item.DataAcrescimo ? (
                      <button type="button" className="btn-warning">
                        <MdSave size={16} color="#fff" /> Data à vencer
                      </button>
                    ) : (
                      <button type="submit" className="btn-save">
                        <MdSave size={16} color="#fff" /> Salvar
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
