import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  background: #fff;
  display: block;
  width: 49%;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  display: flex;
  padding: 9px 12px;
  background: #00468c;
  align-items: center;
  color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: space-between;

  button {
    border: 0;
    background: none;

    svg {
      color: #fff;
      font-size: 22px;
    }
  }
`;

export const Days = styled.ul`
  display: grid;
  width: 100%;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(7, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* margem interna que afasta os elementos da borda do grid */
  text-align: center;

  li {
    display: block;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
    font-weight: bold;

    & + li {
      border-left: 1px solid #ccc;
    }
  }
`;

export const Cells = styled.div`
  display: grid;
  width: 100%;
  height: 72%;
  /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
  grid-template-columns: repeat(7, 1fr);
  /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
  grid-auto-rows: auto;
  /* margem interna que afasta os elementos da borda do grid */
  text-align: center;

  .calendar-cell--selected {
    color: #ccc;
  }

  .calendar-cell--sunday {
    color: #ff5c26;
  }

  .calendar-cell--today {
    background: #0059b2;
    color: #fff;
  }

  .calendar-cell--disabled {
    background: #eee;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  height: 100%;
  position: relative;
  transition: background-color 0.2s;

  &:hover {
    background: #ccc;
  }
`;

export const Info = styled.div`
  display: flex;
  margin-top: 16px;
  position: absolute;
  z-index: 4;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, 0%);

  .obr {
    background: #ff7a4d;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .date {
    background: #129184;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .birthday {
    background: #c926ff;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .reminder {
    background: #0080ff;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  span + span {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  flex: 1;

  div {
    display: flex;
    align-items: center;
    font-size: 12px;

    .obr {
      background: #ff7a4d;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 4px;
    }

    .date {
      background: #129184;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      margin-right: 4px;
    }

    .birthday {
      width: 8px;
      height: 8px;
      background: #c926ff;
      border-radius: 4px;
      margin-right: 4px;
    }

    .reminder {
      width: 8px;
      height: 8px;
      background: #0080ff;
      border-radius: 4px;
      margin-right: 4px;
    }

    & + div {
      margin-left: 8px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;

  div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
    flex: 1;

    p {
      display: flex;
      width: 70%;
    }

    small {
      display: flex;
      align-items: center;
      padding-left: 6px;
      padding-right: 6px;
    }

    .obr {
      font-size: 11px;
      background: #ff7a4d;
      color: #fff;
    }

    .feriado {
      font-size: 11px;
      padding: 3px 4px;
      background: #129184;
      color: #fff;
    }

    .birthday {
      font-size: 11px;
      padding: 3px 4px;
      background: #c926ff;
      color: #fff;
    }

    .reminder {
      font-size: 11px;
      padding: 3px 4px;
      background: #0080ff;
      color: #fff;
    }
  }
`;
