import React, { useState, useEffect, useCallback } from 'react';

import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Form, Input } from '@rocketseat/unform';

import api from '~/services/api';
import history from '~/services/history';

import { Wrapper, Content, Conteudo } from './styles';

import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';

export default function RedefinirPassword() {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    async function loadUser() {
      const response = await api.get(`password/find/${token}`);

      if (response.data.error) {
        history.push('/');
        toast.warning(response.data.error);
        return;
      }

      setUser(response.data);
    }

    loadUser();
  }, [token]);

  const handleSubmit = useCallback(
    async ({ password, passwordRepeat }) => {
      if (password !== passwordRepeat) {
        toast.warning('Por favor! Preencha corretamente a senha.');
        return;
      }

      setLoading(true);
      try {
        const { data } = await api.post('password/reset', {
          email: user.email,
          password,
          password_confirmation: passwordRepeat,
          token: user.token,
        });

        if (data.error) {
          toast.warning(data.error);
          return;
        }

        setLoading(false);
        toast.success('Senha alterada com sucesso.');
        history.push('/');
      } catch (err) {
        toast.error('algo deu errado');
        setLoading(false);
      }
    },
    [user.email, user.token]
  );

  return (
    <>
      <Wrapper>
        <Conteudo>
          <Content>
            <img src={logo} alt="Logo Secran" />
            <Form onSubmit={handleSubmit}>
              <Input
                name="password"
                type="password"
                placeholder="Digite uma nova senha"
              />
              <Input
                name="passwordRepeat"
                type="password"
                placeholder="Repete a senha"
              />

              <button type="submit">
                {loading ? <Carregando /> : 'Alterar senha'}
              </button>
            </Form>
            <p>
              <Link to="/">Voltar para login</Link>
            </p>
          </Content>
        </Conteudo>
      </Wrapper>
    </>
  );
}
