import React, { useEffect, useState, useCallback } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import renderHTML from 'react-render-html';

import { MdDelete } from 'react-icons/md';
import api from '~/services/api';

import Container from '~/components/Container';
import Title from '~/components/Title';
import User from '~/components/User';
import Nav from '~/components/Navigation';
import Footer from '~/components/Footer';
import Info from '~/components/Modal/Info';

import { Content, Bloco, Grid } from './styles';

export default function ShowComunicado() {
  const { token } = useParams();
  const [comunicado, setComunicado] = useState('');
  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    api.get(`comunicados/${token}`).then(response => {
      setComunicado(response.data);
    });
  }, [token]);

  const handleDelete = useCallback(async () => {
    await api.delete(`comunicados/${comunicado.id}`);

    history.push('/comunicados');
  }, [comunicado.id, history]);

  return (
    <>
      <Nav page="comunicado" />
      <Container>
        <User />

        <Content>
          <Title title={`Comunicado / ${comunicado.subject}`} />
          <Bloco>
            <div>
              <h2>Comunicado / {comunicado.subject}</h2>
            </div>
            <div className="btn">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="btn-delete"
              >
                <MdDelete size={16} color="#fff" /> Excluir
              </button>
            </div>
          </Bloco>
        </Content>

        <Grid>{comunicado && renderHTML(comunicado.body)}</Grid>
      </Container>

      <Footer />

      <Info
        close={() => setOpen(false)}
        open={open}
        handleCancel={() => setOpen(false)}
        handleDelete={handleDelete}
        title="Você deseja realmente deletar?"
      >
        Clique no botão abaixo para deletar
      </Info>
    </>
  );
}
