import React, { useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { uuid } from 'uuidv4';

import { useHistory } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { FiMail, FiSend, FiBell, FiClock } from 'react-icons/fi';

import * as Yup from 'yup';

import { toast } from 'react-toastify';

import api from '~/services/api';

import Container from '~/components/Container';
import Input from '~/components/FormElement/Input';
import Select from '~/components/FormElement/Select';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import User from '~/components/User';
import Nav from '~/components/Navigation';
import LoadingButton from '~/components/Carregando/MaterialUi/button';

import { Content, Card, BForm, BtnSave, Bloco, Button } from './styles';

const cargos = [
  { id: 'fiscal', title: 'Departamento Fiscal' },
  { id: 'contabil', title: 'Departamento Contábil' },
  { id: 'tributos', title: 'Departamento Tributos' },
  { id: 'pessoal', title: 'Departamento Pessoal' },
  { id: 'consultoria', title: 'Departamento Consultoria' },
  { id: 'paralegal', title: 'Departamento Para Legal' },
  { id: 'ti', title: 'Departamento TI' },
  { id: 'diretor', title: 'Departamento Diretoria' },
];

export default function Create() {
  const profile = useSelector(state => state.user.profile);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [users, setUsers] = useState([]);
  const [forms, setForms] = useState({
    subject: '',
    from: profile.email,
    to: '',
    cargo: '',
    body: '',
    tipo: 'individual',
    user: '',
    repeat: false,
    type_repeat: '',
    hours: '',
    day: '',
    email: false,
    notification: true,
    reading: false,
  });
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    try {
      if (forms.tipo === 'individual') {
        const schemaIndividual = Yup.object().shape({
          body: Yup.string().required(
            'Por favor! Preencha o campo de mensagem'
          ),
          to: Yup.string()
            .required('Por favor! Preencha o email')
            .email('Digite o email válido'),
          subject: Yup.string().required('Por favor! Preencha o assunto'),
        });

        await schemaIndividual.validate(forms);
      }
      if (forms.tipo === 'usuario') {
        const schema = Yup.object().shape({
          body: Yup.string().required(
            'Por favor! Preencha o campo de mensagem'
          ),
          notification: Yup.boolean().required(
            'Por favor! Ative a notificação.'
          ),
          user: Yup.string().required('Por favor! Selecione o usuário'),
          cargo: Yup.string().required('Por favor! Selecione o departamento'),
          subject: Yup.string().required('Por favor! Preencha o assunto'),
        });

        await schema.validate(forms);
      }
      if (forms.tipo === 'departamento') {
        const schema = Yup.object().shape({
          body: Yup.string().required(
            'Por favor! Preencha o campo de mensagem'
          ),
          cargo: Yup.string().required('Por favor! Selecione o departamento'),
          subject: Yup.string().required('Por favor! Preencha o assunto'),
        });

        await schema.validate(forms);
      }
      if (forms.tipo === 'programado') {
        const schema = Yup.object().shape({
          body: Yup.string().required(
            'Por favor! Preencha o campo de mensagem'
          ),
          hours: Yup.string().required('Por favor! Preencha o campo horas'),
          type_repeat: Yup.string().required(
            'Por favor! Preencha o tipo da repetição'
          ),
          to: Yup.string()
            .required('Por favor! Preencha o email')
            .email('Digite o email válido'),
          subject: Yup.string().required('Por favor! Preencha o assunto'),
        });

        await schema.validate(forms);
      }

      const dataforms = {
        ...forms,
        token: uuid(),
      };

      setLoadingBtn(true);
      await api.post('comunicados', dataforms);

      toast.success('Comunicado criado com sucesso.');
      history.push('/comunicados');
      setLoadingBtn(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        setLoadingBtn(false);
        return;
      }
      toast.error('Ops! Algo deu errado.');
      setLoadingBtn(false);
    }
  }, [forms, history]);

  const handleChange = useCallback(
    event => {
      setForms({
        ...forms,
        [event.target.name]: event.target.value,
      });
    },
    [forms]
  );

  const handleTipo = useCallback(
    event => {
      setForms({ ...forms, tipo: event, to: '', cargo: '' });
    },
    [forms]
  );

  const handleUser = useCallback(
    async event => {
      const response = await api.get(`users?funcao=${event}`);

      setUsers(response.data);
      setForms({ ...forms, cargo: event });
    },
    [forms]
  );

  const handleTextArea = useCallback(
    event => {
      const text = event.target.value;
      const textbr = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

      setForms({ ...forms, body: textbr });
    },
    [forms]
  );

  return (
    <>
      <Nav page="comunicado" />
      <Container flexDirection="column">
        <User />

        <Content>
          <Title title="Comunicado / Create" />
        </Content>

        <Card>
          <Bloco>
            <Button
              type="button"
              active={forms.tipo === 'individual'}
              onClick={() => handleTipo('individual')}
            >
              <FiMail />
              <h4>Email individual</h4>
            </Button>
            <Button
              type="button"
              active={forms.tipo === 'usuario'}
              onClick={() => handleTipo('usuario')}
            >
              <FiBell />
              <h4>Email/notificação individual usuário</h4>
            </Button>
            <Button
              type="button"
              active={forms.tipo === 'departamento'}
              onClick={() => handleTipo('departamento')}
            >
              <FiSend />
              <h4>Email/notificação departamento/geral</h4>
            </Button>
            <Button
              type="button"
              active={forms.tipo === 'programado'}
              onClick={() => handleTipo('programado')}
            >
              <FiClock />
              <h4>Email/notificação programado</h4>
            </Button>
          </Bloco>
          <BForm>
            <div className="form-input">
              <Input
                id="subject"
                label="Assunto"
                name="subject"
                onChange={handleChange}
                variant="outlined"
              />
            </div>
            {forms.tipo === 'individual' && (
              <div className="form-input">
                <Input
                  id="from"
                  label="Email do envio"
                  name="to"
                  onChange={handleChange}
                  variant="outlined"
                />
              </div>
            )}
            {forms.tipo === 'usuario' && (
              <>
                <div className="form-input">
                  <Select
                    name="cargo"
                    id="setor"
                    native
                    onChange={e => handleUser(e.target.value)}
                    label="Departamento"
                    variant="outlined"
                  >
                    {cargos.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form-input">
                  <Select
                    name="user"
                    id="usuario"
                    native
                    onChange={handleChange}
                    label="Selecionar usuário"
                    variant="outlined"
                  >
                    {users.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </>
            )}
            {forms.tipo === 'departamento' && (
              <div className="form-input">
                <Select
                  name="cargo"
                  id="setor"
                  native
                  onChange={handleChange}
                  label="Departamento"
                  variant="outlined"
                >
                  {cargos.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                  <option value="geral">Para todos os Departamentos</option>
                  <option value="coord">Para todos os Coordenadores</option>
                  <option value="supervisao">Para todos os Supervisores</option>
                </Select>
              </div>
            )}
            {forms.tipo === 'programado' && (
              <>
                <div className="form-input">
                  <Input
                    id="to"
                    label="Email do envio"
                    name="to"
                    onChange={handleChange}
                    variant="outlined"
                  />
                </div>
                <div className="form-input">
                  <Select
                    name="type_repeat"
                    native
                    onChange={handleChange}
                    label="Tipo da Repetição"
                    variant="outlined"
                  >
                    <option value="mensal">Mensal</option>
                    <option value="hora">Todo dia a cada hora</option>
                  </Select>
                </div>
                {forms.type_repeat === 'mensal' && (
                  <>
                    <div className="form-input">
                      <Input
                        label="Dia do mês"
                        name="day"
                        type="number"
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </div>
                    <div className="form-input">
                      <Input
                        label="A cada hora"
                        name="hours"
                        type="time"
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </>
                )}
                {forms.type_repeat === 'quinze' && (
                  <>
                    <div className="form-input">
                      <Input
                        label="A cada hora"
                        name="hours"
                        type="time"
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </>
                )}
                {forms.type_repeat === 'hora' && (
                  <>
                    <div className="form-input">
                      <Input
                        label="A cada hora"
                        name="hours"
                        type="time"
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </BForm>
          <BForm>
            <div className="form-input">
              <textarea
                placeholder="Mensagem da anotação"
                name="body"
                onChange={handleTextArea}
              />
            </div>
          </BForm>
          <FormGroup row>
            {forms.tipo === 'usuario' || forms.tipo === 'departamento' ? (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={forms.email}
                      onChange={() =>
                        setForms({ ...forms, email: !forms.email })
                      }
                      name="email"
                      color="primary"
                    />
                  }
                  label="Enviar email"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={forms.notification}
                      onChange={() =>
                        setForms({
                          ...forms,
                          notification: !forms.notification,
                          reading: false,
                        })
                      }
                      name="notification"
                      color="primary"
                    />
                  }
                  label="Enviar notificação"
                />
                {forms.notification && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={forms.reading}
                        onChange={() =>
                          setForms({
                            ...forms,
                            reading: !forms.reading,
                          })
                        }
                        name="notification"
                        color="primary"
                      />
                    }
                    label="Confirmação de leitura"
                  />
                )}
              </>
            ) : (
              ''
            )}
          </FormGroup>
        </Card>

        <BtnSave loading={loadingBtn}>
          <button type="button" onClick={handleSubmit}>
            {!loadingBtn && 'CRIAR COMUNICADO'}
          </button>
          {loadingBtn && <LoadingButton size={24} />}
        </BtnSave>
      </Container>

      <Footer />
    </>
  );
}
