import React from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdLockOpen } from 'react-icons/md';
import { Menus } from './styles';

export default function MenusEdit({ client, page, permission }) {
  return (
    <>
      <Menus>
        <ul>
          <li>
            <Link
              to={`/edit/cliente/${client}`}
              className={page === 'geral' ? 'active' : ''}
            >
              Geral
            </Link>
          </li>
          <li>
            <Link
              to={`/servico/cliente/${client}`}
              className={page === 'servico' ? 'active' : ''}
            >
              Serviços
            </Link>
          </li>
          <li>
            <Link
              to={`/tributacoes/cliente/${client}`}
              className={page === 'tributacoes' ? 'active' : ''}
            >
              {permission === 'fiscal' && <MdLockOpen size={12} color="#fff" />}
              {permission === 'pessoal' && (
                <MdLockOpen size={12} color="#fff" />
              )}
              Tributação
            </Link>
          </li>
          <li>
            <Link
              to={`/incentivos-fiscais/cliente/${client}`}
              className={page === 'incentivos-fiscais' ? 'active' : ''}
            >
              Incentivos Fiscais
            </Link>
          </li>
          <li>
            <Link
              to={`/cnae/cliente/${client}`}
              className={page === 'cnae' ? 'active' : ''}
            >
              Cnae
            </Link>
          </li>
          <li>
            <Link
              to={`/socios/cliente/${client}`}
              className={page === 'socios' ? 'active' : ''}
            >
              Sócios
            </Link>
          </li>
          <li>
            <Link
              to={`/atos-societarios/cliente/${client}`}
              className={page === 'atos-societarios' ? 'active' : ''}
            >
              Atos Societários
            </Link>
          </li>
          <li>
            <Link
              to={`/senhas/cliente/${client}`}
              className={page === 'senhas' ? 'active' : ''}
            >
              Senhas
            </Link>
          </li>
          <li>
            <Link
              to={`/certidoes-negativas/cliente/${client}`}
              className={page === 'certidoes-negativas' ? 'active' : ''}
            >
              Certidões Negativas
            </Link>
          </li>
          <li>
            <Link
              to={`/procuracoes/cliente/${client}`}
              className={page === 'procuracoes' ? 'active' : ''}
            >
              Procurações
            </Link>
          </li>
          <li>
            <Link
              to={`/certificados-digitais/cliente/${client}`}
              className={page === 'certificados-digitais' ? 'active' : ''}
            >
              Certificados Digitais
            </Link>
          </li>
          <li>
            <Link
              to={`/licenca/cliente/${client}`}
              className={page === 'licenca' ? 'active' : ''}
            >
              Licença
            </Link>
          </li>
          <li>
            <Link
              to={`/contatos/cliente/${client}`}
              className={page === 'contatos' ? 'active' : ''}
            >
              Contatos
            </Link>
          </li>
          <li>
            <Link
              to={`/executores/cliente/${client}`}
              className={page === 'executores' ? 'active' : ''}
            >
              Executores
            </Link>
          </li>
          <li>
            <Link
              to={`/primeiro-contato/cliente/${client}`}
              className={page === 'primeiro-contato' ? 'active' : ''}
            >
              Primeiro Contato
            </Link>
          </li>
          <li>
            <Link
              to={`/adicionais/cliente/${client}`}
              className={page === 'info-add' ? 'active' : ''}
            >
              Informações Adicionais
            </Link>
          </li>
        </ul>
      </Menus>
    </>
  );
}

MenusEdit.propTypes = {
  client: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
};
