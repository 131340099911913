import styled, { keyframes } from 'styled-components';
import { darken, shade } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
  margin-top: 22px;

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;

    .fiscal {
      display: flex;
      background: #0faf69;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .contabil {
      display: flex;
      background: #00468c;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .tributos {
      display: flex;
      background: #ff5c26;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  .btn-green {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }

  .btnEdit {
    background: #00b259;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00b259')};
    }
  }

  .btnDelete {
    background: #ff4d4d;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF4D4D')};
    }
  }

  .btn-org {
    display: flex;

    button + button {
      margin-left: 4px;
    }
  }

  input {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #999;
    padding-left: 5px;
    margin-right: 4px;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }
  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #fff;
    &:hover {
      background: #0faf69;
    }
  }
  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .clearBtn {
    background: #999;
    color: #fff;
    border-radius: 5px;
    margin-right: 22px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#999')};
    }
  }

  .createBtn {
    background: #00b259;
    color: #fff;
    border-radius: 5px;
    margin-right: 22px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#00b259')};
    }
  }

  .conteudo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      padding-top: 8px;
    }

    div {
      display: flex;
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;
  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 6px;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;

  .total {
    display: block;

    p {
      font-size: 12px;
      color: #000;
    }

    span {
      font-size: 42px;
      font-weight: bold;
    }
  }

  .blue {
    color: #00468c;
  }

  .green {
    color: #36d900;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff3e3e;
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;

    + select {
      margin-left: 5px;
    }
  }
  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;
    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }
    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }
  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    select {
      background: #999;
      color: #fff;
    }
    span {
      margin-right: 5px;
    }
  }
  .bloco-one {
    display: flex;
    align-items: center;
    justify-items: center;

    .form {
      display: flex;
      align-items: center;
      justify-content: center;

      + .form {
        margin-left: 12px;
      }
    }

    p {
      font-size: 12px;
    }

    p + p {
      padding-right: 5px;
    }

    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  .bloco-two {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      margin-right: 5px;
    }
    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;
      &:hover {
        background: #2a9fe5;
      }
    }
    button + button {
      margin-left: 5px;
    }
  }
`;

export const Pagination = styled.div`
  display: block;
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
  }
  select {
    border: 0;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
  }
`;

export const PageVer = styled.div`
  display: flex;
  align-items: center;
  form span {
    font-size: 12px;
    margin-right: 6px;
  }
  input {
    border: 0;
    width: 45px;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 8px;
  }
  button {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    border-radius: 6px;
    background: #00b259;
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
  }
  button + button {
    margin-left: 6px;
  }
  .hidden {
    display: none;
  }
  button.desativa {
    background: #999;
  }
`;

export const Loading = styled.div`
  position: fixed;
  display: flex;
  z-index: 9;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;

  img {
    width: 70px;
    height: 70px;
    animation: ${rotate} 1s linear infinite;
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        font-size: 12px;
        background: #008c69;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;
