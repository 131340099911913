import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Link, useParams } from 'react-router-dom';

import Lottie from 'lottie-react-web';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import {
  MdDelete,
  MdCheckBox,
  MdVpnKey,
  MdEdit,
  MdWarning,
  MdCancel,
} from 'react-icons/md';
import animation from '~/assets/skeleton.json';
import history from '~/services/history';

import api from '~/services/api';

import Container from '~/components/Container';
import Title from '~/components/Title';
import User from '~/components/User';
import Navigation from '~/components/Navigation/Cliente';
import Nav from '~/components/Navigation';
import ServModal from '~/components/Modal/ClienteServ';
import SenhaModal from '~/components/Modal/ClienteSenha';
import {
  Content,
  Bloco,
  Grid,
  Texto,
  List,
  Ativo,
  Modal,
  ContentModal,
  TabelaDiv,
} from './styles';

import Footer from '~/components/Footer';

export default function Show() {
  const profile = useSelector(state => state.user.profile);
  const [cliente, setCliente] = useState('');
  const { cnpj } = useParams();
  const [modal, setModal] = useState(false);
  const [modalServ, setModalServ] = useState(false);
  const [modalSenha, setModalSenha] = useState(false);

  const [tributacoes, setTributacoes] = useState([]);
  const [fiscais, setFiscais] = useState([]);
  const [cnaes, setCnaes] = useState([]);
  const [socios, setSocios] = useState([]);
  const [atos, setAtos] = useState([]);
  const [negativas, setNegativas] = useState([]);
  const [procuracoes, setProcuracoes] = useState([]);
  const [digitais, setDigitais] = useState([]);
  const [licencas, setLicencas] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [info, setInfo] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`clientes/${cnpj}`).then(response => {
      try {
        const clienteRes = response.data;

        const percorre = {
          ...clienteRes,
          abertura: clienteRes.abertura
            ? format(parseISO(clienteRes.abertura), "dd 'de' MMMM 'de' yyyy", {
                locale: pt,
              })
            : null,
          data_servico: clienteRes.data_servico
            ? format(
                parseISO(clienteRes.data_servico),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
        };

        setCliente(percorre);

        setTributacoes(response.data.tributacoes);
        setFiscais(response.data.fiscais);
        setCnaes(response.data.cnaes);
        setSocios(response.data.socios);
        setAtos(response.data.atos);
        setNegativas(response.data.negativas);
        setProcuracoes(response.data.procuracoes);
        setDigitais(response.data.certificados);
        setLicencas(response.data.licencas);
        setContatos(response.data.contatos);
        setInfo(response.data.adicionais);

        setLoading(false);

        localStorage.setItem('cliente', JSON.stringify(response.data));
        localStorage.setItem(
          'matriz_name',
          JSON.stringify(response.data.razao)
        );
      } catch (err) {
        toast.warning(err.message);
        if (err.message === 'Request failed with status code 404') {
          history.push('/dashboard');
        }
      }
    });
  }, [cnpj]);

  const handleModal = useCallback(e => {
    if (e === 'open') {
      setModal(true);
      return;
    }
    setModal(false);
  }, []);

  const handleDelete = useCallback(async event => {
    try {
      await api.delete(`clientes/${event}`);

      history.push('/clientes');
      toast.success('Cliente deletado com sucesso!');
    } catch (err) {
      //
    }
  }, []);

  const handleModalServ = useCallback(e => {
    if (e === 'open') {
      setModalServ(true);
      return;
    }
    setModalServ(false);
  }, []);

  const handleModalSenha = useCallback(e => {
    if (e === 'open') {
      setModalSenha(true);
      return;
    }
    setModalSenha(false);
  }, []);

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title={`Cliente / ${cliente.razao}`} />
          <Bloco>
            <div>
              <h2>Dados do Cliente</h2>
            </div>
            <div className="btn">
              <Link to={`/edit/cliente/${cliente.id}`}>
                <MdEdit size={16} color="#fff" /> Editar
              </Link>
              {profile.role_id === 1 && (
                <button
                  type="button"
                  onClick={() => handleModal('open')}
                  className="btn-delete"
                >
                  <MdDelete size={16} color="#fff" /> Excluir
                </button>
              )}
              {profile.role_id === 3 && (
                <button
                  type="button"
                  onClick={() => handleModal('open')}
                  className="btn-delete"
                >
                  <MdDelete size={16} color="#fff" /> Excluir
                </button>
              )}
            </div>
          </Bloco>
        </Content>

        <TabelaDiv>
          <Navigation
            tributacoes={tributacoes}
            fiscais={fiscais}
            cnaes={cnaes}
            socios={socios}
            atos={atos}
            negativas={negativas}
            procuracoes={procuracoes}
            digitais={digitais}
            licencas={licencas}
            contatos={contatos}
            info={info}
            id={cliente.id}
            cnpj={cnpj}
          />
        </TabelaDiv>

        <Grid>
          {loading ? (
            <div className="loading">
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </div>
          ) : (
            <>
              <List>
                <div className="grid-100">
                  <span>Razão Social</span>
                  <Texto>
                    {cliente.tipo === 'MATRIZ' ? (
                      <>
                        <h4>{cliente.razao}</h4>
                        <Link to={`/filial/${cliente.id}/clientes`}>
                          Outros estabelecimentos
                        </Link>
                      </>
                    ) : (
                      <>
                        <h4>{cliente.razao}</h4>
                        {cliente.id_matriz && (
                          <Link to={`/cliente/${cliente.id_matriz}`}>
                            Visualizar matriz
                          </Link>
                        )}
                      </>
                    )}
                  </Texto>
                </div>
              </List>

              <List>
                <div className="grid-none">
                  <Texto>
                    {cliente.status === 'Ativo' && (
                      <p className="green">
                        <MdCheckBox size={20} color="#0FAF69" /> Contrato Ativo
                      </p>
                    )}
                    {cliente.status === 'Rescindido' && (
                      <p className="red">
                        <MdCancel size={20} color="#FF2626" /> Contrato
                        Rescindido
                      </p>
                    )}
                    {cliente.status === 'Baixada' && (
                      <p className="red">
                        <MdCancel size={20} color="#FF2626" /> Empresa Baixada
                      </p>
                    )}
                    {cliente.status === 'Suspenso' && (
                      <p className="orange">
                        <MdWarning size={20} color="#FF7F00" /> Contrato
                        Suspenso
                      </p>
                    )}
                    <button
                      type="button"
                      onClick={() => handleModalServ('open')}
                    >
                      + Detalhes
                    </button>
                  </Texto>
                </div>

                <div className="grid-none">
                  <Texto>
                    <p>Serviços:</p>
                    EXECUÇÃO (
                    {cliente.execucao && (
                      <>
                        {cliente.execucao.map(c => (
                          <span>{c}</span>
                        ))}
                      </>
                    )}
                    ) - CONSULTORIA (
                    {cliente.consultoria && (
                      <>
                        {cliente.consultoria.map(c => (
                          <span>{c}</span>
                        ))}
                      </>
                    )}
                    )
                  </Texto>
                </div>
                <div className="grid-none">
                  <Texto>
                    <p>Vigência do Contrato: {cliente.data_servico}</p>
                  </Texto>
                </div>
              </List>

              <List>
                <div className="grid-20">
                  <span>Nome Fantasia:</span>
                  <Texto>
                    <div>{cliente.fantasia}</div>
                  </Texto>
                </div>

                <div className="grid-10">
                  <span>Unidade:</span>
                  <Texto>
                    <div>{cliente.tipo}</div>
                  </Texto>
                </div>

                <div className="grid-30">
                  <span>Natureza Jurídica:</span>
                  <Texto>
                    <div>
                      {cliente.nat_jur} - {cliente.titleJuri}
                    </div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Endereço:</span>
                  <Texto>
                    <div>
                      {cliente.end}, {cliente.end_n} {cliente.end_c},{' '}
                      {cliente.cidade} - {cliente.state} CEP:
                      {cliente.cep}
                    </div>
                  </Texto>
                </div>
              </List>

              <List>
                <div className="grid-flex">
                  <span>CNPJ / CPF:</span>
                  <Texto>
                    <div>
                      {cliente.cnpj.length === 14 ? (
                        <InputMask
                          mask="99.999.999/9999-99"
                          value={cliente.cnpj.replace('x', '1')}
                          disabled
                        />
                      ) : (
                        <InputMask
                          mask="99.999.999-99"
                          value={cliente.cnpj}
                          disabled
                        />
                      )}
                    </div>
                  </Texto>
                  <Ativo>
                    Situação: <strong>{cliente.situacaoCnpj}</strong> -
                    Abertura: <strong>{cliente.abertura}</strong>
                  </Ativo>
                </div>

                <div className="grid-flex">
                  <span>PORTE:</span>
                  <Texto>
                    <div>{cliente.porte}</div>
                  </Texto>
                  <Ativo>
                    Capital Social: <strong>{cliente.capital}</strong>
                  </Ativo>
                </div>

                <div className="grid-flex">
                  <span>Inscrição Municipal:</span>
                  <Texto>
                    <div>{cliente.insc_mun}</div>
                  </Texto>
                  <Ativo>
                    Situação: <strong>{cliente.situacaoMuni}</strong>
                  </Ativo>
                </div>

                <div className="grid-flex">
                  <span>Inscrição Estadual:</span>
                  <Texto>
                    <div>{cliente.insc_est}</div>
                  </Texto>
                  <Ativo>
                    Situação: <strong>{cliente.situacaoEst}</strong>
                  </Ativo>
                </div>

                <div className="grid-flex">
                  <span>NIRE/Registro:</span>
                  <Texto>
                    <div>{cliente.nire}</div>
                  </Texto>
                  <Ativo>
                    Órgão de Registro: <strong>{cliente.situacaoOrgao}</strong>
                  </Ativo>
                </div>
              </List>

              <List>
                <div className="grid-flex">
                  <span>Possui Caixa Postal?</span>
                  <Texto>
                    <div>{cliente.postal_rfb ? 'SIM' : 'NÃO'}</div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Possui Opção DT-e?</span>
                  <Texto>
                    <div>{cliente.dte}</div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Participa de licitação?</span>
                  <Texto>
                    <div>{cliente.licitacoes ? 'SIM' : 'NÃO'}</div>
                  </Texto>
                </div>
              </List>

              <List>
                <div className="grid-flex">
                  <span>Tributação Federal:</span>
                  <Texto>
                    <div>{cliente.federal}</div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Tributação Estadual:</span>
                  <Texto>
                    <div>{cliente.estadual}</div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Tributação Municipal:</span>
                  <Texto>
                    <div>{cliente.municipal}</div>
                  </Texto>
                </div>

                <button type="button" onClick={() => handleModalSenha('open')}>
                  <MdVpnKey size={14} color="#fff" /> Senhas
                </button>
              </List>

              <List>
                <div className="grid-flex">
                  <span>Sindicato Patronal:</span>
                  <Texto>
                    <div>{cliente.sindPa}</div>
                  </Texto>
                </div>

                <div className="grid-flex">
                  <span>Sindicato Laboral:</span>
                  <Texto>
                    <div>{cliente.sindLa}</div>
                  </Texto>
                </div>
              </List>
            </>
          )}
        </Grid>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          <h4>Aviso!</h4>
          <p>Você deseja raelmente deletar o cliente?</p>
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModal('close')}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-save"
              onClick={() => handleDelete(cliente.id)}
            >
              Sim
            </button>
          </div>
        </ContentModal>
      </Modal>

      <Modal modal={modalServ}>
        <ContentModal>
          <h4>Histórico de Status</h4>
          <ServModal id={cliente.id} carrega={modalServ} />
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModalServ('close')}
            >
              Fechar
            </button>
          </div>
        </ContentModal>
      </Modal>

      <Modal modal={modalSenha}>
        <ContentModal>
          <h4>Logins, Senhas e Códigos de Acesso</h4>
          <SenhaModal
            pgdas_cnpj={cliente.pgdas_cnpj}
            pgdas_cpf={cliente.pgdas_cpf}
            pgdas_cod={cliente.pgdas_cod}
            login_iss={cliente.login_iss}
            senha_iss={cliente.senha_iss}
            login_prev={cliente.login_prev}
            senha_prev={cliente.senha_prev}
            login_sefaz={cliente.login_sefaz}
            login_fap={cliente.login_fap}
            senha_fap={cliente.senha_fap}
            senha_censo={cliente.senha_censo}
            senha_rde={cliente.senha_rde}
            senha_dcbe={cliente.senha_dcbe}
            outras={cliente.outras}
            idCliente={cliente.id}
            ativoUser={cliente.ativoUser}
          />
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModalSenha('close')}
            >
              Fechar
            </button>
          </div>
        </ContentModal>
      </Modal>
    </>
  );
}
