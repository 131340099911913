import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding-top: 14px;
`;

export const Head = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #00468c;
`;

export const Calendar = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  background-color: #fff;
  padding: 10px;

  .grid-item {
    background-color: #eeeeee;
    border: 1px solid #fff;
    padding: 20px;
    font-size: 22px;
    text-align: center;
    transition: background-color 0.2s;

    &:hover {
      background: #00468c;
      color: #fff;
    }
  }

  .active {
    background: #00468c;
    color: #fff;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    border: 0;
    background: #00468c;
    color: #fff;
    font-size: 12px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#00468c')};
    }
  }
`;
