import React, { useEffect, useState, useCallback } from 'react';

import pt from 'date-fns/locale/pt';
import { format, parseISO, isBefore, getMonth, setMonth } from 'date-fns';
import InputMask from 'react-input-mask';

import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  MdInfoOutline,
  MdHighlightOff,
  MdCheckCircle,
  MdReportProblem,
  MdCheck,
  MdArrowDropDown,
  MdModeEdit,
} from 'react-icons/md';

import {
  Content,
  Bloco,
  Card,
  Modal,
  ContentModal,
  Loading,
  Footer,
  Pesquisa,
  ModalDialogDiv,
} from './styles';
import 'react-month-picker-input/dist/react-month-picker-input.css';

import { usePermission } from '~/hooks/permission';

import CalendarMonth from '~/components/CalendarMonth';
import MonthDays from '~/components/MonthDays';
import ModalDialog from '~/components/Modal/Dialog';
import Container from '~/components/Container';
import Title from '~/components/Title';
import Carregando from '~/components/Carregando/MaterialUi/loading';
import logo from '~/assets/logo-secran.svg';
import FooterPage from '~/components/Footer';

import api from '~/services/api';
import { urlFile, urlRh } from '~/utils/url.json';

import SemAvatar from '~/assets/no-avatar.png';

const listClienteMovimento = [
  {
    id: 1,
    title: 'DOC. RECEBIDA EM ATRASO DO CLIENTE - DATA REC.',
    type: 'date',
  },
  {
    id: 2,
    title: 'DOC. RECEBIDO EM ATRASO DE OUTRO SETOR SECRAN - DATA REC.',
    type: 'datesetor',
  },
  {
    id: 3,
    title: 'CLIENTE CONFIRMOU INFORMAÇÕES EM ATRASO - DATA CONF.',
    type: 'date',
  },
  {
    id: 4,
    title: 'OUTRO SETOR SECRAN CONFIRMOU INFORMAÇÕES EM ATRASO - DATA CONF.',
    type: 'datesetor',
  },
  { id: 5, title: 'SISTEMA FORA DO AR | NOME DO SISTEMA:', type: 'text' },
  { id: 6, title: 'PROBLEMAS ESTRUTURA SECRAN | DETALHAR:', type: 'text' },
];

export default function Table() {
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const cargo = profile.cargo.subtitle;
  const [obr, setObr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkClienteMovimento, setCheckClienteMovimento] = useState(false);
  const [typeListClienteMovimento, setTypeClienteMovimento] = useState('');
  const [clientes, setClientes] = useState([]);
  const [viewObs, setViewObs] = useState('');
  const [deleteObs, setDeleteObs] = useState('');
  const [idcliente, setIDCliente] = useState(0);
  const [funcao, setFuncao] = useState(
    getStorageFuncao && profile.role_id === 1
      ? JSON.parse(getStorageFuncao)
      : profile.cargo.subtitle
  );

  const [modal, setModal] = useState(false);
  // const [cobs, setObs] = useState(false);
  const [view, setView] = useState('');
  const [loadingMarca, setLoadingMarca] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [editMarcado, setEditMarcado] = useState('');
  const [vencimento, setVencimento] = useState('');
  const [dateVerify, setDateVerify] = useState(true);
  const [loadApi, setLoadApi] = useState(false);
  const [loadDel, setLoadDel] = useState(false);
  const [reviewed, setReviewed] = useState([]);
  const [loadReview, setLoadReview] = useState(false);
  const [modalret, setModalRet] = useState(false);
  const [obrigacoes, setObrigacoes] = useState([]);
  const [analistas, setAnalistas] = useState([]);
  const [executores, setExecutores] = useState([]);
  const [modalAnalista, setModalAnalista] = useState(true);
  const [nameAnalista, setNameAnalista] = useState('');
  const [inputExecutor, setInputExecutor] = useState(0);
  const [obridParams, setObrId] = useState('');
  const [totalC, setTotalCliente] = useState(0);
  const [btnEdit, setBtnEdit] = useState(true);
  const [modalMonth, setModalMonth] = useState(false);

  const [anoCalendar, setAnoCalendar] = useState(
    Number(format(new Date(), 'yyyy'))
  );

  const [mesCalendar, setMesCalendar] = useState(
    Number(format(new Date(), 'MM'))
  );
  const [mesCalendarFormatted, setMesCalendarFormatted] = useState(
    format(new Date(), 'MMMM', { locale: pt })
  );

  const [dayCalendar, setDayCalendar] = useState('');
  const [modalDay, setModalDay] = useState(false);
  const [inputCliente, setInputCliente] = useState({
    title: '',
    date: '',
    setor: '',
    obs: '',
  });

  const { cargoPermission } = usePermission();
  const history = useHistory();

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const executor = params.get('executor') || inputExecutor;
  const getfuncao = params.get('funcao') || funcao;
  const page = params.get('page') || 1;
  const limit = params.get('limit') || 1000;
  const dateYear = params.get('year') || anoCalendar;
  const dateMonth = params.get('month') || mesCalendar;
  const dateDay = params.get('day') || dayCalendar;
  const obrid = params.get('obrid') || obridParams;
  const done = params.get('done') || '';
  const getMes = getMonth(new Date()) + 1;

  useEffect(() => {
    cargoPermission({ cargo });

    if (profile.role_id === 1 && modalAnalista) {
      return;
    }

    if (profile.cargo.tipo === 'coord' && modalAnalista) {
      return;
    }

    if (profile.cargo.tipo === 'supervisao' && modalAnalista) {
      return;
    }

    async function loadCliente() {
      setBtnEdit(getMes === Number(dateMonth));

      setLoading(true);

      const resObr = await api.get(
        `table/obrigacoes?funcao=${getfuncao}&executor=${executor ||
          ''}&year=${dateYear}&month=${dateMonth}&done=${done}&obrid=${obridParams}`
      );

      const response = await api.get(
        `obr/clientes?executor=${executor ||
          ''}&funcao=${getfuncao}&year=${dateYear}&month=${dateMonth}&done=${done}&obrid=${obridParams}`
      );

      const clienteIds = response.data.map(item => item.id);

      const resVencimentos = await api.patch('vencimento/update/obrigacoes', {
        id_cliente: clienteIds,
        funcao: getfuncao,
      });

      const verificaExes = !!resObr.data.exes.length;

      const percorre = response.data.map(item => ({
        ...item,
        execucao: verificaExes
          ? resObr.data.exes.map(
              i =>
                i.cliente_id === item.id && {
                  ...i,
                  dateFormatted: format(
                    parseISO(i.date_entregue),
                    'dd/MM/yyyy'
                  ),
                  verificaIgualDate: i.date_entregue === i.date_prazo,
                  verificaDateE: isBefore(
                    parseISO(i.date_entregue),
                    parseISO(i.date_prazo)
                  ),
                }
            )
          : [],
      }));

      const tbres = await api.get(
        `date/obrigacoes?status=${getfuncao}&year=${dateYear}&month=${dateMonth}&day=${dateDay}&obrid=${obridParams}`
      );

      const doneRes = tbres.data.keys();
      const verificaObr = doneRes.next().done;

      // Adicionando total de obrigacoes
      const obrAtua = tbres.data.map(p => ({
        ...p,
        totalObr: percorre.map(c => c.obrigacoes.filter(i => i === p.obr_id)),
      }));

      // Convertendo o total em somente um array
      const passa = obrAtua.map(item => ({
        ...item,
        prazo_d: resObr.data.exes
          ? resObr.data.exes.filter(
              t => t.id_obr === item.obr_id && t.date_entregue <= t.date_prazo
            )
          : null,
        prazo_f: resObr.data.exes
          ? resObr.data.exes.filter(
              t =>
                t.id_obr === item.obr_id &&
                t.date_entregue > t.date_prazo &&
                !t.execucao
            )
          : null,
        prazo_c: resObr.data.exes
          ? resObr.data.exes.filter(t => t.id_obr === item.obr_id && t.execucao)
          : null,
        totalObr: item.totalObr.reduce((acc, val) => acc.concat(val), []),
      }));

      // Percorrendo e somando a porcentagem
      const porcentagem = passa.map(item => ({
        ...item,
        calculoD: (item.prazo_d.length / item.totalObr.length) * 100,
        calculoF: (item.prazo_f.length / item.totalObr.length) * 100,
        calculoC: (item.prazo_c.length / item.totalObr.length) * 100,
        somaTotal:
          item.prazo_c.length + item.prazo_d.length + item.prazo_f.length,
        vencimentoFormatted: format(
          parseISO(item.vencimento_emp),
          'dd/MM/yyyy'
        ),
      }));
      // Calculando a porcentagem de cada
      const porcentagemT = porcentagem.map(item => ({
        ...item,
        percentageT: (item.somaTotal / item.totalObr.length) * 100,
        somaAfazer:
          item.prazo_d.length + item.prazo_c.length + item.prazo_f.length,
        vencimento: format(parseISO(item.obrigacoes.vencimento), 'dd/MM/yyyy'),
      }));

      setObr(porcentagemT);
      setObrId(obrid);
      setLoading(false);
      setDateVerify(verificaObr);
      setClientes(percorre);
      setFuncao(getfuncao);
      setObrigacoes(resVencimentos.data);
      setTotalCliente(response.data.length);
    }

    loadCliente();

    if (loadApi) {
      loadCliente();
    }
  }, [done, dayCalendar, executor, funcao, getMes, getfuncao, inputExecutor, limit, loadApi, mesCalendar, obrid, page, profile.cargo.subtitle, cargoPermission, cargo, profile.role_id, profile.cargo.tipo, obridParams]); //eslint-disable-line

  useEffect(() => {
    if (profile.role_id === 4) return;
    async function loadTabs() {
      setLoadReview(true);
      const res = await api.get(
        `table/obrigacoes?reviewed=true&date=&funcao=${getfuncao}`
      );

      setLoadReview(false);
      setReviewed(res.data);
    }

    loadTabs();
  }, [getfuncao, profile.role_id]);

  useEffect(() => {
    async function loadUsers() {
      await api.put(`users/${profile.id}?only=true`, {
        page: window.location.pathname,
      });
    }

    loadUsers();
  }, [profile.id]);

  useEffect(() => {
    async function loadUsers() {
      if (profile.role_id === 1) {
        const response = await api.get(`executores/geral?funcao=${funcao}`);
        setAnalistas(response.data);
        return;
      }

      const response = await api.get(`executores/geral`);

      if (
        profile.cargo.tipo === 'coord' ||
        profile.cargo.tipo === 'supervisao'
      ) {
        setAnalistas(response.data);
      } else {
        setModalAnalista(false);
        setExecutores(response.data);
      }
    }

    loadUsers();
  }, [funcao, profile.cargo.tipo, profile.role_id]);

  const handleModal = useCallback(
    ({ open, item, clienteID }) => {
      if (open === 'open') {
        const obrigacao = obrigacoes.find(
          obrAlterado =>
            obrAlterado.id_cliente === clienteID &&
            obrAlterado.id_obr === item.obr_id
        );

        const dadosItem = {
          ...item,
          date_venc: item.vencimento_emp,
          title: item.obrigacoes.title,
          dateFormattedVenc: format(
            parseISO(item.vencimento_emp),
            'dd-MM-yyyy'
          ),
          obrigacao,
        };

        setView(dadosItem);
        setIDCliente(clienteID);
        setModal(true);

        const dataAtual = format(new Date(), 'yyyy-MM-dd');
        const dadosDate = {
          venc: item.vencimento_emp,
          atual: dataAtual,
        };

        setVencimento(dadosDate);

        return;
      }
      setModal(false);
      // setObs(false);
    },
    [obrigacoes]
  );

  const handleModalEdit = useCallback(
    ({ open, item, marcado, clienteID }) => {
      if (open !== 'open') {
        setModalEdit(!modalEdit);
        setEditMarcado('');
        setCheckClienteMovimento(false);
        setInputCliente({
          title: '',
          date: '',
          setor: '',
          obs: '',
        });
        setTypeClienteMovimento('');
        return;
      }

      const obrigacao = obrigacoes.find(
        obrAlterado =>
          obrAlterado.id_cliente === clienteID &&
          obrAlterado.id_obr === item.obr_id
      );

      const dadosItem = {
        ...item,
        date_venc: item.vencimento_emp,
        title: item.obrigacoes.title,
        dateFormattedVenc: format(parseISO(item.vencimento_emp), 'dd-MM-yyyy'),
        obrigacao,
        marcado,
      };

      const dataAtual = format(new Date(), 'yyyy-MM-dd');
      const dadosDate = {
        venc: item.vencimento_emp,
        atual: dataAtual,
      };

      setVencimento(dadosDate);

      setModalEdit(!modalEdit);
      setEditMarcado(dadosItem);
    },
    [modalEdit, obrigacoes]
  );

  const handleMarcado = useCallback(
    async e => {
      const dataPost = format(new Date(), 'yyyy-MM-dd');
      const check = document.querySelector('.check:checked');
      const movimento = document.querySelector('.movimento:checked');

      let obser = '';

      obser = inputCliente.obs === '' ? null : inputCliente.obs;

      if (checkClienteMovimento) {
        if (!typeListClienteMovimento.type) {
          toast.warning('Por favor! Selecione uma opção');
          return;
        }

        if (typeListClienteMovimento.type === 'date') {
          if (!inputCliente.date) {
            toast.warning('Por favor! Preencha o campo vazio');
            return;
          }
          obser = `${inputCliente.title}: ${inputCliente.date}`;
        }

        if (typeListClienteMovimento.type === 'datesetor') {
          if (!inputCliente.date || !inputCliente.setor) {
            toast.warning('Por favor! Preencha os campos vazio');
            return;
          }
          obser = `${inputCliente.title}: ${inputCliente.date} / Setor: ${inputCliente.setor}`;
        }

        if (typeListClienteMovimento.type === 'text') {
          if (!inputCliente.obs) {
            toast.warning('Por favor! Preencha o campo vazio');
            return;
          }

          obser = `${inputCliente.title}: ${inputCliente.obs}`;
        }
      }

      const verificaCheck = !!check;

      try {
        setLoadingMarca(true);
        const resMarca = await api.post(`table/obrigacoes`, {
          date_prazo: e.venc,
          id_obr: e.id,
          acesso: funcao,
          cliente_id: e.clienteID,
          obs: e.digito ? e.digito : obser,
          execucao: verificaCheck,
          notmove: !!movimento,
        });

        if (resMarca.data.error) {
          toast.warning(resMarca.data.error);
          setLoadingMarca(false);
          return;
        }

        const percorreMarca = {
          ...resMarca.data,
          dateFormatted: format(
            parseISO(resMarca.data.date_entregue),
            'dd/MM/yyyy'
          ),
          verificaIgualDate:
            resMarca.data.date_entregue === resMarca.data.date_prazo,
          verificaDateE: isBefore(
            parseISO(resMarca.data.date_entregue),
            parseISO(resMarca.data.date_prazo)
          ),
          retificacao: [],
        };

        const resCliente = clientes.map(i => ({
          ...i,
          execucao:
            i.id === e.clienteID
              ? i.execucao.concat(percorreMarca)
              : i.execucao,
        }));

        // Percorre e passa os prazos
        const resOBR = obr.map(o => ({
          ...o,
          prazo_d:
            o.obr_id === e.id && e.venc >= dataPost
              ? o.prazo_d.concat(percorreMarca)
              : o.prazo_d,
          prazo_f:
            o.obr_id === e.id && e.venc < dataPost && !check
              ? o.prazo_f.concat(percorreMarca)
              : o.prazo_f,
          prazo_c:
            o.obr_id === e.id && check
              ? o.prazo_c.concat(percorreMarca)
              : o.prazo_c,
        }));

        // Percorrendo e somando a porcentagem
        const porcentagem = resOBR.map(item => ({
          ...item,
          calculoD: (item.prazo_d.length / item.totalObr.length) * 100,
          calculoF: (item.prazo_f.length / item.totalObr.length) * 100,
          calculoC: (item.prazo_c.length / item.totalObr.length) * 100,
          somaTotal:
            item.prazo_c.length + item.prazo_d.length + item.prazo_f.length,
        }));

        // Percorrendo e somando o total em porcentagens
        const porcentagemPercorre = porcentagem.map(item => ({
          ...item,
          percentageT: (item.somaTotal / item.totalObr.length) * 100,
          somaAfazer:
            item.prazo_d.length + item.prazo_c.length + item.prazo_f.length,
        }));

        setObr(porcentagemPercorre);
        setClientes(resCliente);
        setLoadingMarca(false);
        setModal(false);
        // setObs(false);

        toast.success('Você marcou uma obrigação.');
      } catch (err) {
        setLoadingMarca(false);
        toast.error('Algo deu errado.');
      }
    },
    [
      checkClienteMovimento,
      clientes,
      funcao,
      inputCliente.date,
      inputCliente.obs,
      inputCliente.setor,
      inputCliente.title,
      obr,
      typeListClienteMovimento.type,
    ]
  );

  const handleChangeMarcado = useCallback(
    event => {
      setEditMarcado({
        ...editMarcado,
        marcado: { ...editMarcado.marcado, [event.name]: event.event },
      });

      setInputCliente({ ...inputCliente, [event.name]: event.event });
      setCheckClienteMovimento(event.event);
    },
    [editMarcado, inputCliente]
  );

  const handleMarcadoEdit = useCallback(async () => {
    const { id, execucao, obs, notmove } = editMarcado.marcado;
    try {
      setLoadingMarca(true);
      await api.put(`table/obrigacoes/${id}`, {
        execucao,
        obs,
        notmove,
      });

      const percorreClientes = clientes.map(cliente => ({
        ...cliente,
        execucao: cliente.execucao.map(obrigacao => ({
          ...obrigacao,
          execucao: obrigacao.id === id ? execucao : obrigacao.execucao,
          obs: obrigacao.id === id ? obs : obrigacao.obs,
          notmove: obrigacao.id === id ? notmove : obrigacao.notmove,
        })),
      }));

      toast.success('Atualizado com sucesso.');
      setModalEdit(!modalEdit);
      setEditMarcado('');
      setLoadingMarca(false);
      setClientes(percorreClientes);
    } catch (err) {
      toast.error(err.message);
      setModalEdit(!modalEdit);
      setEditMarcado('');
      setLoadingMarca(false);
    }
  }, [clientes, editMarcado.marcado, modalEdit]);

  const handleCalendar = useCallback(
    day => {
      setDayCalendar(day);
      setModalDay(false);

      history.push(
        `/view/table/obrigacoes?year=${dateYear}&month=${dateMonth}&day=${day}&obrid=${obridParams}&done=${done}`
      );
    },
    [dateMonth, dateYear, done, history, obridParams]
  );

  const handleMes = useCallback(
    monthYear => {
      const monthAddMore = monthYear.month + 1;
      setAnoCalendar(monthYear.year);
      setMesCalendar(monthAddMore);

      history.push(
        `/view/table/obrigacoes?year=${monthYear.year}&month=${monthAddMore}&day=${dayCalendar}&obrid=${obridParams}&done=${done}`
      );
      const monthFormatted = format(
        setMonth(new Date(), monthYear.month),
        'MMMM',
        {
          locale: pt,
        }
      );
      setModalMonth(false);
      setMesCalendarFormatted(monthFormatted);
    },
    [dayCalendar, done, history, obridParams]
  );

  const handleObservacao = useCallback(event => {
    if (event.modal === 'open') {
      setViewObs(event.obs);
      setModal(true);
      return;
    }

    setModal(false);
    setViewObs(null);
  }, []);

  const handleDeleteObs = useCallback(async event => {
    if (event.modal === 'open') {
      setModal(true);
      setDeleteObs({
        obs: `Deseja desativar essa obrigação de ${event.name}!`,
        id: event.id,
        name: event.name,
        cliente_id: event.cliente_id,
      });
      return;
    }

    if (event.modal === 'delete') {
      setLoadDel(true);
      await api.delete(
        `obr/clientes/${event.id}?cliente_id=${event.cliente_id}&name=${event.name}`
      );
      setLoadDel(false);
    }

    toast.success('Deletado com sucesso.');

    setLoadApi(true);
    setDeleteObs(null);
    setModal(false);
  }, []);

  const handleReview = useCallback(
    async e => {
      try {
        setLoadReview(true);
        const resRev = await api.post('table/obrigacoes', {
          cliente_id: e,
          reviewed: true,
        });

        const dadosReview = reviewed.concat(resRev.data);

        setReviewed(dadosReview);

        setLoadReview(false);
        toast.success('Revisado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado.');
      }
    },
    [reviewed]
  );

  const handleRetificacao = useCallback(({ open, item, clienteID }) => {
    if (open === 'open') {
      const dadosItem = {
        ...item,
        date_venc: item.vencimento_emp,
        title: item.obrigacoes.title,
      };

      setView(dadosItem);
      setIDCliente(clienteID);
      setModalRet(true);
      return;
    }

    setModalRet(false);
  }, []);

  const handleRetMarca = useCallback(
    async e => {
      const observacao = document.querySelector('.obss').value;
      const obser = observacao === '' ? null : observacao;

      if (!obser) {
        toast.error('Por favor! Preencha a observação');
        return;
      }

      try {
        setLoadingMarca(true);
        const retRes = await api.post(`table/obrigacoes`, {
          id_obr: e.id,
          acesso: funcao,
          cliente_id: e.clienteID,
          obs: obser,
          retificate: true,
        });

        const percorreRet = [retRes.data];

        const resCliente = clientes.map(i => ({
          ...i,
          execucao:
            i.id === e.clienteID
              ? i.execucao.map(j => ({
                  ...j,
                  retificacao:
                    j.id_obr === e.id
                      ? percorreRet.concat(j.retificacao)
                      : j.retificacao,
                }))
              : i.execucao,
        }));

        setClientes(resCliente);
        setLoadingMarca(false);
        setModalRet(false);

        toast.success('Você adicionou uma retificação');
      } catch (err) {
        setLoadingMarca(false);
        toast.error('Algo deu errado.');
      }
    },
    [clientes, funcao]
  );

  const handleExecutor = useCallback(async e => {
    if (e.target.value === 'somente') {
      setInputExecutor(0);
      return;
    }

    const idExe = e.target.value;

    setInputExecutor(idExe);
  }, []);

  const handleExecutorAnalista = useCallback(
    async event => {
      const idUser = event.target.value;

      try {
        setModalAnalista(false);
        const executoresResponse = await api.get(
          `executores/geral?user=${idUser}`
        );

        const findUserAnalista = analistas.find(
          user => user.id === Number(idUser)
        );

        setExecutores(executoresResponse.data);
        setNameAnalista(findUserAnalista ? findUserAnalista.name : '');
        setInputExecutor(idUser);
        setLoadApi(true);
      } catch (err) {
        toast.error(err.message);
        setModalAnalista(true);
      }
    },
    [analistas]
  );

  const handleModalAnalista = useCallback(() => {
    setModalAnalista(!modalAnalista);
  }, [modalAnalista]);

  const handleModalDay = useCallback(() => {
    setModalDay(!modalDay);
  }, [modalDay]);

  const handleModalMonth = useCallback(() => {
    setModalMonth(!modalMonth);
  }, [modalMonth]);

  const handleCheckClienteMovimento = useCallback(event => {
    const check = event.target.checked;

    setCheckClienteMovimento(check);
  }, []);

  const handleChangeClienteMovimento = useCallback(event => {
    const { value } = event.target;
    const findList = listClienteMovimento.find(
      item => item.id === Number(value)
    );
    setTypeClienteMovimento({ title: findList.title, type: findList.type });
  }, []);

  const handleChangeInput = useCallback(
    event => {
      setInputCliente({
        ...inputCliente,
        title: typeListClienteMovimento.title,
        [event.target.name]: event.target.value,
      });
    },
    [inputCliente, typeListClienteMovimento.title]
  );

  return (
    <>
      {loading && (
        <Loading>
          <Carregando />
        </Loading>
      )}

      <Container>
        <Content>
          <Title title="Obrigações / Tabela Geral" />

          <Bloco>
            <div>
              <h2>Obrigações / Geral</h2>
            </div>
            <Pesquisa>
              <div className="bloco">
                {
                  (profile.role_id === 1 || profile.cargo.tipo === 'coord') && nameAnalista
                    ? <div className="form">
                        <button
                          type="button"
                          className="btnanalista"
                          onClick={handleModalAnalista}
                        >
                          {nameAnalista} - (Analista)
                          <MdArrowDropDown size={15} color="#fff" />
                        </button>
                      </div>
                    : null
                }
                <div className="form">
                  <p>Executores: ({funcao}) </p>
                  <select onChange={handleExecutor}>
                    <option value="somente">Somente os meus</option>
                    {executores.map(item => (
                      <option
                        value={item.id}
                        selected={item.id === inputExecutor}
                      >
                        {item.name} ({item.cargo.title})
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="bloco">
                <div className="form">
                  <button
                    type="button"
                    onClick={handleModalMonth}
                    className="btnModalMonthYear"
                  >
                    MÊS / ANO REFERÊNCIA: {mesCalendarFormatted.toUpperCase()} /{' '}
                    {anoCalendar}
                  </button>

                  <div className="form">
                    <button
                      type="button"
                      onClick={handleModalDay}
                      className="btnModalDay"
                    >
                      PESQUISA POR DIA DA OBRIGAÇÃO:{' '}
                      {dayCalendar ? `DIA ${dayCalendar}` : 'MODO GERAL'}
                    </button>
                  </div>
                </div>
              </div>
            </Pesquisa>
          </Bloco>

          <div className="logo">
            <Link to="/obrigacoes">
              <img src={logo} title="secran" alt="secran contabilidade" />
            </Link>
          </div>
        </Content>

        <Card>
          {!dateVerify ? (
            <>
              <div className="bloco">
                <table className="tab table-striped" width="auto">
                  <thead>
                    <tr>
                      <td>
                        <p>Clientes</p>
                        {profile.role_id !== 4 && (
                          <p>
                            Total: {totalC} / {totalC - reviewed.length}
                          </p>
                        )}
                      </td>
                      {obr.map(elem => (
                        <>
                          {elem.totalObr.length > 0 && (
                            <td key={elem.id}>
                              <button
                                type="button"
                                className={
                                  elem.obrigacoes.id === Number(obridParams)
                                    ? 'btn-obractive'
                                    : 'btn-obr'
                                }
                                id={
                                  elem.obrigacoes.dateVerify ? 'red' : 'black'
                                }
                                onClick={() => {
                                  if (
                                    elem.obrigacoes.id === Number(obridParams)
                                  ) {
                                    setObrId('');
                                    setDayCalendar('');
                                  } else {
                                    setObrId(elem.obrigacoes.id);
                                    setDayCalendar('');
                                  }
                                }}
                              >
                                {elem.obrigacoes.title}
                              </button>
                              <p className="black">
                                PRAZO LEGAL: {elem.vencimento}
                              </p>
                              <p>PRAZO SECRAN: {elem.vencimentoFormatted}</p>
                              <p className="black">
                                TOTAL OBRIGAÇÕES: {elem.totalObr.length}
                              </p>
                              <div className="porcentagem">
                                <Link
                                  to="/view/table/obrigacoes?done="
                                  className="gray"
                                >
                                  {done === '' && (
                                    <MdCheck size={14} color="#fff" />
                                  )}
                                  A Fazer: {(100 - elem.percentageT).toFixed(2)}
                                  % - QTD:{' '}
                                  {elem.totalObr.length - elem.somaAfazer}
                                </Link>
                                <Link
                                  to="/view/table/obrigacoes?done=yes"
                                  className="green"
                                >
                                  {done === 'yes' && (
                                    <MdCheck size={14} color="#fff" />
                                  )}
                                  Feito no prazo :{' '}
                                  {elem.calculoD ? elem.calculoD.toFixed(2) : 0}
                                  % - QTD: {elem.prazo_d.length}
                                </Link>
                                <Link
                                  to="/view/table/obrigacoes?done=not"
                                  className="red"
                                >
                                  {done === 'not' && (
                                    <MdCheck size={14} color="#fff" />
                                  )}
                                  Feito fora no prazo:{' '}
                                  {elem.calculoF ? elem.calculoF.toFixed(2) : 0}
                                  % - QTD: {elem.prazo_f.length}
                                </Link>
                                <Link
                                  to="/view/table/obrigacoes?done=normal"
                                  className="orange"
                                >
                                  {done === 'normal' && (
                                    <MdCheck size={14} color="#fff" />
                                  )}
                                  Feito fora do prazo c/ ressalva :{' '}
                                  {elem.calculoC ? elem.calculoC.toFixed(2) : 0}
                                  % - QTD: {elem.prazo_c.length}
                                </Link>
                              </div>
                            </td>
                          )}
                        </>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {clientes.map(item => (
                      <>
                        <tr key={item.id} title={item.razao}>
                          <td>
                            <div className="div">
                              {item.razao}
                              {profile.role_id !== 4 && (
                                <span>
                                  {loadReview ? (
                                    <Carregando />
                                  ) : (
                                    <>
                                      {reviewed.find(
                                        element =>
                                          element.cliente_id === item.id
                                      ) ? (
                                        <MdCheckCircle
                                          size={14}
                                          color="#00B259"
                                        />
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() => handleReview(item.id)}
                                          className="btn-circle"
                                        >
                                          <MdCheckCircle
                                            size={14}
                                            color="#000"
                                          />
                                        </button>
                                      )}
                                    </>
                                  )}
                                </span>
                              )}
                            </div>
                            <p className="trib">
                              Executor: <strong>{item.executor}</strong>
                            </p>
                            <p className="trib">
                              Trib. Federal: <strong>{item.federal}</strong>
                            </p>
                            <p className="trib">
                              Trib. Estadual: <strong>{item.estadual}</strong>
                            </p>
                            <p className="trib">
                              Trib. Municipal: <strong>{item.municipal}</strong>
                            </p>
                          </td>
                          {obr.map(elem => (
                            <>
                              {elem.totalObr.length > 0 && (
                                <td key={elem.id} className="tableRelative">
                                  {!!obrigacoes.find(
                                    obrigacao =>
                                      obrigacao.id_cliente === item.id &&
                                      obrigacao.id_obr === elem.obr_id
                                  ) && (
                                    <div className="vencimentoAlterado">
                                      Vencimento específico do cliente:{' '}
                                      {
                                        obrigacoes.find(
                                          obrigacao =>
                                            obrigacao.id_cliente === item.id &&
                                            obrigacao.id_obr === elem.obr_id
                                        ).vencimento
                                      }
                                    </div>
                                  )}
                                  {item.obrigacoes.includes(elem.obr_id) ? (
                                    <>
                                      {item.execucao.find(
                                        ex => ex.id_obr === elem.obr_id
                                      ) ? (
                                        item.execucao.map(
                                          i =>
                                            i.id_obr === elem.obr_id && (
                                              <>
                                                <div className="btn-Ativo">
                                                  <button
                                                    key={i.id}
                                                    type="button"
                                                    className={
                                                      i.verificaIgualDate
                                                        ? 'btn-green'
                                                        : !i.verificaDateE &&
                                                          !i.execucao
                                                        ? 'btn-red'
                                                        : !i.verificaDateE &&
                                                          i.execucao
                                                        ? 'btn-orange'
                                                        : 'btn-green'
                                                    }
                                                  >
                                                    <span>
                                                      {i.file ? (
                                                        <img
                                                          src={`${urlFile}${i.file.file}`}
                                                          alt={i.user.name}
                                                          title={i.user.name}
                                                        />
                                                      ) : (
                                                        <>
                                                          {i.user.avatar ? (
                                                            <img
                                                              src={`${urlRh}${i.user.avatar}`}
                                                              alt={i.user.name}
                                                              title={
                                                                i.user.name
                                                              }
                                                            />
                                                          ) : (
                                                            <img
                                                              src={SemAvatar}
                                                              alt={i.user.name}
                                                              title={
                                                                i.user.name
                                                              }
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </span>
                                                    <div className="txt_user">
                                                      <p>{i.user.name}</p>
                                                      <p>
                                                        Atualizado:{' '}
                                                        {i.dateFormatted} às{' '}
                                                        {i.time_entregue}
                                                      </p>
                                                    </div>
                                                  </button>
                                                  {i.obs && (
                                                    <button
                                                      type="button"
                                                      className="circlo"
                                                      onClick={() =>
                                                        handleObservacao({
                                                          obs: i.obs,
                                                          modal: 'open',
                                                        })
                                                      }
                                                    >
                                                      <MdInfoOutline
                                                        size={17}
                                                        color="#FF5C26"
                                                        title="Ver Observação"
                                                      />
                                                    </button>
                                                  )}
                                                  {profile.role_id !== 4 && (
                                                    <button
                                                      type="button"
                                                      className="circlo"
                                                      title="Deletar"
                                                      onClick={() =>
                                                        handleDeleteObs({
                                                          modal: 'open',
                                                          name: i.user.name,
                                                          id: i.id,
                                                          index: i.id.index,
                                                          cliente_id: item.id,
                                                        })
                                                      }
                                                    >
                                                      <MdHighlightOff
                                                        size={17}
                                                        color="#FF4D4D"
                                                      />
                                                    </button>
                                                  )}
                                                  {btnEdit && (
                                                    <button
                                                      type="button"
                                                      className="circlo"
                                                      title="Add Retificação"
                                                      onClick={() =>
                                                        handleRetificacao({
                                                          open: 'open',
                                                          item: elem,
                                                          clienteID: item.id,
                                                        })
                                                      }
                                                    >
                                                      <MdReportProblem
                                                        size={17}
                                                        color="#FFBF00"
                                                      />
                                                    </button>
                                                  )}
                                                  {profile.role_id !== 4 && (
                                                    <button
                                                      type="button"
                                                      className="circlo"
                                                      title="Editar"
                                                      onClick={() =>
                                                        handleModalEdit({
                                                          open: 'open',
                                                          item: elem,
                                                          marcado: i,
                                                          clienteID: item.id,
                                                        })
                                                      }
                                                    >
                                                      <MdModeEdit
                                                        size={17}
                                                        color="#048A4F"
                                                      />
                                                    </button>
                                                  )}
                                                </div>
                                                {i.notmove ? (
                                                  <p className="txtMove">
                                                    Sem movimento
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                                {i.retificacao.length > 0 && (
                                                  <div className="btn-Retificadora">
                                                    <strong>
                                                      {i.retificacao.length > 1
                                                        ? 'Retificações'
                                                        : 'Retificação'}
                                                    </strong>
                                                    {i.retificacao.map(ret => (
                                                      <div
                                                        key={ret.id}
                                                        className="btn-yellow"
                                                      >
                                                        <div className="txt_retificao">
                                                          <p>
                                                            <strong>
                                                              Observação:
                                                            </strong>{' '}
                                                            {ret.obs}
                                                          </p>
                                                          <p>
                                                            Atualizado:{' '}
                                                            {ret.created_at} -
                                                            Por: {ret.user.name}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )}
                                              </>
                                            )
                                        )
                                      ) : (
                                        <>
                                          {done ? (
                                            '--'
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn-black"
                                              onClick={() =>
                                                handleModal({
                                                  open: 'open',
                                                  item: elem,
                                                  clienteID: item.id,
                                                })
                                              }
                                            >
                                              A Fazer
                                            </button>
                                          )}
                                        </>
                                      )}
                                      <p className="txtRazao">{item.razao}</p>
                                      <p className="txtRazao">
                                        CNPJ:{' '}
                                        <InputMask
                                          mask="99.999.999/9999-99"
                                          value={item.cnpj.replace('x', '1')}
                                          disabled
                                        />
                                      </p>
                                    </>
                                  ) : (
                                    'Não possui'
                                  )}
                                </td>
                              )}
                            </>
                          ))}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            'A data seleciona não consta obrigações realizadas'
          )}
        </Card>

        <Footer>
          <FooterPage />
        </Footer>
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          {deleteObs && (
            <>
              {loadDel ? (
                'carregando...'
              ) : (
                <>
                  {deleteObs.obs}
                  <div>
                    <button
                      type="button"
                      className="btn-delete"
                      onClick={() =>
                        handleDeleteObs({ modal: 'close', obs: null })
                      }
                    >
                      Fechar
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn-save"
                      onClick={() =>
                        handleDeleteObs({
                          modal: 'delete',
                          id: deleteObs.id,
                          name: deleteObs.name,
                          cliente_id: deleteObs.cliente_id,
                        })
                      }
                    >
                      Confirmar
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          {viewObs ? (
            <>
              {viewObs}
              <div>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() =>
                    handleObservacao({ modal: 'close', obs: null })
                  }
                >
                  Fechar
                </button>
              </div>
            </>
          ) : (
            <>
              {!deleteObs && (
                <>
                  {loadingMarca ? (
                    ''
                  ) : (
                    <>
                      <h4>Verificação de marcação</h4>
                      <div className="vencimento">
                        Dia do vencimento:{' '}
                        {view.obrigacao
                          ? view.obrigacao.vencimento
                          : view.dateFormattedVenc}
                      </div>
                      <div className="title">Obrigação: {view.title}</div>
                      {vencimento.venc < vencimento.atual && (
                        <div className="inputCheck">
                          <input
                            type="checkbox"
                            className="check"
                            onChange={handleCheckClienteMovimento}
                          />{' '}
                          Cliente atrasou na documentação.
                          <input type="checkbox" className="movimento" /> Sem
                          movimento
                        </div>
                      )}
                      {checkClienteMovimento ? (
                        <>
                          <p style={{ marginTop: 8, fontSize: 12 }}>
                            Opções para executor "justificar" entrega fora do
                            prazo com/ ressalva
                          </p>
                          <div className="inputForm">
                            <select onChange={handleChangeClienteMovimento}>
                              <option value="">...</option>
                              {listClienteMovimento.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                            {typeListClienteMovimento.type === 'date' && (
                              <input
                                type="date"
                                name="date"
                                className="dateMovimento"
                                onChange={handleChangeInput}
                              />
                            )}
                            {typeListClienteMovimento.type === 'datesetor' && (
                              <>
                                <input
                                  type="date"
                                  name="date"
                                  className="dateMovimento"
                                  style={{ marginTop: 6 }}
                                  onChange={handleChangeInput}
                                />
                                <select
                                  name="setor"
                                  style={{ marginTop: 5 }}
                                  onChange={handleChangeInput}
                                >
                                  <option value="">Escolha o setor</option>
                                  <option value="Setor Contábil">
                                    Setor Contábil
                                  </option>
                                  <option value="Setor Fiscal">
                                    Setor Fiscal
                                  </option>
                                  <option value="Setor Pessoal">
                                    Setor Pessoal
                                  </option>
                                  <option value="Setor Tributos">
                                    Setor Tributos
                                  </option>
                                  <option value="Setor Consultoria">
                                    Setor Consultoria
                                  </option>
                                </select>
                              </>
                            )}
                            {typeListClienteMovimento.type === 'text' && (
                              <input
                                type="text"
                                name="obs"
                                placeholder="Escreva aqui"
                                onChange={handleChangeInput}
                                style={{ marginTop: 6 }}
                              />
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="inputForm">
                          <input
                            type="text"
                            name="obs"
                            placeholder="Escrever Observação"
                            className="observacao"
                            onChange={handleChangeInput}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    {loadingMarca ? (
                      'Carregando...'
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn-save"
                          onClick={() =>
                            handleMarcado({
                              id: view.obr_id,
                              venc: view.obrigacao
                                ? view.obrigacao.vencimento
                                : view.date_venc,
                              clienteID: idcliente,
                            })
                          }
                        >
                          Feito
                        </button>
                        <button
                          type="button"
                          className="btn-delete"
                          onClick={() =>
                            handleModal({ open: 'close', item: null })
                          }
                        >
                          Cancelar
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </ContentModal>
      </Modal>

      <Modal modal={modalEdit}>
        <ContentModal>
          {loadingMarca ? (
            ''
          ) : (
            <>
              <h4>Editar marcação</h4>
              <div className="vencimento">
                Dia do vencimento:{' '}
                {editMarcado.obrigacao
                  ? editMarcado.obrigacao.vencimento
                  : editMarcado.dateFormattedVenc}
              </div>
              <div className="title">Obrigação: {editMarcado.title}</div>
              {vencimento.venc < vencimento.atual && (
                <div className="inputCheck">
                  <input
                    type="checkbox"
                    name="execucao"
                    checked={
                      editMarcado.marcado
                        ? !!editMarcado.marcado.execucao
                        : false
                    }
                    onChange={e =>
                      handleChangeMarcado({
                        name: e.target.name,
                        event: e.target.checked,
                      })
                    }
                  />{' '}
                  Cliente atrasou na documentação.
                  <input
                    type="checkbox"
                    name="notmove"
                    checked={
                      editMarcado.marcado
                        ? !!editMarcado.marcado.notmove
                        : false
                    }
                    onChange={e =>
                      handleChangeMarcado({
                        name: e.target.name,
                        event: e.target.checked,
                      })
                    }
                  />{' '}
                  Sem movimento
                </div>
              )}
              <div className="inputForm">
                {checkClienteMovimento ? (
                  <>
                    <p style={{ marginTop: 8, fontSize: 12 }}>
                      Opções para executor "justificar" entrega fora do prazo
                      com/ ressalva
                    </p>
                    <div className="inputForm">
                      <select onChange={handleChangeClienteMovimento}>
                        <option value="">...</option>
                        {listClienteMovimento.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      {typeListClienteMovimento.type === 'date' && (
                        <input
                          type="date"
                          name="date"
                          className="dateMovimento"
                          onChange={handleChangeInput}
                        />
                      )}
                      {typeListClienteMovimento.type === 'datesetor' && (
                        <>
                          <input
                            type="date"
                            name="date"
                            className="dateMovimento"
                            style={{ marginTop: 6 }}
                            onChange={handleChangeInput}
                          />
                          <select
                            name="setor"
                            style={{ marginTop: 5 }}
                            onChange={handleChangeInput}
                          >
                            <option value="">Escolha o setor</option>
                            <option value="Setor Contábil">
                              Setor Contábil
                            </option>
                            <option value="Setor Fiscal">Setor Fiscal</option>
                            <option value="Setor Pessoal">Setor Pessoal</option>
                            <option value="Setor Tributos">
                              Setor Tributos
                            </option>
                            <option value="Setor Consultoria">
                              Setor Consultoria
                            </option>
                          </select>
                        </>
                      )}
                      {typeListClienteMovimento.type === 'text' && (
                        <input
                          type="text"
                          name="obs"
                          placeholder="Escreva aqui"
                          onChange={handleChangeInput}
                          style={{ marginTop: 6 }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <input
                    type="text"
                    name="obs"
                    placeholder="Escrever Observação"
                    defaultValue={
                      editMarcado.marcado ? editMarcado.marcado.obs : ''
                    }
                    onChange={e =>
                      handleChangeMarcado({
                        name: e.target.name,
                        event: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={handleMarcadoEdit}
                >
                  Atualizar
                </button>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => handleModalEdit({ open: 'close' })}
                >
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ContentModal>
      </Modal>

      <Modal modal={modalret}>
        <ContentModal>
          {loadingMarca ? (
            <Loading>
              <Carregando />
            </Loading>
          ) : (
            <>
              <h4>Adicionar Retificação</h4>
              <div className="title">Obrigação: {view.title}</div>
              <div className="inputForm">
                <input
                  type="text"
                  placeholder="Escrever Observação"
                  className="obss"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="btn-save"
                  onClick={() =>
                    handleRetMarca({
                      id: view.obr_id,
                      clienteID: idcliente,
                    })
                  }
                >
                  Adicionar
                </button>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() =>
                    handleRetificacao({ open: 'close', item: null })
                  }
                >
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ContentModal>
      </Modal>

      <ModalDialog close={handleModalDay} width="sm" open={modalDay}>
        <MonthDays
          title="Escolha o dia para visualizar a obrigação"
          handleClickDay={handleCalendar}
          handleClickAlls={handleCalendar}
          value={dayCalendar}
        />
      </ModalDialog>

      <ModalDialog close={handleModalMonth} width="sm" open={modalMonth}>
        <CalendarMonth
          handleClick={handleMes}
          valueMonth={mesCalendar}
          valueYear={anoCalendar}
        />
      </ModalDialog>

      <ModalDialog
        title="Escolha o Analista"
        close={() => setModalAnalista(false)}
        text="Buscar"
        open={modalAnalista}
      >
        <ModalDialogDiv>
          <select name="dialog" onChange={handleExecutorAnalista}>
            <option value="">...</option>
            {analistas.map(exe => (
              <option
                key={exe.id}
                value={exe.id}
                selected={exe.name === nameAnalista}
              >
                {exe.name}
              </option>
            ))}
            <option value="">Todos</option>
          </select>
        </ModalDialogDiv>
      </ModalDialog>
    </>
  );
}
