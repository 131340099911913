import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#0059B2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
}));

export default function Loading({ size }) {
  const classes = useStyles();
  return <CircularProgress size={size} className={classes.buttonProgress} />;
}

Loading.propTypes = {
  size: PropTypes.number.isRequired,
};
