import React, { useState, useEffect, useCallback } from 'react';

import { MdControlPoint, MdLockOutline, MdLockOpen } from 'react-icons/md';

import { Link } from 'react-router-dom';

import pt from 'date-fns/locale/pt';
import { parseISO, format } from 'date-fns';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando/Tabela';
import User from '~/components/User';
import Nav from '~/components/Navigation';
import Pagination from '~/components/Pagination';

import api from '~/services/api';
import history from '~/services/history';

import { Content, Bloco, Card, Pesquisa } from './styles';

export default function Index() {
  const [historicos, setHistoricos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btn, setBtn] = useState(false);
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    lastPage: 1,
  });
  const url = new URL(window.location);

  useEffect(() => {
    async function loadAnnotations() {
      setLoading(true);
      const urlParams = new URLSearchParams(url.search);
      const limit = urlParams.get('limit') || 20;
      const page = urlParams.get('page') || 1;
      const my = urlParams.get('my') || '';

      const response = await api.get(
        `annotations?limit=${limit}&page=${page}&my=${my}`
      );

      setParams({ ...params, lastPage: response.data.last_page });

      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.created_at),
          "dd 'de' MMMM 'de' yyyy",
          {
            locale: pt,
          }
        ),
      }));

      setHistoricos(percorre);
      setLoading(false);
    }

    loadAnnotations();
  }, [params, url.search]);

  const handlePageSelect = useCallback(
    ({ page }) => {
      setLoading(true);
      setParams({ ...params, page });
      history.push(`/historicos?limit=${params.limit}&page=${page}`);
    },
    [params]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = params.page === 1 ? 1 : params.page - 1;
        setParams({ ...params, page: ptotal });
        history.push(`/historicos?limit=${params.limit}&page=${ptotal}`);
        return;
      }
      setLoading(true);
      const ptotal = Number(params.page) + 1;
      setParams({ ...params, page: ptotal });
      history.push(`/historicos?limit=${params.limit}&page=${ptotal}`);
    },
    [params]
  );

  return (
    <>
      <Nav page="historico" />
      <Container>
        <User />

        <Content>
          <Title title="Históricos" />

          <Bloco>
            <div>
              <h2>Históricos</h2>
            </div>
            <Link to="/historico/create">
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <ul>
                <li>
                  <Link
                    to="/historicos?my="
                    className={!btn ? 'ativo' : ''}
                    onClick={() => setBtn(false)}
                  >
                    Anotações Gerais
                  </Link>
                </li>
                <li>
                  <Link
                    to="/historicos?my=true"
                    className={btn ? 'ativo' : ''}
                    onClick={() => setBtn(true)}
                  >
                    Minhas Anotações
                  </Link>
                </li>
              </ul>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          {loading ? (
            <Carregando />
          ) : (
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td width="4%">#ID</td>
                  <td width="20%">Cliente</td>
                  <td width="11.33%">Assunto</td>
                  <td width="11.33%">Setor</td>
                  <td width="11.33%">Criado</td>
                  <td width="11.33%">Sigilo</td>
                  <td width="11.33%">Anexo</td>
                  <td width="11.33%">Data Criação</td>
                  <td width="8%">Detalhar</td>
                </tr>
              </thead>
              <tbody>
                {historicos.map(item => (
                  <tr key={item.id} className="table-hover">
                    <td>#{item.id}</td>
                    <td>
                      <div className="circle">{item.cliente.razao}</div>
                    </td>
                    <td>{item.title}</td>
                    <td>{item.cargo.title}</td>
                    <td>{item.user.name}</td>
                    <td>
                      {item.sigilo ? (
                        <div className="sigiloClosed">
                          <MdLockOutline size={14} color="#fff" /> Fechado
                        </div>
                      ) : (
                        <div className="sigiloOpen">
                          <MdLockOpen size={14} color="#fff" /> Aberto
                        </div>
                      )}
                    </td>
                    <td>
                      Anexo: {item.files ? item.files.length : 'Nenhum arquivo'}
                    </td>
                    <td>
                      <div className="date">{item.dateFormatted}</div>
                    </td>
                    <td align="right">
                      <button
                        type="button"
                        className="btn-blue"
                        onClick={() =>
                          history.push(`/historico/show/${item.id}`)
                        }
                      >
                        Visualizar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Card>

        <Pagination
          total={params.total}
          limit={params.limit}
          page={params.page}
          handleLimit={() => {}}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />
      </Container>
      <Footer />
    </>
  );
}
