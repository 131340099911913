import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { MdControlPoint } from 'react-icons/md';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando/Tabela';
import User from '~/components/User';
import Nav from '~/components/Navigation';
import Pagination from '~/components/Pagination';

import api from '~/services/api';

import { Content, Bloco, Card, Pesquisa, Tipo } from './styles';

export default function Comunicado() {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total: 1,
    page: 1,
    limit: 20,
  });
  const [comunicados, setComunicados] = useState([]);
  const history = useHistory();

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const user = params.get('user') || '';
  const tipo = params.get('tipo') || '';

  useEffect(() => {
    async function loadComunicados() {
      setLoading(true);
      const { data } = await api.get(
        `comunicados?page=&limit=&user=${user}&tipo=${tipo}`
      );

      setComunicados(data.data);
      setPagination({
        ...pagination,
        total: data.last_page,
        page: data.current_page,
      });
      setLoading(false);
    }

    loadComunicados();
  }, [user, tipo]); //eslint-disable-line

  return (
    <>
      <Nav page="comunicado" />
      <Container>
        <User />

        <Content>
          <Title title="Comunicados" />

          <Bloco>
            <div>
              <h2>Comunicados</h2>
            </div>
            <Link to="/comunicado/create">
              <MdControlPoint size={16} color="#fff" /> Adicionar Comunicado
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <ul>
                <li>
                  <Link
                    to="/comunicados?user="
                    className={!user ? 'ativo' : 'inativo'}
                    onClick={() => {}}
                  >
                    Comunicados Geral
                  </Link>
                </li>
                <li>
                  <Link
                    to="/comunicados?user=true"
                    className={user ? 'ativo' : 'inativo'}
                    onClick={() => {}}
                  >
                    Meus Comunicados
                  </Link>
                </li>
              </ul>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          {loading ? (
            <Carregando />
          ) : (
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td width="4%">#ID</td>
                  <td width="28%">Assunto</td>
                  <td width="24%">Autor</td>
                  <td width="16%">
                    <div className="search">
                      Tipo
                      <select
                        onChange={e =>
                          history.push(`/comunicados?tipo=${e.target.value}`)
                        }
                      >
                        <option value="">Geral</option>
                        <option value="individual">Individual</option>
                        <option value="usuario">Usuário Individual</option>
                        <option value="departamento">
                          Departamento ou Geral
                        </option>
                        <option value="programado">
                          Comunicado Programado
                        </option>
                      </select>
                    </div>
                  </td>
                  <td width="16%">Data Criação</td>
                  <td width="12%">Detalhar</td>
                </tr>
              </thead>
              <tbody>
                {comunicados.map(item => (
                  <tr key={item.id} className="table-hover">
                    <td>#{item.id}</td>
                    <td>{item.subject}</td>
                    <td>{item.user.name}</td>
                    <td>
                      <Tipo cor={item.tipo}>{item.tipo}</Tipo>
                    </td>
                    <td>{item.created_at}</td>
                    <td>
                      <Link to={`/comunicado/show/${item.token}`}>
                        Visualizar
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Card>

        <Pagination
          total={pagination.total}
          limit={pagination.limit}
          page={pagination.page}
          handleLimit={() => {}}
          handlePage={() => {}}
          handlePrev={() => {}}
          handleNext={() => {}}
        />
      </Container>

      <Footer />
    </>
  );
}
