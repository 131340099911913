import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  #exe {
    color: #0faf69;
  }

  .contrato {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;

    + a {
      background: #71a812;
    }
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &:hover {
      background: ${shade(0.4, '#0059b2')};
    }
  }

  button {
    margin-top: 12px;
    margin-right: 4px;
    background: #71a812;
    border: 0;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 4px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#71A812')};
    }
  }

  button:disabled {
    background: #999;
    cursor: not-allowed;
  }

  .bg-red {
    background: #ff4d4d;

    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }

  #red {
    color: #c9321f;
  }

  #green {
    color: #0faf69;
  }

  #orange {
    color: #ff7f00;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  button {
    background: #0059b2;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
    border: 0;
    font-size: 16px;
    margin-left: 5px;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.03, '#0059B2')};
    }
  }

  .links {
    display: flex;
    flex-direction: row;
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  margin-top: 20px;

  .bloco {
    display: flex;
    flex: 1;
    flex-direction: column;

    .blocorow {
      display: flex;
    }

    .form-input {
      display: flex;
      flex: 1;

      div {
        width: 100%;
      }
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 1;

  .bloco {
    display: flex;
    flex-direction: column;
    flex: 1;

    .avatar {
      display: block;
      text-align: center;
      margin-bottom: 16px;
    }

    .user {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      background: #999;
      margin: 0px auto;

      img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
      }
    }

    .name {
      font-size: 12px;
      padding-top: 8px;
    }
  }
`;
