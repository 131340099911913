import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;

  ul {
    list-style: none;
    display: grid;
    width: 100%;
    /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
    grid-template-columns: repeat(4, 1fr);
    /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
    grid-auto-rows: auto;
    /* espaço entre um elemento interno e outro */
    grid-gap: 5px 8px;
    /* margem interna que afasta os elementos da borda do grid */
    text-align: center;
  }

  ul + ul {
    margin-top: 22px;
  }

  ul li a {
    font-size: 12px;
    width: 100%;
    border: none;
    height: 56px;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0f73af;

    &:hover {
      background: #2a9fe5;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #82cc33;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #999;
  }

  a {
    background: #82cc33;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#82cc33')};
  }
`;
