import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import { MdSave } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import { useParams } from 'react-router-dom';
import Title from '~/components/Title';
import Footer from '~/components/Footer';

import User from '~/components/User';
import api from '~/services/api';

import { Container, Content, Bloco, Card, Editar } from './styles';
import Nav from '~/components/Navigation';

export default function EditGrupo() {
  const { id } = useParams();
  const [tab, setTab] = useState('');

  useEffect(() => {
    async function loadTab() {
      const response = await api.get(`tb/grupoeconomico/${id}`);

      setTab(response.data);
    }

    loadTab();
  }, [id]);

  async function handleUpdate({ title }) {
    try {
      await api.put(`tb/grupoeconomico/${id}`, {
        title,
      });

      toast.success('Atualizado com sucesso');
    } catch (err) {
      toast.error('Erro na atualização');
    }
  }

  return (
    <>
      <Nav page="itens" />
      <Container>
        <User />

        <Content>
          <Title title="Itens / Tabelas / Grupo Econômico" />
          <Bloco>
            <div>
              <h2>Grupo Econômico</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <Editar>
            <Form onSubmit={handleUpdate} initialData={tab}>
              <Input name="title" />
              <button type="submit">
                <MdSave size={18} color="#fff" /> Atualizar
              </button>
            </Form>
          </Editar>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
