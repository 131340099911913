import React, { useState, useEffect, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector } from 'react-redux';

import {
  MdExitToApp,
  MdDeleteForever,
  MdModeEdit,
  MdTrendingUp,
  MdTrendingDown,
} from 'react-icons/md';
import { IoMdPodium } from 'react-icons/io';

import { toast } from 'react-toastify';

import 'react-month-picker-input/dist/react-month-picker-input.css';

import api from '~/services/api';
import xml from '~/services/xmlApi';
import history from '~/services/history';

import {
  Content,
  Bloco,
  Card,
  Submenu,
  Pesquisa,
  Grid,
  GridTab,
} from './styles';

import Autocomplete from '~/components/FormElement/AutoloadInput';
import Input from '~/components/FormElement/Input';
import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import MenuSub from '~/components/Navigation/Obrigacoes';
import CnpjMaskCustom from '~/components/MaskInput/cnpj';
import MoneyMaskCustom from '~/components/MaskInput/money';
import LoadSkeleton from '~/components/Carregando/Skeleton';

import excel from '~/assets/icons/excel-file.svg';

export default function Periodica() {
  const { token } = useParams();
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [, setDelFuncao] = useState(!getStorageFuncao);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [ativaTr, setTr] = useState(false);
  const [edit, setEdit] = useState(0);
  const [idTab, setIdTab] = useState(0);
  const [periodica, setPeriodica] = useState('');
  const [obrs, setObrs] = useState([]);
  const [fields, setFields] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [forms, setForms] = useState('');
  const [update, setUpdate] = useState(false);
  const [btnPesquisa, setBtnPesquisa] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderAZ, setOrderAZ] = useState(false);
  const loadingInput = open && clientes.length === 0;

  const handleSetor = useCallback(e => {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
  }, []);

  const handleChange = useCallback(
    (event, value, maskedValue) => {
      event.preventDefault();

      setForms({
        ...forms,
        [event.target.name]: maskedValue || event.target.value,
      });
    },
    [forms]
  );

  const handleSave = useCallback(async () => {
    const verifica = fields.map(field => ({
      [field.name]: document.querySelector(`#${field.name}`).value,
      validation: field.validation,
      name: field.name,
      title: field.title,
    }));

    const find = verifica.find(item => item[item.name] === '');

    if (find && find.validation) {
      toast.warning(`Ops! Preencha o campo ${find.title}`);
      return;
    }

    setLoadSave(true);
    try {
      const { data } = await api.post('periodicaObr', {
        fields: forms,
        id_periodicaTab: idTab,
      });

      setObrs(state => [data, ...state]);
      setForms('');
      setTr(!ativaTr);
      setLoadSave(false);
      toast.success('Sucesso! Sua postagem foi adicionado.');
    } catch (err) {
      toast.error('Ops! Algo deu errado.');
      setLoadSave(false);
      setTr(!ativaTr);
    }
  }, [ativaTr, fields, forms, idTab]);

  const handleCancela = useCallback(
    e => {
      if (e) {
        setTr(!ativaTr);
        setEdit(0);
        setForms('');
        return;
      }
      setEdit(0);
      setForms('');
      setTr(false);
    },
    [ativaTr]
  );

  const handleUpdate = useCallback(
    async e => {
      if (e) {
        setForms(e.fields);
        setEdit(e.id);
        return;
      }

      setLoadSave(true);
      try {
        const { data } = await api.put(`periodicaObr/${edit}`, {
          fields: forms,
        });

        setObrs(state =>
          state.map(item => ({
            ...item,
            fields: item.id === edit ? data.fields : item.fields,
          }))
        );
        setForms('');
        setEdit(0);
        setLoadSave(false);
        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Ops! Algo deu errado.');
        setLoadSave(false);
        setEdit(0);
      }
    },
    [edit, forms]
  );

  const handleDelete = useCallback(
    async e => {
      await api.delete(`periodicaObr/${e}`);

      setEdit(0);

      setObrs(obrs.filter(item => item.id !== e));

      toast.success('Deletado com sucesso!');
    },
    [obrs]
  );

  const handleDeletar = useCallback(async () => {
    await api.delete(`/periodicaTabs/${periodica.id}`);
    history.push('/obrigacoes-periodicas');
    toast.success('Deletado com sucesso.');
  }, [periodica.id]);

  const handleFilter = useCallback(
    ({ event, name }) => {
      if (event === 'none') {
        const filter = obrs.filter(item => !item.fields[name]);
        setObrs(filter);
        setBtnPesquisa(true);
        return;
      }

      if (name === 'user') {
        const filter = obrs.filter(item => item.user.name === event);
        setObrs(filter);
        setBtnPesquisa(true);
        return;
      }

      const filter = obrs.filter(item => item.fields[name] === event);
      setObrs(filter);
      setBtnPesquisa(true);
    },
    [obrs]
  );

  const handleOrder = useCallback(name => {
    setOrderAZ(true);
    setObrs(state =>
      state
        .sort((a, b) => {
          if (a.fields[name] > b.fields[name]) {
            return 1;
          }
          if (a.fields[name] < b.fields[name]) {
            return -1;
          }
          // a must be equal to b
          return 0;
        })
        .map(order => order)
    );
  }, []);

  const handleExport = useCallback(async () => {
    await xml
      .get(`periodica/export?idTab=${periodica.id}`, {
        responseType: 'blob', // important
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const date = `${periodica.title}-export.xlsx`;
        link.href = url;
        link.setAttribute('download', date); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }, [periodica.id, periodica.title]);

  const handleCliente = useCallback(async () => {
    setOpen(true);
    const response = await api.get(`/simples-clientes`);

    setClientes(response.data);
  }, []);

  const handleChangeAutoLoad = useCallback(
    event => {
      setForms({
        ...forms,
        [event.name]: event.razao,
      });
    },
    [forms]
  );

  useEffect(() => {
    setLoading(true);
    api.get(`/periodicaTabs/${token}`).then(response => {
      api.get(`/periodicaObr?idTab=${response.data.id}`).then(data => {
        setObrs(data.data);
        setLoading(false);
      });

      if (response.data.error) {
        toast.warning(response.data.error);
        history.push('/obrigacoes-periodicas');
        return;
      }

      setIdTab(response.data.id);
      setFields(response.data.fields);
      setPeriodica(response.data);
    });
  }, [token, update]); // eslint-disable-line

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="obrigacoes-periodicas" />
          {profile.cargo.subtitle === 'diretor' && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <Title title={`Obrigações / Periódicas / ${periodica.title}`} />

          <Bloco>
            <div>
              <h2>Obrigações / Periódicas / {periodica.title}</h2>
              <p>{periodica.description}</p>
            </div>
            {profile.id === periodica.user_id && (
              <div className="btn-info">
                <button
                  type="button"
                  className="delete"
                  title="Deletar"
                  onClick={() => setModal(true)}
                >
                  <MdDeleteForever size={18} color="#fff" />
                </button>
                <button
                  type="button"
                  className="editar"
                  title="Editar"
                  onClick={() => history.push(`/edit/periodica/${token}`)}
                >
                  <MdModeEdit size={18} color="#fff" />
                </button>
              </div>
            )}
          </Bloco>

          <Bloco>
            <GridTab>
              <Grid>
                <div className="total">
                  <p>Total</p>
                  <span className="blue">{obrs.length}</span>
                </div>
                <div className="icon">
                  <IoMdPodium size={68} color="#00468c" />
                </div>
              </Grid>
            </GridTab>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <button
                type="button"
                className={ativaTr ? 'btn-cancela' : 'btn-green'}
                onClick={() => handleCancela(true)}
              >
                {ativaTr ? 'Cancelar' : 'Adicionar'}
              </button>
            </div>

            <div className="bloco-one">
              <button type="button" className="exportar" onClick={handleExport}>
                <img src={excel} alt="Excel" />
                Exportar EXCEL
              </button>
              {btnPesquisa && (
                <button
                  type="button"
                  className="pesquisa"
                  onClick={() => {
                    setBtnPesquisa(false);
                    setUpdate(!update);
                  }}
                >
                  Desfazer Pesquisa
                </button>
              )}
            </div>
          </Pesquisa>
        </Content>

        <Card>
          {loading ? (
            <LoadSkeleton number="12345678" />
          ) : (
            <table className="tab table-striped">
              <thead>
                <tr>
                  {fields.map((field, index) => (
                    <td
                      key={field.name}
                      width={field.width ? field.width : 'auto'}
                    >
                      <div>
                        {field.validation && '*'}

                        {field.title}
                        <select
                          onChange={e =>
                            handleFilter({
                              event: e.target.value,
                              name: field.name,
                            })
                          }
                        >
                          <option value="">...</option>
                          {obrs
                            .map(item => item.fields != null ? item.fields[field.name] : null)
                            .filter(
                              (item, pos, self) => self.indexOf(item) === pos
                            )
                            .map(item => (
                              <>
                                {item && (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                )}
                              </>
                            ))}
                          <option value="none">EM BRANCO</option>
                        </select>
                        {index === 0 && (
                          <button
                            type="button"
                            className="az"
                            onClick={() => handleOrder(field.name)}
                          >
                            {orderAZ ? (
                              <>
                                <MdTrendingUp size={12} color="#008080" />{' '}
                                Clique aqui para ordenar de A-Z
                              </>
                            ) : (
                              <>
                                <MdTrendingDown size={12} color="#000" /> Clique
                                aqui para ordenar de A-Z
                              </>
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                  ))}
                  <td width="12%">
                    <div>
                      Ação
                      <select
                        onChange={e =>
                          handleFilter({
                            event: e.target.value,
                            name: 'user',
                          })
                        }
                      >
                        <option value="">...</option>
                        {obrs
                          .map(item => item.user.name)
                          .filter(
                            (item, pos, self) => self.indexOf(item) === pos
                          )
                          .map(item => (
                            <>
                              {item && (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              )}
                            </>
                          ))}
                      </select>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {ativaTr && (
                  <tr>
                    {fields.map(field => (
                      <>
                        {field.type === 'select' && (
                          <td key={field.name}>
                            <Select
                              native
                              value={forms[field.name]}
                              id={field.name}
                              name={field.name}
                              onChange={handleChange}
                            >
                              <option aria-label="None" value="" />
                              {field.option.map(option => (
                                <option value={option}>{option}</option>
                              ))}
                            </Select>
                          </td>
                        )}
                        {field.type === 'textarea' && (
                          <td key={field.name}>
                            <TextareaAutosize
                              id={field.name}
                              name={field.name}
                              onChange={handleChange}
                            />
                          </td>
                        )}
                        {field.type === 'text' && (
                          <td key={field.name}>
                            {periodica.viewcliente === 'inputclientes' &&
                            field.title === 'CLIENTES' ? (
                              <div className="autoload">
                                <Autocomplete
                                  id={field.name}
                                  open={open}
                                  onOpen={handleCliente}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  getOptionSelected={(option, value) =>
                                    option.razao === value.razao
                                  }
                                  name={field.name}
                                  onChange={(event, newValue) =>
                                    handleChangeAutoLoad({
                                      razao: newValue.razao,
                                      name: field.name,
                                    })
                                  }
                                  getOptionLabel={option => option.razao}
                                  options={clientes}
                                  loading={loadingInput}
                                  renderInput={params => (
                                    <Input
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {loadingInput ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={12}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            ) : (
                              <TextField
                                type="text"
                                id={field.name}
                                name={field.name}
                                onChange={handleChange}
                                InputProps={
                                  (field.format &&
                                    field.format === 'cnpj' && {
                                      inputComponent: CnpjMaskCustom,
                                    }) ||
                                  (field.format &&
                                    field.format === 'money' && {
                                      inputComponent: MoneyMaskCustom,
                                    })
                                }
                              />
                            )}
                          </td>
                        )}
                        {field.type === 'number' && (
                          <td key={field.name}>
                            <TextField
                              type="number"
                              id={field.name}
                              name={field.name}
                              onChange={handleChange}
                              label=""
                            />
                          </td>
                        )}
                        {field.type === 'date' && (
                          <td key={field.name}>
                            <TextField
                              type="date"
                              id={field.name}
                              name={field.name}
                              onChange={handleChange}
                              label=""
                            />
                          </td>
                        )}
                      </>
                    ))}
                    <td>
                      <span>
                        <button
                          type="button"
                          className="btn-green"
                          onClick={handleSave}
                        >
                          {loadSave ? 'Carregando' : 'Salvar'}
                        </button>
                        <button
                          type="button"
                          className="btnDelete"
                          onClick={() => handleCancela(false)}
                        >
                          Cancelar
                        </button>
                      </span>
                    </td>
                  </tr>
                )}
                {obrs.map(item => (
                  <>
                    {edit === item.id ? (
                      <tr key={item.id}>
                        {fields.map(field => (
                          <>
                            {field.type === 'select' && (
                              <td key={field.name}>
                                <Select
                                  native
                                  value={
                                    forms
                                      ? forms[field.name]
                                      : item.fields[field.name]
                                  }
                                  id={field.name}
                                  name={field.name}
                                  onChange={handleChange}
                                >
                                  <option aria-label="None" value="" />
                                  {field.option.map(option => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </Select>
                              </td>
                            )}
                            {field.type === 'textarea' && (
                              <td key={field.name}>
                                <TextareaAutosize
                                  id={field.name}
                                  defaultValue={item.fields[field.name]}
                                  name={field.name}
                                  onChange={handleChange}
                                />
                              </td>
                            )}
                            {field.type === 'text' && (
                              <td key={field.name}>
                                {periodica.viewcliente === 'inputclientes' &&
                                field.title === 'CLIENTES' ? (
                                  <div className="autoload">
                                    <Autocomplete
                                      id={field.name}
                                      open={open}
                                      onOpen={handleCliente}
                                      onClose={() => {
                                        setOpen(false);
                                      }}
                                      getOptionSelected={(option, value) =>
                                        option.razao === value.razao
                                      }
                                      name={field.name}
                                      onChange={(event, newValue) =>
                                        handleChangeAutoLoad({
                                          razao: newValue.razao,
                                          name: field.name,
                                        })
                                      }
                                      getOptionLabel={option => option.razao}
                                      options={clientes}
                                      loading={loadingInput}
                                      renderInput={params => (
                                        <Input
                                          {...params}
                                          label={item.fields[field.name]}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {loadingInput ? (
                                                  <CircularProgress
                                                    color="inherit"
                                                    size={12}
                                                  />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                ) : (
                                  <TextField
                                    type="text"
                                    id={field.name}
                                    defaultValue={item.fields[field.name]}
                                    name={field.name}
                                    onChange={handleChange}
                                    InputProps={
                                      (field.format &&
                                        field.format === 'cnpj' && {
                                          inputComponent: CnpjMaskCustom,
                                        }) ||
                                      (field.format &&
                                        field.format === 'money' && {
                                          inputComponent: MoneyMaskCustom,
                                        })
                                    }
                                  />
                                )}
                              </td>
                            )}
                            {field.type === 'number' && (
                              <td key={field.name}>
                                <TextField
                                  type="number"
                                  defaultValue={item.fields[field.name]}
                                  id={field.name}
                                  name={field.name}
                                  onChange={handleChange}
                                  label=""
                                />
                              </td>
                            )}
                            {field.type === 'date' && (
                              <td key={field.name}>
                                <TextField
                                  type="date"
                                  defaultValue={item.fields[field.name]}
                                  id={field.name}
                                  name={field.name}
                                  onChange={handleChange}
                                  label=""
                                />
                              </td>
                            )}
                          </>
                        ))}
                        <td>
                          <span>
                            <buton
                              type="button"
                              className="btn-green"
                              onClick={() => handleUpdate('')}
                            >
                              {loadSave ? 'Carregando...' : 'Salvar'}
                            </buton>
                            <buton
                              type="button"
                              className="btnDelete"
                              onClick={() => handleDelete(item.id)}
                            >
                              Delete
                            </buton>
                          </span>
                        </td>
                      </tr>
                    ) : (
                      <tr key={item.id}>
                        {fields.map(field => (
                          <td key={field.name}>{item.fields != null ? item.fields[field.name] : null}</td>
                        ))}
                        <td>
                          <div>
                            <buton
                              type="button"
                              className="btnEdit"
                              onClick={() => handleUpdate(item)}
                            >
                              Editar
                            </buton>
                          </div>
                          <p>Atualizado por: {item.user.name}</p>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </Card>

        <Dialog
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Você tem certeza que deseja deletar?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao clicar sim você deletar o conteúdo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModal(false)} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeletar} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <Footer />
      </Container>
    </>
  );
}
