import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

const CertificadoDigitais = ({ dados }) => {
  const profile = useSelector(state => state.user.profile);

  const certificados = dados.map(item => ({
    ...item,
    dateFormatted: item.data_protocolo
      ? format(parseISO(item.data_protocolo), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid on>
      {certificados.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>Tipo: </strong>
              {item.titulo}
            </div>
            <div>
              <strong>Titular: </strong> {item.titular}
            </div>
            <div>
              <strong>Data limite protocolado pela Secran: </strong>{' '}
              {item.dateFormatted}
            </div>
            <div>
              <strong>Senha: </strong>{' '}
              {profile.cargo.subtitle === 'paralegal' ||
              profile.cargo.subtitle === 'diretor'
                ? item.senha
                : '******'}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
};

CertificadoDigitais.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

CertificadoDigitais.defaultProps = {
  dados: [],
};

export default CertificadoDigitais;
