import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';
import { Container, Button, Logo } from './styles';
import history from '~/services/history';
import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';
import api from '~/services/api';

export default function Domestica() {
  const [adcnpj, setCnpj] = useState();
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    if (profile.role_id > 1) {
      toast.warning('Você não tem permissão');
      history.goBack();
    }
  }, [profile.role_id]);

  function handleCnpj(e) {
    const valor = e.target.value;

    setCnpj(valor.replace(/[^\d]+/g, ''));
  }

  async function handleSubmit({ cnpj, name }) {
    try {
      setLoading(true);

      const response = await api.post('clientes', {
        cnpj,
        status: 'Ativo',
        situacao: 8,
        tipo: 'DOMESTICA',
        razao: name,
      });

      if (response.data.error) {
        toast.error(response.data.error);
        setLoading(false);
        return;
      }

      localStorage.setItem('cliente', JSON.stringify(response.data));

      toast.success('Cliente cadastrado com sucesso!');
      setLoading(false);
      history.push(`/edit/cliente/${response.data.id}`);
    } catch (err) {
      setLoading(false);
      toast.error('Erro no cadastro ou o CPF já existe.');
    }
  }

  return (
    <>
      <Logo>
        <img src={logo} alt="Secran" />
      </Logo>
      <Container>
        <Form onSubmit={handleSubmit}>
          <span>Digite o CPF (Doméstica)</span>
          <Input type="hidden" name="cnpj" value={adcnpj} />
          <InputMask
            mask="999.999.999-99"
            placeholder="999.999.999-99"
            onChange={handleCnpj}
          />
          <span>Digite o Nome Completo</span>
          <Input type="text" name="name" />
          <Button>
            <button type="button" onClick={history.goBack}>
              Cancelar
            </button>
            <button type="submit">
              {loading ? <Carregando /> : 'Avançar'}
            </button>
          </Button>
        </Form>
      </Container>
    </>
  );
}
