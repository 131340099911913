import styled from 'styled-components';

import bg from '~/assets/business.jpg';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: url(${bg}) no-repeat top center;
  background-size: cover;
  z-index: 9;

  form {
    display: flex;
    flex-direction: column;
  }

  span {
    color: #999;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 14px;
  }

  input {
    background: rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 4px;
    width: 380px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
  }

  select {
    background: rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 4px;
    width: 380px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const Button = styled.div`
  button {
    border: 0;
    height: 36px;
    background: #ff4d4d;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;

    &:hover {
      opacity: 0.5;
    }
  }

  button + button {
    background: #006dd9;
    margin-left: 12px;
  }
`;

export const Logo = styled.div`
  display: block;
  position: absolute;
  z-index: 999;
  width: 100px;
  top: 10px;
  left: 15px;
`;
