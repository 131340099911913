import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  #exe {
    color: #0faf69;
  }

  .contrato {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: 0;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;

    + a {
      background: #71a812;
    }
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &:hover {
      background: ${shade(0.4, '#0059b2')};
    }
  }

  button {
    margin-right: 4px;
    background: #71a812;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#71A812')};
    }
  }

  #red {
    color: #c9321f;
  }

  #green {
    color: #0faf69;
  }

  #orange {
    color: #ff7f00;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  a + a {
    background: #0059b2;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
    border: 0;
    font-size: 16px;
    margin-left: 5px;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.03, '#0059B2')};
    }
  }

  .links {
    display: flex;
    flex-direction: row;
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
  }

  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }

    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }

  .search {
    margin-left: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    select {
      background: #999;
      color: #fff;
    }

    span {
      margin-right: 5px;
    }
  }

  .bloco-one {
    display: flex;
    align-items: center;
  }

  .bloco-two {
    display: flex;
    align-items: center;

    select + select {
      margin-left: 6px;
    }

    span {
      font-size: 12px;
      margin-right: 5px;
    }

    button {
      border: 0;
      background: #0f73af;
      height: 35px;
      color: #fff;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 6px;

      &:hover {
        background: #2a9fe5;
      }
    }

    button + button {
      margin-left: 5px;
    }
  }
`;
