import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';
import { MdSave } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, BForm } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Senhas() {
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState('');
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const tipocargo = profile.cargo.tipo;
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }
  }, [idCliente, profile.role_id, validarPermission]);

  async function handleUpdate({
    pgdas_cnpj,
    pgdas_cpf,
    pgdas_cod,
    login_iss,
    senha_iss,
    login_prev,
    senha_prev,
    login_sefaz,
    senha_sefaz,
    login_fap,
    senha_fap,
    senha_censo,
    senha_rde,
    senha_dcbe,
    outras,
  }) {
    try {
      setLoading(true);
      const response = await api.put(`clientes/${idCliente}`, {
        pgdas_cnpj,
        pgdas_cpf,
        pgdas_cod,
        login_iss,
        senha_iss,
        login_prev,
        senha_prev,
        login_sefaz,
        senha_sefaz,
        login_fap,
        senha_fap,
        senha_censo,
        senha_rde,
        senha_dcbe,
        outras,
      });

      setLoading(false);
      toast.success('Senhas atualizada com sucesso.');
      localStorage.setItem('cliente', JSON.stringify(response.data));
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado na atualização da senha.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="senhas"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleUpdate} initialData={cliente}>
            <BForm>
              <span>
                <label>CNPJ de Acesso PGDAS-d</label>
                {profile.cargo.subtitle === 'paralegal' ? (
                  <Input type="text" name="pgdas_cnpj" />
                ) : (
                  <Input
                    type={
                      tipocargo === 'coord' ||
                      tipocargo === 'diretor' ||
                      tipocargo === 'supervisao'
                        ? 'text'
                        : 'password'
                    }
                    name="pgdas_cnpj"
                  />
                )}
              </span>
              <span>
                <label>CPF de Acesso PGDAS-d</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="pgdas_cpf"
                />
              </span>
              <span>
                <label>Código de Acesso PGDAS-d</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="pgdas_cod"
                />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Login ISS (Sistema Escrituração)</label>
                <Input type="text" name="login_iss" />
              </span>
              <span>
                <label>Senha ISS (Sistema Escrituração)</label>
                <Input type="text" name="senha_iss" />
              </span>
              <hr />
              <span>
                <label>Login Previdência Social</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="login_prev"
                />
              </span>
              <span>
                <label>Senha Previdência Social</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_prev"
                />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Login Ambiente Seguro SEFAZ</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="login_sefaz"
                />
              </span>
              <span>
                <label>Senha Ambiente Seguro SEFAZ</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_sefaz"
                />
              </span>
              <hr />
              <span>
                <label>Login FAP</label>
                <Input type="text" name="login_fap" />
              </span>
              <span>
                <label>Senha FAP</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_fap"
                />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Senha CENSO</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_censo"
                />
              </span>
              <hr />
              <span>
                <label>Senha RDE-IED</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_rde"
                />
              </span>
              <hr />
              <span>
                <label>Senha DCBE</label>
                <Input
                  type={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  name="senha_dcbe"
                />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Outras Senhas</label>
                <Input
                  name={
                    tipocargo === 'coord' ||
                    tipocargo === 'diretor' ||
                    tipocargo === 'supervisao'
                      ? 'text'
                      : 'password'
                  }
                  className="textarea"
                  multiline
                />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>
    </>
  );
}
