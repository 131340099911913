import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding-bottom: 18px;
`;

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  .ativo {
    color: #0faf69;
  }

  .inativo {
    color: #c9321f;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }

    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }

  .search {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .bloco-one {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
  }

  select {
    border: 0;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 8px;
  }
`;

export const Editar = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  form {
    display: block;
    width: 100%;
  }

  input {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
  }

  button {
    background: #0faf69;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }

  div {
    display: flex;

    button + button {
      margin-left: 4px;
    }
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const PageVer = styled.div`
  display: flex;
  align-items: center;

  form span {
    font-size: 12px;
    margin-right: 6px;
  }

  input {
    border: 0;
    width: 45px;
    height: 35px;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    margin-right: 8px;
  }

  button {
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
    border: 0;
    border-radius: 6px;
    background: #00b259;
    color: #fff;

    &:hover {
      opacity: 0.6;
    }
  }

  button + button {
    margin-left: 6px;
  }

  .hidden {
    display: none;
  }

  button.desativa {
    background: #999;
  }
`;
