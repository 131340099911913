import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO, addDays } from 'date-fns';

import { MdSave, MdDelete } from 'react-icons/md';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function CertNegativos() {
  const { idCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState('');
  const [ctdns, setCtdns] = useState([]);
  const [tabs, setTabs] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/certidoes-negativas`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        DataAcrescimo: format(addDays(new Date(), item.aviso), 'yyy-MM-dd', {
          locale: pt,
        }),
      }));

      setCtdns(percorre);
    });

    api.get('tb/certidoes-negativas').then(response => {
      setTabs(response.data.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const options = [
    { id: 1, title: '1 Dias' },
    { id: 2, title: '2 Dias' },
    { id: 3, title: '3 Dias' },
    { id: 4, title: '4 Dias' },
    { id: 5, title: '5 Dias' },
    { id: 10, title: '10 Dias' },
    { id: 15, title: '15 Dias' },
    { id: 30, title: '30 Dias' },
    { id: 45, title: '45 Dias' },
    { id: 60, title: '60 Dias' },
    { id: 90, title: '90 Dias' },
  ];

  async function handleSubmit({ tb_id, vigencia, data_protocolo, aviso }) {
    if (!tb_id) {
      toast.warning('Por favor! Preencha o campo Orgão');
      return;
    }

    if (!data_protocolo) {
      toast.warning('Por favor! Preencha o campo Data limite protocolado');
      return;
    }

    if (!aviso) {
      toast.warning('Por favor! Preencha o campo Aviso.');
      return;
    }

    setLoading(true);

    try {
      const { data } = await api.post(
        `clientes/${idCliente}/certidoes-negativas`,
        {
          tb_id,
          vigencia,
          data_protocolo,
          aviso,
        }
      );

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(ctdns);

      setCtdns(
        dadosArray.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.updated_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }))
      );

      setLoading(false);
      toast.success('Cadastrado com sucesso.');
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado no cadastro.');
    }
  }

  async function handleUpdate({ id, vigencia, data_protocolo, aviso }) {
    const orgao = document.querySelector('#orgao').value;
    try {
      await api.put(`clientes/${idCliente}/certidoes-negativas?id=${id}`, {
        tb_id: orgao,
        vigencia,
        data_protocolo,
        aviso,
      });

      toast.success(
        'Atualização de certidões negativas realizada com sucesso.'
      );
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`delete/${idCliente}/certidoes-negativas?id=${id}`);

      setCtdns(ctdns.filter(item => item.id !== id));
      toast.success('Deletado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado no delete.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="certidoes-negativas"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Orgão *</label>
                <Select name="tb_id" options={tabs} />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Fim Validade</label>
                <Input type="date" name="vigencia" />
              </span>
              <span>
                <label>Data limite protocolado pela Secran *</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Avisar em *</label>
                <Select name="aviso" options={options} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {ctdns.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Orgão</label>
                  <select id="orgao">
                    {tabs.map(tab => (
                      <option value={tab.id} selected={tab.id === item.tb_id}>
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Fim Validade</label>
                  <Input type="date" name="vigencia" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <Select name="aviso" options={options} />
                </span>
              </BForm>
              <BForm>
                <span>
                  <p>
                    Adicionado em {item.dateFormatted} por{' '}
                    <strong>{item.user.name}</strong>
                  </p>
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    {item.data_protocolo === item.DataAcrescimo && (
                      <button type="button" className="btn-warning">
                        <MdSave size={16} color="#fff" /> Data à vencer
                      </button>
                    )}
                    {item.data_protocolo < item.DataAcrescimo && (
                      <button type="button" className="btn-warning">
                        <MdSave size={16} color="#fff" /> Data vencida
                      </button>
                    )}

                    {item.data_protocolo > item.DataAcrescimo && (
                      <button type="submit" className="btn-save">
                        <MdSave size={16} color="#fff" /> Salvar
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
