import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Container, Grid } from './styles';

export default function ClienteSenha({
  pgdas_cnpj,
  pgdas_cpf,
  pgdas_cod,
  login_iss,
  senha_iss,
  login_prev,
  senha_prev,
  login_sefaz,
  senha_sefaz,
  login_fap,
  senha_fap,
  senha_censo,
  senha_rde,
  senha_dcbe,
  outras,
  ativoUser,
}) {
  const profile = useSelector(state => state.user.profile);
  const [userVerify, setUserVerify] = useState(false);

  useEffect(() => {
    if (profile.cargo.subtitle === 'diretor') {
      setUserVerify(true);
    }

    setUserVerify(ativoUser);
  }, [ativoUser, profile.cargo.subtitle]);

  return (
    <>
      <Container>
        {userVerify ? (
          <>
            <Grid>
              <div>
                <h5>PGDAS-d</h5>
                <span>
                  CNPJ: <strong>{pgdas_cnpj || ''}</strong>
                </span>
                <span>
                  CPF: <strong>{pgdas_cpf || ''}</strong>
                </span>
                <span>
                  Código: <strong>{pgdas_cod || ''}</strong>
                </span>
              </div>
              <div>
                <h5>ISS (Sistema Escrituração)</h5>
                <span>
                  Login: <strong>{login_iss || ''}</strong>
                </span>
                <span>
                  Senha: <strong>{senha_iss || ''}</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>Previdência Social</h5>
                <span>
                  Login: <strong>{login_prev || ''}</strong>
                </span>
                <span>
                  Senha: <strong>{senha_prev || ''}</strong>
                </span>
              </div>
              <div>
                <h5>Ambiente Seguro SEFAZ</h5>
                <span>
                  Login: <strong>{login_sefaz || ''}</strong>
                </span>
                <span>
                  Senha: <strong>{senha_sefaz || ''}</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>FAP</h5>
                <span>
                  Login: <strong>{login_fap || ''}</strong>
                </span>
                <span>
                  Senha: <strong>{senha_fap || ''}</strong>
                </span>
              </div>
              <div>
                <h5>CENSO</h5>
                <span>
                  Senha: <strong>{senha_censo || ''}</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>RDE-IED</h5>
                <span>
                  Senha: <strong>{senha_rde || ''}</strong>
                </span>
              </div>
              <div>
                <h5>DCBE</h5>
                <span>
                  Senha: <strong>{senha_dcbe || ''}</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>Outras senhas</h5>
                <span>
                  <strong>{outras || ''}</strong>
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid>
              <div>
                <h5>PGDAS-d</h5>
                <span>
                  CNPJ: <strong>*******</strong>
                </span>
                <span>
                  CPF: <strong>*******</strong>
                </span>
                <span>
                  Código: <strong>*******</strong>
                </span>
              </div>
              <div>
                <h5>ISS (Sistema Escrituração)</h5>
                <span>
                  Login: <strong>*******</strong>
                </span>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>Previdência Social</h5>
                <span>
                  Login: <strong>*******</strong>
                </span>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
              <div>
                <h5>Ambiente Seguro SEFAZ</h5>
                <span>
                  Login: <strong>*******</strong>
                </span>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>FAP</h5>
                <span>
                  Login: <strong>*******</strong>
                </span>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
              <div>
                <h5>CENSO</h5>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>RDE-IED</h5>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
              <div>
                <h5>DCBE</h5>
                <span>
                  Senha: <strong>*******</strong>
                </span>
              </div>
            </Grid>
            <Grid>
              <div>
                <h5>Outras senhas</h5>
                <span>
                  <strong>*******</strong>
                </span>
              </div>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

ClienteSenha.propTypes = {
  pgdas_cnpj: PropTypes.any, // eslint-disable-line
  pgdas_cpf: PropTypes.any, // eslint-disable-line
  pgdas_cod: PropTypes.any, // eslint-disable-line
  login_iss: PropTypes.any, // eslint-disable-line
  senha_iss: PropTypes.any, // eslint-disable-line
  login_prev: PropTypes.any, // eslint-disable-line
  senha_prev: PropTypes.any, // eslint-disable-line
  login_sefaz: PropTypes.any, // eslint-disable-line
  senha_sefaz: PropTypes.any, // eslint-disable-line
  login_fap: PropTypes.any, // eslint-disable-line
  senha_fap: PropTypes.any, // eslint-disable-line
  senha_censo: PropTypes.any, // eslint-disable-line
  senha_rde: PropTypes.any, // eslint-disable-line
  senha_dcbe: PropTypes.any, // eslint-disable-line
  outras: PropTypes.any, // eslint-disable-line
  ativoUser: PropTypes.bool, // eslint-disable-line
};
