import React from 'react';

import { CalendarProvider } from '~/hooks/calendar';
import CalendarHeader from './CalendarHeader';
import CalendarDays from './CalendarDays';
import CalendarCells from './CalendarCells';
import CalendarFooter from './CalendarFooter';

import { Container } from './styles';

const Calendar = ({ events, weekStartsOn = 1 }) => (
  <>
    <Container>
      <CalendarProvider events={events} weekStartsOn={weekStartsOn}>
        <CalendarHeader />
        <CalendarDays />
        <CalendarCells />
        <CalendarFooter />
      </CalendarProvider>
    </Container>
  </>
);

export default Calendar;
