import React, { useState, useEffect } from 'react';

import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { Scrollbars } from 'react-custom-scrollbars';
// import InfiniteScroll from 'react-infinite-scroller';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import { urlFile as url, urlRh } from '~/utils/url.json';

import { Grid, Titulo, ContentMessenger, Messenger } from './styles';

import Carregando from '~/components/Carregando/Button';

export default function Comments() {
  const [loadComment, setLoadComment] = useState(true);
  const [comments, setComments] = useState([]);
  const [, setNext] = useState(1);
  const [loadBtnComment, setLoadBtnComment] = useState(false);
  const [, setMore] = useState(true);

  useEffect(() => {
    setLoadComment(false);
    async function loadComments() {
      const response = await api.get(`comments`);

      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.created_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      const verify = response.data.next_page_url;
      const numeroPage = response.data.next_page_url.replace(/[^0-9]/g, '');

      if (!verify) {
        setMore(false);
      }

      setLoadComment(false);
      setNext(numeroPage || 1);
      setComments(percorre);
    }

    loadComments();
  }, []);

  async function handleComment() {
    try {
      setLoadBtnComment(true);
      const comment = document.querySelector('.comment').value;
      const res = await api.post('comments', {
        comment,
      });

      const percorre = res.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.created_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      document.getElementById('comment').reset();

      setLoadBtnComment(false);

      const comentario = percorre.concat(comments);

      setComments(comentario);
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  /* async function handleLoadMore(page) {
    const count = next - 1;
    const paginate = page + count;

    if (next > paginate) {
      setMore(false);
      return;
    }

    const apirest = await api.get(`comments?page=${paginate}`);

    const verify = apirest.data.next_page_url;

    const percorre = apirest.data.data.map(item => ({
      ...item,
      dateFormatted: format(
        parseISO(item.created_at),
        "dd 'de' MMMM', às ' HH:mm'h'",
        {
          locale: pt,
        }
      ),
    }));

    setNext(paginate + 1);
    const comentario = comments.concat(percorre);

    setComments(comentario);

    if (!verify) {
      setMore(false);
    }
  } */

  return (
    <Grid>
      <Titulo>Mural de ajuda.</Titulo>
      <ContentMessenger>
        {loadComment ? (
          <Carregando />
        ) : (
          <>
            <Scrollbars className="scrollbar" style={{ height: 250 }}>
              {comments.map(item => (
                <div key={item.id} className="bloco">
                  <div className="perfil">
                    <div>
                      {item.avatar ? (
                        <img src={`${urlRh}${item.avatar}`} alt={item.name} />
                      ) : (
                        <img
                          src={
                            item.imagem
                              ? `${url}${item.imagem}`
                              : `https://api.adorable.io/avatars/285/${item.username}@adorable.png`
                          }
                          alt={item.username}
                        />
                      )}
                    </div>
                    <div>
                      <div className="messenger">{item.comment}</div>
                      <div className="data">
                        <span>
                          {item.username} - {item.dateFormatted}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Scrollbars>
          </>
        )}
        <Messenger>
          <Form onSubmit={handleComment} id="comment">
            <textarea placeholder="Digite aqui..." className="comment" />
            <button type="submit">
              {loadBtnComment ? 'Carregando...' : 'Enviar Comentário'}
            </button>
          </Form>
        </Messenger>
      </ContentMessenger>
    </Grid>
  );
}
