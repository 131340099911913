import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .blocoavatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }

  .buttonExecutor {
    width: 100%;
    height: 42px;
    border: none;
    background: #ccc;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 4px;

    &:hover {
      background: #ff732f;
      color: #fff;
    }
  }

  h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px dotted #ccc;
    padding-bottom: 6px;
  }

  .bloco {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 8px;
    width: 100%;

    .grid {
      display: flex;
      align-items: center;
      flex: 1;
      background: #fff;
      border-radius: 6px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 15px;
      padding-right: 15px;
      justify-content: space-between;

      .total {
        display: block;

        .percentage {
          font-size: 18px;
          font-weight: bold;

          .red {
            color: #ff3e3e;
          }

          .black {
            color: #222222;
          }

          .green {
            color: #00b285;
          }

          .orange {
            color: #ff7f00;
          }

          .blue {
            color: #00468c;
          }
        }

        p {
          font-size: 12px;
        }

        span {
          font-size: 30px;
          font-weight: bold;
        }
      }

      .totalprazo {
        p {
          font-size: 9px;
        }

        span {
          font-size: 18px;
        }
      }

      .blue {
        color: #00468c;
      }

      .green {
        color: #36d900;
      }

      .orange {
        color: #ff7f00;
      }

      .red {
        color: #ff3e3e;
      }
    }
  }
`;

export const GridButton = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  text-align: left;
  background: #fff;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  border: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.4;
  }

  .total {
    display: block;

    .percentage {
      font-size: 18px;
      font-weight: bold;

      .red {
        color: #ff3e3e;
      }

      .black {
        color: #222222;
      }

      .green {
        color: #00b285;
      }

      .orange {
        color: #ff7f00;
      }

      .blue {
        color: #00468c;
      }
    }

    p {
      font-size: 12px;
    }

    span {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .totalprazo {
    p {
      font-size: 9px;
    }

    span {
      font-size: 18px;
    }
  }

  .blue {
    color: #00468c;
  }

  .green {
    color: #36d900;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff3e3e;
  }
`;
