import React, { useState, useEffect, useCallback } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import {
  MdRemoveRedEye,
  MdCheckBox,
  MdWarning,
  MdCancel,
  MdCheck,
  MdClose,
} from 'react-icons/md';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';

import { Link, useHistory } from 'react-router-dom';

import api from '~/services/api';
import xml from '~/services/xmlApi';

import { urlFile, urlRh } from '~/utils/url.json';

import SemAvatar from '~/assets/no-avatar.png';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando';
import Pagination from '~/components/Pagination';
import Nav from '~/components/Navigation';
import User from '~/components/User';

import { Content, Bloco, Card, Pesquisa } from './styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ExecutoresRelatorios = () => {
  const classes = useStyles();
  const [clientes, setClientes] = useState([]);
  const [executores, setExecutores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [total, setTotal] = useState(1);
  const [grafico, setGrafico] = useState({
    ativo: 0,
    inativo: 0,
  });
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const limit = params.get('limit') || '';
  const funcao = params.get('funcao') || '';
  const executor = params.get('executor') || '';
  const tipo = params.get('tipo') || '';
  const status = params.get('status') || '';
  const page = params.get('page') || '1';

  const history = useHistory();

  useEffect(() => {
    if (!funcao || !status || !tipo) {
      return;
    }

    async function loadclientes() {
      setLoading(true);
      const response = await api.get(
        `relatorios/executores/clientes?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limit}&page=${page}`
      );

      setClientes(response.data.clientes.data);
      setTotal(response.data.clientes.last_page);
      setGrafico({
        ativo: response.data.ativo,
        inativo: response.data.inativo,
      });
      setLoading(false);
    }

    loadclientes();
  }, [executor, funcao, limit, page, status, tipo]);

  const handleLimit = useCallback(
    e => {
      setLoading(true);
      const limitView = e.target.value;

      history.push(
        `/relatorio/executores?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limitView}&page=${page}`
      );
    },
    [executor, funcao, history, page, status, tipo]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = Number(page) === 1 ? 1 : Number(page) - 1;
        history.push(
          `/relatorio/executores?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limit}&page=${ptotal}`
        );
        return;
      }
      setLoading(true);
      const ptotal = Number(page) + 1;
      history.push(
        `/relatorio/executores?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limit}&page=${ptotal}`
      );
    },
    [executor, funcao, history, limit, page, status, tipo]
  );

  const handlePageSelect = useCallback(
    ({ paginate }) => {
      setLoading(true);
      history.push(
        `/relatorios/executores?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limit}&page=${paginate}`
      );
    },
    [executor, funcao, history, limit, status, tipo]
  );

  const handleChangeStatus = useCallback(
    event => {
      const { value } = event.target;
      history.push(
        `/relatorio/executores?funcao=${funcao}&tipo=${tipo}&executor=${executor}&status=${value}&limit=${limit}&page=${page}`
      );
    },
    [history, funcao, tipo, executor, limit, page]
  );

  const handleChangeFuncao = useCallback(
    event => {
      const { value } = event.target;
      history.push(
        `/relatorio/executores?funcao=${value}&tipo=${tipo}&executor=${executor}&status=${status}&limit=${limit}&page=${page}`
      );
    },
    [executor, history, limit, page, status, tipo]
  );

  const handleSearchFuncao = useCallback(
    async event => {
      const { value } = event.target;
      try {
        const response = await api.get(`users?funcao=${funcao}&tipo=${value}`);

        setExecutores(response.data);
        history.push(
          `/relatorio/executores?funcao=${funcao}&tipo=${value}&executor=${executor}&status=${status}&limit=${limit}&page=${page}`
        );
      } catch (err) {
        toast.warning(err.message);
      }
    },
    [history, funcao, executor, status, limit, page]
  );

  const handleSearchExecutor = useCallback(
    event => {
      const { value } = event.target;

      history.push(
        `/relatorio/executores?funcao=${funcao}&tipo=${tipo}&executor=${value}&status=${status}&limit=${limit}&page=${page}`
      );
    },
    [funcao, history, limit, page, status, tipo]
  );

  const handleExport = useCallback(async () => {
    const windows = window.location.href;
    const url2 = windows.split('?')[1];

    setLoadingExport(true);
    try {
      await xml
        .get(`relatorio/executores?${url2}`, {
          responseType: 'blob', // important
        })
        .then(response => {
          const dataUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const date = `executoreslist-${new Date()}-export.xlsx`;
          link.href = dataUrl;
          link.setAttribute('download', date); // or any other extension
          document.body.appendChild(link);
          link.click();
          setLoadingExport(false);
        });
    } catch (err) {
      toast.error(err.message);
      setLoadingExport(false);
    }
  }, []);

  return (
    <>
      <Nav page="relatorios" />
      <Container>
        <User />

        <Content>
          <Title title="Relatórios / Executores" />
          <Bloco>
            <div>
              <h2>Relatórios Executores</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <select onChange={handleChangeStatus}>
                <option value="">Seleciona o Status</option>
                <option value="ativo">Pesquisar por Preenchido</option>
                <option value="inativo">Pesquisar por Não Preenchido</option>
              </select>
              {status && (
                <select onChange={handleChangeFuncao}>
                  <option value="">Escolha o executor (Setor)</option>
                  <option value="fiscal">Fiscal</option>
                  <option value="contabil">Contábil</option>
                  <option value="tributos">Tributos</option>
                  <option value="pessoal">Pessoal</option>
                </select>
              )}
              {funcao && (
                <select onChange={handleSearchFuncao}>
                  <option value="">Escolha a função</option>
                  <option value="diretor">Diretoria</option>
                  <option value="coord">Coordernador</option>
                  <option value="supervisao">Supervisão</option>
                  <option value="analista">Analista</option>
                  <option value="executor">Executor</option>
                </select>
              )}
              {executores.length > 0 && (
                <select onChange={handleSearchExecutor}>
                  <option value="">Escolha a função</option>
                  {executores.map(exe => {
                    return <option value={exe.id}>{exe.name}</option>;
                  })}
                </select>
              )}
            </div>
            <div className="bloco-two">
              {tipo && funcao && status && (
                <button type="button" onClick={handleExport}>
                  EXPORTAR EM EXCEL
                </button>
              )}
            </div>
          </Pesquisa>
        </Content>

        <Content>
          <Bloco>
            <div className="grid">
              <div className="bloco">
                <span>
                  <h4>Total Preenchido</h4>
                  <h2>{grafico.ativo}</h2>
                </span>
                <span>
                  <div className="icon">
                    <MdCheck size={46} color="#fff" />
                  </div>
                </span>
              </div>
              <div className="bloco">
                <span>
                  <h4>Total Não Preenchido</h4>
                  <h2>{grafico.inativo}</h2>
                </span>
                <span>
                  <div className="icon">
                    <MdClose size={46} color="#fff" />
                  </div>
                </span>
              </div>
            </div>
          </Bloco>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Razão Social</td>
                <td>Status</td>
                <td>Unidade</td>
                <td>CNPJ / CPF</td>
                <td>Executores</td>
                <td>Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {clientes.map(item => (
                    <>
                      <tr
                        key={item.id}
                        className="table-hover"
                        id={
                          (item.status === 'Suspenso' && 'orange') ||
                          (item.status === 'Rescindido' && 'red')
                        }
                      >
                        <td>{item.razao}</td>
                        <td className="contrato">
                          {item.status === 'Ativo' && (
                            <>
                              <MdCheckBox size={20} color="#0FAF69" />
                              Ativo
                            </>
                          )}
                          {item.status === 'Rescindido' && (
                            <>
                              <MdCancel size={20} color="#FF2626" />
                              Rescindido
                            </>
                          )}
                          {item.status === 'Suspenso' && (
                            <>
                              <MdWarning size={20} color="#FF7F00" />
                              Suspenso
                            </>
                          )}
                        </td>
                        <td>{item.tipo}</td>
                        <td>
                          {item.cnpj.length === 14 ? (
                            <InputMask
                              mask="99.999.999/9999-99"
                              value={item.cnpj.replace('x', '1')}
                              disabled
                            />
                          ) : (
                            <InputMask
                              mask="99.999.999-99"
                              value={item.cnpj}
                              disabled
                            />
                          )}
                        </td>
                        <td>
                          <div className="perfil">
                            {item.user ? (
                              <>
                                {item.user.file_id ? (
                                  <img
                                    src={`${urlFile}${item.avatar}`}
                                    alt={item.user.name}
                                  />
                                ) : (
                                  <>
                                    {item.user.avatar ? (
                                      <img
                                        src={`${urlRh}${item.avatar}`}
                                        alt={item.user.name}
                                      />
                                    ) : (
                                      <img
                                        src={SemAvatar}
                                        alt={item.user.name}
                                      />
                                    )}
                                  </>
                                )}
                                <p>{item.user.name}</p>
                              </>
                            ) : (
                              'Sem Executor'
                            )}
                          </div>
                        </td>
                        <td>
                          <div>
                            <Link
                              to={`/cliente/${item.cnpj}`}
                              alt="Visualizar"
                              title="Visualizar"
                            >
                              <MdRemoveRedEye size={20} color="#fff" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limit}
          page={page}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Footer />

        <Backdrop className={classes.backdrop} open={loadingExport}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
};

export default ExecutoresRelatorios;
