import React, { useState, useEffect, useCallback } from 'react';

import { Form, Input, Select } from '@rocketseat/unform';
import { MdSave } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';

import { Container, Content, Bloco, Grid, BForm } from './styles';

import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';

export default function Adicionar() {
  const profile = useSelector(state => state.user.profile);
  const [cargos, setCargos] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAdd() {
      const cargoRes = await api.get('cargos?search=%%&limit=50');

      if (profile.role_id === 1) {
        const rolesAdd = [
          { id: 1, title: 'ADMINISTRADOR' },
          { id: 3, title: 'GESTÃO' },
          { id: 6, title: 'EDITOR' },
          { id: 4, title: 'MODERADOR' },
        ];
        setRoles(rolesAdd);
      }

      if (profile.role_id === 3) {
        const rolesAdd = [
          { id: 3, title: 'GESTÃO' },
          { id: 6, title: 'EDITOR' },
          { id: 4, title: 'MODERADOR' },
        ];
        setRoles(rolesAdd);
      }

      if (profile.role_id === 6) {
        const rolesAdd = [
          { id: 6, title: 'EDITOR' },
          { id: 4, title: 'MODERADOR' },
        ];
        setRoles(rolesAdd);
      }

      setCargos(cargoRes.data.data);
    }

    loadAdd();
  }, []); // eslint-disable-line

  const tabstatus = [
    { id: 'Ativo', title: 'Ativo' },
    { id: 'Inativo', title: 'Inativo' },
  ];

  const handleSubmit = useCallback(
    async ({ name, email, role_id, cargo_id, status, ramal, password }) => {
      try {
        setLoading(true);
        await api.post('users', {
          name,
          email,
          role_id,
          cargo_id,
          status,
          ramal,
          password,
        });

        setLoading(false);
        toast.success('Usuário cadastrado com sucesso.');
        history.push('/usuarios');
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
      }
    },
    []
  );

  return (
    <>
      <Nav page="administracao" />

      <Container>
        <User />

        <Content>
          <Title title="Administrador / Usuario / Adicionar" />

          <Bloco>
            <div>
              <h2>Adicionar usuário</h2>
            </div>
          </Bloco>
        </Content>

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Name</label>
                <Input type="text" name="name" />
              </span>
              <span>
                <label>Email</label>
                <Input type="email" name="email" />
              </span>
              <span>
                <label>Ramal</label>
                <Input type="number" name="ramal" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Permissão</label>
                <Select name="role_id" options={roles} />
              </span>
              <span>
                <label>Cargo</label>
                <Select name="cargo_id" options={cargos} />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Status</label>
                <Select name="status" options={tabstatus} />
              </span>
              <span>
                <label>Password</label>
                <Input type="password" name="password" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>
    </>
  );
}
