import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Form, Input } from '@rocketseat/unform';

import { format } from 'date-fns';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';
import { usePermission } from '~/hooks/permission';
import { token_sintegra as tokenCnpj } from '~/utils/url.json';

import { Container, Button, Logo } from './styles';
import history from '~/services/history';
import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';
import api from '~/services/api';
import apiCnpj from '~/services/cnpj';

export default function Cnpj() {
  const [adcnpj, setCnpj] = useState();
  const [loading, setLoading] = useState(false);
  const [diretor, setDiretor] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { adminAcessoPermission } = usePermission();

  useEffect(() => {
    async function loadDiretor() {
      const response = await api.get('users?diretor=true');

      setDiretor(response.data);
    }

    loadDiretor();
  }, []);

  useEffect(() => {
    adminAcessoPermission({ role_id: profile.role_id });
  }, [adminAcessoPermission, profile.role_id]);

  function handleCnpj(e) {
    const valor = e.target.value;

    setCnpj(valor.replace(/[^\d]+/g, ''));
  }

  async function handleSubmit({ cnpj, data_servico }) {
    const userDiretor = document.querySelector(`.diretor`).value;

    if (!cnpj) {
      toast.error('Por favor! Preencha o campo CNPJ');
      return;
    }

    if (!data_servico) {
      toast.error('Por favor! Preencha a data do Início...');
      return;
    }

    if (!userDiretor) {
      toast.error('Por favor! Preencha o diretor responsável');
      return;
    }

    try {
      setLoading(true);
      const cnpjapi = await apiCnpj.get(
        `execute-api.php?token=${tokenCnpj}&cnpj=${cnpj}&plugin=RF`
      );

      const resCnpj = cnpjapi.data;

      if (resCnpj.status === 'ERROR') {
        toast.warning('Esse CNPJ não existe.');
        setLoading(false);
        return;
      }

      const response = await api.post('clientes', {
        cnpj,
        status: 'Ativo',
        data_servico,
        situacao: resCnpj.situacao === 'ATIVA' ? 8 : null,
        razao: resCnpj.nome || null,
        tipo: resCnpj.tipo || null,
        fantasia: resCnpj.fantasia || null,
        end: resCnpj.logradouro || null,
        end_n: resCnpj.numero || null,
        end_c: resCnpj.complemento || null,
        cep: resCnpj.cep.replace(/[^\d]+/g, '') || null,
        bairro: resCnpj.bairro || null,
        porte: resCnpj.porte || null,
        cidade: resCnpj.municipio || null,
        uf:
          (resCnpj.uf === 'AC' && 12) ||
          (resCnpj.uf === 'AL' && 27) ||
          (resCnpj.uf === 'AM' && 13) ||
          (resCnpj.uf === 'AP' && 16) ||
          (resCnpj.uf === 'BA' && 29) ||
          (resCnpj.uf === 'CE' && 23) ||
          (resCnpj.uf === 'DF' && 53) ||
          (resCnpj.uf === 'ES' && 32) ||
          (resCnpj.uf === 'GO' && 52) ||
          (resCnpj.uf === 'MA' && 21) ||
          (resCnpj.uf === 'MG' && 31) ||
          (resCnpj.uf === 'MS' && 50) ||
          (resCnpj.uf === 'MT' && 51) ||
          (resCnpj.uf === 'PA' && 15) ||
          (resCnpj.uf === 'PB' && 25) ||
          (resCnpj.uf === 'PE' && 26) ||
          (resCnpj.uf === 'PI' && 22) ||
          (resCnpj.uf === 'PR' && 41) ||
          (resCnpj.uf === 'RJ' && 33) ||
          (resCnpj.uf === 'RN' && 24) ||
          (resCnpj.uf === 'RO' && 11) ||
          (resCnpj.uf === 'RR' && 14) ||
          (resCnpj.uf === 'RS' && 43) ||
          (resCnpj.uf === 'SC' && 42) ||
          (resCnpj.uf === 'SE' && 28) ||
          (resCnpj.uf === 'SP' && 35),
      });

      if (response.data.error) {
        toast.error(response.data.error);
        setLoading(false);
        return;
      }

      await api.post(`clientes/${response.data.id}/servicos-store`, {
        data: format(new Date(), 'yyyy-MM-dd'),
        title: 'Ativo',
      });

      localStorage.setItem('cliente', JSON.stringify(response.data));

      await api.post(`add/cnaes/${response.data.id}`, {
        atividades_secundarias: resCnpj.atividades_secundarias || null,
        atividade_principal: resCnpj.atividade_principal || null,
      });

      await api.post(`clientes/${response.data.id}/executores`, {
        funcao: 'diretor',
        cargo_id: 1,
        user_id: userDiretor,
        cnpj: response.data.cnpj,
      });

      toast.success('Cliente cadastrado com sucesso!');
      setLoading(false);
      history.push(`/edit/cliente/${response.data.id}`);
    } catch (err) {
      setLoading(false);
      toast.error('Erro no cadastro!');
    }
  }

  return (
    <>
      <Logo>
        <Link to="/dashboard">
          <img src={logo} alt="Secran" />
        </Link>
      </Logo>
      <Container>
        <Form onSubmit={handleSubmit}>
          <span>Digite o CNPJ</span>
          <Input type="hidden" name="cnpj" value={adcnpj} />
          <InputMask
            mask="99.999.999/9999-99"
            placeholder="99.999.999/9999-99"
            onChange={handleCnpj}
          />
          <span>Data início vigência do contrato</span>
          <Input type="date" name="data_servico" />
          <span>Diretor Responsável</span>
          <select name="diretor" className="diretor">
            <option value="">...</option>
            {diretor.map(item => (
              <option value={item.id}>{item.name}</option>
            ))}
          </select>
          <Button>
            <button type="button" onClick={history.goBack}>
              Cancelar
            </button>
            <button type="submit">
              {loading ? <Carregando /> : 'Avançar'}
            </button>
          </Button>
        </Form>
      </Container>
    </>
  );
}
