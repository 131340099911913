import styled from 'styled-components';

export const Container = styled.div`
  table {
    tr {
      border-bottom: 1px solid #ccc;
    }
    td {
      height: 42px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;
