import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './route';

import NotFoundPage from '~/pages/404';

import Login from '~/pages/SignIn';
import ResetPassword from '~/pages/SignIn/resetSenha';
import RedefinirPassword from '~/pages/SignIn/findRedirect';

import Dashboard from '~/pages/Dashboard';

import Clientes from '~/pages/Clientes';
import ClientesUploads from '~/pages/Clientes/UploadsArchives';
import ClienteShowUploads from '~/pages/Clientes/UploadsArchives/Show';
import ClientesEditExe from '~/pages/Clientes/Executores';
import ClienteShow from '~/pages/Clientes/Show';
import FilialClientes from '~/pages/Clientes/filial';

import Historicos from '~/pages/Historicos';
import HistoricosCreate from '~/pages/Historicos/Create';
import HistoricosShow from '~/pages/Historicos/Show';

import Comunicados from '~/pages/Comunicado';
import ComunicadosCreate from '~/pages/Comunicado/Create';
import ComunicadoShow from '~/pages/Comunicado/Show';

import ClientesRelatorio from '~/pages/Clientes/Relatorio';
import ClienteAddIndex from '~/pages/Clientes/Adicionar';
import ClienteAddCnpj from '~/pages/Clientes/Adicionar/Cnpj';
import ClienteAddScp from '~/pages/Clientes/Adicionar/Scp';
import ClienteAddCpf from '~/pages/Clientes/Adicionar/Cpf';
import ClienteAddDomestica from '~/pages/Clientes/Adicionar/Domestico';
import AddClienteFilial from '~/pages/Clientes/Adicionar/Cnpj/filial';

import EditCliente from '~/pages/Clientes/Edit';
import ServicoCliente from '~/pages/Clientes/Edit/Servicos';
import TributacoesCliente from '~/pages/Clientes/Edit/Tributacao';
import IncentivosFiscaisC from '~/pages/Clientes/Edit/IncentivosFiscais';
import CnaeCliente from '~/pages/Clientes/Edit/Cnae';
import SociosCliente from '~/pages/Clientes/Edit/Socios';
import AtosCliente from '~/pages/Clientes/Edit/AtosSocietarios';
import SenhasCliente from '~/pages/Clientes/Edit/Senhas';
import CtdNegativaCliente from '~/pages/Clientes/Edit/CertNegativos';
import ProcuracoesCliente from '~/pages/Clientes/Edit/Procuracoes';
import CertDigitaisCliente from '~/pages/Clientes/Edit/CertDigitais';
import LicencaCliente from '~/pages/Clientes/Edit/Licencas';
import ContatosCliente from '~/pages/Clientes/Edit/Contatos';
import ExecutoresCliente from '~/pages/Clientes/Edit/Executores';
import PCCliente from '~/pages/Clientes/Edit/PrimeiroContato';
import InfoAddliente from '~/pages/Clientes/Edit/InfoAdicionais';

import Relatorios from '~/pages/Relatorios';
import RelatoriosGeral from '~/pages/Relatorios/Geral';
import RelatoriosExecutores from '~/pages/Relatorios/Executores';
import RelatoriosClientesExecutores from '~/pages/Relatorios/ClientesStatus';
import RelatoriosClientesTributacoes from '~/pages/Relatorios/ClientesTributacoes';
import Obrigacoes from '~/pages/Obrigacoes';
import ObrigacoesTable from '~/pages/Obrigacoes/Table';
import ExecutoresRelatorios from '~/pages/Obrigacoes/Relatorio';

import ObrigacoesCalendar from '~/pages/Obrigacoes/Calendar';
import ObrigacoesCalendarShow from '~/pages/Obrigacoes/Calendar/show';

import Itens from '~/pages/Itens';
import GeralItens from '~/pages/Itens/TabelasGeral';
import EditGeralItens from '~/pages/Itens/TabelasGeral/Edit';
import AdicionarItens from '~/pages/Itens/TabelasGeral/adicionar';

import eSocialIndex from '~/pages/Itens/Esocial';
import eSocialAdd from '~/pages/Itens/Esocial/adicionar';
import eSocialEdit from '~/pages/Itens/Esocial/edit';

import GrupoIndex from '~/pages/Itens/GrupoEconomico';
import GrupoAdd from '~/pages/Itens/GrupoEconomico/adicionar';
import GrupoEdit from '~/pages/Itens/GrupoEconomico/edit';

import CertNegativos from '~/pages/Itens/CertNegativas';
import CertNegativosAdd from '~/pages/Itens/CertNegativas/adicionar';
import CertNegativosEdit from '~/pages/Itens/CertNegativas/edit';

import Administracao from '~/pages/Administrativo';
import ObrigacoesIndex from '~/pages/Administrativo/Obrigacoes';
import Usuarios from '~/pages/Administrativo/Usuarios';
import UserEdit from '~/pages/Administrativo/Usuarios/Edit';
import UserAdd from '~/pages/Administrativo/Usuarios/Adicionar';

import Setores from '~/pages/Administrativo/Setores';
import SetorAdd from '~/pages/Administrativo/Setores/Adicionar';
import SetorEdit from '~/pages/Administrativo/Setores/Edit';

import Roles from '~/pages/Administrativo/Permissoes';
import RolesAdd from '~/pages/Administrativo/Permissoes/Adicionar';

import Auditorias from '~/pages/Administrativo/Auditorias';

import Notification from '~/pages/Notifications';
import ShowNotification from '~/pages/Notifications/View';

import Profile from '~/pages/Profile';
import Ticket from '~/pages/Ticket';
import TicketCreate from '~/pages/Ticket/create';
import TicketRelatorio from '~/pages/Ticket/relatorio';
import Activity from '~/pages/Ticket/activity';
import ShowTicket from '~/pages/Ticket/show';

import Periodica from '~/pages/Obrigacoes/Periodica';
import PeriodicaShow from '~/pages/Obrigacoes/Periodica/Show';
import PeriodicaCreate from '~/pages/Obrigacoes/Periodica/Create';
import PeriodicaEdit from '~/pages/Obrigacoes/Periodica/Edit';
import PeriodicaAcordo from '~/pages/Obrigacoes/Periodica/Acordo';
import ImportExport from '~/pages/Obrigacoes/ImpExp';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/password/create" component={ResetPassword} />
      <Route path="/password/reset/:token" component={RedefinirPassword} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/historicos" component={Historicos} isPrivate />
      <Route path="/historico/create" component={HistoricosCreate} isPrivate />
      <Route path="/historico/show/:id" component={HistoricosShow} isPrivate />

      <Route path="/comunicados" component={Comunicados} isPrivate />
      <Route
        path="/comunicado/create"
        component={ComunicadosCreate}
        isPrivate
      />
      <Route
        path="/comunicado/show/:token"
        component={ComunicadoShow}
        isPrivate
      />

      <Route path="/clientes" component={Clientes} isPrivate />
      <Route path="/uploads/clientes" component={ClientesUploads} isPrivate />
      <Route
        path="/uploads/cliente/protocol/:protocol"
        component={ClienteShowUploads}
        isPrivate
      />
      <Route path="/edit/executores" component={ClientesEditExe} isPrivate />
      <Route
        path="/relatorio/clientes"
        component={ClientesRelatorio}
        isPrivate
      />
      <Route path="/filial/:id/clientes" component={FilialClientes} isPrivate />
      <Route path="/cliente/:cnpj" component={ClienteShow} isPrivate />
      <Route path="/adicionar/cliente" component={ClienteAddIndex} isPrivate />
      <Route
        path="/adicionar/cnpj/cliente/"
        component={ClienteAddCnpj}
        isPrivate
      />
      <Route
        path="/adicionar/scp/cliente/"
        component={ClienteAddScp}
        isPrivate
      />
      <Route
        path="/adicionar/cpf/cliente/"
        component={ClienteAddCpf}
        isPrivate
      />
      <Route
        path="/adicionar/domestica/cliente/"
        component={ClienteAddDomestica}
        isPrivate
      />
      <Route
        path="/adicionar/filial/:id"
        component={AddClienteFilial}
        isPrivate
      />
      <Route path="/edit/cliente/:id" component={EditCliente} isPrivate />
      <Route
        path="/servico/cliente/:idCliente"
        component={ServicoCliente}
        isPrivate
      />
      <Route
        path="/tributacoes/cliente/:idCliente"
        component={TributacoesCliente}
        isPrivate
      />
      <Route
        path="/incentivos-fiscais/cliente/:idCliente"
        component={IncentivosFiscaisC}
        isPrivate
      />
      <Route
        path="/cnae/cliente/:idCliente"
        component={CnaeCliente}
        isPrivate
      />
      <Route
        path="/socios/cliente/:idCliente"
        component={SociosCliente}
        isPrivate
      />
      <Route
        path="/atos-societarios/cliente/:idCliente"
        component={AtosCliente}
        isPrivate
      />
      <Route
        path="/senhas/cliente/:idCliente"
        component={SenhasCliente}
        isPrivate
      />
      <Route
        path="/certidoes-negativas/cliente/:idCliente"
        component={CtdNegativaCliente}
        isPrivate
      />
      <Route
        path="/procuracoes/cliente/:idCliente"
        component={ProcuracoesCliente}
        isPrivate
      />
      <Route
        path="/certificados-digitais/cliente/:idCliente"
        component={CertDigitaisCliente}
        isPrivate
      />
      <Route
        path="/licenca/cliente/:idCliente"
        component={LicencaCliente}
        isPrivate
      />
      <Route
        path="/contatos/cliente/:idCliente"
        component={ContatosCliente}
        isPrivate
      />
      <Route
        path="/executores/cliente/:idCliente"
        component={ExecutoresCliente}
        isPrivate
      />
      <Route
        path="/obrigacoes/executores/relatorios"
        component={ExecutoresRelatorios}
        isPrivate
      />
      <Route
        path="/primeiro-contato/cliente/:idCliente"
        component={PCCliente}
        isPrivate
      />
      <Route
        path="/adicionais/cliente/:idCliente"
        component={InfoAddliente}
        isPrivate
      />

      <Route path="/obrigacoes" component={Obrigacoes} isPrivate />
      <Route path="/tickets" component={Ticket} isPrivate />
      <Route path="/ticket/create" component={TicketCreate} isPrivate />
      <Route path="/ticket/relatorio" component={TicketRelatorio} isPrivate />
      <Route path="/show/ticket/:id" component={ShowTicket} isPrivate />
      <Route path="/ticket/activitys" component={Activity} isPrivate />
      <Route path="/obrigacoes-periodicas" component={Periodica} isPrivate />
      <Route path="/periodica/:token" component={PeriodicaShow} isPrivate />
      <Route path="/create/periodica" component={PeriodicaCreate} isPrivate />
      <Route
        path="/edit/periodica/:token"
        component={PeriodicaEdit}
        isPrivate
      />
      <Route path="/periodica-acordo" component={PeriodicaAcordo} isPrivate />
      <Route
        path="/obrigacoes-import-export"
        component={ImportExport}
        isPrivate
      />
      <Route
        path="/view/table/obrigacoes"
        component={ObrigacoesTable}
        isPrivate
      />

      <Route path="/edit/obrigacoes" component={ObrigacoesCalendar} isPrivate />
      <Route
        path="/show/obrigacoes/:id"
        component={ObrigacoesCalendarShow}
        isPrivate
      />

      <Route path="/relatorios" component={Relatorios} isPrivate />
      <Route path="/relatorio/geral" component={RelatoriosGeral} isPrivate />
      <Route
        path="/relatorio/executores"
        component={RelatoriosExecutores}
        isPrivate
      />
      <Route
        path="/relatorio/cliente/status"
        component={RelatoriosClientesExecutores}
        isPrivate
      />
      <Route
        path="/relatorio/tributacoes"
        component={RelatoriosClientesTributacoes}
        isPrivate
      />
      <Route path="/itens" component={Itens} isPrivate />
      <Route path="/show/itens" component={GeralItens} isPrivate />
      <Route path="/edit/item/:id" component={EditGeralItens} isPrivate />
      <Route path="/adicionar/item" component={AdicionarItens} isPrivate />

      <Route
        path="/show/certidoes-negativas"
        component={CertNegativos}
        isPrivate
      />
      <Route
        path="/adicionar/certidoes-negativas"
        component={CertNegativosAdd}
        isPrivate
      />
      <Route
        path="/edit/certidoes-negativas/:id"
        component={CertNegativosEdit}
        isPrivate
      />

      <Route path="/show/esocial" component={eSocialIndex} isPrivate />
      <Route path="/adicionar/esocial" component={eSocialAdd} isPrivate />
      <Route path="/edit/esocial/:id" component={eSocialEdit} isPrivate />

      <Route path="/show/grupoeconomico" component={GrupoIndex} isPrivate />
      <Route path="/adicionar/grupoeconomico" component={GrupoAdd} isPrivate />
      <Route path="/edit/grupoeconomico/:id" component={GrupoEdit} isPrivate />

      <Route path="/administracao" component={Administracao} isPrivate />
      <Route
        path="/administrativo/obrigacoes"
        component={ObrigacoesIndex}
        isPrivate
      />
      <Route path="/usuarios" component={Usuarios} isPrivate />
      <Route path="/adicionar/usuario" component={UserAdd} isPrivate />
      <Route path="/edit/usuario/:id" component={UserEdit} isPrivate />

      <Route path="/setores" component={Setores} isPrivate />
      <Route path="/adicionar/setor" component={SetorAdd} isPrivate />
      <Route path="/edit/setor/:id" component={SetorEdit} isPrivate />

      <Route path="/permissoes" component={Roles} isPrivate />
      <Route path="/adicionar/permissao" component={RolesAdd} isPrivate />

      <Route path="/auditorias" component={Auditorias} isPrivate />

      <Route path="/notifications" component={Notification} isPrivate />
      <Route
        path="/show/notification/:id"
        component={ShowNotification}
        isPrivate
      />
      <Route path="/404" component={NotFoundPage} />
      <Redirect to="/404" />
    </Switch>
  );
}
