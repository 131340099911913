import React from 'react';

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

import pt from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { useCalendar } from '~/hooks/calendar';

import { Header } from './styles';

const CalendarHeader = () => {
  const { currentMonth, setCurrentMonth, nextMonth, prevMonth } = useCalendar();

  if (!currentMonth || !setCurrentMonth) return null;

  return (
    <Header>
      <button onClick={prevMonth}>
        <MdKeyboardArrowLeft />
      </button>
      <div className="calendar-current">
        {format(currentMonth, "MMMM 'de' yyyy", {
          locale: pt,
        })}
      </div>
      <button className="calendar-next" onClick={nextMonth}>
        <MdKeyboardArrowRight />
      </button>
    </Header>
  );
};

export default CalendarHeader;
