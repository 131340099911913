import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';

import { Bloco, Content, Card } from './styles';

const Relatorios = () => {
  const history = useHistory();

  const handlePage = useCallback(
    page => {
      history.push(`/relatorio/${page}`);
    },
    [history]
  );

  return (
    <>
      <Nav page="relatorios" />
      <Container>
        <User />

        <Content>
          <Title title="Relatórios" />
          <Bloco>
            <div>
              <h2>Relatórios</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <div className="bloco">
            <button type="button" onClick={() => handlePage('executores')}>
              Relatório Executores Preenchido ou Não Preenchido
              <p>
                Aqui você gera relatório de executores que tem preenchido na
                empresa.
              </p>
            </button>
            <button type="button" onClick={() => handlePage('tributacoes')}>
              Relatório de clientes por tipo de tributação
              <p>
                Aqui você gera relatório de clientes por tipo de tributações
                como (FEDERAL, ESTADUAL, MUNICIPAL)
              </p>
            </button>
            <button type="button">
              Relatório de clientes por status e tipo
              <p>
                Aqui você gera relatório de clientes por status ativo e tipo da
                empresa (Matriz, Filial ou SCP)
              </p>
            </button>
            <button type="button" onClick={() => handlePage('geral')}>
              Relatório Geral
              <p>Aqui você gera relatório Geral.</p>
            </button>
          </div>
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default Relatorios;
