import styled from 'styled-components';
import { darken } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #82cc33;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  margin-top: 20px;
`;

export const TotalClientes = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
`;

export const Contagem = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;

  div + div {
    margin-left: 8px;
  }

  .bg-blue {
    background: #00468c;
  }
`;

export const Bloco = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  background: #006dd9;
  border-radius: 8px;
  color: #fff;
  padding: 20px;
  align-items: center;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 18px;
`;

export const Total = styled.div`
  font-size: 46px;
  font-weight: bold;
  margin-bottom: -10px;
  margin-top: -10px;

  a {
    color: #fff;
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
`;

export const Icon = styled.div`
  img {
    height: 50px;
  }
`;

export const Conteudo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`;

export const Grid = styled.div`
  margin-bottom: 20px;
  background: #fff;
  display: block;
  width: 49%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .red {
    background: #ff4d4d;
  }

  .blue {
    background: #00468c;
  }
`;

export const GridBlog = styled.div`
  margin-bottom: 20px;
  display: block;
  width: 49%;
  border-radius: 12px;
`;

export const Titulo = styled.div`
  padding: 12px;
  color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;

  a {
    display: flex;
    background: #0080ff;
    color: #fff;
    font-size: 12px;
    width: 100%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover {
      background: #eee;
    }
  }

  .date {
    font-size: 10px;
    color: #707070;
  }

  .subtitle {
    font-size: 11px;
    color: #707070;
  }

  a.bloco {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    align-items: center;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
    background: transparent !important;
    border-radius: 0;
    color: #000;

    &:hover {
      opacity: 0.7;

      div {
        opacity: 0.7;
      }

      span {
        opacity: 0.7;
      }
    }

    span {
      font-size: 10px;
    }
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .date_service {
    display: block;
    padding: 2px 4px 2px 4px;
    background: #0080ff;
    color: #fff;
    border-radius: 4px;
    margin-left: 8px;
    width: 140px;
    text-align: center;
  }

  .date_service_red {
    display: block;
    padding: 2px 4px 2px 4px;
    background: #ff4d4d;
    color: #fff;
    border-radius: 4px;
    margin-left: 8px;
    width: 140px;
    text-align: center;
  }

  .perfil {
    display: flex;
    align-items: center;

    input {
      width: auto;
      height: auto;
      border: none;
      background: transparent !important;
      font-size: 11px;
      padding: none;
    }

    div img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }

    div {
      display: flex;
      flex-direction: column;

      strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        width: 30em;
        font-weight: 200;
      }
    }
  }
`;

export const PopUp = styled.div`
  display: ${Props => (Props.open ? 'flex' : 'none')};
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff4040;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: fixed;
    top: 14px;
    right: 18px;
  }
`;
