import React from 'react';

import { MdKeyboardBackspace } from 'react-icons/md';

import PropTypes from 'prop-types';
import { TopoContent, Voltar, TitleNav } from './styles';
import history from '~/services/history';

export default function Title({ title }) {
  return (
    <>
      <TopoContent>
        <Voltar>
          <button type="button" onClick={history.goBack}>
            <MdKeyboardBackspace size={20} color="#000" /> Voltar
          </button>
        </Voltar>
        <TitleNav>
          Você está: <strong>Dashboard / {title}</strong>
        </TitleNav>
      </TopoContent>
    </>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
};
