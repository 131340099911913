import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 22px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a + a {
    background: #b22d00;
    margin-left: 10px;
  }

  a:hover {
    opacity: 0.5;
  }

  .btn {
    display: flex;
    align-items: center;
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 5px;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const Grid = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px;
  background: #fff;
  display: block;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .loading {
    display: flex;
    flex-direction: row;
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #afafaf;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  font-size: 12px;

  div + div {
    margin-left: 10px;
  }

  p {
    padding-top: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-right: 6px;
  }

  span {
    font-weight: bold;
    margin-bottom: 4px;
  }

  h4 {
    color: #0059b2;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    font-size: 11px;
    border-radius: 8px;
    border: 0;
    height: 24px;
    padding-left: 12px;
    padding-right: 12px;
    background: #083f6d;
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: 8px;

    &:hover {
      background: #2a9fe5;
    }
  }

  a {
    font-size: 11px;
    border-radius: 8px;
    height: 24px;
    padding-left: 12px;
    padding-right: 12px;
    background: #083f6d;
    color: #fff;
    display: flex;
    align-items: center;
    margin-left: 8px;

    &:hover {
      background: #2a9fe5;
    }
  }

  .grid-100 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .grid-none {
    display: flex;
    flex-direction: column;
  }

  .green {
    color: #0faf69;
  }

  .orange {
    color: #ff7f00;
  }

  .red {
    color: #ff2626;
  }

  .grid-20 {
    width: 20%;
    display: flex;
    flex-direction: column;
  }

  .grid-10 {
    width: 10%;
    display: flex;
    flex-direction: column;
  }

  .grid-30 {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  .grid-flex {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  input {
    background: none;
    border: 0;
  }
`;

export const Texto = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span + span {
    margin-left: 5px;
  }

  span::before {
    content: '» ';
  }

  div {
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    height: 28px;
    background: #d1d1d1;
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

export const Ativo = styled.div`
  font-size: 11px;
  padding-top: 4px;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }

  div {
    display: flex;

    button + button {
      margin-left: 4px;
    }
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const TabelaDiv = styled.div`
  display: block;
  width: 100%;
`;
