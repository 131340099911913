import React, { useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Form, Input } from '@rocketseat/unform';
import { MdSave } from 'react-icons/md';

import api from '~/services/api';
import { urlFile } from '~/utils/url.json';

import {
  Content,
  Conteudo,
  Profile,
  EditPerfil,
  Avatar,
  Logo,
  Text,
  BForm,
  Hr,
  BFormCheck,
} from './styles';

import Container from '~/components/Container';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Footer from '~/components/Footer';
import Title from '~/components/Title';
import Carregando from '~/components/Carregando/Button';

import bg from '~/assets/background-business.jpg';

import {
  updateProfileRequest,
  updateAvatarRequest,
} from '~/store/modules/user/actions';

import SemAvatar from '~/assets/no-avatar.png';

export default function Perfil() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [loadAvatar, setLoadAvatar] = useState(false);
  const [check, setCheck] = useState(false);

  const url = profile.file ? `${urlFile}${profile.file.file}` : null;

  const handleChange = useCallback(
    async e => {
      const data = new FormData();

      setLoadAvatar(true);

      data.append('file', e.target.files[0]);

      const response = await api.post('files', data);

      const { id } = response.data;

      dispatch(updateAvatarRequest({ file_id: id }));
      setLoadAvatar(false);
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    data => {
      setLoading(true);
      dispatch(updateProfileRequest(data));
      setLoading(false);
    },
    [dispatch]
  );

  const handleOneSignal = useCallback(() => {
    setCheck(!check);
    // onesignal

    console.log('teste'); // eslint-disable-line
  }, [check]);

  return (
    <>
      <Nav page="profile" />
      <Container>
        <User />

        <Content>
          <Title title="Usuários" />
        </Content>

        <Conteudo>
          <Profile>
            <Avatar>
              <img src={bg} alt="Secran Painel" />
            </Avatar>
            <Logo>
              {loadAvatar ? (
                <label>
                  <div className="carrega">
                    <Carregando />
                  </div>
                </label>
              ) : (
                <label>
                  <img src={url || SemAvatar} alt={profile.name} />

                  <input
                    type="file"
                    id="avatar"
                    accept="image/*"
                    onChange={handleChange}
                  />

                  <div>
                    <p>Edit</p>
                  </div>
                </label>
              )}
            </Logo>
            <Text>
              <h4>{profile.name}</h4>
              <p>{profile.email}</p>
              <p>{profile.cargo.title}</p>
            </Text>
          </Profile>
          <EditPerfil>
            <Form initialData={profile} onSubmit={handleSubmit}>
              <h4>Dados Pessoal</h4>
              <BForm>
                <span>
                  <label>Nome</label>
                  <Input type="text" name="name" />
                </span>
                <span>
                  <label>Email</label>
                  <Input type="email" name="email" disabled />
                </span>
                <span>
                  <label>Ramal</label>
                  <Input type="text" name="ramal" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Data de Nascimento</label>
                  <Input type="date" name="birthday" />
                </span>
                <span>
                  <label>Local de Trabalho?</label>
                  <Input type="text" name="job" disabled />
                </span>
              </BForm>
              <Hr />
              <h4>Alterar Senha</h4>
              <BForm>
                <span>
                  <label>Senha Nova</label>
                  <Input type="password" name="password" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Repetir a senha</label>
                  <Input type="password" name="password_confirmation" />
                </span>
              </BForm>
              <BFormCheck>
                <FormControlLabel
                  control={
                    <Switch
                      checked={check}
                      onChange={handleOneSignal}
                      name="checked"
                      color="primary"
                    />
                  }
                  label="Ativar ou desativar notificação via navegador"
                />
              </BFormCheck>
              <BForm>
                <span>
                  <button type="submit">
                    {loading ? (
                      <Carregando />
                    ) : (
                      <>
                        <MdSave size={16} color="#fff" /> Salvar
                      </>
                    )}
                  </button>
                </span>
              </BForm>
            </Form>
          </EditPerfil>
        </Conteudo>

        <Footer />
      </Container>
    </>
  );
}
