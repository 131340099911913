import styled from 'styled-components';

import bg from '~/assets/business.jpg';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: url(${bg}) no-repeat top center;
  background-size: cover;
  z-index: 9;

  h3 {
    font-size: 28px;
    color: #fff;
    padding-bottom: 12px;
  }
`;

export const Content = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  margin: 0px auto;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 36px;
    padding-bottom: 36px;
    border-radius: 5px;
    flex: 1;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    img {
      width: 120px;
      opacity: 0.5;
    }

    span {
      font-size: 22px;
      color: #fff;
      margin-top: 20px;
    }
  }

  a + a {
    margin-left: 6px;
  }
`;

export const Logo = styled.div`
  display: block;
  position: absolute;
  z-index: 999;
  width: 100px;
  top: 10px;
  left: 15px;
`;
