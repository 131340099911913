import styled from 'styled-components';
import { shade, darken } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  table div {
    display: flex;

    svg {
      margin-right: 4px;
    }
  }

  .sector {
    background: #0059b2;
    padding: 4px 6px;
    color: #fff;
    border-radius: 4px;
  }

  a {
    background: #008c69;
    border-radius: 4px;
    color: #fff;
    padding: 5px 6px;
    margin-right: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#008C69')};
    }
  }

  button {
    background: #0059b2;
    border-radius: 4px;
    border: none;
    color: #fff;
    padding: 4px 6px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#0059b2')};
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }

  .grid {
    display: flex;
    flex: 1;
  }

  .bloco {
    display: flex;
    justify-content: space-between;
    background: #0059b2;
    flex: 1;
    border-radius: 5px;
    padding: 16px 10px;
    color: #fff;

    .icon {
      padding-top: 8px;
    }

    h2 {
      color: #fff;
      font-size: 36px;
    }

    & + .bloco {
      background: #ff4d4d;
      margin-left: 12px;
    }
  }
`;
