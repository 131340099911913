import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';
import { Container, Button, Logo } from './styles';
import history from '~/services/history';
import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';
import api from '~/services/api';

export default function Scp() {
  const [adcnpj, setCnpj] = useState();
  const [loading, setLoading] = useState(false);
  const [diretor, setDiretor] = useState([]);
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    async function loadDiretor() {
      const response = await api.get('users?diretor=true');

      setDiretor(response.data);
    }

    loadDiretor();
  }, []);

  useEffect(() => {
    if (profile.role_id > 1) {
      toast.warning('Você não tem permissão');
      history.goBack();
    }
  }, [profile.role_id]);

  function handleCnpj(e) {
    const valor = e.target.value;
    const cnumero = valor.replace(/[^\d]+/g, '');
    const cnpjBase = cnumero.substr(0, 8);
    const cnpjFinal = cnumero.substr(12, 14);
    const scp = `${cnpjBase}000x${cnpjFinal}`;

    setCnpj(scp);
  }

  async function handleSubmit({ cnpj, data_servico }) {
    const userDiretor = document.querySelector(`.diretor`).value;

    if (!cnpj) {
      toast.error('Por favor! Preencha o campo CNPJ');
      return;
    }

    if (!data_servico) {
      toast.error('Por favor! Preencha a data do Início...');
      return;
    }

    if (!userDiretor) {
      toast.error('Por favor! Preencha o diretor responsável');
      return;
    }

    try {
      setLoading(true);

      const response = await api.post('clientes', {
        cnpj,
        status: 'Ativo',
        situacao: 8,
      });

      if (response.data.error) {
        toast.error(response.data.error);
        setLoading(false);
        return;
      }

      localStorage.setItem('cliente', JSON.stringify(response.data));

      await api.post(`clientes/${response.data.id}/executores`, {
        funcao: 'diretor',
        cargo_id: 1,
        user_id: userDiretor,
        cnpj: response.data.cnpj,
      });

      toast.success('Cliente cadastrado com sucesso!');
      setLoading(false);
      history.push(`/edit/cliente/${response.data.id}`);
    } catch (err) {
      setLoading(false);
      toast.error('Erro no cadastro ou o CNPJ já existe.');
    }
  }

  return (
    <>
      <Logo>
        <img src={logo} alt="Secran" />
      </Logo>
      <Container>
        <Form onSubmit={handleSubmit}>
          <span>Digite o CNPJ tipo SCP</span>
          <Input type="hidden" name="cnpj" value={adcnpj} />
          <InputMask
            mask="99.999.999/9999-99"
            placeholder="99.999.999/9999-99"
            onChange={handleCnpj}
          />
          <span>Data início vigência do contrato</span>
          <Input type="date" name="data_servico" />
          <span>Diretor Responsável</span>
          <select name="diretor" className="diretor">
            <option value="">...</option>
            {diretor.map(item => (
              <option value={item.id}>{item.name}</option>
            ))}
          </select>
          <Button>
            <button type="button" onClick={history.goBack}>
              Cancelar
            </button>
            <button type="submit">
              {loading ? <Carregando /> : 'Avançar'}
            </button>
          </Button>
        </Form>
      </Container>
    </>
  );
}
