import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import { Wrapper, Content, Conteudo } from './styles';

import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function Login() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <>
      <Wrapper>
        <Conteudo>
          <Content>
            <img src={logo} alt="Logo Secran" />
            <Form schema={schema} onSubmit={handleSubmit}>
              <Input name="email" type="email" placeholder="Seu Email" />
              <Input name="password" type="password" placeholder="Sua Senha" />

              <button type="submit">
                {loading ? <Carregando /> : 'Acessar'}
              </button>
            </Form>
            <p>
              <Link to="/password/create">Esqueci minha senha</Link>
            </p>
          </Content>
        </Conteudo>
      </Wrapper>
    </>
  );
}
