import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { format } from 'date-fns';

import CheckboxGroup from 'react-checkbox-group';

import { MdSave, MdDateRange, MdClear } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import {
  Content,
  Bloco,
  Grid,
  BForm,
  Modal,
  ContentModal,
  FormList,
  InputDate,
} from './styles';
import apiCnpj from '~/services/cnpj';
import api from '~/services/api';
import history from '~/services/history';

import rfb from '~/assets/logo-receita.svg';

import { usePermission } from '~/hooks/permission';
import { token_sintegra as tokenCnpj } from '~/utils/url.json';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';
import Cno from '~/components/Cno';

const useStyles = makeStyles({
  list: {
    width: 700,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Edit() {
  const classes = useStyles();
  const { id } = useParams();
  const profile = useSelector(state => state.user.profile);
  const [cliente, setCliente] = useState('');
  const [tabelas, setTabelas] = useState([]);
  const [vcep, setVcep] = useState('');
  const [vuf, setVuf] = useState([]);
  const [city, setCity] = useState([]);
  const [loadCity, setLoadCity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregaCnpj, setCarregaCnpj] = useState(false);
  const [natjuri, setNatjuri] = useState([]);
  const [sindLaboral, setSindLaboral] = useState([]);
  const [sindPatronal, setSindPatronal] = useState([]);
  const [check, setCheck] = useState([]);
  const [obrigacoes, setObrigacoes] = useState([]);
  const [loadingObr, setLoadingObr] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [funcao, setFuncao] = useState('');
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // Forms Selects Situação
  const [selectedSitucao, setSelectedSitucao] = useState('');
  const [selectedNatjuri, setSelectedNatJuri] = useState('');
  const [selectedSiMun, setSelectedSiMun] = useState('');
  const [selectedSiEst, setSelectedSiEst] = useState('');
  const [selectedNire, setSelectedNire] = useState('');
  const [selectUF, setSelectedUF] = useState('');
  const [selectCity, setSelectCity] = useState('');
  const [selectedRFB, setSelectedRFB] = useState('');
  const [selectedDTE, setSelectedDTE] = useState('');
  const [selectedLIC, setSelectedLIC] = useState('');
  const [selectedSindPatro, setSelectedSindPatro] = useState('');
  const [selectedSindLabo, setSelectedSindLabo] = useState('');
  const [countCnpj, setCountCnpj] = useState('');
  const [vencimentoDate, setVencimentoDate] = useState([]);

  const [countCno, setCountCno] = useState(0);
  const [loadCno, setLoadCno] = useState(false);
  const [btnmodal, setBtnmodal] = useState(false);

  const [loadGrupo, setLoadGrupo] = useState(true);
  const [eSocial, setEsocial] = useState([]);
  const [grupo, setGrupos] = useState([]);
  const { validarPermission } = usePermission();

  useEffect(() => {
    api.get('tb/esocial?limit=20').then(response => {
      setEsocial(response.data.data);
    });

    api.get(`count/${id}/cno`).then(response => {
      setCountCno(response.data);

      if (response.data !== 0) {
        setLoadCno(true);
      }
    });

    api.get('tb/grupoeconomico').then(response => {
      setLoadGrupo(false);
      setGrupos(response.data.data);
    });

    api.get(`tb/natureza-juridica`).then(response => {
      setNatjuri(response.data);
    });

    api.get('tb/geral').then(response => {
      setTabelas(response.data);
    });

    api.get('tb/estado').then(response => {
      setVuf(response.data);
    });

    const dadosStorage = localStorage.getItem('cliente');

    if (dadosStorage) {
      const verify = JSON.parse(dadosStorage).ativoUser;
      setCliente(JSON.parse(dadosStorage));
      setCountCnpj(JSON.parse(dadosStorage).cnpj.length);

      validarPermission({ role_id: profile.role_id, ativo: verify });
    } else {
      api.get(`clientes/${id}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
        setCountCnpj(response.data.cnpj.length);
      });
    }

    const dadosLaboral = localStorage.getItem('tbLaboral');
    const dadosPatronal = localStorage.getItem('tbPatronal');

    if (dadosPatronal) {
      setSindPatronal(JSON.parse(dadosPatronal));
    } else {
      api.get(`tb/sindicatos?search=%%&tipo=patronal`).then(response => {
        setSindPatronal(response.data);
        localStorage.setItem('tbPatronal', JSON.stringify(response.data));
      });
    }

    if (dadosLaboral) {
      setSindLaboral(JSON.parse(dadosLaboral));
    } else {
      api.get(`tb/sindicatos?search=%%&tipo=laboral`).then(response => {
        setSindLaboral(response.data);
        localStorage.setItem('tbLaboral', JSON.stringify(response.data));
      });
    }
  }, [id, profile.role_id, validarPermission]);

  const handleCep = useCallback(e => {
    const valor = e.target.value;

    setVcep(valor.replace(/[^\d]+/g, ''));
  }, []);

  const handleUf = useCallback(async e => {
    setLoadCity(true);

    const valor = e.target.value;
    const rescity = await api.get(`tb/cidade?cod=${valor}`);

    setLoadCity(false);
    setCity(rescity.data);
    setSelectedUF(valor);
  }, []);

  const handleSubmit = useCallback(
    async ({
      cnpj,
      situacao,
      razao,
      fantasia,
      nat_jur,
      insc_mun,
      sit_mun,
      insc_est,
      sit_est,
      nire,
      org_reg,
      cep,
      end,
      end_n,
      end_c,
      bairro,
      uf,
      cidade,
      postal_rfb,
      dte,
      licitacoes,
      sind_pa,
      sind_la,
      porte,
    }) => {
      try {
        setLoading(true);
        const tipo = document.querySelector('.form_tipo').value;
        const esocial = document.querySelector('.esocial').value;
        const getgrupo = document.querySelector('.grupo').value;
        const resenvio = await api.put(`clientes/${id}`, {
          cnpj,
          situacao,
          tipo,
          razao,
          fantasia,
          nat_jur,
          insc_mun,
          sit_mun,
          insc_est,
          sit_est,
          nire,
          org_reg,
          cep,
          end,
          end_n,
          end_c,
          bairro,
          uf,
          cidade,
          postal_rfb,
          dte,
          licitacoes,
          sind_pa,
          sind_la,
          porte,
          esocial,
          grupo_id: getgrupo,
        });

        setLoading(false);

        localStorage.setItem('cliente', JSON.stringify(resenvio.data));

        toast.success('Cliente atualizado com sucesso!');
      } catch (err) {
        toast.error('Erro no cadastro!');
        setLoading(false);
      }
    },
    [id]
  );

  const handleRFB = useCallback(
    async e => {
      try {
        setCarregaCnpj(true);
        const cnpjapi = await apiCnpj.get(
          `execute-api.php?token=${tokenCnpj}&cnpj=${e}&plugin=RF`
        );

        const resCnpj = cnpjapi.data;
        const naturezaJuridica = resCnpj.natureza_juridica.substr(0, 5);

        const resSituacao = tabelas.find(
          item => item.title === resCnpj.situacao
        );

        if (resCnpj.situacao === 'BAIXADA') {
          await api.post(`clientes/${id}/servicos-store`, {
            data: format(new Date(), 'yyyy-MM-dd'),
            title: 'Baixada',
          });
        }

        const resenvio = await api.put(`clientes/${id}`, {
          situacao: resSituacao.id,
          razao: resCnpj.nome,
          status: resCnpj.situacao === 'BAIXADA' ? 'Baixada' : 'Ativo',
          tipo: resCnpj.tipo,
          telefone: resCnpj.telefone || null,
          email: resCnpj.email || null,
          fantasia: resCnpj.fantasia,
          end: resCnpj.logradouro,
          end_n: resCnpj.numero,
          abertura: resCnpj.abertura,
          capital: resCnpj.capital_social,
          nat_jur: naturezaJuridica,
          end_c: resCnpj.complemento,
          cep: resCnpj.cep.replace(/[^\d]+/g, ''),
          bairro: resCnpj.bairro,
          cidade: resCnpj.municipio,
          porte: resCnpj.porte,
          uf:
            (resCnpj.uf === 'AC' && 12) ||
            (resCnpj.uf === 'AL' && 27) ||
            (resCnpj.uf === 'AM' && 13) ||
            (resCnpj.uf === 'AP' && 16) ||
            (resCnpj.uf === 'BA' && 29) ||
            (resCnpj.uf === 'CE' && 23) ||
            (resCnpj.uf === 'DF' && 53) ||
            (resCnpj.uf === 'ES' && 32) ||
            (resCnpj.uf === 'GO' && 52) ||
            (resCnpj.uf === 'MA' && 21) ||
            (resCnpj.uf === 'MG' && 31) ||
            (resCnpj.uf === 'MS' && 50) ||
            (resCnpj.uf === 'MT' && 51) ||
            (resCnpj.uf === 'PA' && 15) ||
            (resCnpj.uf === 'PB' && 25) ||
            (resCnpj.uf === 'PE' && 26) ||
            (resCnpj.uf === 'PI' && 22) ||
            (resCnpj.uf === 'PR' && 41) ||
            (resCnpj.uf === 'RJ' && 33) ||
            (resCnpj.uf === 'RN' && 24) ||
            (resCnpj.uf === 'RO' && 11) ||
            (resCnpj.uf === 'RR' && 14) ||
            (resCnpj.uf === 'RS' && 43) ||
            (resCnpj.uf === 'SC' && 42) ||
            (resCnpj.uf === 'SE' && 28) ||
            (resCnpj.uf === 'SP' && 35),
        });

        localStorage.setItem('cliente', JSON.stringify(resenvio.data));
        setCliente(resenvio.data);

        await api.post(`add/cnaes/${id}`, {
          atividades_secundarias: resCnpj.atividades_secundarias,
          atividade_principal: resCnpj.atividade_principal,
        });

        if (resCnpj.qsa.length > 1) {
          await api.post(`add/qsa/${id}`, {
            qsa: resCnpj.qsa,
          });
        }

        toast.success('Atualizado com sucesso.');

        setCarregaCnpj(false);
      } catch (err) {
        setCarregaCnpj(false);
        toast.error('Cnpj está incorreto ou algo deu errado na atualização.');
      }
    },
    [id, tabelas]
  );

  const handleVoltar = useCallback(e => {
    setCarregaCnpj(false);
    history.push(`/cliente/${e}`);
  }, []);

  const handleObr = useCallback(async () => {
    if (check.length === 0) {
      toast.warning('Por favor! Selecione uma opção abaixo.');
      return;
    }
    try {
      setLoadingBtn(true);
      await api.put(`add/obrigacoes/cliente/${id}`, {
        dados: check,
        funcao,
      });

      await api.post(`vencimento/update/obrigacoes`, {
        json: vencimentoDate,
      });

      const obrigacoesArray = obrigacoes.map(item => ({
        ...item,
        vencimentoEmp: vencimentoDate.find(venc => venc.id_obr === item.id)
          ? vencimentoDate.find(venc => venc.id_obr === item.id).vencimento
          : item.vencimentoEmp,
        vencimentoObrs: !!vencimentoDate.find(venc => venc.id_obr === item.id),
      }));

      setLoadingBtn(false);
      setObrigacoes(obrigacoesArray);
      toast.success('Obrigações atualizado com sucesso.');
      setState({ ...state, right: false });
    } catch (err) {
      setLoadingBtn(false);
      toast.error('Algo deu errado.');
    }
  }, [check, funcao, id, obrigacoes, state, vencimentoDate]);

  const handleVerificaObr = useCallback(
    async e => {
      const response = await api.get(`verifica/obrigacoes/${e}?cliente=${id}`);

      if (response.data.error) {
        toast.warning(response.data.error);
        setCheck([e, ...check]);
      }
    },
    [check, id]
  );

  const handleDate = useCallback(
    event => {
      const { id_obr, name, value, tipo } = event;

      const data = [
        ...vencimentoDate,
        { [name]: value, id, id_obr, tipo, status: funcao },
      ];

      setVencimentoDate(data);
    },
    [funcao, id, vencimentoDate]
  );

  const handleDeleteDate = useCallback(
    async ({ id_obr, status }) => {
      const object = { id_cliente: id, id_obr, status };

      try {
        const response = await api.delete('vencimento/update/obrigacoes', {
          params: object,
        });

        const percorre = obrigacoes.map(item => ({
          ...item,
          vencimentoEmp:
            item.id === id_obr ? response.data.vencimento : item.vencimentoEmp,
          vencimentoObrs: false,
        }));

        setObrigacoes(percorre);
      } catch (err) {
        toast.error(err.message);
      }
    },
    [id, obrigacoes]
  );

  const toggleDrawer = anchor => async event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!anchor.open) {
      setObrigacoes([]);
      setCheck([]);
      setFuncao('');
      setState({ ...state, right: anchor.open });
      return;
    }

    setState({ ...state, right: anchor.open });

    setLoadingObr(true);

    if (obrigacoes.length === 0) {
      try {
        const response = await api.get(
          `tb/obrigacoes?limit=100&status=${anchor.funcao}`
        );
        const resVencimentos = await api.patch('vencimento/update/obrigacoes', {
          id_cliente: [id],
          funcao: anchor.funcao,
        });

        const obrvencimentos = resVencimentos.data;

        const percorreData = response.data.data.map(item => ({
          ...item,
          vencimentoEmp: obrvencimentos.find(venc => venc.id_obr === item.id)
            ? obrvencimentos.find(venc => venc.id_obr === item.id).vencimento
            : item.vencimentoEmp,
          vencimentoObrs: !!obrvencimentos.find(
            venc => venc.id_obr === item.id
          ),
        }));

        setObrigacoes(percorreData);

        const responseObr = await api.get(
          `get/obrigacoes/${id}?status=${anchor.funcao}`
        );

        const dados = responseObr.data ? responseObr.data : [];
        setFuncao(anchor.funcao);
        setCheck(dados);
      } catch (err) {
        toast.error(err.message);
      }
    }
    setLoadingObr(false);
  };

  const list = anchor => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]:
            anchor.anchor === 'top' || anchor.anchor === 'bottom',
        })}
        role="presentation"
        onKeyDown={toggleDrawer({
          anchor: 'right',
          open: true,
        })}
      >
        <FormList>
          <h4>Obrigações ({check.length})</h4>
        </FormList>
        {loadingObr ? (
          <ListItem>Carregando...</ListItem>
        ) : (
          <>
            {obrigacoes.length > 0 && (
              <>
                <CheckboxGroup name="check" value={check} onChange={setCheck}>
                  {Checkbox => (
                    <>
                      {obrigacoes.map(item => (
                        <ListItem key={item.id}>
                          <ListItemIcon>
                            <Checkbox
                              value={item.id}
                              onClick={() => handleVerificaObr(item.id)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${item.title} - (${item.tipo})`}
                          />
                          <InputDate>
                            {item.vencimentoObrs && (
                              <button
                                title="Resetar"
                                onClick={() =>
                                  handleDeleteDate({
                                    id_obr: item.id,
                                    status: item.status,
                                  })
                                }
                              >
                                <MdClear size={11} color="#fff" />
                              </button>
                            )}
                            <input
                              type="date"
                              name="vencimento"
                              defaultValue={item.vencimentoEmp}
                              onChange={event =>
                                handleDate({
                                  id_obr: item.id,
                                  name: event.target.name,
                                  value: event.target.value,
                                  tipo: item.tipo,
                                })
                              }
                            />
                          </InputDate>
                        </ListItem>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
                <FormList>
                  <button type="button" onClick={handleObr}>
                    {loadingBtn ? 'CARREGANDO...' : 'ATUALIZAR OBRIGAÇÕES'}
                  </button>
                </FormList>
              </>
            )}
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'sem nome da empresa'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'sem nome da empresa'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu client={id} page="geral" permission={profile.cargo.subtitle} />

        <Grid>
          <Form onSubmit={handleSubmit} initialData={cliente}>
            <BForm>
              <div>
                <label>CNPJ / CPF:</label>
                <Input type="hidden" name="cnpj" />
                {countCnpj === 14 ? (
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={cliente.cnpj.replace('x', '1')}
                    disabled
                  />
                ) : (
                  <InputMask
                    mask="99.999.999-99"
                    value={cliente.cnpj}
                    disabled
                  />
                )}
              </div>
              <div>
                <label>Porte:</label>
                <Input type="text" name="porte" />
              </div>
              <div>
                <label>Situação:</label>
                <Input
                  type="hidden"
                  name="situacao"
                  value={selectedSitucao || cliente.situacao}
                />
                <select onChange={e => setSelectedSitucao(e.target.value)}>
                  <option value="">Selecione</option>
                  {tabelas.map(item => (
                    <>
                      {item.tb_name === 'cnpj' && (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={cliente.situacao === item.id || false}
                        >
                          {item.title}
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
              <div>
                <label>Tipo:</label>
                <select className="form_tipo">
                  <option value="">----</option>
                  <option
                    value="MATRIZ"
                    selected={cliente.tipo === 'MATRIZ' || false}
                  >
                    MATRIZ
                  </option>
                  <option
                    value="FILIAL"
                    selected={cliente.tipo === 'FILIAL' || false}
                  >
                    FILIAL
                  </option>
                  <option
                    value="SCP"
                    selected={cliente.tipo === 'SCP' || false}
                  >
                    SCP
                  </option>
                  <option
                    value="CPF"
                    selected={cliente.tipo === 'CPF' || false}
                  >
                    CPF
                  </option>
                  <option
                    value="DOMESTICA"
                    selected={cliente.tipo === 'DOMESTICA' || false}
                  >
                    DOMÉSTICA
                  </option>
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                <label>Razão Social:</label>
                <Input type="text" name="razao" />
              </div>
              <div>
                <label>Nome Fantasia:</label>
                <Input name="fantasia" />
              </div>
              <div>
                <label>Natureza Jurídica:</label>
                <Input
                  type="hidden"
                  name="nat_jur"
                  value={selectedNatjuri || cliente.nat_jur}
                />
                <select onChange={e => setSelectedNatJuri(e.target.value)}>
                  <option value="">Selecione</option>
                  {natjuri.map(item => (
                    <option
                      key={item.id}
                      value={item.cod}
                      selected={cliente.nat_jur === item.cod || false}
                    >
                      {item.cod} - {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                <label>Insc. Municipal:</label>
                <Input name="insc_mun" />
              </div>
              <div>
                <label>Situação:</label>
                <Input
                  type="hidden"
                  name="sit_mun"
                  value={selectedSiMun || cliente.sit_mun}
                />
                <select onChange={e => setSelectedSiMun(e.target.value)}>
                  <option value="">Selecione</option>
                  {tabelas.map(item => (
                    <>
                      {item.tb_name === 'ins_municipal' && (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={cliente.sit_mun === item.id || false}
                        >
                          {item.title}
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
              <hr />
              <div>
                <label>Insc. Estadual:</label>
                <Input name="insc_est" />
              </div>
              <div>
                <label>Situação:</label>
                <Input
                  type="hidden"
                  name="sit_est"
                  value={selectedSiEst || cliente.sit_est}
                />
                <select onChange={e => setSelectedSiEst(e.target.value)}>
                  <option value="">Selecione</option>
                  {tabelas.map(item => (
                    <>
                      {item.tb_name === 'ins_estadual' && (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={cliente.sit_est === item.id || false}
                        >
                          {item.title}
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
              <hr />
              <div>
                <label>NIRE/Registro:</label>
                <Input name="nire" />
              </div>
              <div>
                <label>Órgão de Registro:</label>
                <Input
                  type="hidden"
                  name="org_reg"
                  value={selectedNire || cliente.org_reg}
                />
                <select onChange={e => setSelectedNire(e.target.value)}>
                  <option value="">Selecione</option>
                  {tabelas.map(item => (
                    <>
                      {item.tb_name === 'reg_nire' && (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={cliente.org_reg === item.id || false}
                        >
                          {item.title}
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                <label>Cep:</label>
                {vcep ? (
                  <Input type="hidden" name="cep" value={vcep} />
                ) : (
                  <Input type="hidden" name="cep" />
                )}
                <InputMask
                  mask="99999-999"
                  value={cliente.cep}
                  onChange={handleCep}
                />
              </div>
              <div>
                <label>Logradouro:</label>
                <Input type="text" name="end" />
              </div>
              <div>
                <label>Número:</label>
                <Input type="text" name="end_n" />
              </div>
              <div>
                <label>Complemento:</label>
                <Input type="text" name="end_c" />
              </div>
              <div>
                <label>Bairro:</label>
                <Input type="text" name="bairro" />
              </div>
            </BForm>
            <BForm>
              <div>
                <label>UF:</label>
                <Input type="hidden" name="uf" value={selectUF || cliente.uf} />
                <select onChange={handleUf}>
                  <option value="">Selecione</option>
                  {vuf.map(item => (
                    <>
                      <option
                        key={item.id}
                        value={item.cod_estado}
                        selected={item.cod_estado === cliente.uf || false}
                      >
                        {item.nome}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div>
                <label>Cidade:</label>
                <Input
                  type="hidden"
                  name="cidade"
                  value={selectCity || cliente.cidade}
                />
                <select onChange={e => setSelectCity(e.target.value)}>
                  {city.length === 0 && (
                    <option>{cliente.cidade || '...'}</option>
                  )}
                  {loadCity && <option selected>carregando...</option>}
                  {city.map(item => (
                    <option key={item.id} value={item.nome}>
                      {item.nome}
                    </option>
                  ))}
                </select>
              </div>
              <hr />
              <div>
                <label>Possui Caixa Postal - RFB?:</label>
                <Input
                  type="hidden"
                  name="postal_rfb"
                  value={selectedRFB || cliente.postal_rfb}
                />
                <select onChange={e => setSelectedRFB(e.target.value)}>
                  <option>Selecione</option>
                  <option
                    value="1"
                    selected={cliente.postal_rfb === 1 || false}
                  >
                    Sim
                  </option>
                  <option
                    Value="0"
                    selected={cliente.postal_rfb === 0 || false}
                  >
                    Não
                  </option>
                </select>
              </div>
              <div>
                <label>Possui Opção DT-e?:</label>
                <Input
                  type="hidden"
                  name="dte"
                  value={selectedDTE || cliente.dte}
                />
                <select onChange={e => setSelectedDTE(e.target.value)}>
                  <option>Selecione</option>
                  <option
                    value="NÃO POSSUI"
                    selected={cliente.dte === 'NÃO POSSUI' || false}
                  >
                    NÃO POSSUI
                  </option>
                  <option
                    value="TODOS"
                    selected={cliente.dte === 'TODOS' || false}
                  >
                    TODOS
                  </option>
                  <option
                    value="SÓ FEDERAL"
                    selected={cliente.dte === 'SÓ FEDERAL' || false}
                  >
                    SÓ FEDERAL
                  </option>
                  <option
                    value="SÓ ESTADUAL"
                    selected={cliente.dte === 'SÓ ESTADUAL' || false}
                  >
                    SÓ ESTADUAL
                  </option>
                  <option
                    value="SÓ MUNICIPAL"
                    selected={cliente.dte === 'SÓ MUNICIPAL' || false}
                  >
                    SÓ MUNICIPAL
                  </option>
                  <option
                    value="SÓ FEDERAL E ESTADUAL"
                    selected={cliente.dte === 'SÓ FEDERAL E ESTADUAL' || false}
                  >
                    SÓ FEDERAL E ESTADUAL
                  </option>
                  <option
                    value="SÓ FEDERAL E MUNICIPAL"
                    selected={cliente.dte === 'SÓ FEDERAL E MUNICIPAL' || false}
                  >
                    SÓ FEDERAL E MUNICIPAL
                  </option>
                  <option
                    value="SÓ ESTADUAL E MUNICIPAL"
                    selected={
                      cliente.dte === 'SÓ ESTADUAL E MUNICIPAL' || false
                    }
                  >
                    SÓ ESTADUAL E MUNICIPAL
                  </option>
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                <label>Participa Licitação:</label>
                <Input
                  type="hidden"
                  name="licitacoes"
                  value={selectedLIC || cliente.licitacoes}
                />
                <select onChange={e => setSelectedLIC(e.target.value)}>
                  <option>Selecione</option>
                  <option
                    value="1"
                    selected={cliente.licitacoes === 1 || false}
                  >
                    Sim
                  </option>
                  <option
                    Value="0"
                    selected={cliente.licitacoes === 0 || false}
                  >
                    Não
                  </option>
                </select>
              </div>
              <div>
                <label>Sindicato Patronal:</label>
                <Input
                  type="hidden"
                  name="sind_pa"
                  value={selectedSindPatro || cliente.sind_pa}
                />
                <select onChange={e => setSelectedSindPatro(e.target.value)}>
                  <option>Selecione</option>
                  {sindPatronal.map(item => (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={item.id === cliente.sind_pa || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Sindicato Laboral:</label>
                <Input
                  type="hidden"
                  name="sind_la"
                  value={selectedSindLabo || cliente.sind_la}
                />
                <select onChange={e => setSelectedSindLabo(e.target.value)}>
                  <option>Selecione</option>
                  {sindLaboral.map(item => (
                    <option
                      key={item.id}
                      value={item.id}
                      selected={cliente.sind_la === item.id || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                <label>Fase do eSocial</label>
                <select className="esocial">
                  <option value="">...</option>
                  {eSocial.map(item => (
                    <option
                      key={item.id}
                      value={item.title}
                      selected={item.title === cliente.esocial || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </BForm>
            <BForm>
              <div>
                {loadGrupo ? (
                  'Carregando...'
                ) : (
                  <>
                    <label>Grupo Econômico</label>
                    <select className="grupo">
                      <option value="">...</option>
                      {grupo.map(item => (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={item.id === cliente.grupo || false}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </BForm>
            <BForm>
              <div>
                <div className="cno">
                  {countCno !== 0 ? (
                    <input
                      type="checkbox"
                      name="radio"
                      checked
                      onClick={() => setLoadCno(!loadCno)}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name="radio"
                      onClick={() => setLoadCno(!loadCno)}
                    />
                  )}
                  <span>Possui CNO (CEI)</span>
                </div>
              </div>
            </BForm>
            <BForm>
              {loadCno && (
                <span>
                  <button
                    type="button"
                    className="btn-cno"
                    onClick={() => setBtnmodal(true)}
                  >
                    Adicionar ou visualizar número de inscrição
                  </button>
                </span>
              )}
            </BForm>
            <BForm>
              <div className="bloco_btn">
                <div className="btn">
                  <span>
                    <button
                      type="button"
                      className="btn-editar"
                      onClick={() => handleVoltar(cliente.id)}
                    >
                      Sair do editar
                    </button>
                  </span>
                  <span>
                    <button type="submit" className="btn-save">
                      {loading ? (
                        <Carregando />
                      ) : (
                        <>
                          <MdSave size={16} color="#fff" /> Salvar
                        </>
                      )}
                    </button>
                  </span>
                  <span>
                    {countCnpj === 14 && (
                      <>
                        {cliente.tipo !== 'SCP' && (
                          <button
                            type="button"
                            onClick={() => handleRFB(cliente.cnpj)}
                            className="btn-warning"
                          >
                            {carregaCnpj ? (
                              <Carregando />
                            ) : (
                              <>
                                <img
                                  src={rfb}
                                  alt="Atualizar Via Receita Federal"
                                />
                              </>
                            )}
                          </button>
                        )}
                      </>
                    )}
                  </span>
                </div>

                {profile.cargo.subtitle === 'fiscal' && (
                  <div className="btnObrigacoes">
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'fiscal',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                    </button>
                  </div>
                )}

                {profile.cargo.subtitle === 'contabil' && (
                  <div className="btnObrigacoes">
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'contabil',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                    </button>
                  </div>
                )}

                {profile.cargo.subtitle === 'pessoal' && (
                  <div className="btnObrigacoes">
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'pessoal',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                    </button>
                  </div>
                )}

                 {profile.cargo.subtitle === 'tributos' && (
                  <div className="btnObrigacoes">
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'tributos',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                    </button>
                  </div>
                )}

                {profile.role_id === 1 && (
                  <div className="btnObrigacoes">
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'fiscal',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                      Fiscal
                    </button>
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'contabil',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                      Contábil
                    </button>
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'pessoal',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                      Pessoal
                    </button>
                    <button
                      type="button"
                      className="btn-white"
                      onClick={toggleDrawer({
                        anchor: 'right',
                        open: true,
                        funcao: 'tributos',
                      })}
                    >
                      <MdDateRange size={16} color="#000" /> Editar Obrigações
                      Tributos
                    </button>
                  </div>
                )}
              </div>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>

      <Modal modal={btnmodal}>
        <ContentModal>
          <Cno cliente={cliente.id} ativo={btnmodal} />
          <button
            type="button"
            className="btn-delete"
            onClick={() => setBtnmodal(false)}
          >
            Fechar
          </button>
        </ContentModal>
      </Modal>

      <div>
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </div>
    </>
  );
}
