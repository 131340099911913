import styled from 'styled-components';
import { darken } from 'polished';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
`;

export const Col = styled.div`
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;

  .btn-delete {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }

  form {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;

    input[type='text'],
    input[type='date'] {
      display: flex;
      flex: 1;
      height: 32px;
      border-radius: 12px;
      border: 1px solid #ccc;
      padding-left: 5px;
    }

    .btn-save {
      border: 0;
      background: #0faf69;
      color: #fff;
      border-radius: 5px;
      width: 140px;
      height: 32px;
      margin-left: 8px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      display: flex;
      flex: 1;
    }
  }
`;
