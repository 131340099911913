import React, { useState, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';

import { Wrapper, Content, Conteudo } from './styles';

import logo from '~/assets/logo.svg';
import Carregando from '~/components/Carregando/Button';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
});

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async ({ email }) => {
    setLoading(true);
    try {
      const response = await api.post('password/create', {
        email,
      });

      if (response.data.error) {
        toast.warning(response.data.error);
        setLoading(false);
        return;
      }

      toast.success(response.data.success);
      setLoading(false);
    } catch (err) {
      toast.error('algo deu errado');
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Conteudo>
          <Content>
            <img src={logo} alt="Logo Secran" />
            <Form schema={schema} onSubmit={handleSubmit}>
              <Input name="email" type="email" placeholder="Seu Email" />

              <button type="submit">
                {loading ? <Carregando /> : 'Esqueci senha'}
              </button>
            </Form>
            <p>
              <Link to="/">Voltar para login</Link>
            </p>
          </Content>
        </Conteudo>
      </Wrapper>
    </>
  );
}
