import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Navigation = styled.div`
  width: ${Props => Props.width};
  position: relative;

  .closed {
    border: 0;
    width: 22px;
    height: 22px;
    background: #022c44;
    position: absolute;
    z-index: 9;
    top: 20px;
    right: -22px;
  }

  .barraLateral {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-image: linear-gradient(to bottom, #022c44, #001f2f 130%);
    height: 100vh;
    width: ${Props => Props.width};

    ul {
      list-style: none;
      padding-top: 30px;
    }

    ul li a {
      color: #eee;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      padding: 15px 12px;
      border-bottom: 1px dotted #001622;
    }

    ul li a.active {
      background: #2a9fe5;
    }

    ul li a:hover {
      background: #2a9fe5;
      color: #fff;
    }

    li img {
      height: 20px;
      margin-right: 6px;
    }
  }

  .logout {
    border: 0;
    width: 100%;
    background: #ff2626;
    color: #fff;
    padding: 15px 12px;
    align-items: center;
    font-size: 16px;
    display: flex;
    transition: background-color 1s;

    &:hover {
      background: ${shade(0.3, '#ff2626')};
    }
  }

  .logo {
    width: 65%;
    margin: 0px auto;
    padding-top: 12px;
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9999;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }

  div {
    display: flex;

    button + button {
      margin-left: 4px;
    }
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }

  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;
