import React, { useState, useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { MdSearch } from 'react-icons/md';

import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { parseISO } from 'date-fns/esm';
import Container from '~/components/Container';

import api from '~/services/api';
import history from '~/services/history';

import { usePermission } from '~/hooks/permission';

import InputField from '~/components/FormElement/Input';
import Select from '~/components/FormElement/Select';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando';
import Pagination from '~/components/Pagination';

import { Content, Bloco, Pesquisa, Card } from './styles';

export default function Obrigacoes() {
  const profile = useSelector(state => state.user.profile);
  const [obrigacoes, setObrigacoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(0);
  const [add, setAdd] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingBtnDelete, setLoadingBtnDelete] = useState(false);
  const [state, setState] = useState({
    title: '',
    vencimento: '',
    vencimentoEmp: '',
    tipo: '',
    status: '',
    month: '',
  });
  const [total, setTotal] = useState(1);

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const limit = params.get('limit') || '40';
  const page = params.get('page') || '1';
  const paramsStatus = params.get('status') || '';

  const { admGestaoPermission } = usePermission();

  useEffect(() => {
    admGestaoPermission({ role_id: profile.role_id });
    setLoading(true);

    api
      .get(
        `tb/obrigacoes?limit=${limit}&page=${page}&get=true&status=${paramsStatus}`
      )
      .then(response => {
        const percorre = response.data.data.map(item => ({
          ...item,
          dateFormattedVencimentoEmp: format(
            parseISO(item.vencimentoEmp),
            'dd-MM-yyy'
          ),
        }));

        setTotal(response.data.last_page);
        setObrigacoes(percorre);
        setLoading(false);
      });
  }, [admGestaoPermission, profile.role_id, limit, page, paramsStatus]);

  const handleSearch = useCallback(() => {
    //
  }, []);

  const handlePageSelect = useCallback(
    ({ paginate }) => {
      history.push(`/auditorias?limit=${limit}&page=${paginate}`);
    },
    [limit]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        const ptotal = Number(page) === 1 ? 1 : Number(page) - 1;
        history.push(
          `/administrativo/obrigacoes?limit=${limit}&page=${ptotal}`
        );
        return;
      }
      const ptotal = Number(page) + 1;
      history.push(`/administrativo/obrigacoes?limit=${limit}&page=${ptotal}`);
    },
    [limit, page]
  );

  const handleEdit = useCallback(event => {
    if (!event) {
      setState({
        title: '',
        vencimento: '',
        vencimentoEmp: '',
        tipo: '',
        month: '',
      });
    }

    setEdit(event);
  }, []);

  const handleInput = useCallback(
    event => {
      const data = event.target.value;
      setState({ ...state, [event.target.name]: data });
    },
    [state]
  );

  const handleSave = useCallback(
    async event => {
      try {
        const obrigacao = obrigacoes.find(item => item.id === event);

        setLoadingBtn(true);
        const { data } = await api.put(`tb/obrigacoes/${event}`, {
          title: state.title ? state.title : obrigacao.title,
          vencimento: state.vencimento ? state.vencimento : obrigacao.date_venc,
          vencimentoEmp: state.vencimentoEmp
            ? state.vencimentoEmp
            : obrigacao.date_secran,
          tipo: state.tipo ? state.tipo : obrigacao.tipo,
          status: obrigacao.status,
        });

        const findIndex = obrigacoes.findIndex(item => item.id === event);

        obrigacoes[findIndex] = {
          ...data,
          dateFormattedVencimentoEmp: format(
            parseISO(data.vencimentoEmp),
            'dd-MM-yyy'
          ),
        };

        toast.success('Atualizado com sucesso.');

        setEdit(0);
        setState({
          title: '',
          vencimento: '',
          vencimentoEmp: '',
          tipo: '',
          month: '',
        });
        setLoadingBtn(false);
      } catch (err) {
        toast.error(err.message);
        setEdit(0);
        setState({
          title: '',
          vencimento: '',
          vencimentoEmp: '',
          tipo: '',
          month: '',
        });
        setLoadingBtn(false);
      }
    },
    [obrigacoes, state.tipo, state.title, state.vencimento, state.vencimentoEmp]
  );

  const handleCreate = useCallback(async () => {
    try {
      setLoadingBtn(true);
      const schema = Yup.object().shape({
        tipo: Yup.string().required('Por favor! Preencha o campo tipo.'),
        vencimentoEmp: Yup.string().required(
          'Por favor! Preencha o campo vencimento Empresa.'
        ),
        vencimento: Yup.string().required(
          'Por favor! Preencha o campo vencimento.'
        ),
        title: Yup.string().required('Por favor! Preencha o campo título.'),
      });

      await schema.validate(state);

      const { data } = await api.post('tb/obrigacoes', state);

      setAdd(false);
      toast.success('Obrigação adicionado.');
      setLoadingBtn(false);

      const formatData = {
        ...data,
        dateFormattedVencimentoEmp: format(
          parseISO(data.vencimentoEmp),
          'dd-MM-yyy'
        ),
      };

      setObrigacoes([formatData, ...obrigacoes]);
      setState({
        title: '',
        vencimento: '',
        vencimentoEmp: '',
        tipo: '',
        status: '',
        month: '',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        setLoadingBtn(false);
        return;
      }
      toast.error('Ops! Algo deu errado.');
    }
  }, [obrigacoes, state]);

  const handleAddCancela = useCallback(() => {
    setAdd(false);
  }, []);

  const handleDelete = useCallback(
    async event => {
      try {
        setLoadingBtnDelete(true);
        await api.delete(`tb/obrigacoes/${event}?clear=true`);
        const percorre = obrigacoes.filter(item => item.id !== event);
        setObrigacoes(percorre);

        toast.success('Deletando com sucesso.');
        setState({ title: '', vencimento: '', vencimentoEmp: '', tipo: '' });
        setLoadingBtnDelete(false);
      } catch (err) {
        toast.error(err.message);
        setLoadingBtnDelete(false);
        setState({ title: '', vencimento: '', vencimentoEmp: '', tipo: '' });
      }
    },
    [obrigacoes]
  );

  const handleLimit = useCallback(
    e => {
      setLoading(true);
      const limitTotal = e.target.value;

      history.push(
        `/administrativo/obrigacoes?limit=${limitTotal}&page=${page}&status=${paramsStatus}`
      );
    },
    [page, paramsStatus]
  );

  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Title title="Administrador / Obrigações" />
          <Bloco>
            <div>
              <h2>Administrador / Obrigações</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Search" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>

            <div className="bloco-one">
              {add ? (
                <button
                  type="button"
                  className="cancelar"
                  onClick={handleAddCancela}
                >
                  CANCELAR
                </button>
              ) : (
                <button
                  type="button"
                  className="save"
                  onClick={() => setAdd(true)}
                >
                  ADICIONAR
                </button>
              )}
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td width="40%">Título</td>
                <td>Dia do Vencimento</td>
                <td>Vencimento Empresa</td>
                <td width="8%">
                  {profile.role_id === 1 ? (
                    <span>
                      status
                      <select
                        onChange={e =>
                          history.push(
                            `/administrativo/obrigacoes?limit=${limit}&page=${page}&status=${e.target.value}`
                          )
                        }
                      >
                        <option value="">...</option>
                        <option
                          value="contabil"
                          selected={paramsStatus === 'contabil'}
                        >
                          Contábil
                        </option>
                        <option
                          value="fiscal"
                          selected={paramsStatus === 'fiscal'}
                        >
                          Fiscal
                        </option>
                        <option
                          value="tributos"
                          selected={paramsStatus === 'tributos'}
                        >
                          Tributos
                        </option>
                        <option
                          value="pessoal"
                          selected={paramsStatus === 'pessoal'}
                        >
                          Pessoal
                        </option>
                      </select>
                    </span>
                  ) : (
                    'Status'
                  )}
                </td>
                <td width="8%">Tipo</td>
                <td width="14%" align="right">
                  Ação
                </td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {add && (
                    <tr className="table-hover">
                      <td>
                        <InputField name="title" onChange={handleInput} />
                      </td>
                      <td>
                        <InputField
                          type="number"
                          name="vencimento"
                          onChange={handleInput}
                        />
                      </td>
                      <td>
                        <InputField
                          type="number"
                          name="vencimentoEmp"
                          onChange={handleInput}
                        />
                      </td>
                      <td>
                        <Select native name="status" onChange={handleInput}>
                          <option value="fiscal">Fiscal</option>
                          <option value="contabil">Contábil</option>
                          <option value="tributos">Tributos</option>
                          <option value="Pessoal">Pessoal</option>
                        </Select>
                      </td>
                      <td>
                        <Select native name="tipo" onChange={handleInput}>
                          <option value="Mensal">Mensal</option>
                          <option value="Trimestral">Trimestral</option>
                          <option value="Anual">Anual</option>
                        </Select>
                        {state.tipo === 'Anual' && (
                          <InputField
                            type="number"
                            name="month"
                            placeholder="Digite o mês"
                            onChange={handleInput}
                          />
                        )}
                      </td>
                      <td>
                        <div>
                          <button type="button" onClick={handleAddCancela}>
                            Cancelar
                          </button>
                          <button
                            type="button"
                            className="save"
                            onClick={handleCreate}
                          >
                            {loadingBtn ? 'carregando...' : 'salvar'}
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                  {obrigacoes.map(item => (
                    <tr key={item.id} className="table-hover">
                      <td>
                        {edit === item.id ? (
                          <InputField
                            name="title"
                            value={state.title ? state.title : item.title}
                            onChange={handleInput}
                          />
                        ) : (
                          item.title
                        )}
                      </td>
                      <td>
                        {edit === item.id ? (
                          <InputField
                            type="number"
                            name="vencimento"
                            value={
                              state.vencimento
                                ? state.vencimento
                                : item.date_venc
                            }
                            onChange={handleInput}
                          />
                        ) : (
                          `Dia ${item.date_venc}`
                        )}
                      </td>
                      <td>
                        {edit === item.id ? (
                          <InputField
                            type="number"
                            name="vencimentoEmp"
                            defaultValue={
                              state.vencimentoEmp
                                ? state.vencimentoEmp
                                : item.date_secran
                            }
                            onChange={handleInput}
                          />
                        ) : (
                          item.dateFormattedVencimentoEmp
                        )}
                      </td>
                      <td>
                        <div>
                          {item.status === 'fiscal' && (
                            <div className="fiscal">Fiscal</div>
                          )}
                          {item.status === 'contabil' && (
                            <div className="contabil">Contábil</div>
                          )}
                          {item.status === 'tributos' && (
                            <div className="tributos">Tributos</div>
                          )}
                          {item.status === 'pessoal' && (
                            <div className="pessoal">Pessoal</div>
                          )}
                        </div>
                      </td>
                      <td>
                        {edit === item.id ? (
                          <>
                            <Select
                              native
                              name="tipo"
                              value={state.tipo ? state.tipo : item.tipo}
                              onChange={handleInput}
                            >
                              <option value="Mensal">Mensal</option>
                              <option value="Trimestral">Trimestral</option>
                              <option value="Anual">Anual</option>
                            </Select>
                            {edit === item.id && item.tipo === 'Anual' && (
                              <InputField
                                type="number"
                                name="month"
                                defaultValue={item.month}
                                placeholder="Digite o mês"
                                onChange={handleInput}
                              />
                            )}
                            {edit === item.id && state.tipo === 'Anual' && (
                              <InputField
                                type="number"
                                name="month"
                                defaultValue={state.month}
                                placeholder="Digite o mês"
                                onChange={handleInput}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {item.tipo === 'Anual'
                              ? `Anual - Mês: ${item.month}`
                              : item.tipo}
                          </>
                        )}
                      </td>
                      <td align="right">
                        {edit === item.id ? (
                          <div>
                            <button type="button" onClick={() => handleEdit(0)}>
                              Cancelar
                            </button>
                            <button
                              type="button"
                              className="delete"
                              onClick={() => handleDelete(item.id)}
                            >
                              {loadingBtnDelete ? 'carregando...' : 'deletar'}
                            </button>
                            <button
                              type="button"
                              className="save"
                              onClick={() => handleSave(item.id)}
                            >
                              {loadingBtn ? 'carregando...' : 'salvar'}
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleEdit(item.id)}
                          >
                            Editar
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limit}
          page={page}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />
      </Container>

      <Footer />
    </>
  );
}
