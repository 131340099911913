import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt';
import { format, differenceInDays, parseISO } from 'date-fns';

import {
  MdToday,
  MdLockOutline,
  MdLockOpen,
  MdWork,
  MdPerson,
} from 'react-icons/md';
import {
  IoIosTrendingDown,
  IoIosTrendingUp,
  IoMdPodium,
  IoIosPie,
} from 'react-icons/io';

import api from '~/services/api';
import xml from '~/services/xmlApi';

import { Content, Bloco, Card, Pesquisa, Grid } from './styles';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import history from '~/services/history';
import excel from '~/assets/icons/excel-file.svg';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const setores = [
  { id: 'diretor', title: 'Diretoria' },
  { id: 'paralegal', title: 'Paralegal' },
  { id: 'contabil', title: 'Contábil' },
  { id: 'fiscal', title: 'Fiscal' },
  { id: 'tributos', title: 'Tributos' },
  { id: 'pessoal', title: 'Pessoal' },
  { id: 'consultoria', title: 'Consultoria' },
  { id: 'ti', title: 'Tecnologia da Informação' },
];

export default function Relatorio() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [search, setSearch] = useState({
    pesquisapor: '',
    setor: '',
    usuario: '',
    usergeral: '',
    tipouser: '',
    tipoatividade: '',
    atividade: '',
    dateinicio: '',
    datefim: format(new Date(), 'yyyy-MM-dd'),
  });

  const [relatorio, setRelatorio] = useState('');
  const [users, setUsers] = useState([]);
  const [userssetor, setUsersSetor] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carregaExcel, setCarregaExcel] = useState(false);
  const [openBtnExcel, setOpenBtnExcel] = useState(false);
  const [readsearch, setRead] = useState('geral');
  const [data, setData] = useState('');

  const url = new URL(window.location);

  useEffect(() => {
    async function loadRelatorio() {
      const urlParams = new URLSearchParams(url.search);
      const year = urlParams.get('year') || Number(format(new Date(), 'yyyy'));
      const month = urlParams.get('month') || Number(format(new Date(), 'MM'));

      const response = await api.get(
        `ticket?relatorio=true&year=${year}&month=${month}&readsearch=${readsearch}`
      );

      if (profile.role_id !== 1) {
        const dados = await api.get(`users?cargo=${profile.cargo.subtitle}`);

        setUsersSetor(dados.data);
      }

      const ticketsRes = response.data.ticket.map(item => ({
        ...item,
        date: differenceInDays(parseISO(item.date), new Date()),
        dateFormatted: format(parseISO(item.date), "dd 'de' MMMM 'de' yyyy", {
          locale: pt,
        }),
        dateFeito: item.date_entregue ? item.date >= item.date_entregue : false,
        dateFora: item.date_entregue ? item.date < item.date_entregue : false,
      }));

      const searchdatefim = search.datefim ? search.datefim : null;
      const dateinicio = search.dateinicio
        ? format(parseISO(search.dateinicio), "MMMM 'de' yyyy", {
            locale: pt,
          })
        : null;
      const datefim = searchdatefim
        ? format(parseISO(searchdatefim), "MMMM 'de' yyyy", {
            locale: pt,
          })
        : format(new Date(), "MMMM 'de' yyyy", {
            locale: pt,
          });
      const datevisualizacao = dateinicio
        ? `${dateinicio} de ${datefim}`
        : datefim;

      setData(datevisualizacao);
      setLoading(false);
      setRelatorio(response.data);
      setTickets(ticketsRes);
    }

    loadRelatorio();
  }, [url.search, readsearch]); //eslint-disable-line

  const handleAtividade = useCallback(
    async event => {
      const atividadeid = event.target.value;
      const monthyear = atividadeid === 'geral' ? '' : atividadeid;
      const cargo =
        profile.role_id === 1 ? search.setor : profile.cargo.subtitle;
      const dados = await api.get(
        `tb/geral?tb=demanda&funcao=${cargo}&notify=${monthyear}`
      );

      setAtividades(dados.data);
      setSearch({
        ...search,
        tipoatividade: atividadeid,
      });
    },
    [profile.cargo.subtitle, profile.role_id, search]
  );

  const handleUsers = useCallback(
    async event => {
      const pesquisa = event.target.value;
      const dados = await api.get('ticket-user', {
        params: {
          usuario: search.usuario,
          tipo: pesquisa,
          setor: search.setor,
        },
      });

      setSearch({ ...search, tipouser: pesquisa });

      setUsers(dados.data);
    },
    [search]
  );

  const handleUsersSetor = useCallback(
    async event => {
      const { value } = event.target;

      if (search.pesquisapor !== 'eu') {
        const dados = await api.get(`users?cargo=${value}`);

        setUsersSetor(dados.data);
      }

      setSearch({
        ...search,
        setor: value,
      });
    },
    [search]
  );

  const handlePesquisa = useCallback(
    event => {
      const pesquisa = event.target.value;
      if (pesquisa === 'eu') {
        setSearch({
          ...search,
          pesquisapor: pesquisa,
          usuario: profile.id,
        });
      }
      setSearch({
        ...search,
        pesquisapor: pesquisa,
      });
    },
    [profile.id, search]
  );

  const handleSearch = useCallback(async () => {
    const {
      usergeral,
      tipouser,
      usuario,
      tipoatividade,
      atividade,
      dateinicio,
      datefim,
      setor,
    } = search;

    try {
      if (profile.role_id === 1) {
        const schemaAdmin = Yup.object().shape({
          dateinicio: Yup.string().required('Escolha a data de início'),
          usergeral: Yup.string().required('Selecione o usuário'),
          tipouser: Yup.string().required('Selecione o tipo de usuário'),
          atividade: Yup.string().required('Selecione a atividade'),
          tipoatividade: Yup.string().required('Selecione tipo de atividade'),
          usuario: Yup.string().required('Selecione o usuário do setor'),
          setor: Yup.string().required('Selecione o setor'),
          pesquisapor: Yup.string().required('Selecione o pesquisar por'),
        });

        await schemaAdmin.validate(search);
      } else {
        const schemaGeral = Yup.object().shape({
          dateinicio: Yup.string().required('Escolha a data de início'),
          usergeral: Yup.string().required('Selecione o usuário'),
          usuario: Yup.string().required('Selecione o usuário do setor'),
          tipouser: Yup.string().required('Selecione o tipo de usuário'),
          atividade: Yup.string().required('Selecione a atividade'),
          tipoatividade: Yup.string().required('Selecione tipo de atividade'),
        });

        await schemaGeral.validate(search);
      }

      setLoading(true);
      const response = await api.get(`ticket`, {
        params: {
          setor,
          tipoatividade,
          atividade,
          usergeral: usergeral === 'todos' ? '' : usergeral,
          usuario,
          tipo: tipouser,
          dateinicio,
          readsearch,
          datefim: datefim || format(new Date(), 'yyyy-MM-dd'),
        },
      });

      const ticketsRes = response.data.ticket.map(item => ({
        ...item,
        date: differenceInDays(parseISO(item.date), new Date()),
        dateFormatted: format(parseISO(item.date), "dd 'de' MMMM 'de' yyyy", {
          locale: pt,
        }),
        dateFeito: item.date_entregue ? item.date >= item.date_entregue : false,
        dateFora: item.date_entregue ? item.date < item.date_entregue : false,
      }));

      const dateiniciod = format(
        parseISO(dateinicio),
        "dd 'de' MMMM 'de' yyyy",
        {
          locale: pt,
        }
      );

      const datefimd = datefim
        ? format(parseISO(datefim), "dd 'de' MMMM 'de' yyyy", {
            locale: pt,
          })
        : format(new Date(), "dd 'de' MMMM 'de' yyyy", {
            locale: pt,
          });
      const datevisualizacao = dateiniciod
        ? `${dateiniciod} a ${datefimd}`
        : datefim;

      setData(datevisualizacao);
      setLoading(false);
      setRelatorio(response.data);
      setTickets(ticketsRes);
      setOpenBtnExcel(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.warning(err.message);
        setLoading(false);
        return;
      }
      toast.error('Ops! Algo deu errado.');
      setLoading(false);
    }
  }, [profile.role_id, readsearch, search]);

  const handleExport = useCallback(async () => {
    const {
      usergeral,
      tipouser,
      usuario,
      tipoatividade,
      atividade,
      dateinicio,
      datefim,
      setor,
    } = search;

    setCarregaExcel(true);
    try {
      /* const verifyusergeral = usergeral === 'todos' ? '' : usergeral;
      console.log(
        `?setor=${setor}&tipoatividade=${tipoatividade}&atividade=${atividade}&usergeral=${verifyusergeral}&usuario=${usuario}&setorusuario=${profile.cargo.subtitle}&tipo=${tipouser}&dateinicio=${dateinicio}&datefim=${datefim}&readsearch=${readsearch}`
      ); */
      await xml
        .get(`export/ticket`, {
          params: {
            setor,
            tipoatividade,
            atividade,
            usergeral: usergeral === 'todos' ? '' : usergeral,
            usuario,
            setorusuario: profile.cargo.subtitle,
            tipo: tipouser,
            dateinicio,
            readsearch,
            datefim: datefim || format(new Date(), 'yyyy-MM-dd'),
          },
          responseType: 'blob', // important
        })
        .then(response => {
          const dataUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const date = `Ticket-${new Date()}-export.xlsx`;
          link.href = dataUrl;
          link.setAttribute('download', date); // or any other extension
          document.body.appendChild(link);
          link.click();
          setCarregaExcel(false);
        });
    } catch (err) {
      toast.error(err.message);
      setCarregaExcel(false);
    }
  }, [profile, readsearch, search]);

  return (
    <>
      <Nav page="tickets" />
      <Container>
        <User />

        <Content>
          <Title title="Tickets / Relatórios" />

          <div className="blocodiv">
            <span>Data de visualização: {data}</span>

            {openBtnExcel && (
              <button type="button" className="exportar" onClick={handleExport}>
                <img src={excel} alt="Excel" />
                Exportar EXCEL
              </button>
            )}
          </div>
        </Content>

        <Content>
          <Bloco>
            <div className="bloco">
              <Grid
                active={readsearch === 'geral'}
                onClick={() => setRead('geral')}
              >
                <div className="total">
                  <p>Total</p>
                  <span className="blue">{!loading ? relatorio.total : 0}</span>
                </div>
                <div className="icon">
                  <IoMdPodium size={68} color="#00468c" />
                </div>
              </Grid>

              <Grid
                active={readsearch === 'feitoprazo'}
                onClick={() => setRead('feitoprazo')}
              >
                <div className="total">
                  <p>Feitos no Prazo</p>
                  <span className="green">
                    {!loading ? relatorio.feitosprazo : 0}
                  </span>
                  <p className="percentage green">
                    {!loading ? relatorio.cfeitosprazo : '0%'}
                  </p>
                </div>
                <div className="icon">
                  <IoIosTrendingUp size={68} color="#36d900" />
                </div>
              </Grid>

              <Grid
                active={readsearch === 'foraprazo'}
                onClick={() => setRead('foraprazo')}
              >
                <div className="total">
                  <p>Feitos Fora no Prazo</p>
                  <span className="orange">
                    {!loading ? relatorio.feitosforaprazo : 0}
                  </span>
                  <p className="percentage orange">
                    {!loading ? relatorio.cfeitosforaprazo : '0%'}
                  </p>
                </div>
                <div className="icon">
                  <IoIosTrendingDown size={68} color="#FF7F00" />
                </div>
              </Grid>

              <Grid
                active={readsearch === 'aguardando'}
                onClick={() => setRead('aguardando')}
              >
                <div className="total">
                  <p>Total Não Feitos</p>
                  <span className="blue">{!loading ? relatorio.nao : 0}</span>
                </div>
                <div className="icon">
                  <IoIosPie size={68} color="#00468c" />
                </div>
              </Grid>
            </div>
          </Bloco>

          <Bloco>
            <div>
              <h2>Tickets / Relatório</h2>
            </div>
          </Bloco>

          <Pesquisa>
            {profile.role_id === 1 ? (
              <>
                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Pesquisar por</InputLabel>
                      <Select
                        native
                        onChange={handlePesquisa}
                        label="Pesquisar por"
                      >
                        <option aria-label="None" value="" />
                        <option value="eu">Somente Eu</option>
                        <option value="escolher">Selecionar Setor</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Escolha o setor</InputLabel>
                      <Select
                        native
                        onChange={handleUsersSetor}
                        label="Escolha o setor"
                      >
                        <option aria-label="None" value="" />
                        {setores.map(setor => (
                          <option key={setor.id} value={setor.id}>
                            {setor.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {search.pesquisapor !== 'eu' && (
                    <div className="form-input">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Usuário do Setor</InputLabel>
                        <Select
                          native
                          onChange={e =>
                            setSearch({
                              ...search,
                              usuario: e.target.value,
                            })
                          }
                          label="Usuário do Setor"
                        >
                          <option aria-label="None" value="" />
                          {userssetor.map(user => (
                            <option key={user.id} value={user.id}>
                              {user.name}
                            </option>
                          ))}
                          <option value="todos">Todos usuários</option>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </div>
                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Tipo da Atividade</InputLabel>
                      <Select
                        native
                        onChange={handleAtividade}
                        label="Tipo da Atividade"
                      >
                        <option aria-label="None" value="" />
                        <option value="mensal">Mensal</option>
                        <option value="anual">Anual</option>
                        <option value="geral">Geral</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Atividades</InputLabel>
                      <Select
                        native
                        onChange={e =>
                          setSearch({ ...search, atividade: e.target.value })
                        }
                        label="Atividades"
                      >
                        <option aria-label="None" value="" />
                        {atividades.map(atividade => (
                          <option key={atividade.id} value={atividade.id}>
                            {atividade.title}
                          </option>
                        ))}
                        <option value="todos">Pesquisar todos</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    {search.pesquisapor === 'escolher' ? (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Tipo de Usuário</InputLabel>
                        <Select
                          native
                          onChange={handleUsers}
                          label="Tipo de Usuário"
                        >
                          <option aria-label="None" value="" />
                          <option value="naosolicitante">
                            Usuário do setor sendo solicitante
                          </option>
                          <option value="solicitante">
                            Quero escolher o solicitante para o usuário do setor
                          </option>
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Tipo de Usuário</InputLabel>
                        <Select
                          native
                          onChange={e => {
                            handleUsers(e.target.value);
                            setSearch({ ...search, tipouser: e.target.value });
                          }}
                          label="Tipo de Usuário"
                        >
                          <option aria-label="None" value="" />
                          <option value="naosolicitante">
                            Eu sendo solicitante
                          </option>
                          <option value="solicitante">
                            Eu quero escolher o solicitante
                          </option>
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </div>

                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Usuários</InputLabel>
                      <Select
                        native
                        onChange={e =>
                          setSearch({ ...search, usergeral: e.target.value })
                        }
                        label="Usuários"
                      >
                        <option aria-label="None" value="" />
                        {users.map(user => (
                          <option key={user.id} value={user.usuario_write}>
                            {user.name}
                          </option>
                        ))}
                        <option value="todos">Todos</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <TextField
                      id="date"
                      label="Data Início"
                      type="date"
                      variant="outlined"
                      className={classes.formControl}
                      onChange={e =>
                        setSearch({ ...search, dateinicio: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <TextField
                      id="date"
                      label="Data Final"
                      type="date"
                      variant="outlined"
                      className={classes.formControl}
                      defaultValue={search.datefim}
                      onChange={e =>
                        setSearch({ ...search, datefim: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Usuários do Setor</InputLabel>
                      <Select
                        native
                        onChange={e =>
                          setSearch({
                            ...search,
                            usuario: e.target.value,
                          })
                        }
                        label="Usuário do Setor"
                      >
                        <option aria-label="None" value="" />
                        {userssetor.map(user => (
                          <option key={user.id} value={user.id}>
                            {user.name === profile.name
                              ? 'Sendo Eu'
                              : user.name}
                          </option>
                        ))}
                        <option value="todos">Todos usuários</option>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Tipo da Atividade</InputLabel>
                      <Select
                        native
                        onChange={handleAtividade}
                        label="Tipo da Atividade"
                      >
                        <option aria-label="None" value="" />
                        <option value="mensal">Mensal</option>
                        <option value="anual">Anual</option>
                        <option value="geral">Geral</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Atividades</InputLabel>
                      <Select
                        native
                        onChange={e =>
                          setSearch({ ...search, atividade: e.target.value })
                        }
                        label="Atividades"
                      >
                        <option aria-label="None" value="" />
                        {atividades.map(atividade => (
                          <option key={atividade.id} value={atividade.id}>
                            {atividade.title}
                          </option>
                        ))}
                        <option value="todos">Pesquisar todos</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    {search.usuario !== String(profile.id) ? (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Tipo de Usuário</InputLabel>
                        <Select
                          native
                          onChange={handleUsers}
                          label="Tipo de Usuário"
                        >
                          <option aria-label="None" value="" />
                          <option value="naosolicitante">
                            Usuário selecionado sendo solicitante
                          </option>
                          <option value="solicitante">
                            Quero escolher o solicitante para o usuário
                            selecionado
                          </option>
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Tipo de Usuário</InputLabel>
                        <Select
                          native
                          onChange={handleUsers}
                          label="Tipo de Usuário"
                        >
                          <option aria-label="None" value="" />
                          <option value="naosolicitante">
                            Eu sendo solicitante
                          </option>
                          <option value="solicitante">
                            Eu quero escolher o solicitante
                          </option>
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </div>

                <div className="bloco">
                  <div className="form-input">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel>Usuários</InputLabel>
                      <Select
                        native
                        onChange={e =>
                          setSearch({ ...search, usergeral: e.target.value })
                        }
                        label="Usuários"
                      >
                        <option aria-label="None" value="" />
                        {users.map(user => (
                          <option key={user.id} value={user.usuario_write}>
                            {user.name}
                          </option>
                        ))}
                        <option value="todos">Todos</option>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-input">
                    <TextField
                      id="date"
                      label="Data Início"
                      type="date"
                      variant="outlined"
                      className={classes.formControl}
                      onChange={e =>
                        setSearch({ ...search, dateinicio: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <TextField
                      id="date"
                      label="Data Final"
                      type="date"
                      variant="outlined"
                      className={classes.formControl}
                      defaultValue={search.datefim}
                      onChange={e =>
                        setSearch({ ...search, datefim: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="bloco-btn">
              <button type="button" onClick={handleSearch}>
                PESQUISAR AGORA
              </button>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td width="4%">#ID</td>
                <td width="16%">Cliente</td>
                <td width="11.42%">Titulo</td>
                <td width="11.42%">
                  <span>Contagem de Prazo</span>
                </td>
                <td width="11.42%">
                  <span>Nível</span>
                </td>
                <td width="11.42%">
                  <span>Sigilo</span>
                </td>
                <td width="11.42%">
                  <span>Setor</span>
                </td>
                <td width="11.42%">
                  <span>Responsável</span>
                </td>
                <td width="11.42%">Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {tickets.map(item => (
                    <tr>
                      <td>#{item.id}</td>
                      <td>
                        <div>
                          {item.cliente_id
                            ? item.cliente.razao
                            : item.cliente_sec}
                          {!item.cliente_id && (
                            <span className="btncliente">Provisório</span>
                          )}
                        </div>
                      </td>
                      <td>{item.activity.title}</td>
                      <td>
                        {item.date < 0 ? (
                          <div className="expirado">
                            <MdToday size={14} color="#000" />{' '}
                            {item.dateFormatted}
                          </div>
                        ) : (
                          <div className="faltaDays">
                            {item.date === 0 ? (
                              'é hoje'
                            ) : (
                              <>
                                {item.date === 1
                                  ? 'é amanhã'
                                  : `Faltam ${item.date} dias`}
                              </>
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {item.nivel === 'alto' && (
                          <div className="alto">Nível Alto</div>
                        )}
                        {item.nivel === 'media' && (
                          <div className="media">Nível Média</div>
                        )}
                        {item.nivel === 'baixa' && (
                          <div className="baixa">Nível Baixo</div>
                        )}
                      </td>
                      <td>
                        {item.sigilo ? (
                          <div className="sigiloClosed">
                            <MdLockOutline size={14} color="#fff" /> Fechado
                          </div>
                        ) : (
                          <div className="sigiloOpen">
                            <MdLockOpen size={14} color="#fff" /> Aberto
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="setor">
                          <MdWork size={14} color="#fff" /> {item.setor}
                        </div>
                      </td>
                      <td>
                        {item.usuario === profile.id ? (
                          <div className="usuarioVc">
                            <MdPerson size={14} color="#fff" /> Você é
                            responsável
                          </div>
                        ) : (
                          <div className="usuario">
                            <MdPerson size={14} color="#fff" /> {item.username}
                          </div>
                        )}
                      </td>
                      <td align="right">
                        {item.dateFeito && (
                          <button
                            type="button"
                            className="btn-success"
                            onClick={() =>
                              history.push(`/show/ticket/${item.token}`)
                            }
                          >
                            Chamado Finalizado
                          </button>
                        )}
                        {item.dateFora && (
                          <button
                            type="button"
                            className="btn-warning"
                            onClick={() =>
                              history.push(`/show/ticket/${item.token}`)
                            }
                          >
                            Chamado Finalizado
                          </button>
                        )}
                        {item.read === 'aguardando' && (
                          <button
                            type="button"
                            className="btn-blue"
                            onClick={() =>
                              history.push(`/show/ticket/${item.token}`)
                            }
                          >
                            Aguardando Chamado
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
      </Container>

      <Backdrop className={classes.backdrop} open={carregaExcel}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Footer />
    </>
  );
}
