import React, { useEffect, useState } from 'react';

import { getDaysInMonth } from 'date-fns';

import { Container, Head, Calendar, Footer } from './styles';

const MonthDays = ({ title, value, handleClickDay, handleClickAlls }) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    const dateFormatted = getDaysInMonth(new Date());
    const daysFormatted = [];

    for (let i = 0; i < dateFormatted; i++) {
      daysFormatted.push(i + 1);
    }

    setDays(daysFormatted);
  }, []);

  return (
    <Container>
      <Head>{title}</Head>
      <Calendar>
        {days.map(day => (
          <button
            key={day}
            className={`grid-item ${Number(value) === day ? 'active' : ''}`}
            onClick={() => handleClickDay(day)}
          >
            {day}
          </button>
        ))}
      </Calendar>
      <Footer>
        <button type="button" onClick={() => handleClickAlls('')}>
          VISUALIZAR GERAL
        </button>
      </Footer>
    </Container>
  );
};

export default MonthDays;
