import React from 'react';

import { Link } from 'react-router-dom';

import { Container, Content, Bloco, Card } from './styles';
import Nav from '~/components/Navigation';
import User from '~/components/User';

export default function Administrativo() {
  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Bloco>
            <div>
              <h2>Administrativo</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <ul>
            <li>
              <Link to="/permissoes">Permissões</Link>
            </li>
            <li>
              <Link to="/setores">Setores</Link>
            </li>
            <li>
              <Link to="/usuarios">Usuários</Link>
            </li>
            <li>
              <Link to="/auditorias">Auditorias</Link>
            </li>
            <li>
              <Link to="/administrativo/obrigacoes">Obrigações</Link>
            </li>
            <li>
              <Link to="/itens">Itens</Link>
            </li>
          </ul>
        </Card>
      </Container>
    </>
  );
}
