import React from 'react';

import Lottie from 'lottie-react-web';
import animation from '~/assets/7774-loading.json';

import { Div } from './styles';

export default function Tabela() {
  return (
    <Div>
      <Lottie
        options={{
          animationData: animation,
        }}
      />
    </Div>
  );
}
