import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles({
  root: {
    width: '100%',
    flex: 1,
  },
  container: {
    maxHeight: 600,
  },
});

export default function StickyHeadTable({ children }) {
  const classes = useStyles();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const checkParse = localStorage.getItem('@GFACIL:navigation');
    const check = JSON.parse(checkParse);

    let resultado = 0;

    if (check) {
      resultado = 48 + 40;
    } else {
      resultado = 180 + 40;
    }

    const widthCalc = window.innerWidth - resultado;

    setWidth(widthCalc);
  }, []);

  return (
    <div style={{ width: '100%' }} id="container">
      <Paper className={classes.root}>
        <TableContainer
          className={classes.container}
          style={{ maxWidth: width }}
        >
          <Table stickyHeader aria-label="sticky table">
            {children}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
