import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { MdSearch, MdDelete, MdControlPoint, MdEdit } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  Container,
  Content,
  Bloco,
  Card,
  Pagination,
  Pesquisa,
  Qtd,
  Modal,
  ContentModal,
} from './styles';

import { usePermission } from '~/hooks/permission';

import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando';

export default function Permissoes() {
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [idRole, setIdRole] = useState('');
  const [loading, setLoading] = useState(true);
  const profile = useSelector(state => state.user.profile);
  const { adminAcessoPermission } = usePermission();

  useEffect(() => {
    adminAcessoPermission({ role_id: profile.role_id });
    async function loadSetor() {
      const response = await api.get('roles');

      setLoading(false);
      setRoles(response.data.data);
    }

    loadSetor();
  }, [adminAcessoPermission, profile.role_id]);

  function handleSearch() {}

  const handleModal = useCallback(e => {
    if (e) {
      setModal(true);
      setIdRole(e);
      return;
    }
    setModal(false);
    setIdRole('');
  }, []);

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`cargos/${id}`);

        toast.success('Deletado com sucesso.');
        setModal(false);
        setRoles(roles.filter(item => item.id !== id));
      } catch (err) {
        setModal(false);
        toast.error('Algo deu errado no delete.');
      }
    },
    [roles]
  );

  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Title title="Administrador / Permissões" />
          <Bloco>
            <div>
              <h2>Permissões</h2>
            </div>
            <Link to="/adicionar/permissao">
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Search" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Title</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {roles.map(role => (
                    <tr className="table-hover">
                      <td>{role.title}</td>
                      <td>
                        <div>
                          <Link to={`/edit/role/${role.id}`}>
                            <MdEdit size={20} color="#fff" />
                          </Link>
                          <button
                            type="button"
                            onClick={() => handleModal(role)}
                          >
                            <MdDelete size={20} color="#fff" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <Qtd>
            <span>Página de:</span>
            <select onChange={() => {}}>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </select>
          </Qtd>
        </Pagination>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          <h4>Aviso!</h4>
          <p>
            Você deseja raelmente deletar
            <br />o setor: <strong>{idRole.title}?</strong>
          </p>
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModal()}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-save"
              onClick={() => handleDelete(idRole.id)}
            >
              Sim
            </button>
          </div>
        </ContentModal>
      </Modal>
    </>
  );
}
