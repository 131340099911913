import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { useParams } from 'react-router-dom';
import { MdEdit, MdSave, MdDelete } from 'react-icons/md';

import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

const aliqOption = [
  { id: 0, title: 'Não se aplica' },
  { id: 1.5, title: '1,5%' },
  { id: 2, title: '2%' },
  { id: 3, title: '3%' },
  { id: 3.5, title: '4.5%' },
];

const ratOption = [
  { id: 1, title: '1' },
  { id: 2, title: '2' },
  { id: 3, title: '3' },
];

export default function Tributacao() {
  const profile = useSelector(state => state.user.profile);
  const [cliente, setCliente] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [btnEdit, setBtnEdit] = useState(true);
  const [loadDados, setLoadDados] = useState(true);
  const [carrega, setCarrega] = useState(false);

  const [carregaRural, setCarregaRural] = useState(false);
  const [loadingRural, setLoadingRural] = useState(true);
  const [fpas, setFpas] = useState([]);
  const [gps, setGps] = useState([]);
  const [prev, setPrev] = useState([]);
  const [tabfederal, setTabFederal] = useState([]);
  const [tabestadual, setTabEstadual] = useState([]);
  const [tabmunicipal, setTabMunicipal] = useState([]);

  const [tributacoes, setTributacoes] = useState([]);

  const { validarPermission } = usePermission();

  const { idCliente } = useParams();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    const tabelaGeral = {
      id: 0,
      title: '...',
    };

    api.get('tb/geral?tb=regras_tr_federal').then(response => {
      setTabFederal([...response.data, tabelaGeral]);
    });

    api.get('tb/geral?tb=regras_tr_estadual').then(response => {
      setTabEstadual([...response.data, tabelaGeral]);
    });

    api.get('tb/geral?tb=regras_tr_municipal').then(response => {
      setTabMunicipal([...response.data, tabelaGeral]);
    });

    api.get(`clientes/${idCliente}/tributacoes`).then(response => {
      const percorretrib = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setLoadDados(false);
      setTributacoes(percorretrib);
    });

    api.get('tb/previdenciario?limit=100').then(response => {
      setPrev(response.data.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  useEffect(() => {
    if (!carregaRural) {
      return;
    }

    async function loadFpas() {
      const response = await api.get('tb/fpas?limit=100');
      const resGps = await api.get('tb/gps?limit=220');

      setLoadingRural(false);
      setFpas(response.data.data);
      setGps(resGps.data.data);
    }

    loadFpas();
  }, [carregaRural]);

  async function handleSubmit({
    data_virg,
    federal,
    estadual,
    municipal,
    previ,
    aliq,
    rat,
    fap,
    entidades,
  }) {
    const regime = document.querySelector('.formregime').value;

    setCarrega(true);

    try {
      await api.post(`clientes/${idCliente}/tributacoes`, {
        data_virg,
        federal,
        estadual,
        municipal,
        previ,
        aliq,
        rat,
        fap,
        regime,
        entidades,
      });

      const percorreData = await api.get(`clientes/${idCliente}/tributacoes`);

      document.getElementById('form_reset').reset();
      setCarrega(false);

      toast.success('Atualizado com sucesso.');
      setTributacoes(
        percorreData.data.data.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.updated_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }))
      );
    } catch (err) {
      setCarrega(false);
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handlePart({ part_trib, envio }) {
    if (!envio) {
      return;
    }

    try {
      const parttrib = await api.post(
        `clientes/${idCliente}/tributacoes-part-trib`,
        {
          part_trib,
        }
      );

      setEnabled(true);
      setBtnEdit(true);
      localStorage.setItem('cliente', JSON.stringify(parttrib.data));

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handleUpdate({ idTrib, data_virg, fap, entidades }) {
    const valFederal = document.querySelector(`.form_federal${idTrib}`).value;
    const valEstadual = document.querySelector(`.form_estadual${idTrib}`).value;
    const valMunicipal = document.querySelector(`.form_municipal${idTrib}`)
      .value;
    const valPrev = document.querySelector(`.form_previ${idTrib}`).value;
    const valAliq = document.querySelector(`.form_aliq${idTrib}`).value;
    const valRat = document.querySelector(`.form_rat${idTrib}`).value;

    try {
      await api.post(`update/${idCliente}/tributacoes?id=${idTrib}`, {
        data_virg,
        federal: valFederal,
        estadual: valEstadual,
        municipal: valMunicipal,
        previ: valPrev,
        aliq: valAliq,
        rat: valRat,
        fap,
        entidades,
      });

      toast.success('atualizado com sucesso!');
    } catch (err) {
      toast.error('Erro no update');
    }
  }

  async function handleDelete(id) {
    try {
      const tribdel = await api.delete(
        `delete/cliente/tributacoes?delete=${id}`
      );

      toast.success(tribdel.data.success);
      setTributacoes(tributacoes.filter(item => item.id !== id));
    } catch (err) {
      toast.error('Erro no delete.');
    }
  }

  function handleAtiva() {
    setEnabled(false);
    setBtnEdit(false);
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="tributacoes"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handlePart} initialData={cliente}>
            <BForm>
              <span>
                <label>Particularidades Tributárias</label>
                <Input
                  name="part_trib"
                  className="textarea"
                  multiline
                  disabled={enabled}
                />
                {btnEdit ? (
                  <button
                    type="button"
                    className="btn-editar"
                    onClick={handleAtiva}
                  >
                    <MdEdit size={16} color="#000" /> Editar
                  </button>
                ) : (
                  <>
                    <Input type="hidden" name="envio" value="ativo" />
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                  </>
                )}
              </span>
            </BForm>
          </Form>
        </Grid>

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Data Vigência</label>
                <Input type="date" name="data_virg" />
              </span>
              <span>
                <label>Regime de Apuração</label>
                <select className="formregime">
                  <option>...</option>
                  <option value="REGIME DE CAIXA">REGIME DE CAIXA</option>
                  <option value="REGIME DE COMPETÊNCIA">
                    REGIME DE COMPETÊNCIA
                  </option>
                </select>
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Federal</label>
                <Select name="federal" options={tabfederal} />
              </span>
              <span>
                <label>Estadual</label>
                <Select name="estadual" options={tabestadual} />
              </span>
              <span>
                <label>Municipal</label>
                <Select name="municipal" options={tabmunicipal} />
              </span>
              <span>
                <label>Previdenciário</label>
                <Select name="previ" options={prev} />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Alíq. s/ Faturam. (CPRB)</label>
                <Select name="aliq" options={aliqOption} />
              </span>
              <span>
                <label>Rat</label>
                <Select name="rat" options={ratOption} />
              </span>
              <span>
                <label>Fap</label>
                <Input type="text" name="fap" />
              </span>
              <span>
                <label>Outras Entidades</label>
                <Input type="text" name="entidades" />
              </span>
              <span>
                <label>Produtor Rural?</label>
                <select
                  onChange={e => setCarregaRural(e.target.value === 'SIM')}
                >
                  <option value="NÃO">NÃO</option>
                  <option value="SIM">SIM</option>
                </select>
              </span>
            </BForm>

            {carregaRural && (
              <>
                {loadingRural ? (
                  <BForm>Carregando...</BForm>
                ) : (
                  <>
                    <BForm>
                      <span>
                        <label>
                          FPAS: Fundo da Previdência e Assistência Social.
                        </label>
                        <select className="fpas">
                          <option value="">...</option>
                          {fpas.map(item => (
                            <option key={item.id} value={item.cod}>
                              {item.cod}
                            </option>
                          ))}
                        </select>
                      </span>
                    </BForm>
                    <BForm>
                      <span>
                        <label>GPS: Guia da Previdência Social.</label>
                        <select className="gps">
                          <option value="">...</option>
                          {gps.map(item => (
                            <option key={item.id} value={item.cod}>
                              {item.cod} - {item.title}
                            </option>
                          ))}
                        </select>
                      </span>
                    </BForm>
                  </>
                )}
              </>
            )}
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {carrega ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {tributacoes.length > 0 && (
          <>
            {loadDados ? (
              'carregando...'
            ) : (
              <>
                {tributacoes.map(item => (
                  <GridT key={item.id}>
                    <h4>{item.regime}</h4>
                    <Form onSubmit={handleUpdate} initialData={item}>
                      <Input type="hidden" name="idTrib" value={item.id} />
                      <BForm>
                        <span>
                          <label>Data Vigência</label>
                          <Input
                            type="date"
                            name="data_virg"
                            disabled={profile.cargo.subtitle !== 'pessoal'}
                          />
                        </span>
                        <span>
                          <label>Federal</label>
                          <select
                            className={`form_federal${item.id}`}
                            disabled={
                              profile.cargo.subtitle !== 'fiscal' &&
                              profile.cargo.subtitle !== 'tributos'
                            }
                          >
                            {tabfederal.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.federal || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                        <span>
                          <label>Estadual</label>
                          <select
                            className={`form_estadual${item.id}`}
                            disabled={
                              profile.cargo.subtitle !== 'fiscal' &&
                              profile.cargo.subtitle !== 'tributos'
                            }
                          >
                            {tabestadual.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.estadual || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                        <span>
                          <label>Municipal</label>
                          <select
                            className={`form_municipal${item.id}`}
                            disabled={
                              profile.cargo.subtitle !== 'fiscal' &&
                              profile.cargo.subtitle !== 'tributos'
                            }
                          >
                            {tabmunicipal.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.municipal || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                        <span>
                          <label>Previdenciário</label>
                          <select
                            className={`form_previ${item.id}`}
                            disabled={profile.cargo.subtitle !== 'pessoal'}
                          >
                            {prev.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.previ || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                      </BForm>
                      <BForm>
                        <span>
                          <label>Alíq. s/ Faturam. (CPRB)</label>
                          <select
                            className={`form_aliq${item.id}`}
                            disabled={profile.cargo.subtitle !== 'pessoal'}
                          >
                            {aliqOption.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.aliq || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                        <span>
                          <label>Rat</label>
                          <select
                            className={`form_rat${item.id}`}
                            disabled={profile.cargo.subtitle !== 'pessoal'}
                          >
                            {ratOption.map(tab => (
                              <option
                                key={tab.id}
                                value={tab.id}
                                selected={tab.id === item.rat || false}
                              >
                                {tab.title}
                              </option>
                            ))}
                          </select>
                        </span>
                        <span>
                          <label>Fap</label>
                          <Input
                            type="text"
                            name="fap"
                            disabled={profile.cargo.subtitle !== 'pessoal'}
                          />
                        </span>
                        <span>
                          <label>Outras Entidades</label>
                          <Input type="text" name="entidades" />
                        </span>
                      </BForm>
                      {item.fpas && (
                        <>
                          <BForm>
                            <span>
                              <label>
                                FPAS: Fundo da Previdência e Assistência Social.
                              </label>
                              <Input
                                type="text"
                                name="fpas"
                                disabled={profile.cargo.subtitle !== 'pessoal'}
                              />
                            </span>
                          </BForm>
                          <BForm>
                            <span>
                              <label>GPS: Guia da Previdência Social.</label>
                              <Input
                                type="text"
                                name="gps"
                                disabled={profile.cargo.subtitle !== 'pessoal'}
                              />
                            </span>
                          </BForm>
                        </>
                      )}
                      <BForm>
                        <span>
                          <p>
                            Adicionado em {item.dateFormatted} por{' '}
                            <strong>{item.user.name}</strong>
                          </p>
                        </span>
                      </BForm>
                      <BForm>
                        <span>
                          <ButtonView>
                            <button type="submit" className="btn-save">
                              <MdSave size={16} color="#fff" /> Salvar
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDelete(item.id)}
                              className="btn-delete"
                            >
                              <MdDelete size={16} color="#fff" /> Deletar
                            </button>
                          </ButtonView>
                        </span>
                      </BForm>
                    </Form>
                  </GridT>
                ))}
              </>
            )}
          </>
        )}

        <Footer />
      </Container>
    </>
  );
}
