import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .blocoavatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }

  h4 {
    width: 100%;
    text-align: center;
    border-bottom: 1px dotted #ccc;
    padding-bottom: 6px;
  }

  .bloco {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 8px;
    width: 100%;

    .grid {
      display: flex;
      align-items: center;
      flex: 1;
      background: #fff;
      border-radius: 6px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 15px;
      padding-right: 15px;
      justify-content: space-between;

      .total {
        display: block;

        .percentage {
          font-size: 18px;
          font-weight: bold;

          .red {
            color: #ff3e3e;
          }

          .black {
            color: #222222;
          }

          .green {
            color: #00b285;
          }

          .orange {
            color: #ff7f00;
          }

          .blue {
            color: #00468c;
          }
        }

        p {
          font-size: 12px;
        }

        span {
          font-size: 34px;
          font-weight: bold;
        }
      }

      .totalprazo {
        p {
          font-size: 9px;
        }

        span {
          font-size: 18px;
        }
      }

      .blue {
        color: #00468c;
      }

      .green {
        color: #36d900;
      }

      .orange {
        color: #ff7f00;
      }

      .red {
        color: #ff3e3e;
      }
    }
  }
`;

export const GridButton = styled.button`
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 4px;
  justify-content: space-between;
  padding: 16px 10px;
  border: 0;
  text-align: left;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }

  & + button {
    margin-left: 6px;
  }

  .divisao {
    display: flex;
    flex-direction: column;
    width: 100%;

    .total {
      display: flex;
      flex-direction: column;

      p {
        font-size: 12px;
      }

      span {
        font-size: 32px;
        font-weight: bold;
      }
    }
  }
`;

export const ContentGrid = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  .table {
    display: block;
    width: 100%;

    table {
      width: 100%;
    }
  }
`;

export const Search = styled.div`
  display: flex;
  padding-right: 20px;
  margin-top: 12px;

  .btnModalMonthYear {
    background: #008c46;
    color: #fff;
    padding: 8px 6px;
    border: 0;
    border-radius: 4px;
    margin-left: 4px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#008C46')};
    }
  }

  .form {
    display: flex;
    font-size: 12px;
    align-items: center;

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .conteudo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      background: #00b259;
      color: #fff;
      border-radius: 5px;
      margin-right: 22px;
      padding: none;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;

      &:hover {
        background: ${darken(0.03, '#0FAF69')};
      }
    }
  }
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }

  .bloco {
    display: flex;
    margin-top: 14px;
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        font-size: 12px;
        background: #008c69;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;

export const Popup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .conteudo {
    display: flex;

    h4 {
      padding-bottom: 12px;
    }

    button {
      background: #00b285;
      padding: 12px;
      color: #fff;
      border-radius: 5px;
      border: 0;

      &:hover {
        background: #009570;
      }
    }

    button + button {
      margin-left: 4px;
    }
  }
`;

export const DivGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .bg-red {
    background: #ff4d4d;
    color: #fff;
  }

  .bg-orange {
    background: #d96d00;
    color: #fff;
  }

  .bg-green {
    background: #00b259;
    color: #fff;
  }

  .bg-black {
    background: #353535;
    color: #fff;
  }

  .grid {
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    justify-content: space-between;
    padding: 16px 10px;
    margin-left: 6px;

    .divisao {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 6px;

      .total {
        display: flex;
        flex-direction: column;

        p {
          font-size: 12px;
        }

        span {
          font-size: 32px;
          font-weight: bold;
        }
      }
    }
  }

  .grid + .grid {
    margin-left: 12px;
  }
`;
