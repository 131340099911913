import React, { useEffect, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { urlFile } from '~/utils/url.json';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';

import { Content, Card, Bloco } from './styles';

const UploadsShowCliente = () => {
  const { protocol } = useParams();
  const [upload, setUpload] = useState('');
  const [cliente, setCliente] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUploads() {
      const response = await api.get(`clientes-uploads/${protocol}`);

      setCliente(response.data.cliente);
      setUpload(response.data);
      setFiles(response.data.files);
      setLoading(false);
    }

    loadUploads();
  }, [protocol]);

  const handleDownload = useCallback(image => {
    const dataUrl = window.URL.createObjectURL(new Blob([image]));
    const link = document.createElement('a');
    const imageDownload = `imagem-download.jpg`;
    link.href = dataUrl;
    link.setAttribute('download', imageDownload); // or any other extension
    document.body.appendChild(link);
    link.click();
  }, []);

  return (
    <>
      <Nav page="clientes" />
      <Container>
        <User />

        <Content>
          <Title
            title={`Clientes / Uploads / ${cliente.razao} / ${upload.name}`}
          />
          <Bloco>
            <div>
              <h2>
                Clientes / Uploads / {cliente.razao} / {upload.name}
              </h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td width="6%">ID</td>
                <td width="6%">Extensão</td>
                <td width="38%">Arquivo</td>
                <td>Data da Postagem</td>
                <td>Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {files.map(file => {
                    return (
                      <tr key={file.id}>
                        <td>{file.id}</td>
                        <td>{file.type}</td>
                        <td>{file.name}</td>
                        <td>{file.created_at}</td>
                        <td align="right">
                          {file.type === 'jpg' && (
                            <a
                              href={`${urlFile}${file.file}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar na Web
                            </a>
                          )}
                          {file.type === 'jpeg' && (
                            <a
                              href={`${urlFile}${file.file}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar na Web
                            </a>
                          )}
                          {file.type === 'png' && (
                            <a
                              href={`${urlFile}${file.file}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar na Web
                            </a>
                          )}
                          {file.type === 'pdf' && (
                            <a
                              href={`${urlFile}${file.file}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar na Web
                            </a>
                          )}
                          <button
                            type="button"
                            onClick={() =>
                              handleDownload(`${urlFile}${file.file}`)
                            }
                          >
                            Baixar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default UploadsShowCliente;
