import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Socios({ dados }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.data_protocolo
      ? format(parseISO(item.data_protocolo), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
    dateIngresso: item.data_ingre
      ? format(parseISO(item.data_ingre), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid>
      {data.map(item => (
        <div key={item.id} className="content">
          <div className="tabelas">
            <div>
              <strong>
                Possui Sócio Residente / Estabelecido no Exterior ou Possui no
                Ano Anterior?{' '}
              </strong>{' '}
              {item.pais === 23 ? 'Não' : 'Sim'}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Nome: </strong> {item.nome}
            </div>
            <div>
              <strong>Data Nascimento: </strong> {item.data_nas}
            </div>
            <div>
              <strong>CPF/CNPJ: </strong> {item.cpf_cnpj}
            </div>
            <div>
              <strong>País de Residência: </strong>
              {item.pais}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Qualificação: </strong> {item.quali}
            </div>
            <div>
              <strong>Data Ingresso: </strong> {item.dateIngresso}
            </div>
            <div>
              <strong>Data Saída: </strong> {item.data_saida}
            </div>
            <div>
              <strong>Representante Legal: </strong> {item.repre_legal}
            </div>
          </div>
          <div className="tabelas">
            <div>
              <strong>Período de Mandato: </strong> {item.first_mand} até{' '}
              {item.end_mand}
            </div>
            <div>
              <strong>Data limite protocolado pela Secran: </strong>{' '}
              {item.dateFormatted}
            </div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Socios.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Socios.defaultProps = {
  dados: [],
};
