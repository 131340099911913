import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { MdSearch, MdDelete, MdControlPoint, MdEdit } from 'react-icons/md';
import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  Content,
  Bloco,
  Card,
  Pagination,
  Pesquisa,
  Qtd,
  Modal,
  ContentModal,
  PageVer,
} from './styles';

import { usePermission } from '~/hooks/permission';

import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando';

export default function Setores() {
  const [setores, setSetores] = useState([]);
  const [modal, setModal] = useState(false);
  const [idSetor, setIdSetor] = useState('');
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);
  const [limitView, setLimitView] = useState(10);
  const [pageView, setPageView] = useState(1);

  const profile = useSelector(state => state.user.profile);
  const url = new URL(window.location);
  const { administradorPermission } = usePermission();

  useEffect(() => {
    administradorPermission({ role_id: profile.role_id });

    async function loadSetor() {
      const params = new URLSearchParams(url.search);
      const limit = params.get('limit') || 10;
      const page = params.get('page') || 1;

      const response = await api.get(
        `cargos?search=%%&limit=${limit}&page=${page}`
      );

      setLoading(false);

      setLimitView(limit);
      setPageView(Number(page));

      setSetores(response.data.data);

      setTotal(response.data.last_page);
    }

    loadSetor();
  }, [administradorPermission, profile.role_id, url.search]);

  function handleSearch() {}

  function handleModal(e) {
    if (e) {
      setModal(true);
      setIdSetor(e);
      return;
    }
    setModal(false);
    setIdSetor('');
  }

  function handleLimit(e) {
    const limit = e.target.value;

    setLimitView(limit);
    setPageView(1);
    history.push(`/setores?limit=${limit}&page=1`);
  }

  function handlePage(e) {
    if (e === 'prev') {
      const ptotal = pageView === 1 ? 1 : pageView - 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(`/setores?limit=${limitView}&page=${ptotal}`);
      return;
    }
    const ptotal = Number(pageView) + 1;
    setPageView(ptotal);
    setLimitView(limitView);
    history.push(`/setores?limit=${limitView}&page=${ptotal}`);
  }

  function handlePageSelect({ page }) {
    setPageView(page);
    setLimitView(limitView);
    history.push(`/setores?limit=${limitView}&page=${page}`);
  }

  async function handleDelete(id) {
    try {
      await api.delete(`cargos/${id}`);

      toast.success('Deletado com sucesso.');
      setModal(false);
      setSetores(setores.filter(item => item.id !== id));
    } catch (err) {
      setModal(false);
      toast.error('Algo deu errado no delete.');
    }
  }

  return (
    <>
      <Nav page="administracao" />
      <Container>
        <User />

        <Content>
          <Title title="Administrador / Setores" />
          <Bloco>
            <div>
              <h2>Setores</h2>
            </div>
            <Link to="/adicionar/setor">
              <MdControlPoint size={16} color="#fff" /> Adicionar
            </Link>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="search">
                <Form onSubmit={handleSearch}>
                  <Input name="search" placeholder="Search" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Title</td>
                <td>Atualizado por</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Carregando />
              ) : (
                <>
                  {setores.map(setor => (
                    <tr className="table-hover">
                      <td>{setor.title}</td>
                      <td>{setor.user.name}</td>
                      <td>
                        {profile.role_id === 1 && (
                          <div>
                            <Link to={`/edit/setor/${setor.id}`}>
                              <MdEdit size={20} color="#fff" />
                            </Link>
                            <button
                              type="button"
                              onClick={() => handleModal(setor)}
                            >
                              <MdDelete size={20} color="#fff" />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <Qtd>
            <span>Página de:</span>
            <select onChange={handleLimit}>
              <option value="10" selected={limitView === '10' || false}>
                10
              </option>
              <option value="20" selected={limitView === '20' || false}>
                20
              </option>
              <option value="40" selected={limitView === '40' || false}>
                40
              </option>
              <option value="60" selected={limitView === '60' || false}>
                60
              </option>
              <option value="80" selected={limitView === '80' || false}>
                80
              </option>
              <option value="100" selected={limitView === '100' || false}>
                100
              </option>
            </select>
          </Qtd>
          <PageVer>
            {total > 1 && (
              <>
                <Form onSubmit={handlePageSelect}>
                  <span>
                    Página de {pageView} para {total}
                  </span>
                  <Input name="page" />
                  <button type="submit" className="hidden">
                    s
                  </button>
                </Form>
                <button
                  type="button"
                  onClick={() => handlePage('prev')}
                  disabled={pageView === 1 || false}
                >
                  Previous
                </button>
                <button
                  type="button"
                  onClick={() => handlePage('next')}
                  disabled={pageView === total || false}
                >
                  Next
                </button>
              </>
            )}
          </PageVer>
        </Pagination>

        <Footer />
      </Container>

      <Modal modal={modal}>
        <ContentModal>
          <h4>Aviso!</h4>
          <p>
            Você deseja raelmente deletar
            <br />o setor: <strong>{idSetor.title}?</strong>
          </p>
          <div>
            <button
              type="button"
              className="btn-delete"
              onClick={() => handleModal()}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-save"
              onClick={() => handleDelete(idSetor.id)}
            >
              Sim
            </button>
          </div>
        </ContentModal>
      </Modal>
    </>
  );
}
