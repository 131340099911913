import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import MonthPickerInput from 'react-month-picker-input';
import 'react-month-picker-input/dist/react-month-picker-input.css';

import { MdToday } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { Content, Card, Pesquisa, Bloco } from './styles';
import api from '~/services/api';
import history from '~/services/history';

import Container from '~/components/Container';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';

export default function Relatorio() {
  const [clientes, setClientes] = useState([]);
  const [statusView, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [anoCalendar, setAnoCalendar] = useState(
    Number(format(new Date(), 'yyyy'))
  );
  const [mesCalendar, setMesCalendar] = useState(
    Number(format(new Date(), 'MM'))
  );
  const url = new URL(window.location);

  useEffect(() => {
    const params = new URLSearchParams(url.search);
    const status = params.get('status') || '';
    const month = params.get('month') || mesCalendar;
    const year = params.get('year') || anoCalendar;

    async function loadClientes() {
      setLoading(true);
      const response = await api.get(
        `relatorio/clientes?month=${month}&year=${year}&status=${status}`
      );

      setStatus(status);
      setLoading(false);
      setClientes(response.data);
    }

    loadClientes();
  }, [anoCalendar, mesCalendar, url.search]); // eslint-disable-line

  function handleCalendar(e) {
    const dataYear = Number(e.year);
    const dataMonth = Number(e.month) + 1;
    setAnoCalendar(dataYear);
    setMesCalendar(dataMonth);

    history.push(
      `/relatorio/clientes?month=${dataMonth}&year=${dataYear}&status=${statusView}`
    );
  }

  function handleStatus(ativo) {
    if (ativo) {
      setStatus('');
      history.push(
        `/relatorio/clientes?month=${mesCalendar}&year=${anoCalendar}&status=`
      );
      return;
    }

    setStatus('Rescindido');
    history.push(
      `/relatorio/clientes?month=${mesCalendar}&year=${anoCalendar}&status=Rescindido`
    );
  }

  return (
    <>
      <Nav page="dashboard" />
      <Container>
        <User />

        <Content>
          <Title title="Relatórios de Clientes" />

          <Bloco>
            <div>
              <h2>Relatórios / Clientes</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <p>
                Mês de referência: <MdToday size={25} color="#0059B4" />
              </p>{' '}
              <MonthPickerInput
                year={Number(anoCalendar)}
                month={Number(mesCalendar) - 1}
                onChange={(maskedValue, selectedYear, selectedMonth) =>
                  handleCalendar({
                    value: maskedValue,
                    year: selectedYear,
                    month: selectedMonth,
                  })
                }
              />
            </div>
            <div className="bloco-one">
              <button
                type="button"
                className={!statusView ? 'btn-green' : 'btn-ok'}
                onClick={() => handleStatus(true)}
              >
                Ativo
              </button>
              <button
                type="button"
                className={statusView ? 'btn-green' : 'btn-ok'}
                onClick={() => handleStatus(false)}
              >
                Rescindido
              </button>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Razão Social</td>
                <td>CNPJ</td>
                <td>Data Início</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {clientes.map(item => (
                    <tr key={item.id}>
                      <td>{item.razao}</td>
                      <td>
                        <InputMask
                          mask="99.999.999/9999-99"
                          value={item.cnpj.replace('x', '1')}
                          disabled
                        />
                      </td>
                      <td>
                        {item.data_servico && (
                          <div className="date">{item.data_servico}</div>
                        )}
                      </td>
                      <td align="right">
                        por {item.user && item.user.name}
                        <br />
                        Atualizacao: {item.updated_at}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
