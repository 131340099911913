import React, { useEffect, useState, useCallback } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import { useSelector, useDispatch } from 'react-redux';

import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Carregando from '~/components/Carregando/Button';
import api from '~/services/api';

import { Notification, IconCount, Item, Load } from './styles';

import { signOut } from '../../store/modules/auth/actions';
import { updateNotificationRequest } from '~/store/modules/user/actions';

export default function Notifications() {
  const user = useSelector(state => state.user.profile);
  const notification = useSelector(state => state.user.notification);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadCount, setLoadCount] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    async function carregaCount() {
      try {
        const response = await api.get(`notifications?confirmed=true`);

        dispatch(updateNotificationRequest(response.data));
      } catch (err) {
        const error = err.message.replace(/\D+/g, '');
        if (error === '401') {
          dispatch(signOut());
        }
      }
    }

    /* setInterval(() => {
      carregaCount();
    }, 8000); */

    carregaCount();
  }, [dispatch, user, loadCount]);

  useEffect(() => {
    const idUser = user ? user.id : 0;

    async function loadUsers() {
      await api.put(`users/${idUser}?only=true`, {
        page: window.location.pathname,
      });
    }

    loadUsers();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!open) {
      setLoading(true);
      return;
    }

    async function loadNotifications() {
      const response = await api.get('notifications');

      const res = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.created_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        procVigencia: item.procuracao
          ? format(
              parseISO(item.procuracao.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        negVigencia: item.negativa
          ? format(
              parseISO(item.negativa.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        digVigencia: item.digital
          ? format(
              parseISO(item.digital.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        licVigencia: item.licenca
          ? format(
              parseISO(item.licenca.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        fisVigencia: item.fiscais
          ? format(
              parseISO(item.fiscais.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
      }));

      setNotifications(res);

      setLoading(false);
    }

    loadNotifications();
  }, [open]);

  const handleNotification = useCallback(
    async e => {
      try {
        await api.put(`notifications/${e}`, {
          confirmed: true,
        });

        const notificationsRes = notifications.map(item => ({
          ...item,
          confirmed: item.id === e || item.confirmed,
        }));

        setNotifications(notificationsRes);

        const contagem = notification - 1;

        dispatch(updateNotificationRequest(contagem));
      } catch (err) {
        toast.error('Algo deu errado');
      }
    },
    [dispatch, notification, notifications]
  );

  const handlePush = useCallback(
    async e => {
      await api.put(`notifications/${e}`, {
        confirmed: true,
        ticket: true,
      });

      const contagem = notification - 1;

      dispatch(updateNotificationRequest(contagem));
      setLoadCount(!loadCount);
      setOpen(!open);
    },
    [dispatch, loadCount, notification, open]
  );

  return (
    <>
      {user && (
        <div className="notifications">
          <button type="button" onClick={() => setOpen(!open)}>
            <IoIosNotificationsOutline size={30} color="#999" />
          </button>
          {notification > 0 && <IconCount>{notification}</IconCount>}
          {open && (
            <Notification>
              {loading ? (
                <Load>
                  <Carregando />
                </Load>
              ) : (
                <Scrollbars className="scrollbar" style={{ height: 300 }}>
                  <div className="bloco">
                    {notifications.map(item => (
                      <Item key={item.id}>
                        {item.type === 'cliente' ? (
                          <span>
                            <Link to={`/show/notification/${item.id}`}>
                              {`${item.title.substr(0, 38)}...`}
                              <p className="green">
                                {item.procVigencia &&
                                  `${item.comando}: ${item.procVigencia}`}
                                {item.negVigencia &&
                                  `${item.comando}: ${item.negVigencia}`}
                                {item.digVigencia &&
                                  `${item.comando}: ${item.digVigencia}`}
                                {item.fisVigencia &&
                                  `${item.comando}: ${item.fisVigencia}`}
                                {item.licVigencia &&
                                  `${item.comando}: ${item.licVigencia}`}
                              </p>
                              <p className="red">Aviso: {item.dateFormatted}</p>
                            </Link>
                          </span>
                        ) : (
                          <span>
                            {item.type === 'ticket' && (
                              <Link
                                to={`/show/ticket/${item.comando}`}
                                onClick={() => handlePush(item.id)}
                              >
                                {`${item.title.substr(0, 38)}...`}
                                <p className="green">
                                  Ticket: {item.created_at}
                                </p>
                              </Link>
                            )}
                            {item.type === 'comunicado' && (
                              <Link
                                to={`/comunicado/${item.comando}`}
                                onClick={() => handlePush(item.id)}
                              >
                                {`${item.title.substr(0, 38)}...`}
                                <p className="green">
                                  Comunicado: {item.created_at}
                                </p>
                              </Link>
                            )}
                            {item.type === 'annotation' && (
                              <Link
                                to={`/annotation/show/${item.comando.replace(
                                  /[^0-9]/g,
                                  ''
                                )}`}
                                onClick={() => handlePush(item.id)}
                              >
                                {`${item.title.substr(0, 38)}...`}
                                <p className="green">
                                  Anotação: {item.created_at}
                                </p>
                              </Link>
                            )}
                          </span>
                        )}

                        <div>
                          {item.confirmed ? (
                            <div className="lido">já lido</div>
                          ) : (
                            <button
                              type="button"
                              className="btn-notification"
                              onClick={() => handleNotification(item.id)}
                            >
                              Marcar como Lida
                            </button>
                          )}
                        </div>
                      </Item>
                    ))}
                  </div>
                </Scrollbars>
              )}
            </Notification>
          )}
        </div>
      )}
    </>
  );
}
