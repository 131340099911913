import React from 'react';

import { useCalendar } from '~/hooks/calendar';

import Modal from '~/components/Modal/Dialog';

import { Footer, Description } from './styles';

const FooterCell = () => {
  const { getData, handleClose } = useCalendar();

  return (
    <>
      <Footer>
        <div>
          <span className="obr" /> Obrigações
        </div>
        <div>
          <span className="date" /> Feriados
        </div>
        <div>
          <span className="birthday" /> Aniversariantes
        </div>
        <div>
          <span className="reminder" /> Lembretes
        </div>
      </Footer>

      <Modal
        open={getData.open}
        title={getData.title}
        close={false}
        handleCancel={handleClose}
      >
        <Description>
          {getData.info.length > 0 &&
            getData.info.map(item => (
              <div key={item.id}>
                <p>{item.name} </p>
                {item.type !== 'obrigação' &&
                  item.type !== 'aniversário' &&
                  item.type !== 'anotação' && (
                    <small className="feriado">{item.type}</small>
                  )}
                {item.type === 'obrigação' && (
                  <small className="obr">{item.type}</small>
                )}
                {item.type === 'aniversário' && (
                  <small className="birthday">{item.type}</small>
                )}
                {item.type === 'anotação' && (
                  <small className="reminder">{item.type}</small>
                )}
              </div>
            ))}
        </Description>
      </Modal>
    </>
  );
};

export default FooterCell;
