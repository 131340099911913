import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import {
  MdRemoveRedEye,
  MdCheckBox,
  MdWarning,
  MdCancel,
} from 'react-icons/md';

import InputMask from 'react-input-mask';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { toast } from 'react-toastify';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Carregando from '~/components/Carregando';
import Pagination from '~/components/Pagination';
import Nav from '~/components/Navigation';
import User from '~/components/User';

import api from '~/services/api';
import xml from '~/services/xmlApi';
import history from '~/services/history';

import { Content, Bloco, Card, Pesquisa, Relatorio } from './styles';

import excel from '~/assets/icons/excel-file.svg';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Geral() {
  const classes = useStyles();
  const [clientes, setClientes] = useState([]);
  const [ativa, setAtiva] = useState(true);

  const [gDados, setDados] = useState('');
  const [guser, setUser] = useState('');
  const [gcliente, setCliente] = useState('');
  const [gsearch, setSearch] = useState('');
  const [gdata, setData] = useState('');
  const [status, setStatus] = useState('');

  const [virgencia, setVirgencia] = useState('');
  const [datavirgencia, setVirgenciaData] = useState('');

  const [ativado, setAtivado] = useState(0);
  const [desativado, setDesativado] = useState(0);

  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitView, setLimitView] = useState(100);
  const [pageView, setPageView] = useState(1);

  const [users, setUsers] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [cargosView, setCargosView] = useState([]);
  const [geral, setGeral] = useState('');
  const [carregaExe, setCarregaExe] = useState(false);
  const [carregaUser, setCarregaUser] = useState(false);

  const [carregaExcel, setCarregaExcel] = useState(false);

  const [searchTipoCliente, setSearchTipoCliente] = useState({
    tipo: '',
    campo: '',
  });

  const url = new URL(window.location);

  const windows = window.location.href;
  const url2 = windows.split('?')[1];

  const options = [
    { id: 'diretor', title: 'DIRETOR' },
    { id: 'contabil', title: 'CONTÁBIL' },
    { id: 'fiscal', title: 'FISCAL' },
    { id: 'pessoal', title: 'PESSOAL' },
    { id: 'tributos', title: 'TRIBUTOS' },
  ];

  useEffect(() => {
    async function loadClientes() {
      const params = new URLSearchParams(url.search);
      const limit = params.get('limit') || 100;
      const page = params.get('page') || 1;
      const dados = params.get('dados') || '';
      const user = params.get('user') || '';
      const cliente = params.get('cliente') || 0;
      const data = params.get('data') || '';
      const search = params.get('search') || '';
      const ativo = params.get('ativo') || false;
      const statusView = params.get('status') || '';

      setLoading(true);

      setDados(dados);
      setUser(user);
      setSearch(search);
      setData(data);
      setCliente(cliente);

      if (ativo === false) {
        return;
      }

      if (dados) {
        setAtiva(false);
        const response = await api.get(
          `relatorios?limit=${limit}&page=${page}&user=${user}&data=${data}&search=${search}&cliente=${cliente}&dados=${dados}&status=${statusView}`
        );

        setClientes(response.data.clientes.data);
        setTotal(response.data.clientes.last_page);
        setDesativado(response.data.desativado);
        setAtivado(response.data.ativo);
      }

      setLoading(false);

      setLimitView(limit);
      setPageView(Number(page));
      setStatus(statusView);
    }

    loadClientes();
  }, [ativa, url.search]);

  useEffect(() => {
    async function loadCargos() {
      const response = await api.get(
        `cargos?search=%%&limit=100&tipo=fiscal,tributos,contabil,pessoal`
      );

      setCargosView(response.data.data);
    }

    loadCargos();
  }, []);

  function handleLimit(e) {
    setLoading(true);
    const limit = e.target.value;

    setLimitView(limit);
    setPageView(1);
    history.push(
      `/relatorio/geral?limit=${limit}&page=1&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=${gcliente}&ativo=true&status=${status}`
    );
  }

  function handlePage(e) {
    if (e === 'prev') {
      setLoading(true);
      const ptotal = pageView === 1 ? 1 : pageView - 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(
        `/relatorio/geral?limit=${limitView}&page=${ptotal}&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=${gcliente}&ativo=true&status=${status}`
      );
      return;
    }
    setLoading(true);
    const ptotal = Number(pageView) + 1;
    setPageView(ptotal);
    setLimitView(limitView);
    history.push(
      `/relatorio/geral?limit=${limitView}&page=${ptotal}&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=${gcliente}&ativo=true&status=${status}`
    );
  }

  function handlePageSelect({ page }) {
    setLoading(true);
    setPageView(page);
    setLimitView(limitView);
    history.push(
      `/relatorio/geral?limit=${limitView}&page=${page}&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=${gcliente}&ativo=true&status=${status}`
    );
  }

  function handleGeral(e) {
    setGeral(e.target.value);
    history.push(
      `/relatorio/geral?limit=${limitView}&page=${pageView}&dados=${e.target.value}&search=&user=${guser}&data=&cliente=${gcliente}&ativo=true&status=${status}`
    );
  }

  function handleStatus(e) {
    const viewS = e.target.value;
    setStatus(viewS);
    history.push(
      `/relatorio/geral?limit=${limitView}&page=${pageView}&dados=${gDados}&search=${gsearch}&user=${guser}&data=${gdata}&cliente=${gcliente}&ativo=true&status=${viewS}`
    );
  }

  async function handleCargo(e) {
    const searchvalor = e.target.value;
    setCarregaExe(true);

    const resCargo = await api.get(`cargos?search=%${searchvalor}%&limit=50`);

    setCargos(resCargo.data.data);
    setUsers([]);
    setCarregaExe(false);
  }

  async function handleUser(e) {
    const usersearch = e.target.value;
    setCarregaUser(true);

    const resUser = await api.get(`users?setor=${usersearch}&limit=100`);

    setUsers(resUser.data.data);
    setCarregaUser(false);
  }

  async function handleExport() {
    setCarregaExcel(true);
    try {
      await xml
        .get(`export/cliente/geral?${url2}`, {
          responseType: 'blob', // important
        })
        .then(response => {
          const dataUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const date = `clientes-dados-${gDados}-search-${gsearch}-export.xlsx`;
          link.href = dataUrl;
          link.setAttribute('download', date); // or any other extension
          document.body.appendChild(link);
          link.click();
          setCarregaExcel(false);
        });
    } catch (err) {
      toast.error(err.message);
      setCarregaExcel(false);
    }
  }

  return (
    <>
      <Nav page="relatorios" />
      <Container>
        <User />

        <Content>
          <Title title="Relatórios / Geral" />
          <Bloco>
            <div>
              <h2>Relatórios / Geral</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <div className="bloco_form">
                <select className="funcao" onChange={handleCargo}>
                  <option value="">Selecione o Setor</option>
                  {options.map(item => (
                    <option value={item.id}>{item.title}</option>
                  ))}
                </select>
              </div>
              <div className="bloco_form">
                <select className="cargo_id" onChange={handleUser}>
                  {carregaExe ? (
                    <option value="">carregando...</option>
                  ) : (
                    <>
                      <option value="">Selecione o Cargo</option>
                      {cargos.map(item => (
                        <option value={item.id}>{item.title}</option>
                      ))}
                    </>
                  )}
                </select>
              </div>
              <div className="bloco_form">
                <select
                  onChange={e =>
                    history.push(
                      `/relatorio/geral?limit=${limitView}&page=1&dados=executor&user=${e.target.value}&data=&cliente=1&ativo=true`
                    )
                  }
                >
                  {carregaUser ? (
                    <option>carregando...</option>
                  ) : (
                    <>
                      <option>Selecione o usuário</option>
                      {users.map(user => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="bloco-one">
              <div className="bloco_form">
                <select
                  className="funcao"
                  onChange={e =>
                    setSearchTipoCliente({
                      ...searchTipoCliente,
                      tipo: e.target.value,
                    })
                  }
                >
                  <option value="">Selecionar por tipo do cliente</option>
                  <option value="MATRIZ" selected={gDados === 'MATRIZ'}>
                    MATRIZ
                  </option>
                  <option value="FILIAL" selected={gDados === 'FILIAL'}>
                    FILIAL
                  </option>
                  <option value="SCP" selected={gDados === 'SCP'}>
                    SCP
                  </option>
                  <option value="CPF" selected={gDados === 'CPF'}>
                    CPF
                  </option>
                </select>
              </div>
              <div className="bloco_form">
                <select
                  className="funcao"
                  onChange={e =>
                    setSearchTipoCliente({
                      ...searchTipoCliente,
                      campo: e.target.value,
                    })
                  }
                >
                  <option value="">Selecionar campo da pesquisa</option>
                  <option
                    value="clientes_tribs"
                    selected={gsearch === 'clientes_tribs'}
                  >
                    Tributações (Recentes)
                  </option>
                  <option
                    value="clientes_fiscs"
                    selected={gsearch === 'clientes_fiscs'}
                  >
                    Incentivos Fiscais (Recentes)
                  </option>
                  <option
                    value="clientes_certns"
                    selected={gsearch === 'clientes_certns'}
                  >
                    Certidões Negativas (Recentes)
                  </option>
                  <option
                    value="clientes_certds"
                    selected={gsearch === 'clientes_certds'}
                  >
                    Certificados Digitais (Recentes)
                  </option>
                </select>
              </div>
              <button
                type="button"
                onClick={() => {
                  if (!searchTipoCliente.tipo || !searchTipoCliente.campo) {
                    toast.warning('Por favor! Preencha o campo');
                    return;
                  }
                  history.push(
                    `/relatorio/geral?limit=${limitView}&page=1&dados=${searchTipoCliente.tipo}&user=&search=${searchTipoCliente.campo}&data=&cliente=1&ativo=true&status=${status}`
                  );
                }}
              >
                Pesquisar
              </button>
            </div>
            <div className="bloco-one">
              <div className="bloco_form">
                <select onChange={handleGeral}>
                  <option value="">Pesquisar por vazios</option>
                  <optgroup label="Clientes / Geral">
                    <option value="tipo">Tipo</option>
                    <option value="razao">Razão Social</option>
                    <option value="fantasia">Nome Fantasia</option>
                    <option value="porte">Porte</option>
                    <option value="cidade">Cidade</option>
                    <option value="uf">Estado</option>
                    <option value="cep">Cep</option>
                    <option value="insc_mun">Inscrições Municipal</option>
                    <option value="insc_est">Inscrições Estadual</option>
                    <option value="sit_mun">Situação Municipal</option>
                    <option value="sit_est">Situação Estadual</option>
                    <option value="nire">Nire / Registro</option>
                    <option value="org_reg">Orgão de Registro</option>
                    <option value="postal_rfb">
                      Possui Caixa Postal - RFB
                    </option>
                    <option value="dte">Possui Opção DT-e</option>
                    <option value="licitacoes">Participa Licitação</option>
                    <option value="sind_pa">Sindicato Patronal</option>
                    <option value="sind_la">Sindicato Laboral</option>
                    <option value="esocial">Fase do eSocial</option>
                    <option value="obr_fiscal">Obrigações Fiscais</option>
                    <option value="obr_contabil">Obrigações Contábeis</option>
                    <option value="obr_pessoal">Obrigações Pessoal</option>
                    <option value="obr_tributos">Obrigações Tributos</option>
                  </optgroup>
                  <optgroup label="Clientes / Serviços">
                    <option value="status">Status do Contrato</option>
                    <option value="data_servico">Data do serviço</option>
                    <option value="execucao">Execução</option>
                    <option value="consultoria">Consultoria</option>
                    <option value="eventual">Eventual</option>
                  </optgroup>
                  <optgroup label="Clientes / Tributações">
                    <option value="part_trib">
                      Particularidades Tributárias
                    </option>
                    <option value="tributacoes">Geral (Tributações)</option>
                    <option value="tr-data_virg">
                      Data Vigência (Tributações)
                    </option>
                    <option value="tr-regime">
                      Regime de Apuração (Tributações)
                    </option>
                    <option value="tr-federal">Federal (Tributações)</option>
                    <option value="tr-estadual">Estadual (Tributações)</option>
                    <option value="tr-municipal">
                      Municipal (Tributações)
                    </option>
                    <option value="tr-previ">
                      Previdenciário (Tributações)
                    </option>
                    <option value="tr-aliq">
                      Alíq. s/ Faturam. (CPRB) (Tributações)
                    </option>
                    <option value="tr-rat">Rat (Tributações)</option>
                    <option value="tr-fap">Fap (Tributações)</option>
                    <option value="tr-entidades">
                      Outras Entidades (Tributações)
                    </option>
                    <option value="tr-fpas">FPAS (Tributações)</option>
                    <option value="tr-gps">GPS (Tributações)</option>
                  </optgroup>
                  <optgroup label="Clientes / Incentivos Fiscais">
                    <option value="incentivos-fiscais">
                      Geral (Incentivos Fiscais)
                    </option>
                    <option value="if-orgaos">
                      Órgão Firmado (Incentivos Fiscais)
                    </option>
                    <option value="if-n_acordo">
                      Nº Acordo (Incentivos Fiscais)
                    </option>
                    <option value="if-fim_vig">
                      Fim Vigência (Incentivos Fiscais)
                    </option>
                    <option value="if-data_protocolo">
                      Data da Protocolação (Incentivos Fiscais)
                    </option>
                    <option value="if-aviso">
                      Vigência (Incentivos Fiscais)
                    </option>
                  </optgroup>
                  <optgroup label="Clientes / Cnaes">
                    <option value="cnaes">Geral (Cnaes)</option>
                  </optgroup>
                  <optgroup label="Clientes / Sócios">
                    <option value="socios">Geral (Sócios)</option>
                    <option value="sc-nome">Nome (Sócios)</option>
                    <option value="sc-data_nas">
                      Data Nascimento (Sócios)
                    </option>
                    <option value="sc-cpf_cnpj">CPF / CNPJ (Sócios)</option>
                    <option value="sc-pais">País de Residência (Sócios)</option>
                    <option value="sc-quali">Qualificação (Sócios)</option>
                    <option value="sc-data_ingre">
                      Data Ingresso (Sócios)
                    </option>
                    <option value="sc-data_saida">Data Saída (Sócios)</option>
                    <option value="sc-repre_legal">
                      Representante Legal (Sócios)
                    </option>
                    <option value="sc-first_mand">
                      Início Mandato (Sócios)
                    </option>
                    <option value="sc-end_mand">Fim Mandato (Sócios)</option>
                    <option value="sc-avisar">Avisar em (Sócios)</option>
                  </optgroup>
                  <optgroup label="Clientes / Atos Societários">
                    <option value="atos-societarios">
                      Geral (Atos Societários)
                    </option>
                    <option value="at-atos">Ato (Atos Societários)</option>
                    <option value="at-data_regi">
                      Data Registro (Atos Societários)
                    </option>
                    <option value="at-orgao_regi">
                      Órgão de Registro (Atos Societários)
                    </option>
                    <option value="at-resumo">Resumo (Atos Societários)</option>
                  </optgroup>
                  <optgroup label="Clientes / Senhas">
                    <option value="pgdas_cnpj">CNPJ de Acesso PGDAS-d</option>
                    <option value="pgdas_cpf">CPF de Acesso PGDAS-d</option>
                    <option value="pgdas_cod">Código de Acesso PGDAS-d</option>
                    <option value="login_iss">
                      Login ISS (Sistema Escrituração)
                    </option>
                    <option value="senha_iss">
                      Senha ISS (Sistema Escrituração)
                    </option>
                    <option value="login_prev">Login Previdência Social</option>
                    <option value="senha_prev">Senha Previdência Social</option>
                    <option value="login_sefaz">
                      Login Ambiente Seguro SEFAZ
                    </option>
                    <option value="senha_sefaz">
                      Senha Ambiente Seguro SEFAZ
                    </option>
                    <option value="login_fap">Login FAP</option>
                    <option value="senha_fap">Senha FAP</option>
                    <option value="senha_censo">Senha CENSO</option>
                    <option value="senha_rde">Senha RDE-IED</option>
                    <option value="senha_dcbe">Senha DCBE</option>
                    <option value="outras">Outras Senhas</option>
                  </optgroup>
                  <optgroup label="Clientes / Certidões Negativas">
                    <option value="cert_negativas">
                      Geral (Certidões Negativas)
                    </option>
                    <option value="cn-tb_id">
                      Orgão (Certidões Negativas)
                    </option>
                    <option value="cn-vigencia">
                      Fim Validade (Certidões Negativas)
                    </option>
                  </optgroup>
                  <optgroup label="Clientes / Procurações">
                    <option value="procuracoes">Geral (Procurações)</option>
                    <option value="pr-orgao">Órgão (Procurações)</option>
                    <option value="pr-certificado">
                      Tipo Certficado (Procurações)
                    </option>
                    <option value="pr-titular">Titular (Procurações)</option>
                    <option value="pr-outorgado">
                      Outorgado (Procurações)
                    </option>
                    <option value="pr-vigencia">
                      Fim Vigência (Procurações)
                    </option>
                    <option value="pr-aviso">Avisar em (Procurações)</option>
                  </optgroup>
                  <optgroup label="Clientes / Certificados Digitais">
                    <option value="certificados-digitais">
                      Geral (Certificados Digitais)
                    </option>
                    <option value="cd-tb_id">
                      Tipo (Certificados Digitais)
                    </option>
                    <option value="cd-titular">
                      Titular (Certificados Digitais)
                    </option>
                    <option value="cd-senha">
                      Senha (Certificados Digitais)
                    </option>
                    <option value="cd-aviso">
                      Fim Vigência (Certificados Digitais)
                    </option>
                  </optgroup>
                  <optgroup label="Clientes / Licença">
                    <option value="licenca">Geral (Licença)</option>
                    <option value="lc-tb_all_id">
                      Tipo da funcionalidade (Licença)
                    </option>
                    <option value="lc-n_doc">Nº Documento (Licença)</option>
                    <option value="lc-data_venc">Data Vencimento</option>
                    <option value="lc-data_prot">
                      Data Protocolo (Licença)
                    </option>
                    <option value="lc-n_proto">
                      Nº Protocolo Pedido (Licença)
                    </option>
                    <option value="lc-aviso">Avisar em (Licença)</option>
                  </optgroup>
                  <optgroup label="Clientes / Contatos">
                    <option value="contatos">Geral (Contatos)</option>
                    <option value="ct-nome">Nome (Contatos)</option>
                    <option value="ct-email">Email (Contatos)</option>
                    <option value="ct-telefone">Telefone (Contatos)</option>
                    <option value="ct-cargo">Cargo (Contatos)</option>
                  </optgroup>
                  <optgroup label="Clientes / Executores por Setor">
                    {cargosView.map(item => (
                      <option
                        key={item.id}
                        value={`cargoexe-${
                          item.id > 9 ? item.id : `0${item.id}`
                        }`}
                      >
                        {item.subtitle} ({item.title})
                      </option>
                    ))}
                  </optgroup>
                  <optgroup label="Clientes / Executores Geral">
                    <option value="executores">Geral (Executores)</option>
                    <option value="executores-diretor">
                      Diretor (Executores)
                    </option>
                    <option value="executores-pessoal">
                      Pessoal (Executores)
                    </option>
                    <option value="executores-fiscal">
                      Fiscal (Executores)
                    </option>
                    <option value="executores-contabil">
                      Contabil (Executores)
                    </option>
                    <option value="executores-tributos">
                      Tributos (Executores)
                    </option>
                  </optgroup>
                  <optgroup label="Clientes / Primeiro Contato">
                    <option value="primeiro-contato">
                      Geral (Primeiro Contato)
                    </option>
                    <option value="pc-date_feito">
                      Feito em (Primeiro Contato)
                    </option>
                    <option value="pc-falou">
                      Falou com (Primeiro Contato)
                    </option>
                    <option value="pc-feito_via">
                      Feito via (Primeiro Contato)
                    </option>
                    <option value="pc-user_id">
                      Feito por (Primeiro Contato)
                    </option>
                    <option value="pc-date_visita">
                      Visita em (Primeiro Contato)
                    </option>
                  </optgroup>
                  <optgroup label="Clientes / Informações Adicionais">
                    <option value="informacoes-adicionais">
                      Geral (Info. Adicionais)
                    </option>
                  </optgroup>
                </select>
              </div>
              <div className="bloco_form">
                <input type="text" onChange={e => setSearch(e.target.value)} />
                <button
                  type="button"
                  onClick={() =>
                    history.push(
                      `/relatorio/geral?limit=${limitView}&page=1&dados=${geral}&user=&search=${gsearch}&data=&cliente=1&ativo=true&status=${status}`
                    )
                  }
                >
                  Pesquisar
                </button>
              </div>
            </div>
            <div className="bloco-one">
              <div className="bloco_form">
                <select onChange={e => setVirgencia(e.target.value)}>
                  <option value="">Pesquisar por data de vigência</option>
                  <option value="virgencia-fiscais">Incentivos Fiscais</option>
                  <option value="virgencia-socios">Sócios</option>
                  <option value="virgencia-negativas">
                    Certidões Negativas
                  </option>
                  <option value="virgencia-procuracoes">Procurações</option>
                  <option value="virgencia-digitais">
                    Certificado Digitais
                  </option>
                  <option value="virgencia-licenca">Licença</option>
                </select>
              </div>
              <div className="bloco_form">
                <input
                  type="date"
                  onChange={e => setVirgenciaData(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() =>
                    history.push(
                      `/relatorio/geral?limit=${limitView}&page=${pageView}&dados=${virgencia}&user=${guser}&search=${gsearch}&data=${datavirgencia}&cliente=1&ativo=true&status=${status}`
                    )
                  }
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Content>
          <Relatorio>
            <div className="bloco">
              <span>
                <select onChange={handleStatus}>
                  <option value="">Escolha o Status</option>
                  <option value="Ativo" selected={status === 'Ativo'}>
                    Ativo
                  </option>
                  <option value="Rescindido" selected={status === 'Rescindido'}>
                    Rescindido
                  </option>
                  <option value="Suspenso" selected={status === 'Suspenso'}>
                    Suspenso
                  </option>
                </select>
              </span>
              <span>
                <Link
                  to={`relatorio/geral?limit=${limitView}&page=${pageView}&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=1&ativo=true&status=${status}`}
                  className="activeGreen"
                >
                  Preenchidos / {ativado}
                </Link>
              </span>
              <span>
                <Link
                  to={`relatorio/geral?limit=${limitView}&page=${pageView}&dados=${gDados}&user=${guser}&search=${gsearch}&data=${gdata}&cliente=0&ativo=true&status=${status}`}
                  className="activeRed"
                >
                  Não preenchido / {desativado}
                </Link>
              </span>
            </div>
            {gDados && (
              <button type="button" className="exportar" onClick={handleExport}>
                <img src={excel} alt="Excel" />
                Exportar EXCEL
              </button>
            )}
          </Relatorio>
        </Content>

        <Card>
          {ativa ? (
            <h5>Selecione a sua pesquisa acima</h5>
          ) : (
            <>
              <h5>
                {gcliente === '1'
                  ? 'Clientes preenchidos'
                  : 'Clientes em brancos'}
              </h5>
              <table className="tab table-striped">
                <thead>
                  <tr>
                    <td>Razão Social</td>
                    <td>Status</td>
                    <td>Unidade</td>
                    <td>CNPJ / CPF</td>
                    <td>CNAE Principal</td>
                    <td>Cidade/UF</td>
                    <td>Detalhar</td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      {clientes.map(item => (
                        <>
                          <tr
                            key={item.id}
                            className="table-hover"
                            id={
                              (item.status === 'Suspenso' && 'orange') ||
                              (item.status === 'Rescindido' && 'red')
                            }
                          >
                            <td>{item.razao}</td>
                            <td className="contrato">
                              {item.status === 'Ativo' && (
                                <>
                                  <MdCheckBox size={20} color="#0FAF69" />
                                  Ativo
                                </>
                              )}
                              {item.status === 'Rescindido' && (
                                <>
                                  <MdCancel size={20} color="#FF2626" />
                                  Rescindido
                                </>
                              )}
                              {item.status === 'Suspenso' && (
                                <>
                                  <MdWarning size={20} color="#FF7F00" />
                                  Suspenso
                                </>
                              )}
                            </td>
                            <td>{item.tipo}</td>
                            <td>
                              {item.cnpj.length === 14 ? (
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  value={item.cnpj.replace('x', '1')}
                                  disabled
                                />
                              ) : (
                                <InputMask
                                  mask="99.999.999-99"
                                  value={item.cnpj}
                                  disabled
                                />
                              )}
                            </td>
                            <td>
                              {item.cnaes.map(cnae => (
                                <>
                                  {cnae.atividade === 'SIM' && (
                                    <>
                                      {cnae.code} - {cnae.cnae}
                                    </>
                                  )}
                                </>
                              ))}
                            </td>
                            <td>
                              {item.cidade}/{item.state}
                            </td>
                            <td>
                              <div>
                                <Link
                                  to={`/cliente/${item.cnpj}`}
                                  alt="Visualizar"
                                  title="Visualizar"
                                >
                                  <MdRemoveRedEye size={20} color="#fff" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </>
          )}
        </Card>

        <Pagination
          total={total}
          limit={limitView}
          page={pageView}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Backdrop className={classes.backdrop} open={carregaExcel}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Footer />
      </Container>
    </>
  );
}
