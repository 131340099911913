import React from 'react';

import { version } from '../../../package.json';

import { Footer } from './styles';

export default function Indexfooter() {
  return (
    <>
      <Footer>
        <a href="https://fabiomoraes.dev">fabiomoraes.dev</a> | v{version}
      </Footer>
    </>
  );
}
