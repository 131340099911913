import React from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { urlFile as url, urlRh as urlRH } from '~/utils/url.json';

import { Avatar, Topo } from './styles';
import Notifications from '~/components/Notifications';

import SemAvatar from '~/assets/no-avatar.png';

export default function User() {
  const profile = useSelector(state => state.user.profile);

  return (
    <>
      <Topo>
        <Notifications />
        <Avatar>
          <span>
            {profile && profile.email} <br />
            {profile && profile.name}
          </span>
          <Link to="/profile">
            {profile && (
              <>
                {profile.file_id ? (
                  <img src={`${url}${profile.file.file}`} alt={profile.name} />
                ) : (
                  <img
                    src={
                      profile.avatar ? `${urlRH}${profile.avatar}` : SemAvatar
                    }
                    alt={profile.name}
                  />
                )}
              </>
            )}
          </Link>
        </Avatar>
      </Topo>
    </>
  );
}
