import React, { useState, useEffect } from 'react';

import pt from 'date-fns/locale/pt';
import { format, parseISO, isPast } from 'date-fns';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import { MdModeEdit, MdRemoveRedEye, MdVisibilityOff } from 'react-icons/md';

import { updateNotificationRequest } from '~/store/modules/user/actions';

import api from '~/services/api';

import { Content, Bloco, Card } from './styles';

export default function Notifications() {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.user.notification);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [calculo, setTotal] = useState(0);

  useEffect(() => {
    async function loadNotifications() {
      try {
        const response = await api.get(`notifications?limit=6&status=&page=1`);

        const { total } = response.data;

        setTotal(total);

        const res = response.data.data.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.created_at),
            "dd 'de' MMMM' de 'yyyy', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
          procVigencia: item.procuracao
            ? format(
                parseISO(item.procuracao.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          negVigencia: item.negativa
            ? format(
                parseISO(item.negativa.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          digVigencia: item.digital
            ? format(
                parseISO(item.digital.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          licVigencia: item.licenca
            ? format(
                parseISO(item.licenca.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          fisVigencia: item.fiscais
            ? format(
                parseISO(item.fiscais.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          socVigencia: item.socios
            ? format(
                parseISO(item.socios.data_protocolo),
                "dd 'de' MMMM 'de' yyyy",
                {
                  locale: pt,
                }
              )
            : null,
          verificaProcData: item.procuracao
            ? isPast(parseISO(item.procuracao.data_protocolo))
            : null,
          verificaNegData: item.negativa
            ? isPast(parseISO(item.negativa.data_protocolo))
            : null,
          verificaDigData: item.digital
            ? isPast(parseISO(item.digital.data_protocolo))
            : null,
          verificaLicData: item.licenca
            ? isPast(parseISO(item.licenca.data_protocolo))
            : null,
          verificaFisData: item.fiscais
            ? isPast(parseISO(item.fiscais.data_protocolo))
            : null,
          verificaSocData: item.socios
            ? isPast(parseISO(item.socios.data_protocolo))
            : null,
        }));

        setNotifications(res);

        setLoading(false);
      } catch (err) {
        toast.warning(err.message, {
          position: 'top-center',
        });
      }
    }

    loadNotifications();
  }, []);

  async function handleNotification(e) {
    await api.put(`notifications/${e}`, {
      confirmed: true,
    });

    const getNotifications = notifications.map(item => ({
      ...item,
      confirmed: item.id === e ? true : item.confirmed,
    }));

    setNotifications(getNotifications);

    const contagem = notification - 1;

    dispatch(updateNotificationRequest(contagem));
  }

  return (
    <>
      <Content>
        <Bloco>
          <div>
            <h2>Notificações Recentes</h2>
          </div>
        </Bloco>
      </Content>

      <Card>
        <table className="tab table-striped">
          <thead>
            <tr>
              <td>Notificação</td>
              <td>Título</td>
              <td>Renovação</td>
              <td>Detalhar</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              'Carregando...'
            ) : (
              <>
                {notifications.map(item => (
                  <tr key={item.id} className="table-hover">
                    <td>{item.title}</td>
                    <td>
                      {item.type === 'cliente' ? (
                        <>{item.comando}</>
                      ) : (
                        <>{item.type}</>
                      )}
                    </td>
                    <td>
                      {item.type === 'cliente' && (
                        <>
                          {(item.procVigencia && item.procVigencia) ||
                            (item.fisVigencia && item.fisVigencia) ||
                            (item.licVigencia && item.licVigencia) ||
                            (item.negVigencia && item.negVigencia) ||
                            (item.socVigencia && item.socVigencia) ||
                            (item.digVigencia && item.digVigencia)}
                        </>
                      )}
                    </td>
                    <td>
                      <div>
                        {item.type === 'cliente' ? (
                          <Link
                            to={`/show/notification/${item.id}`}
                            className="btn-detalhes"
                          >
                            <MdModeEdit size={20} color="#fff" /> Ver Detalhe
                          </Link>
                        ) : (
                          <>
                            {item.type === 'ticket' && (
                              <Link
                                to={`/show/${item.type}/${item.comando}`}
                                className="btn-detalhes"
                              >
                                <MdModeEdit size={20} color="#fff" /> Ver
                                Detalhe
                              </Link>
                            )}
                            {item.type === 'comunicado' && (
                              <Link
                                to={`/comunicado/${item.comando}`}
                                className="btn-detalhes"
                              >
                                <MdModeEdit size={20} color="#fff" /> Ver
                                Detalhe
                              </Link>
                            )}
                            {item.type === 'annotation' && (
                              <Link
                                to={`/${item.type}/show/${item.comando}`}
                                className="btn-detalhes"
                              >
                                <MdModeEdit size={20} color="#fff" /> Ver
                                Detalhe
                              </Link>
                            )}
                          </>
                        )}
                        {item.confirmed ? (
                          <button type="button" id="btn-lido">
                            <MdRemoveRedEye size={20} color="#fff" /> Já lido
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleNotification(item.id)}
                            id="btn-nao-lido"
                          >
                            <MdVisibilityOff size={20} color="#fff" /> Marcar
                            como lido
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {calculo > 6 && (
          <Link to="/notifications" className="btn-notifications">
            Veja mais
          </Link>
        )}
      </Card>
    </>
  );
}
