import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO, addDays } from 'date-fns';

import { useParams } from 'react-router-dom';
import { MdSave, MdDelete } from 'react-icons/md';
import api from '~/services/api';

import { Content, Bloco, Grid, BForm, GridT, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function IncentivosFiscais() {
  const { idCliente } = useParams();
  const [fiscais, setFiscais] = useState([]);
  const [cliente, setCliente] = useState('');
  const [loading, setLoading] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }
  }, [idCliente, profile.role_id, validarPermission]);

  useEffect(() => {
    async function loadFisc() {
      const resFisc = await api.get(`clientes/${idCliente}/incentivos-fiscais`);

      const percorrefisc = resFisc.data.data.map(fisc => ({
        ...fisc,
        dateFormatted: format(
          parseISO(fisc.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        DataAcrescimo: format(addDays(new Date(), fisc.aviso), 'yyy-MM-dd', {
          locale: pt,
        }),
      }));

      setFiscais(percorrefisc);
    }

    loadFisc();
  }, [idCliente]);

  const [tabFiscais, setTabFiscais] = useState([]);

  useEffect(() => {
    async function loadTabs() {
      const tabResFiscais = await api.get('tb/geral?tb=ince_fiscais');

      setTabFiscais(tabResFiscais.data);
    }

    loadTabs();
  }, [idCliente]);

  const avisar = [
    { id: 1, title: '1 Dias' },
    { id: 2, title: '2 Dias' },
    { id: 3, title: '3 Dias' },
    { id: 4, title: '4 Dias' },
    { id: 5, title: '5 Dias' },
    { id: 10, title: '10 Dias' },
    { id: 15, title: '15 Dias' },
    { id: 30, title: '30 Dias' },
    { id: 45, title: '45 Dias' },
    { id: 60, title: '60 Dias' },
    { id: 90, title: '90 Dias' },
  ];

  const handleSubmit = useCallback(
    async ({ orgaos, n_acordo, fim_vig, data_protocolo, aviso }) => {
      if (!orgaos) {
        toast.warning('Por favor! preencha o campo Órgão Firmado');
        return;
      }

      if (!n_acordo) {
        toast.warning('Por favor! preencha o campo Nº Acordo');
        return;
      }

      if (!data_protocolo) {
        toast.warning('Por favor! preencha o campo Data limite protocolado');
        return;
      }

      if (!aviso) {
        toast.warning('Por favor! preencha o campo Avisar em');
        return;
      }

      setLoading(true);

      try {
        const { data } = await api.post(
          `clientes/${idCliente}/incentivos-fiscais`,
          {
            orgaos,
            n_acordo,
            fim_vig,
            data_protocolo,
            aviso,
          }
        );

        document.getElementById('form_reset').reset();

        setLoading(false);

        toast.success('Atualizado com sucesso.');

        const object = { data };

        const convert = Object.values(object); // Transforma objeto em array

        const dadosArray = convert.concat(fiscais);

        setFiscais(
          dadosArray.map(item => ({
            ...item,
            dateFormatted: format(
              parseISO(item.updated_at),
              "dd 'de' MMMM', às ' HH:mm'h'",
              {
                locale: pt,
              }
            ),
          }))
        );
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro');
      }
    },
    [fiscais, idCliente]
  );

  const handleUpdate = useCallback(
    async ({
      idfisc,
      n_acordo,
      fim_vig,
      data_protocolo,
      aviso,
      n_proto,
      obs,
    }) => {
      const valOrgaos = document.querySelector(`.form_orgaos${idfisc}`).value;
      try {
        await api.put(`clientes/${idCliente}/incentivos-fiscais?id=${idfisc}`, {
          orgaos: valOrgaos,
          n_acordo,
          fim_vig,
          data_protocolo,
          aviso,
          n_proto,
          obs,
        });

        toast.success('Atualizado com sucesso!');
      } catch (err) {
        toast.success('Algo deu errado na atualização.');
      }
    },
    [idCliente]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`delete/${idCliente}/incentivos-fiscais?id=${id}`);

        toast.success('Deletado com sucesso!');
        setFiscais(fiscais.filter(item => item.id !== id));
      } catch (err) {
        toast.error('Erro no delete.');
      }
    },
    [fiscais, idCliente]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="incentivos-fiscais"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Órgão Firmado *</label>
                <Select name="orgaos" options={tabFiscais} />
              </span>
              <span>
                <label>Nº Acordo *</label>
                <Input type="text" name="n_acordo" />
              </span>
              <span>
                <label>Vigência</label>
                <Input type="date" name="fim_vig" />
              </span>
              <span>
                <label>Data limite protocolado pela Secran *</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Avisar em *</label>
                <Select name="aviso" options={avisar} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>
        {fiscais.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="idfisc" value={item.id} />
              <BForm>
                <span>
                  <label>Órgão Firmado</label>
                  <select className={`form_orgaos${item.id}`}>
                    {tabFiscais.map(tab => (
                      <option
                        key={tab.id}
                        value={tab.id}
                        selected={tab.id === item.orgaos || false}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Nº Acordo</label>
                  <Input type="text" name="n_acordo" />
                </span>
                <span>
                  <label>Fim Vigência</label>
                  <Input type="date" name="fim_vig" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <Select name="aviso" options={avisar} />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Nº Protocolo</label>
                  <Input type="number" name="n_proto" />
                </span>
                <span>
                  <label>Observações</label>
                  <Input type="text" name="obs" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <p>
                    Adicionado em {item.dateFormatted} por{' '}
                    <strong>{item.user.name}</strong>
                  </p>
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    {item.fim_vig <= item.DataAcrescimo ? (
                      <button type="button" className="btn-warning">
                        <MdSave size={16} color="#fff" /> Data à vencer
                      </button>
                    ) : (
                      <button type="submit" className="btn-save">
                        <MdSave size={16} color="#fff" /> Salvar
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
