import React, { useState, useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import PropTypes from 'prop-types';

import { MdEmail, MdPhoneInTalk, MdPerson } from 'react-icons/md';
import { urlFile as url, urlRh as urlRH } from '~/utils/url.json';

import SemAvatar from '~/assets/no-avatar.png';

import api from '~/services/api';

import {
  Tabela,
  Titulo,
  GridDir,
  GridExe,
  Image,
  Description,
  Icon,
} from './styles';

export default function Executores({ id, cnpj }) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    api.get(`clientes/${id}/executores?cnpj=${cnpj}`).then(response => {
      setUsers(response.data);
      setLoading(false);
    });
  }, [cnpj, id]);

  return (
    <Tabela>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Titulo>Diretor</Titulo>
          <GridDir>
            {users.map(
              user =>
                user.funcao === 'diretor' && (
                  <div key={user.id} className="grid-4">
                    <Image>
                      {user.imagem ? (
                        <img src={`${url}${user.imagem}`} alt={user.name} />
                      ) : (
                        <>
                          {user.avatar ? (
                            <img
                              src={`${urlRH}${user.avatar}`}
                              alt={user.name}
                            />
                          ) : (
                            <img src={SemAvatar} alt={user.name} />
                          )}
                        </>
                      )}
                    </Image>
                    <Description>
                      <Icon>
                        <strong>Diretor Responsável</strong>
                      </Icon>
                      <Icon>
                        <MdPerson size={12} color="#000" />
                        {user.name}
                      </Icon>
                      <Icon>
                        <MdPhoneInTalk size={12} color="#000" />
                        Ramal {user.ramal}
                      </Icon>
                      <Icon>
                        <MdEmail size={12} color="#000" />
                        {user.email}
                      </Icon>
                    </Description>
                  </div>
                )
            )}
          </GridDir>

          <Titulo>Contábil</Titulo>
          <GridExe>
            {users.map(
              user =>
                user.funcao === 'contabil' && (
                  <div key={user.id} className="grid-4">
                    <Image>
                      {user.imagem ? (
                        <img src={`${url}${user.imagem}`} alt={user.name} />
                      ) : (
                        <>
                          {user.avatar ? (
                            <img
                              src={`${urlRH}${user.avatar}`}
                              alt={user.name}
                            />
                          ) : (
                            <img src={SemAvatar} alt={user.name} />
                          )}
                        </>
                      )}
                    </Image>
                    <Description>
                      <Icon>
                        <strong>
                          {(user.tipo === 'coord' && 'Coordernação') ||
                            (user.tipo === 'supervisao' && 'Supervisão') ||
                            (user.tipo === 'analista' && 'Analista') ||
                            (user.tipo === 'executor' && 'Executor')}
                        </strong>
                      </Icon>
                      <Icon>
                        <MdPerson size={12} color="#000" />
                        {user.name}
                      </Icon>
                      <Icon>
                        <MdPhoneInTalk size={12} color="#000" />
                        Ramal {user.ramal}
                      </Icon>
                      <Icon>
                        <MdEmail size={12} color="#000" />
                        {user.email}
                      </Icon>
                    </Description>
                  </div>
                )
            )}
          </GridExe>

          <Titulo>Fiscal</Titulo>
          <GridExe>
            {users.map(
              user =>
                user.funcao === 'fiscal' && (
                  <div key={user.id} className="grid-4">
                    <Image>
                      {user.imagem ? (
                        <img src={`${url}${user.imagem}`} alt={user.name} />
                      ) : (
                        <>
                          {user.avatar ? (
                            <img
                              src={`${urlRH}${user.avatar}`}
                              alt={user.name}
                            />
                          ) : (
                            <img src={SemAvatar} alt={user.name} />
                          )}
                        </>
                      )}
                    </Image>
                    <Description>
                      <Icon>
                        <strong>
                          {(user.tipo === 'coord' && 'Coordernação') ||
                            (user.tipo === 'supervisao' && 'Supervisão') ||
                            (user.tipo === 'analista' && 'Analista') ||
                            (user.tipo === 'executor' && 'Executor')}
                        </strong>
                      </Icon>
                      <Icon>
                        <MdPerson size={12} color="#000" />
                        {user.name}
                      </Icon>
                      <Icon>
                        <MdPhoneInTalk size={12} color="#000" />
                        Ramal {user.ramal}
                      </Icon>
                      <Icon>
                        <MdEmail size={12} color="#000" />
                        {user.email}
                      </Icon>
                    </Description>
                  </div>
                )
            )}
          </GridExe>

          <Titulo>Pessoal</Titulo>
          <GridExe>
            {users.map(
              user =>
                user.funcao === 'pessoal' && (
                  <div key={user.id} className="grid-4">
                    <Image>
                      {user.imagem ? (
                        <img src={`${url}${user.imagem}`} alt={user.name} />
                      ) : (
                        <>
                          {user.avatar ? (
                            <img
                              src={`${urlRH}${user.avatar}`}
                              alt={user.name}
                            />
                          ) : (
                            <img src={SemAvatar} alt={user.name} />
                          )}
                        </>
                      )}
                    </Image>
                    <Description>
                      <Icon>
                        <strong>
                          {(user.tipo === 'coord' && 'Coordernação') ||
                            (user.tipo === 'supervisao' && 'Supervisão') ||
                            (user.tipo === 'analista' && 'Analista') ||
                            (user.tipo === 'executor' && 'Executor')}
                        </strong>
                      </Icon>
                      <Icon>
                        <MdPerson size={12} color="#000" />
                        {user.name}
                      </Icon>
                      <Icon>
                        <MdPhoneInTalk size={12} color="#000" />
                        Ramal {user.ramal}
                      </Icon>
                      <Icon>
                        <MdEmail size={12} color="#000" />
                        {user.email}
                      </Icon>
                    </Description>
                  </div>
                )
            )}
          </GridExe>

          <Titulo>Tributos</Titulo>
          <GridExe>
            {users.map(
              user =>
                user.funcao === 'tributos' && (
                  <div key={user.id} className="grid-4">
                    <Image>
                      {user.imagem ? (
                        <img src={`${url}${user.imagem}`} alt={user.name} />
                      ) : (
                        <>
                          {user.avatar ? (
                            <img
                              src={`${urlRH}${user.avatar}`}
                              alt={user.name}
                            />
                          ) : (
                            <img src={SemAvatar} alt={user.name} />
                          )}
                        </>
                      )}
                    </Image>
                    <Description>
                      <Icon>
                        <strong>
                          {(user.tipo === 'coord' && 'Coordernação') ||
                            (user.tipo === 'supervisao' && 'Supervisão') ||
                            (user.tipo === 'analista' && 'Analista') ||
                            (user.tipo === 'executor' && 'Executor')}
                        </strong>
                      </Icon>
                      <Icon>
                        <MdPerson size={12} color="#000" />
                        {user.name}
                      </Icon>
                      <Icon>
                        <MdPhoneInTalk size={12} color="#000" />
                        Ramal {user.ramal}
                      </Icon>
                      <Icon>
                        <MdEmail size={12} color="#000" />
                        {user.email}
                      </Icon>
                    </Description>
                  </div>
                )
            )}
          </GridExe>
        </>
      )}
    </Tabela>
  );
}

Executores.propTypes = {
  id: PropTypes.number,
  cnpj: PropTypes.string,
};

Executores.defaultProps = {
  id: 0,
  cnpj: '',
};
