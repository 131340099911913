import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export default function DialogModal({
  children,
  text,
  open,
  close,
  title,
  handleSubmit,
  handleCancel,
  width,
}) {
  return (
    <Dialog
      fullWidth={!!width}
      maxWidth={width}
      open={open}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {handleCancel && (
          <Button onClick={handleCancel} color="primary">
            Sair
          </Button>
        )}
        {handleSubmit && (
          <Button onClick={handleSubmit} color="primary">
            {text || 'Adicionar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
