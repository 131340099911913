import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import Carregando from '~/components/Carregando';

import { Grid } from './styles';

import api from '~/services/api';

export default function Primeiro({ id }) {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadContatos() {
      const response = await api.get(`clientes/${id}/primeiro-contato`);
      setLoading(false);
      setContatos(response.data.data);
    }

    loadContatos();
  }, [id]);

  return (
    <Grid>
      {loading ? (
        <Carregando />
      ) : (
        <>
          {contatos.map(item => (
            <div key={item.id} className="content">
              <h4>{item.cargo.title}</h4>
              <div className="tabelas">
                <div>
                  <strong>Feito em: </strong> {item.date_feito}
                </div>
                <div>
                  <strong>Falou com: </strong> {item.falou}
                </div>
                <div>
                  <strong>Feito via: </strong> {item.feito_via}
                </div>
                <div>
                  <strong>Feito por: </strong> {item.user.name}
                </div>
                <div>
                  <strong>Visita em: </strong> {item.date_visita}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Grid>
  );
}

Primeiro.propTypes = {
  id: PropTypes.number.isRequired,
};
