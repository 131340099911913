import styled from 'styled-components';
import { darken } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #82cc33;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Conteudo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #999;
  }

  a {
    background: #82cc33;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#82cc33')};
  }
`;

export const Profile = styled.div`
  display: block;
  width: 38%;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  position: relative;
  text-align: center;
`;

export const EditPerfil = styled.div`
  display: block;
  width: 60%;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  padding: 12px;
`;

export const Avatar = styled.div`
  display: flex;
  flex: 1;
  background: #eee;
  height: 100px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 50px;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

export const Logo = styled.div`
  img {
    width: 100%;
  }

  label {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 2px solid #ccc;
    background: #fff;
    position: absolute;
    z-index: 9;
    top: 60px;
    left: 50%;
    margin-left: -50px;
    overflow: hidden;

    .carrega {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #eee;
    }

    input[type='file'] {
      display: none;
    }

    &:hover {
      color: #fff;

      div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0;
        z-index: 9;
      }
    }
  }
`;

export const Text = styled.div`
  p {
    font-size: 12px;
    margin-top: 4px;
  }
`;

export const BFormCheck = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const BForm = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  margin-bottom: 4px;
  margin-top: 4px;

  input[type='text'],
  input[type='date'],
  input[type='email'],
  input[type='password'] {
    width: 100%;
    height: 42px;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 5px;
    padding-right: 5px;
  }

  span {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    margin-right: 3px;
  }

  span + span {
    margin-left: 3px;
  }

  span label {
    margin-bottom: 3px;
    font-size: 12px;
  }

  button {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    border: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const Hr = styled.div`
  height: 1px;
  background: #eee;
  margin-top: 20px;
  margin-bottom: 20px;
`;
