import styled from 'styled-components';

export const Footer = styled.div`
  display: block;
  width: 100%;
  text-align: right;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 12px;

  a {
    color: #999;
  }
`;
