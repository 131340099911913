import React, { useState, useEffect, useCallback } from 'react';

import { Form, Input } from '@rocketseat/unform';
import { MdSave } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Container, Content, Bloco, Grid, BForm } from './styles';

import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Carregando from '~/components/Carregando/Button';

export default function Adicionar() {
  const [cargos, setCargos] = useState([]);
  const [roles, setRoles] = useState([]);
  const [usuario, setUsuario] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    api
      .get(`users/${id}`)
      .then(response => {
        setUsuario(response.data);
      })
      .catch(err => {
        toast.error(err.message);
      });

    api
      .get(`cargos?search=%%&limit=100`)
      .then(response => {
        setCargos(response.data.data);
      })
      .catch(err => {
        toast.error(err.message);
      });

    api
      .get(`roles`)
      .then(response => {
        setRoles(response.data);
      })
      .catch(err => {
        toast.error(err.message);
      });
  }, [id]);

  const tabstatus = [
    { id: 'Ativo', title: 'Ativo' },
    { id: 'Inativo', title: 'Inativo' },
  ];

  const handleSubmit = useCallback(
    async ({ name, email, ramal, pass }) => {
      try {
        setLoading(true);
        const role_id = document.querySelector(`.role_id`).value;
        const cargo_id = document.querySelector(`.cargo_id`).value;
        const status = document.querySelector(`.status`).value;
        await api.put(`users/${id}`, {
          name,
          email,
          role_id,
          cargo_id,
          status,
          ramal,
          password: pass || null,
        });

        setLoading(false);
        toast.success('Usuário atualizado com sucesso.');
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [id]
  );

  return (
    <>
      <Nav page="administracao" />

      <Container>
        <User />

        <Content>
          <Title title="Administrador / Usuario / Editar" />

          <Bloco>
            <div>
              <h2>Editar usuário</h2>
            </div>
          </Bloco>
        </Content>

        <Grid>
          <Form onSubmit={handleSubmit} initialData={usuario}>
            <BForm>
              <span>
                <label>Name</label>
                <Input type="text" name="name" />
              </span>
              <span>
                <label>Email</label>
                <Input type="email" name="email" />
              </span>
              <span>
                <label>Ramal</label>
                <Input type="number" name="ramal" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Permissão</label>
                <select className="role_id">
                  {roles.map(item => (
                    <option
                      value={item.id}
                      selected={item.id === usuario.role_id || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label>Cargo</label>
                <select className="cargo_id">
                  {cargos.map(item => (
                    <option
                      value={item.id}
                      selected={item.id === usuario.cargo_id || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Status</label>
                <select className="status">
                  {tabstatus.map(item => (
                    <option
                      value={item.id}
                      selected={item.id === usuario.status || false}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label>Password</label>
                <Input type="password" name="pass" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>
    </>
  );
}
