import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { MdSave, MdDelete } from 'react-icons/md';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import MaskCel from '~/components/MaskInput/cel';
import MaskPhone from '~/components/MaskInput/phone';
import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Contatos() {
  const { idCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState('');
  const [contatos, setContatos] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/contatos`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setContatos(percorre);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  async function handleSubmit({ nome, email, cargo }) {
    try {
      setLoading(true);
      const telefone = document.querySelector(`.phone`).value;
      const { data } = await api.post(`clientes/${idCliente}/contatos`, {
        nome,
        email,
        telefone,
        cargo,
      });

      document.getElementById('form_reset').reset();

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(contatos);

      const percorreDados = dadosArray.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setContatos(percorreDados);

      setLoading(false);
      toast.success('Cadastrado com sucesso.');
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado no cadastro.');
    }
  }

  async function handleUpdate({ id, nome, email, cargo }) {
    try {
      const telefone = document.querySelector(`.phone${id}`).value;
      await api.put(`clientes/${idCliente}/contatos?id=${id}`, {
        nome,
        email,
        telefone,
        cargo,
      });

      toast.success('Atualizado com sucesso');
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`delete/${idCliente}/contatos?id=${id}`);

      setContatos(contatos.filter(item => item.id !== id));
      toast.success('Contato deletado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado no cadastro.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="contatos"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Nome</label>
                <Input type="text" name="nome" />
              </span>
              <span>
                <label>E-mail</label>
                <Input type="text" name="email" />
              </span>
              <span>
                <label>Telefone</label>
                <MaskPhone type="text" className="phone" />
              </span>
              <span>
                <label>Cargo/Setor</label>
                <Input type="text" name="cargo" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {contatos.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Nome</label>
                  <Input type="text" name="nome" />
                </span>
                <span>
                  <label>E-mail</label>
                  <Input type="text" name="email" required />
                </span>
                <span>
                  <label>Telefone</label>
                  {item.telefone.length < 15 ? (
                    <MaskPhone
                      type="text"
                      className={`phone${item.id}`}
                      value={item.telefone}
                    />
                  ) : (
                    <MaskCel
                      type="text"
                      className={`phone${item.id}`}
                      value={item.telefone}
                    />
                  )}
                </span>
                <span>
                  <label>Cargo/Setor</label>
                  <Input type="text" name="cargo" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
