import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';
import InputMask from 'react-input-mask';
import { MdSave, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

const options = [
  { id: 'SIM', title: 'Sim' },
  { id: 'NÃO', title: 'Não' },
];

const avisar = [
  { id: 1, title: '1 Dias' },
  { id: 2, title: '2 Dias' },
  { id: 3, title: '3 Dias' },
  { id: 4, title: '4 Dias' },
  { id: 5, title: '5 Dias' },
  { id: 10, title: '10 Dias' },
  { id: 15, title: '15 Dias' },
  { id: 30, title: '30 Dias' },
  { id: 45, title: '45 Dias' },
  { id: 60, title: '60 Dias' },
  { id: 90, title: '90 Dias' },
];

export default function Socios() {
  const profile = useSelector(state => state.user.profile);
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState('');
  const [socios, setSocios] = useState([]);
  const [tabsSocios, setTabsSocios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/socios`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setSocios(percorre);
    });

    api.get('tb/pais?limit=150').then(response => {
      setTabs(response.data.data);
    });

    api.get('tb/qualificacao?limit=100').then(response => {
      setTabsSocios(response.data.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const handleSubmit = useCallback(
    async ({
      nome,
      nascimento,
      pais,
      quali,
      data_ingre,
      data_saida,
      repre_legal,
      first_mand,
      end_mand,
      data_protocolo,
      aviso,
    }) => {
      const valCpf = document.querySelector(`.cpf_cnpj`).value;
      try {
        setLoading(true);
        await api.post(`clientes/${idCliente}/socios`, {
          nome,
          data_nas: nascimento,
          cpf_cnpj: valCpf,
          pais,
          quali,
          data_ingre,
          data_saida,
          repre_legal,
          first_mand,
          end_mand,
          data_protocolo,
          avisar: aviso,
        });

        setLoading(false);
        document.getElementById('form_reset').reset();

        toast.success('Sócio cadastrado com sucesso.');

        const percorreData = await api.get(`clientes/${idCliente}/socios`);

        setSocios(
          percorreData.data.data.map(item => ({
            ...item,
            dateFormatted: format(
              parseISO(item.updated_at),
              "dd 'de' MMMM', às ' HH:mm'h'",
              {
                locale: pt,
              }
            ),
          }))
        );
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [idCliente]
  );

  const handleUpdate = useCallback(
    async ({
      id,
      nome,
      data_nas,
      data_ingre,
      data_saida,
      repre_legal,
      first_mand,
      end_mand,
      data_protocolo,
    }) => {
      try {
        const valCpf = document.querySelector(`.cpf_cnpj${id}`).value;
        const quali = document.querySelector(`.quali${id}`).value;
        const aviso = document.querySelector(`.form_aviso${id}`).value;
        const pais = document.querySelector(`.form_pais${id}`).value;
        await api.put(`clientes/${idCliente}/socios?id=${id}`, {
          nome,
          data_nas,
          cpf_cnpj: valCpf,
          pais,
          quali,
          data_ingre,
          data_protocolo,
          data_saida,
          repre_legal,
          first_mand,
          end_mand,
          avisar: aviso,
        });

        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado na atualização. Tente mais tarde!');
      }
    },
    [idCliente]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`delete/${idCliente}/socios?id=${id}`);

        toast.success('Deletado com sucesso.');
        setSocios(socios.filter(item => item.id !== id));
      } catch (err) {
        toast.error('Algo deu errado no delete');
      }
    },
    [idCliente, socios]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="socios"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} id="form_reset">
            <BForm>
              <span>
                <label>Nome</label>
                <Input type="text" name="nome" />
              </span>
              <span>
                <label>Data Nascimento</label>
                <Input type="date" name="nascimento" />
              </span>
              <span>
                <label>CPF</label>
                <InputMask mask="999.999.999-99" className="cpf_cnpj" />
              </span>
              <span>
                <label>País de Residência</label>
                <Select name="pais" options={tabs} />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Qualificação</label>
                <Select name="quali" options={tabsSocios} />
              </span>
              <span>
                <label>Data Ingresso</label>
                <Input type="date" name="data_ingre" />
              </span>
              <span>
                <label>Data Saída</label>
                <Input type="date" name="data_saida" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Representante Legal?</label>
                <Select name="repre_legal" options={options} />
              </span>
              <span>
                <label>Início Mandato</label>
                <Input type="date" name="first_mand" />
              </span>
              <span>
                <label>Fim Mandato</label>
                <Input type="date" name="end_mand" />
              </span>
              <span>
                <label>Data limite protocolado pela Secran</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Avisar em</label>
                <Select name="aviso" options={avisar} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {socios.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Nome</label>
                  <Input type="text" name="nome" />
                </span>
                <span>
                  <label>Data Nascimento</label>
                  <Input type="date" name="data_nas" />
                </span>
                <span>
                  <label>CPF</label>
                  <InputMask
                    mask="999.999.999-99"
                    className={`cpf_cnpj${item.id}`}
                    value={item.cpf_cnpj}
                  />
                </span>
                <span>
                  <label>País de Residência</label>
                  <select className={`form_pais${item.id}`}>
                    <option value="">...</option>
                    {tabs.map(pais => (
                      <option
                        key={pais.id}
                        value={pais.id}
                        selected={pais.id === item.pais || false}
                      >
                        {pais.title}
                      </option>
                    ))}
                  </select>
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Qualificação</label>
                  <select className={`quali${item.id}`}>
                    <option value="">...</option>
                    {tabsSocios.map(socio => (
                      <option
                        value={socio.id}
                        selected={socio.id === item.quali || false}
                      >
                        {socio.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Data Ingresso</label>
                  <Input type="date" name="data_ingre" />
                </span>
                <span>
                  <label>Data Saída</label>
                  <Input type="date" name="data_saida" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Representante Legal?</label>
                  <Select name="repre_legal" options={options} />
                </span>
                <span>
                  <label>Início Mandato</label>
                  <Input type="date" name="first_mand" />
                </span>
                <span>
                  <label>Fim Mandato</label>
                  <Input type="date" name="end_mand" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <select className={`form_aviso${item.id}`}>
                    <option value="0">...</option>
                    {avisar.map(tab => (
                      <option
                        value={tab.id}
                        selected={tab.id === item.avisar || false}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
              </BForm>
              <BForm>
                <span>
                  <p>
                    Adicionado em {item.dateFormatted} por{' '}
                    <strong>{item.user ? item.user.name : ''}</strong>
                  </p>
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>

                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
