import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { MdSave } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

import { Form, Input, Check } from '@rocketseat/unform';

import CheckboxGroup from 'react-checkbox-group';

import api from '~/services/api';

import { Content, Bloco, Grid, BForm, CheckboxDiv } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Servicos() {
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState('');
  const [loading, setLoading] = useState(false);

  const [viewexecucao, setExecucao] = useState([]);
  const [viewconsultoria, setConsultoria] = useState([]);

  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    const showcliente = JSON.parse(resStorage);
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
      setExecucao(showcliente.execucao || []);
      setConsultoria(showcliente.consultoria || []);
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
        setExecucao(response.data.execucao);
        setConsultoria(response.data.consultoria);
      });
    }
  }, [idCliente, profile.role_id, validarPermission]);

  const handleSubmit = useCallback(
    async ({ data_servico, eventual }) => {
      if (!data_servico) {
        toast.error('Por favor! Preencha a data do serviço.');
        return;
      }

      try {
        setLoading(true);
        const status = document.querySelector('.status').value;
        const rescli = await api.post(`clientes/${idCliente}/servicos`, {
          status,
          data_servico,
          execucao: viewexecucao,
          consultoria: viewconsultoria,
          eventual,
        });

        if (status !== cliente.status) {
          await api.post(`clientes/${idCliente}/servicos-store`, {
            title: status,
            data: data_servico || null,
          });
        }

        setLoading(false);
        localStorage.setItem('cliente', JSON.stringify(rescli.data));
        toast.success('Cliente atualizado com sucesso!');
      } catch (err) {
        setLoading(false);
        toast.error('Erro na atualização!');
      }
    },
    [cliente.status, idCliente, viewconsultoria, viewexecucao]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="servico"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit} initialData={cliente}>
            <BForm>
              <span>
                <label>Status do Contrato:</label>
                <select className="status">
                  <option value="">Selecione</option>
                  <option
                    value="Ativo"
                    selected={cliente.status === 'Ativo' || false}
                  >
                    Ativo
                  </option>
                  <option
                    value="Rescindido"
                    selected={cliente.status === 'Rescindido' || false}
                  >
                    Rescindido
                  </option>
                  <option
                    value="Suspenso"
                    selected={cliente.status === 'Suspenso' || false}
                  >
                    Suspenso
                  </option>
                  <option
                    value="Baixada"
                    selected={cliente.status === 'Baixada' || false}
                  >
                    Baixada
                  </option>
                </select>
              </span>
              <span>
                <label>Data:</label>
                <Input type="date" name="data_servico" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Execução:</label>
                <CheckboxGroup
                  name="execucao"
                  value={viewexecucao}
                  onChange={setExecucao}
                >
                  {Checkbox => (
                    <>
                      <CheckboxDiv>
                        <div className="form-servico">
                          <div>
                            <Checkbox value="pessoal" /> <p>Pessoal</p>
                          </div>
                          <div>
                            <Checkbox value="pessoal_externo" />
                            <p>Executado pela SECRAN dentro do cliente</p>
                          </div>
                        </div>
                        <div className="form-servico">
                          <div>
                            <Checkbox value="contabil" /> <p>Contábil</p>
                          </div>
                          <div>
                            <Checkbox value="contabil_externo" />{' '}
                            <p>Executado pela SECRAN dentro do cliente</p>
                          </div>
                        </div>
                        <div className="form-servico">
                          <div>
                            <Checkbox value="fiscal" /> <p>Fiscal</p>
                          </div>
                          <div>
                            <Checkbox value="fiscal_externo" />{' '}
                            <p>Executado pela SECRAN dentro do cliente</p>
                          </div>
                        </div>
                        <div className="form-servico">
                          <div>
                            <Checkbox value="tributos" /> <p>Tributos</p>
                          </div>
                          <div>
                            <Checkbox value="tributos_externo" />{' '}
                            <p>Executado pela SECRAN dentro do cliente</p>
                          </div>
                        </div>
                      </CheckboxDiv>
                    </>
                  )}
                </CheckboxGroup>
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Consultoria:</label>
                <CheckboxGroup
                  name="consultoria"
                  value={viewconsultoria}
                  onChange={setConsultoria}
                >
                  {Checkbox => (
                    <CheckboxDiv>
                      <Checkbox value="pessoal" /> <p>Pessoal</p>
                      <Checkbox value="contabil" /> <p>Contábil</p>
                      <Checkbox value="fiscal" /> <p>Fiscal</p>
                      <Checkbox value="tributos" /> <p>Tributos</p>
                    </CheckboxDiv>
                  )}
                </CheckboxGroup>
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Eventual:</label>
                <CheckboxDiv>
                  <Check name="eventual" />
                </CheckboxDiv>
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        <Footer />
      </Container>
    </>
  );
}
