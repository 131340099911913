import React, { useState, useEffect, useCallback } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { MdExitToApp } from 'react-icons/md';

import pt from 'date-fns/locale/pt';
import { format, setMonth } from 'date-fns';

import { Chart } from 'react-google-charts';

import history from '~/services/history';
import {
  Content,
  Bloco,
  Card,
  Submenu,
  Search,
  Grid,
  ContentGrid,
  Popup,
  DivGrid,
  GridButton,
} from './styles';

import AnalistasExe from './Executores/analista';
import ExecutoresExe from './Executores/executor';

import ModalDialog from '~/components/Modal/Dialog';
import CalendarMonth from '~/components/CalendarMonth';
import { usePermission } from '~/hooks/permission';
import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import MenuSub from '~/components/Navigation/Obrigacoes';
import User from '~/components/User';

import api from '~/services/api';

export default function Obrigacoes() {
  const { cargoPermission } = usePermission();
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const cargo = profile.cargo.subtitle;
  const { tipo } = profile.cargo;
  const roleid = profile.role_id;
  const [delFuncao, setDelFuncao] = useState(!getStorageFuncao);
  const [funcao, setFuncao] = useState(
    getStorageFuncao && roleid === 1 ? JSON.parse(getStorageFuncao) : cargo
  );
  const [anoCalendar, setAnoCalendar] = useState(
    Number(format(new Date(), 'yyyy'))
  );

  const [mesCalendar, setMesCalendar] = useState(
    Number(format(new Date(), 'MM'))
  );

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const monthDate = params.get('month') || mesCalendar;
  const yearDate = params.get('year') || anoCalendar;
  const executor = params.get('executor') || '';

  const [data, setData] = useState([]);
  const [charts, setCharts] = useState([]);
  const [total, setTotal] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalMonth, setModalMonth] = useState(false);

  const [mesCalendarFormatted, setMesCalendarFormatted] = useState(
    format(setMonth(new Date(), Number(monthDate) - 1), 'MMMM', { locale: pt })
  );

  useEffect(() => {
    cargoPermission({ cargo });

    if (roleid === 1 && !getStorageFuncao) {
      return;
    }

    async function loadobr() {
      try {
        setLoading(true);
        const response = await api.get(
          `graficos/analista?funcao=${funcao}&month=${monthDate}&year=${yearDate}&executor=${executor}`
        );

        if (response.data.error) {
          history.goBack();
          return;
        }

        const filterRes = response.data.filter(item => item.obr_total > 0);

        const percorre = filterRes.map(item => ({
          ...item,
          percFeito: item.percFeito.toFixed(2),
          percAtraso: item.percAtraso.toFixed(2),
          percAtrasoC: item.percAtrasoC.toFixed(2),
          obr_porcentagem: item.obr_porcentagem.toFixed(2),
          percAfazer: 100 - item.percFeito - item.percAtraso - item.percAtrasoC,
          percResultado: item.percFeito + item.percAtrasoC,
          contagem:
            item.obr_feitos + item.obr_nao_feitos + item.obr_nao_cliente,
        }));

        const objectsValues = percorre.map(item => ({
          name: item.name,
          percFeito: Number(item.percFeito),
          percAtraso: Number(item.percAtraso),
          percAtrasoC: Number(item.percAtrasoC),
        }));

        const mapObjects = objectsValues.map(item => Object.values(item));

        const sort = percorre.sort((a, b) => {
          if (a.percFeito > b.percFeito) {
            return -1;
          }

          if (a.percFeito < b.percFeito) {
            return 1;
          }

          return 0;
        });

        let nameExe = '';

        if (roleid !== 1 && tipo === 'executor') {
          nameExe = 'Executor(a)';
        } else {
          nameExe = executor ? 'Executores' : 'Analistas';
        }

        const headChart = [
          nameExe,
          'Feito no prazo %',
          'Feito fora do prazo %',
          'Feito fora do prazo c/ ressalva %',
        ];

        const addArray = mapObjects.concat([headChart]);

        addArray.reverse();

        const count_obr_total = percorre.reduce((accumulator, currentValue) => {
          const { obr_total } = currentValue;
          const count = accumulator + obr_total;
          return count;
        }, 0);

        const count_obr_feitos = percorre.reduce(
          (accumulator, currentValue) => {
            const { obr_feitos } = currentValue;
            const count = accumulator + obr_feitos;
            return count;
          },
          0
        );

        const count_obr_nao_feitos = percorre.reduce(
          (accumulator, currentValue) => {
            const { obr_nao_feitos } = currentValue;
            const count = accumulator + obr_nao_feitos;
            return count;
          },
          0
        );

        const count_obr_nao_cliente = percorre.reduce(
          (accumulator, currentValue) => {
            const { obr_nao_cliente } = currentValue;
            const count = accumulator + obr_nao_cliente;
            return count;
          },
          0
        );

        const contagemTotal = {
          count_obr_total,
          count_obr_feitos,
          count_obr_nao_feitos,
          count_obr_nao_cliente,
          count_obr_pendente:
            count_obr_total -
            count_obr_feitos -
            count_obr_nao_cliente -
            count_obr_nao_feitos,
          count_obr_soma_total:
            count_obr_total -
            count_obr_feitos -
            count_obr_nao_cliente -
            count_obr_nao_feitos,
        };

        const graficoPercorre = {
          ...contagemTotal,
          obr_total_percentagem:
            contagemTotal.count_obr_total - contagemTotal.count_obr_soma_total,
        };

        const porcentagensRes = {
          ...graficoPercorre,
          obr_total_percentagem:
            (graficoPercorre.obr_total_percentagem /
              graficoPercorre.count_obr_total) *
            100,
        };

        setTotal(porcentagensRes);

        setData(sort);
        setCharts(addArray);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    }

    loadobr();
  }, [
    cargo,
    cargoPermission,
    executor,
    funcao,
    getStorageFuncao,
    monthDate,
    profile.cargo.subtitle,
    profile.cargo.tipo,
    roleid,
    tipo,
    yearDate,
  ]);

  const handleSetor = useCallback(e => {
    if (e === 'open') {
      setDelFuncao(true);
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
    setDelFuncao(false);
    setFuncao(e);
  }, []);

  const handleModalMonth = useCallback(() => {
    setModalMonth(!modalMonth);
  }, [modalMonth]);

  const handleMonth = useCallback(
    monthYear => {
      const monthAddMore = monthYear.month + 1;
      setAnoCalendar(monthYear.year);
      setMesCalendar(monthAddMore);

      const monthFormatted = format(
        setMonth(new Date(), monthYear.month),
        'MMMM',
        {
          locale: pt,
        }
      );
      history.push(
        `/obrigacoes?month=${monthAddMore}&year=${monthYear.year}&executor=${executor}`
      );

      setModalMonth(false);
      setMesCalendarFormatted(monthFormatted);
    },
    [executor]
  );

  const handlePage = useCallback(event => {
    history.push(`/obrigacoes/executores/relatorios?${event}`);
  }, []);

  return (
    <>
      <Nav page="obrigacoes" />

      <Container flexDirection="column">
        <User />

        <Submenu>
          <MenuSub page="obrigacoes" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <div className="conteudo">
            <Title title="Obrigações" />

            <Search>
              <div className="bloco">
                <div className="form">
                  <button
                    type="button"
                    onClick={handleModalMonth}
                    className="btnModalMonthYear"
                  >
                    MÊS / ANO REFERÊNCIA: {mesCalendarFormatted.toUpperCase()} /{' '}
                    {anoCalendar}
                  </button>
                </div>
              </div>
            </Search>
          </div>

          <Bloco>
            <div>
              <h2>Obrigações</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          <div style={{ width: '98%' }}>
            <Chart
              width="100%"
              height="550px"
              chartType="Bar"
              loader={<div>Carregando...</div>}
              data={charts}
              options={{
                // Material design options
                colors: ['#00B259', '#FF4D4D', '#D96D00'],
                chart: {
                  title: 'Obrigações mensais - Gráficos',
                  subtitle: `Mês de referência de ${monthDate ||
                    mesCalendar} de ${yearDate || anoCalendar}`,
                },
              }}
              // For tests
              rootProps={{ 'data-testid': '2' }}
            />
          </div>
        </Card>

        <ContentGrid>
          {loading ? (
            <Grid>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <DivGrid>
                <GridButton
                  className="bg-red"
                  onClick={() =>
                    handlePage(
                      `year=${yearDate}&month=${monthDate}&obr=obr_feito_nao&executor=${executor}`
                    )
                  }
                >
                  <div className="divisao">
                    <div className="total">
                      <p>Obrigações em atraso</p>
                      <span className="blue">{total.count_obr_nao_feitos}</span>
                    </div>
                  </div>
                </GridButton>
                <GridButton
                  className="bg-orange"
                  onClick={() =>
                    handlePage(
                      `year=${yearDate}&month=${monthDate}&obr=obr_feito_fora_c&executor=${executor}`
                    )
                  }
                >
                  <div className="divisao">
                    <div className="total">
                      <p>Obrigações em atraso (Cliente)</p>
                      <span className="blue">
                        {total.count_obr_nao_cliente}
                      </span>
                    </div>
                  </div>
                </GridButton>
                <GridButton
                  className="bg-green"
                  onClick={() =>
                    handlePage(
                      `year=${yearDate}&month=${monthDate}&obr=obr_feito_prazo&executor=${executor}`
                    )
                  }
                >
                  <div className="divisao">
                    <div className="total">
                      <p>Obrigações em dia</p>
                      <span className="blue">{total.count_obr_feitos}</span>
                    </div>
                  </div>
                </GridButton>
                <div className="grid bg-black">
                  <div className="divisao">
                    <div className="total">
                      <p>Pendente / Total</p>
                      <span className="blue">
                        {' '}
                        {total.count_obr_pendente} / {total.count_obr_total}
                      </span>
                    </div>
                    <div className="totalprazo">
                      <span className="red">
                        {Number(total.obr_total_percentagem).toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>
              </DivGrid>
              {roleid === 1 && (
                <AnalistasExe
                  data={data}
                  executor={executor}
                  month={monthDate}
                  year={yearDate}
                />
              )}
              {roleid === 3 && (
                <AnalistasExe
                  data={data}
                  executor={executor}
                  month={monthDate}
                  year={yearDate}
                />
              )}
              {roleid === 4 && (
                <ExecutoresExe data={data} month={monthDate} year={yearDate} />
              )}
              {roleid === 6 && (
                <ExecutoresExe data={data} month={monthDate} year={yearDate} />
              )}
            </>
          )}
        </ContentGrid>

        <Footer />
      </Container>

      <ModalDialog close={handleModalMonth} width="sm" open={modalMonth}>
        <CalendarMonth
          handleClick={handleMonth}
          valueMonth={monthDate}
          valueYear={yearDate}
        />
      </ModalDialog>

      {roleid === 1 && (
        <>
          {delFuncao && (
            <Popup>
              <h4>Escolha a opção</h4>
              <div className="conteudo">
                <button type="button" onClick={() => handleSetor('pessoal')}>
                  Setor Pessoal
                </button>
                <button type="button" onClick={() => handleSetor('contabil')}>
                  Setor Contábil
                </button>
                <button type="button" onClick={() => handleSetor('tributos')}>
                  Setor Tributos
                </button>
                <button type="button" onClick={() => handleSetor('fiscal')}>
                  Setor Fiscal
                </button>
              </div>
            </Popup>
          )}
        </>
      )}
    </>
  );
}
