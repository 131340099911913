import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Topo = styled.div`
  background: #fff;
  height: 46px;
  width: 100%;
  display: flex;
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  .bloco {
    background: #0070df;
    border-radius: 4px;
    padding: 12px 8px;
    position: relative;

    &:hover {
      background: ${darken(0.06, '#0070df')};
    }

    h4 {
      font-size: 26px;
      line-height: 24px;
      color: #fff;
      text-align: center;
    }

    p {
      color: #eee;
      margin-top: 4px;
      font-size: 12px;
      text-align: center;
    }

    small {
      display: flex;
      flex: 1;
      margin-bottom: 4px;
      justify-content: center;
      font-size: 11px;
      color: #eee;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  div {
    display: flex;
    flex-direction: row;

    button {
      background: #49a3fc;
      padding: 10px 36px;
      color: #fff;
      align-items: center;
      border-radius: 6px;
      display: flex;
      border: 0px;
      margin-left: 5px;
      font-size: 14px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#49a3fc')};
      }
    }
  }

  .bloco {
    display: flex;
    flex: 1;

    div + div {
      margin-left: 8px;
    }
  }

  h2 {
    color: #0059b2;
    font-size: 26px;
  }
  p {
    padding-top: 8px;
  }
  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }
  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 9;
  display: ${Props => (Props.modal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentModal = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
  div {
    display: flex;
    button + button {
      margin-left: 4px;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .inputForm {
    width: 100%;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    input {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .vencimento {
    font-size: 32px;
    background: #eee;
    padding: 12px;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
  .btn-delete {
    border: 0;
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: ${darken(0.03, '#FF4D4D')};
    }
  }
`;

export const Submenu = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  flex: 1;

  ul {
    list-style: none;
    display: flex;

    li {
      list-style: none;
      display: block;
      font-size: 12px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #000000;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      a.active {
        background: #00468c;
        color: #fff;
      }

      button {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
        color: #fff;
        display: flex;
        border: none;
        background: #008c69;
        align-items: center;
        font-size: 12px;

        svg {
          margin-right: 4px;
        }

        &:hover {
          background: #00468c;
          color: #fff;
        }
      }

      svg:hover {
        color: #fff;
      }
    }

    li + li {
      border-left: 1px solid #dddddd;
    }
  }
`;
