import React, { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';

import {
  differenceInHours,
  format,
  differenceInDays,
  parseISO,
  isAfter,
  isEqual,
  addDays,
} from 'date-fns';

import { Form, Input } from '@rocketseat/unform';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';

import Lottie from 'lottie-react-web';

import {
  MdCheckCircle,
  MdCancel,
  MdAddCircle,
  MdVolumeDown,
  MdAttachFile,
} from 'react-icons/md';

import CheckboxGroup from 'react-checkbox-group';

import pt from 'date-fns/locale/pt';

import animation from '~/assets/skeleton.json';

import api from '~/services/api';
import { urlFile, urlRh } from '~/utils/url.json';

import {
  Content,
  Bloco,
  Card,
  BForm,
  Pesquisa,
  Messenger,
  FormInter,
  Perfil,
  Encerrado,
  FormList,
  AddSub,
  Files,
} from './styles';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Carregando from '~/components/Carregando/Button';
import history from '~/services/history';

import SemAvatar from '~/assets/no-avatar.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  list: {
    width: 450,
  },
  fullList: {
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Show() {
  const { id } = useParams();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(true);
  const [show, setTicket] = useState('');
  const [subcat, setSubcat] = useState([]);
  const [activity, setActivity] = useState();
  const [subcatfeitos, setSubcatfeitos] = useState([]);
  const [carregaBtn, setCarregaBtn] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(0);
  const [comments, setComments] = useState([]);
  const [demandas, setDemandas] = useState([]);
  const [interacoes, setInteracoes] = useState([]);
  const [check, setCheck] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersSec, setUsersSec] = useState([]);
  const [openUser, setOpenUser] = useState(false);
  const [usuarioSecundario, setUsuarioSecundario] = useState(false);
  const [openDemanda, setOpenDemanda] = useState(false);
  const [carregaDialog, setCarregaDialog] = useState(false);
  const [disable, setDisable] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadfile] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);

  const [loadPage, setLoadPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [demanda, setDemanda] = useState({
    read: '',
    option: '',
    color: '',
  });
  const loadingOpen = openUser && users.length === 0;

  const handleClickOpen = useCallback(e => {
    setUsuarioSecundario(e);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const classes = useStyles();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const loadInteracoes = useCallback(async () => {
    const inter = await api.get(`ticket/${id}?interacoes=true`);

    const percorre = inter.data.map(item => ({
      ...item,
      dateFormatted: format(
        parseISO(item.created_at),
        "dd 'de' MMMM', às ' HH:mm'h'",
        {
          locale: pt,
        }
      ),
    }));

    setInteracoes(percorre);
  }, [id]);

  const loadUsersSec = useCallback(async () => {
    const usersSecRes = await api.get(`ticket/${id}?usersSec=true`);

    setUsersSec(usersSecRes.data);
  }, [id]);

  useEffect(() => {
    if (!loadingOpen) {
      return;
    }
    async function loadClientes() {
      const response = await api.get(`/users?limit=1000`);

      setUsers(response.data.data);
    }

    loadClientes();
  }, [loadingOpen]);

  useEffect(() => {
    async function loadTicket() {
      setLoading(true);
      try {
        const response = await api.get(`ticket/${id}`);
        const commentResponse = await api.get(
          `comments/tickets/${response.data.id}`
        );

        const getDemanda = await api.get(
          `tb/geral?tb=show_ticket&setor=${response.data.setor}`
        );

        const verifyIsqual = isEqual(
          parseISO(response.data.date),
          parseISO(response.data.date_entregue)
        );

        const dados = {
          ...response.data,
          activity: response.data.activity.title,
          idActivity: response.data.activity.id,
          user: response.data.user.name,
          dadosCat: response.data.subcat.map(item => item.id),
          dateFormatted: format(
            parseISO(response.data.created_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
          dateVerify:
            verifyIsqual ||
            isAfter(
              parseISO(response.data.date),
              parseISO(response.data.date_entregue)
            ),
          retorno: differenceInDays(parseISO(response.data.date), new Date()),
          razao: response.data.cliente_id ? response.data.cliente.razao : null,
          hours: differenceInHours(
            new Date(),
            parseISO(response.data.created_at)
          ),
        };

        const showHour = dados.hours > 1;

        const trueRes = await api.get(
          `tb/geral?tb=ticket&status=true&whereIn=${dados.dadosCat.toString()}`
        );

        if (
          dados.sigilo &&
          profile.id !== dados.usuario &&
          profile.id !== dados.user_id
        ) {
          history.push('/tickets');
          return;
        }

        const dadosComment = commentResponse.data.map(item => ({
          ...item,
          created_at: format(
            parseISO(item.created_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }));

        const subcatArray = dados.subcat.map(item => ({
          ...item,
          prazo_date: differenceInDays(parseISO(item.prazo_date), new Date()),
        }));

        if (response.data.id_tb) {
          const find = getDemanda.data.find(
            item => item.id === response.data.id_tb
          );
          setDemanda({
            ...demanda,
            color: find.comando.cor,
            read: find.id,
          });
        } else {
          setDemanda({
            ...demanda,
            option: response.data.read,
            color: response.data.read === 'cancelado' ? '#FF4848' : '',
          });
        }

        setDemandas(getDemanda.data);
        setComments(dadosComment);
        setTicket(dados);
        setActivity(trueRes.data);
        setCheck(dados.dadosCat);
        setSubcat(subcatArray);
        setSubcatfeitos(dados.subcatFeitos);
        setLoading(false);
        setDisable(showHour);
        loadInteracoes();

        loadUsersSec();
      } catch (err) {
        toast.warning(err.message, {
          position: 'top-center',
        });
        if (err.message === 'Request failed with status code 404') {
          history.push('/dashboard');
        }
      }
    }

    loadTicket();
  }, [id, profile.id, loadPage]); // eslint-disable-line

  const handleComment = useCallback(
    async ({ comment }) => {
      try {
        setLoadingComment(true);
        const filesIds = files.map(file => file.id);

        const response = await api.post('ticket', {
          messeger: comment,
          usuario: show.user_id,
          id_ticket: show.id,
          commentnotification: true,
          files: filesIds,
        });

        const dadosComment = {
          ...response.data,
          created_at: format(new Date(), "dd 'de' MMMM', às ' HH:mm'h'", {
            locale: pt,
          }),
        };

        const dados = comments.concat(dadosComment);

        toast.success('Resposta adicionado com sucesso.');

        setFiles([]);
        setComments(dados);
        setLoadingComment(false);
      } catch (err) {
        toast.error('Error! Algo deu errado.');
        setLoadingComment(false);
      }
    },
    [comments, show.id, show.user_id, files]
  );

  const handleFinaliza = useCallback(
    async event => {
    const verifyNumber = isNaN(event); // eslint-disable-line

      if (!verifyNumber) {
        const find = demandas.find(item => item.id === Number(event));
        setDemanda({ ...demanda, option: event, color: find.comando.cor });
      } else {
        setDemanda({ ...demanda, option: event });
      }

      try {
        await api.put(`ticket/${id}`, {
          read: !verifyNumber ? 'aguardando' : event,
          id_tb: !verifyNumber ? event : null,
          userfeito: show.usuario,
          userSec: show.usuarios_sec,
          messeger: show.messeger,
          idTicket: id,
          date_entregue: format(new Date(), 'yyyy-MM-dd'),
        });

        const dadosShow = {
          ...show,
          read: true,
        };

        setTicket(dadosShow);

        toast.success('Chamado Finalizado');
      } catch (err) {
        toast.error('Algo deu errado no chamado');
      }
    },
    [demanda, demandas, id, show]
  );

  const handleCreateSub = useCallback(
    async e => {
      setLoadingBtn(e);

      const join = {
        obs: 'Marcado como Feito',
        idSub: e,
      };

      try {
        const response = await api.post('ticket', {
          usuario: show.user_id,
          notification: show.activity,
          subcat: join,
          read: true,
          id_ticket: show.id,
          createSub: true,
        });

        const convert = subcatfeitos.concat(response.data);

        if (subcat.length === convert.length) {
          const resTicketSub = await api.put(`ticket/${show.id}`, {
            subcatread: true,
          });

          const verifyIsqual = isEqual(
            parseISO(resTicketSub.data.date),
            parseISO(resTicketSub.data.date_entregue)
          );

          const modifidTicket = {
            ...show,
            dateVerify:
              verifyIsqual ||
              isAfter(
                parseISO(resTicketSub.data.date),
                parseISO(resTicketSub.data.date_entregue)
              ),
          };

          setTicket(modifidTicket);
        }

        setSubcatfeitos(convert);

        setLoadingBtn(0);

        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado.');
        setLoadingBtn(0);
      }
    },
    [show, subcat.length, subcatfeitos]
  );

  const handleInteracao = useCallback(
    async e => {
      const messeger = document.querySelector(`#interacao${e.idProcesso}`)
        .value;

      if (!messeger) {
        toast.error('Por favor! o campo está vázio.');
        return;
      }

      try {
        const { data } = await api.post('ticket', {
          interacao: show.activity,
          idUser: show.user_id,
          subcat: e.idProcesso,
          id_ticket: id,
          messeger,
        });

        const dataFinal = {
          ...data,
          dateFormatted: format(
            parseISO(data.created_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        };

        const object = { dataFinal };

        const convert = Object.values(object); // Transforma objeto em array

        const atualiza = convert.concat(interacoes);

        setInteracoes(atualiza);

        toast.success('Sucesso! Atualizado interação');
      } catch (err) {
        toast.error(err.message);
      }
    },
    [id, interacoes, show.activity, show.user_id]
  );

  const handleUpdate = useCallback(async () => {
    try {
      setCarregaBtn(true);

      await api.put(`ticket/${id}`, {
        subcat: check,
      });

      setState({ ...state, right: false });
      setLoadPage(!loadPage);
      setCarregaBtn(false);

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }, [check, id, loadPage, state]);

  const handleUser = useCallback(async () => {
    const username = document.querySelector('#user').value;

    if (!username) {
      toast.warning('Por favor! Preencha o usuário.');
      return;
    }

    const verificaSec =
      usersSec.length > 0
        ? usersSec.find(item => item.name === username)
        : false;

    if (verificaSec) {
      toast.warning('Ops! Esse usuário já foi cadastrado');
      return;
    }

    const dados = users.find(item => item.name === username);

    const user = {
      id: dados.id,
      name: dados.name,
      file_id: dados.file_id,
      file: dados.file,
      avatar: dados.avatar,
    };

    try {
      await api.put(`ticket/${id}`, {
        usuarios_sec: user,
        messenger: show.messeger,
        userID: user.id,
      });

      setOpen(false);

      setUsersSec([user, ...usersSec]);
      setUsuarioSecundario(true);
      toast.success('Atualizado com Sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }, [id, show.messeger, users, usersSec]);

  const handleUpdateUser = useCallback(async () => {
    const username = document.querySelector('#user').value;

    if (!username) {
      toast.warning('Ops! Preencha o campo e depois altera.');
      return;
    }

    const findUser = users.find(user => user.name === username);
    try {
      setCarregaDialog(true);
      const ticketData = await api.put(`update/usuario/ticket/${id}`, {
        usuarioSecundario: show.usuario_write ? 'true' : '',
        user_id: findUser.id,
      });

      const ticket = ticketData.data;

      const showTicket = {
        ...show,
        userPriImage: ticket.userPriImage,
        userSecImage: ticket.userSecImage,
        userSecName: ticket.userSecName,
        user: ticket.user,
        usuario_write: ticket.usuario_write,
        usuario: ticket.usuario,
      };

      setTicket(showTicket);
      setOpen(false);
      setCarregaDialog(false);
      toast.success('Atualizado com Sucesso.');
    } catch (err) {
      toast.error(err.message);
      setOpen(false);
      setCarregaDialog(false);
    }
  }, [id, show, users]);

  const handleNivel = useCallback(
    async event => {
      try {
        const { value } = event.target;

        let addDay = 0;

        if (value === 'baixa') {
          addDay = 2;
        }

        if (value === 'media') {
          addDay = 1;
        }

        const dateAddDay = format(addDays(new Date(), addDay), 'yyy-MM-dd');

        await api.put(`update/nivel/ticket/${show.token}`, {
          date: dateAddDay,
          nivel: value,
        });

        const percorreTicket = {
          ...show,
          date: dateAddDay,
          retorno: differenceInDays(parseISO(dateAddDay), new Date()),
          nivel: value,
        };

        setTicket(percorreTicket);

        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error(err.message);
      }
    },
    [show]
  );

  const handleReabrir = useCallback(async () => {
    const msgdemanda = document.querySelector('#msgdemanda').value;

    if (!msgdemanda) {
      toast.warning('Por favor! Escreva o motivo da abertura.');
      return;
    }

    await api.put(`ticket/${id}`, {
      reopening: { msg: msgdemanda, date_entregue: show.date_entregue },
    });

    const percorreD = {
      ...show,
      reopening: { msg: msgdemanda, date_entregue: show.date_entregue },
      read: 'aguardando',
      date_entregue: null,
      dateVerify: false,
    };

    setDemanda({ read: '', option: '', color: '' });

    setTicket(percorreD);
    setOpenDemanda(false);
    toast.success('Chamando reaberto com sucesso.');
  }, [id, show]);

  const handleDelete = useCallback(
    async e => {
      try {
        const res = await api.delete(`ticket/${id}?userSec=${e}`);

        setUsersSec(res.data);

        toast.success('Deletado com sucesso.');
      } catch (err) {
        toast.error('Erro ao deletar');
      }
    },
    [id]
  );

  const toggleDrawer = anchor => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, right: anchor.open });
  };

  const list = anchor => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]:
            anchor.anchor === 'top' || anchor.anchor === 'bottom',
        })}
        role="presentation"
        onKeyDown={toggleDrawer({
          anchor: 'right',
          open: true,
        })}
      >
        <FormList>
          <h4>Selecionar mais subprocessos</h4>
        </FormList>
        {subcat.length > 0 && (
          <>
            <CheckboxGroup name="check" value={check} onChange={setCheck}>
              {Checkbox => (
                <>
                  {activity.map(item => (
                    <ListItem key={item.id}>
                      <ListItemIcon>
                        <Checkbox value={item.id} />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </>
              )}
            </CheckboxGroup>
            <FormList>
              <button type="button" onClick={() => handleUpdate('update')}>
                {carregaBtn ? 'CARREAGNDO...' : 'ADICIONAR LISTA'}
              </button>
            </FormList>
          </>
        )}

        <Divider />
      </div>
    </>
  );

  const handleFile = useCallback(async () => {
    toast.warning('Sem servidor para upload.');
    // setLoadFile(true);

    // const dados = new FormData();
    // dados.append('file', e.target.files[0]);

    // try {
    //   const { data } = await api.post('files?ticket=true', dados);

    //   setFiles([data, ...files]);
    //   setLoadFile(false);
    // } catch (err) {
    //   toast.error('Algo deu errado..');
    //   setLoadFile(false);
    // }
  }, []);

  const handleDeleteFile = useCallback(
    async event => {
      try {
        await api.delete(`files/${event}`);

        toast.success('Deletado com sucesso.');

        const filterFiles = files.filter(file => file.id !== event);

        setFiles(filterFiles);
      } catch (err) {
        toast.error(err.message);
      }
    },
    [files]
  );

  return (
    <>
      <Nav page="tickets" />
      <Container>
        <User />

        <Content>
          <Title title={`Ticket / ${show.activity}`} />

          <Bloco>
            <div>
              <h2>Ticket / {show.activity}</h2>
            </div>
          </Bloco>

          <Pesquisa>
            {!loading && (
              <>
                <div className="bloco-finalizar">
                  {show.dateVerify ? (
                    <>
                      <div className="ativo">Feito dentro do Prazo</div>
                      {profile.id === show.user_id && (
                        <button onClick={() => setOpenDemanda(true)}>
                          Reabrir Demanda
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {show.read === 'cancelado' ? (
                        <>
                          <div className="encerrado">Cancelado</div>
                          {profile.id === show.user_id && (
                            <button onClick={() => setOpenDemanda(true)}>
                              Reabrir Demanda
                            </button>
                          )}
                        </>
                      ) : (
                        <div
                          className={show.retorno < 0 ? 'encerrado' : 'ativo'}
                        >
                          {show.retorno < 0 ? (
                            'Encerrado fora do prazo'
                          ) : (
                            <>
                              {show.retorno === 0
                                ? `Data restante para o retorno: é hoje`
                                : `Data restante para o retorno: Faltam ${show.retorno} dias`}
                            </>
                          )}
                        </div>
                      )}
                      {show.read === 'finalizado' &&
                        profile.id === show.user_id && (
                          <button onClick={() => setOpenDemanda(true)}>
                            Reabrir Demanda
                          </button>
                        )}
                    </>
                  )}
                  {show.read === 'finished' &&
                    subcat.length === subcatfeitos.length && (
                      <div className="bloco-one">
                        <button type="button" className="btn-blue">
                          <MdVolumeDown size={18} color="#fff" /> Comunicar
                          Processo (Setores)
                        </button>
                      </div>
                    )}
                </div>
              </>
            )}
          </Pesquisa>
        </Content>

        <Content
          demanda={show.read === 'finalizado' ? '#36D900' : demanda.color}
        >
          {!loading && (
            <div className="form">
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Andamento do Chamado
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={demanda.option || demanda.read}
                  onChange={e => handleFinaliza(e.target.value)}
                  label="Andamento do Chamado"
                  disabled={show.read === 'finalizado'}
                >
                  <MenuItem value="">Aguardando</MenuItem>
                  {demandas.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                  <MenuItem value="finalizado">Finalizado</MenuItem>
                  <MenuItem value="cancelado">Cancelado</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </Content>

        {!loading && profile.id === show.user_id && (
          <Content>
            <div className="nivel" onChange={handleNivel}>
              <h4>Nível do chamado</h4>
              <select name="nivel" disabled={disable}>
                <option value="baixa" selected={show.nivel === 'baixa'}>
                  Baixa
                </option>
                <option value="media" selected={show.nivel === 'media'}>
                  Média
                </option>
                <option value="alto" selected={show.nivel === 'alto'}>
                  Alta
                </option>
              </select>
            </div>
          </Content>
        )}

        <Card>
          {loading ? (
            <BForm>
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </BForm>
          ) : (
            <BForm>
              <div className="bloco-conteudo">
                <span>
                  <h5>{show.cliente_id ? show.razao : show.cliente_sec}</h5>
                  <p>Mensagem</p>
                  <div className="formbtn">
                    <button onClick={() => history.push('#formmensagem')}>
                      +
                    </button>
                    <textarea className="form-text" disabled>
                      {show.messeger}
                    </textarea>
                  </div>
                  {show.reopening && (
                    <div className="reaberto">
                      <p>Chamado Reaberto</p>
                      <span>{show.reopening.msg}</span>
                    </div>
                  )}
                  <div>
                    {show.usuario_write ? (
                      <div className="usuarioShow">
                        {show.usuario_write.file_id ? (
                          <img
                            src={`${urlFile}${show.usuario_write.avatar}`}
                            alt={show.usuario_write.name}
                          />
                        ) : (
                          <>
                            {show.usuario_write.avatar ? (
                              <img
                                src={`${urlRh}${show.usuario_write.avatar}`}
                                alt={show.usuario_write.name}
                              />
                            ) : (
                              <img
                                src={SemAvatar}
                                alt={show.usuario_write.name}
                              />
                            )}
                          </>
                        )}

                        <span>
                          <p>por: {show.usuario_write.name}</p>
                          <small>publicado: {show.dateFormatted}</small>
                        </span>
                      </div>
                    ) : (
                      <div className="usuarioShow">
                        {show.userPriImage ? (
                          <img src={show.userPriImage} alt={show.user} />
                        ) : (
                          <img src={SemAvatar} alt={show.user} />
                        )}
                        <span>
                          <p>por: {show.user}</p>
                          <small>publicado: {show.dateFormatted}</small>
                        </span>
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="showUsuario">
                      <div className="usuarioShow">
                        {show.userSecImage ? (
                          <img src={show.userSecImage} alt={show.userSecName} />
                        ) : (
                          <img src={SemAvatar} alt={show.userSecName} />
                        )}
                        <span>
                          <p>para: {show.userSecName}</p>
                        </span>
                      </div>
                      {show.usuario === profile.id &&
                        show.read === 'aguardando' && (
                          <button
                            type="button"
                            onClick={() => handleClickOpen(true)}
                          >
                            Alterar demanda para outro usuário
                          </button>
                        )}
                    </div>
                  </div>

                  {profile.id === show.user_id && show.read === 'aguardando' && (
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleClickOpen(false)}
                      >
                        Adicionar Usuário
                      </Button>
                    </div>
                  )}

                  {!show.usuario_write &&
                    profile.id === show.usuario &&
                    show.read === 'aguardando' && (
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleClickOpen(false)}
                        >
                          Adicionar Usuário
                        </Button>
                      </div>
                    )}
                </span>
                {usersSec.length > 0 && (
                  <div className="usuarioSec">
                    <h4>Usuários Secundários</h4>
                    <div className="colunaUser">
                      <div className={classes.root}>
                        {usersSec.map(item => (
                          <>
                            {profile.id === show.user_id ? (
                              <Chip
                                key={item.id}
                                avatar={
                                  <Avatar
                                    alt={item.name}
                                    src={
                                      item.file
                                        ? `${urlFile}${item.file.file}`
                                        : `${urlRh}${item.avatar}`
                                    }
                                  />
                                }
                                label={item.name}
                                onDelete={() => handleDelete(item.id)}
                              />
                            ) : (
                              <>
                                {!show.usuario_write &&
                                profile.id === show.usuario ? (
                                  <Chip
                                    key={item.id}
                                    avatar={
                                      <Avatar
                                        alt={item.name}
                                        src={
                                          item.file
                                            ? `${urlFile}${item.file.file}`
                                            : `${urlRh}${item.avatar}`
                                        }
                                      />
                                    }
                                    label={item.name}
                                    onDelete={() => handleDelete(item.id)}
                                  />
                                ) : (
                                  <Chip
                                    key={item.id}
                                    avatar={
                                      <Avatar
                                        alt={item.name}
                                        src={
                                          item.file
                                            ? `${urlFile}${item.file.file}`
                                            : `${urlRh}${item.avatar}`
                                        }
                                      />
                                    }
                                    label={item.name}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </BForm>
          )}
        </Card>

        {subcat.length > 0 && (
          <Card marginTop>
            {show.read === 'finalizado' && <Encerrado />}

            <AddSub>
              <h3>Subprocesso</h3>
              {profile.id === show.user_id && (
                <button
                  type="button"
                  onClick={toggleDrawer({
                    anchor: 'right',
                    open: true,
                  })}
                >
                  Adicionar ou Editar Subprocesso
                </button>
              )}
            </AddSub>
            <table className="tab table-striped">
              <thead>
                <tr>
                  <td width="15%">Ação</td>
                  <td width="21%">Título</td>
                  <td width="38%">Interações</td>
                  <td width="16%">Comando</td>
                </tr>
              </thead>
              <tbody>
                {subcat.map(item => (
                  <tr
                    className={
                      subcatfeitos.find(
                        elem => elem.subcat.idSub === item.id
                      ) && 'successTr'
                    }
                  >
                    <td>
                      {subcatfeitos.find(
                        elem => elem.subcat.idSub === item.id
                      ) ? (
                        <MdCheckCircle size={18} color="#0faf69" />
                      ) : (
                        <MdCancel size={18} color="#FF5353" />
                      )}
                    </td>
                    <td>{item.title}</td>
                    <td>
                      <FormInter>
                        {subcatfeitos.find(
                          elem => elem.subcat.idSub === item.id
                        ) ? (
                          ''
                        ) : (
                          <>
                            <button type="button" className="interacao">
                              <MdAddCircle
                                size={22}
                                color="#0faf69"
                                onClick={() =>
                                  handleInteracao({
                                    idProcesso: item.id,
                                    idTicket: show.id,
                                  })
                                }
                              />
                            </button>

                            <div>
                              <Perfil>
                                <TextField
                                  id={`interacao${item.id}`}
                                  label=""
                                  placeholder="Adicionar interação..."
                                />
                              </Perfil>
                            </div>
                          </>
                        )}
                        {interacoes.map(
                          inter =>
                            Number(inter.subcat) === item.id && (
                              <div>
                                <Perfil>
                                  {inter.avatar ? (
                                    <img
                                      src={inter.avatar}
                                      alt={inter.username}
                                    />
                                  ) : (
                                    <img src={SemAvatar} alt={inter.username} />
                                  )}

                                  <div>
                                    <div className="mensagem">
                                      {inter.messeger}
                                    </div>
                                    <p>
                                      por {inter.username} | Publicado:{' '}
                                      {inter.dateFormatted}
                                    </p>
                                  </div>
                                </Perfil>
                              </div>
                            )
                        )}
                      </FormInter>
                    </td>
                    <td align="right">
                      {subcatfeitos.find(
                        elem => elem.subcat.idSub === item.id
                      ) ? (
                        <>
                          <div className="atualizado">
                            <img
                              src={
                                subcatfeitos.find(
                                  elem => elem.subcat.idSub === item.id
                                ).avatar
                              }
                              alt={
                                subcatfeitos.find(
                                  elem => elem.subcat.idSub === item.id
                                ).username
                              }
                            />
                            <span>
                              <p>
                                por{' '}
                                {
                                  subcatfeitos.find(
                                    elem => elem.subcat.idSub === item.id
                                  ).username
                                }
                              </p>
                              <small>
                                Atualização:{' '}
                                {
                                  subcatfeitos.find(
                                    elem => elem.subcat.idSub === item.id
                                  ).created_at
                                }
                              </small>
                            </span>
                          </div>
                        </>
                      ) : (
                        <button
                          type="button"
                          className="btn-warning"
                          onClick={() => handleCreateSub(item.id)}
                        >
                          {loadingBtn === item.id ? (
                            <Carregando />
                          ) : (
                            'Marcar como Feito'
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        )}

        <Messenger>
          {comments.map(comment => (
            <div className="comments" key={comment.id}>
              <img src={comment.avatar} alt={comment.username} />
              <span>
                <div>{comment.messeger}</div>
                <p>
                  Publicado: {comment.created_at} / por {comment.username}
                </p>
              </span>
            </div>
          ))}

          <Form onSubmit={handleComment} id="formmensagem">
            <div className="inputAnexo">
              <label>
                <MdAttachFile size={42} color="#585858" />

                <input
                  type="file"
                  id="arquivos"
                  multiple={false}
                  name="file"
                  accept=".jpeg, .jpg, .png, .pdf, .docx, .txt, .xlsx, .xml"
                  onChange={handleFile}
                />
              </label>
              <Input
                name="comment"
                disabled={show.read === 'finalizado'}
                placeholder="Digite um andamento"
              />
            </div>
            <Files>
              {loadfile && (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              )}
              {files.map(file => (
                <Chip
                  label={file.name}
                  onDelete={() => handleDeleteFile(file.id)}
                />
              ))}
            </Files>
            {show.read === 'finalizado' ? (
              <button type="button" className="btn-gray">
                Enviar Andamento
              </button>
            ) : (
              <button type="submit" className="btn-blue">
                {loadingComment ? 'Carregando...' : 'Enviar Andamento'}
              </button>
            )}
          </Form>
        </Messenger>
      </Container>

      <Footer />

      <div>
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!usuarioSecundario ? (
          <>
            <DialogTitle id="form-dialog-title">Usuário Secundário</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Adicione o usuário abaixo para fazer parte do ticket.
              </DialogContentText>
              <Autocomplete
                id="user"
                open={openUser}
                onOpen={() => {
                  setOpenUser(true);
                }}
                onClose={() => {
                  setOpenUser(false);
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={option => option.name}
                options={users}
                loading={loadingOpen}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Usuários"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingOpen ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleUser} color="primary">
                Adicionar
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="form-dialog-title">
              Alterar Demanda Usuário
            </DialogTitle>
            <DialogContent>
              {carregaDialog ? (
                'Carregando...'
              ) : (
                <>
                  <DialogContentText>
                    Escolha o usuário abaixo para alterar a demanda desse ticket
                    e depois não poderá mais ser alterado.
                  </DialogContentText>
                  <Autocomplete
                    id="user"
                    open={openUser}
                    onOpen={() => {
                      setOpenUser(true);
                    }}
                    onClose={() => {
                      setOpenUser(false);
                    }}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={option => option.name}
                    options={users}
                    loading={loadingOpen}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Usuários"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingOpen ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleUpdateUser} color="primary">
                Alterar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={openDemanda}
        onClose={() => setOpenDemanda(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reabrir Demanda</DialogTitle>
        <DialogContent>
          <DialogContentText>Motivo da abertura da demanda?</DialogContentText>
          <div style={{ width: 380 }}>
            <TextField
              label="Preencha aqui"
              id="msgdemanda"
              style={{ width: '100%' }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDemanda(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleReabrir} color="primary">
            Abrir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
