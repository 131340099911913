import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  span {
    display: flex;
    flex-direction: column;

    select {
      margin-top: 5px;
      font-size: 12px;

      option {
        font-size: 12px;
      }
    }

    p {
      font-size: 12px;
      color: #999;
    }
  }

  input {
    font-size: 12px;
  }

  label {
    font-size: 12;
  }

  select {
    option {
      font-size: 12px;
    }
  }

  .ativo {
    color: #0faf69;
  }

  .inativo {
    color: #c9321f;
  }

  div {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;

    .fiscal {
      display: flex;
      background: #0faf69;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .contabil {
      display: flex;
      background: #00468c;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }

    .tributos {
      display: flex;
      background: #ff5c26;
      color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  span {
    padding: 22px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  a {
    background: #0059b2;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .delete {
    background: #ff4d4d;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }

  .save {
    background: #008c69;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.06, '#008C69')};
    }
  }

  button {
    background: #ff8000;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: ${darken(0.06, '#FF8000')};
    }

    & + button {
      margin-left: 5px;
    }
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  form {
    position: relative;
    margin-top: 0;
    padding-top: 0;
    padding-left: 10px;

    input {
      height: 35px;
      border: 0;
      border-radius: 6px;
      padding-left: 6px;
      padding-right: 6px;
      font-size: 15px;
      width: 200px;
    }

    button {
      border: 0;
      background: none;
      position: absolute;
      z-index: 6;
      top: 3px;
      right: 0;
    }
  }

  .search {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }

  .bloco-one {
    display: flex;
    align-items: center;

    .save {
      background: #00b259;
      color: #fff;
      font-size: 12px;
      border: 0;
      border-radius: 4px;
      padding: 6px 10px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#00b259')};
      }
    }

    .cancelar {
      background: #ff8000;
      color: #fff;
      font-size: 12px;
      border: 0;
      border-radius: 4px;
      padding: 6px 10px;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#ff8000')};
      }
    }
  }
`;
