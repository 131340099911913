import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';

import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, BForm, GridT, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function PrimeiroContato() {
  const { idCliente } = useParams();
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState('');
  const [tabs, setTabs] = useState([]);
  const [loadUser, setLoadUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [cargo, setCargo] = useState([]);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/primeiro-contato`).then(response => {
      setContatos(response.data.data);
    });

    api.get('tb/geral?tb=contato_cliente').then(response => {
      setTabs(response.data);
    });

    api.get(`cargos?search=%%&limit=70`).then(response => {
      setCargo(response.data.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  async function handleSubmit({ date_feito, falou, feito_via, date_visita }) {
    const cargo_id = document.querySelector('.cargo_id').value;
    const user_id = document.querySelector('.user_id').value;

    if (!cargo_id) {
      toast.warning('Por favor! Preencha o campo Selecione o setor');
      return;
    }

    if (!falou) {
      toast.warning('Por favor! Preencha o campo Falou com');
      return;
    }

    if (!feito_via) {
      toast.warning('Por favor! Preencha o campo Feito via');
      return;
    }

    setLoading(true);

    try {
      const { data } = await api.post(
        `clientes/${idCliente}/primeiro-contato`,
        {
          date_feito,
          falou,
          feito_via,
          date_visita,
          cargo_id,
          user_id,
        }
      );

      toast.success('Atualizado com sucesso.');
      setLoading(false);

      const object = { data };

      const convert = Object.values(object); // Transforma objeto em array

      const dadosArray = convert.concat(contatos);

      setContatos(dadosArray.map(item => item));
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado no cadastro.');
    }
  }

  async function handleCargo(e) {
    setLoadUser(true);
    const usuario = await api.get(`users?setor=${e.target.value}`);

    setLoadUser(false);
    setUsers(usuario.data.data);
  }

  async function handleUpdate({
    id,
    cargo_id,
    user_id,
    date_feito,
    falou,
    date_visita,
  }) {
    try {
      const feito_via = document.querySelector('.feito_via').value;
      await api.put(`clientes/${idCliente}/primeiro-contato?id=${id}`, {
        cargo_id,
        user_id,
        date_feito,
        falou,
        feito_via,
        date_visita,
      });

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado na atualização.');
    }
  }

  async function handleDelete(id) {
    try {
      const resDel = await api.delete(
        `delete/${idCliente}/primeiro-contato?id=${id}`
      );

      toast.success(resDel.data.success);
      setContatos(contatos.filter(item => item.id !== id));
    } catch (err) {
      toast.error('Algo deu errado no delete. Tente mais tarde.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="primeiro-contato"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Selecione o setor *</label>
                <select className="cargo_id" onChange={handleCargo}>
                  <option value="">...</option>
                  {cargo.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label>Feito em</label>
                <Input type="date" name="date_feito" />
              </span>
              <span>
                <label>Falou com *</label>
                <Input type="text" name="falou" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Feito via *</label>
                <Select name="feito_via" options={tabs} />
              </span>
              <span>
                <label>Feito por</label>
                <select className="user_id">
                  <option value="">{loadUser ? 'carregando...' : '...'}</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </span>
              <span>
                <label>Visita em</label>
                <Input type="date" name="date_visita" />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {contatos.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <Input type="hidden" name="cargo_id" />
              <Input type="hidden" name="user_id" />
              <BForm>
                <h3>{item.cargo ? item.cargo.title : 'Sem título'}</h3>
              </BForm>
              <BForm>
                <span>
                  <label>Feito em</label>
                  <Input type="date" name="date_feito" />
                </span>
                <span>
                  <label>Falou com</label>
                  <Input type="text" name="falou" />
                </span>
                <span>
                  <label>Feito via</label>
                  <select className="feito_via">
                    {tabs.map(tab => (
                      <option
                        value={tab.id}
                        selected={item.feito_via === tab.id || false}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Feito por</label>
                  <input type="text" value={item.user.name} disabled />
                </span>
                <span>
                  <label>Visita em</label>
                  <Input type="date" name="date_visita" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
