import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import configureStore from './store/sw';
// import { SW_INIT, SW_UPDATE } from './Types/sw';

// const store = configureStore();

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: registration =>
//     store.dispatch({ type: SW_UPDATE, payload: registration }),
// });
