import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { MdSave, MdDelete } from 'react-icons/md';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Licencas() {
  const { idCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [tabsLinc, setTabsLinc] = useState([]);
  const [cliente, setCliente] = useState('');
  const [lics, setLics] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/licencas`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setLics(percorre);
    });

    api.get('tb/geral?tb=lincencas').then(response => {
      setTabsLinc(response.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const options = [
    { id: 1, title: '1 Dias' },
    { id: 2, title: '2 Dias' },
    { id: 3, title: '3 Dias' },
    { id: 4, title: '4 Dias' },
    { id: 5, title: '5 Dias' },
    { id: 10, title: '10 Dias' },
    { id: 15, title: '15 Dias' },
    { id: 30, title: '30 Dias' },
    { id: 45, title: '45 Dias' },
    { id: 60, title: '60 Dias' },
    { id: 90, title: '90 Dias' },
  ];

  const handleSubmit = useCallback(
    async ({ n_doc, data_venc, aviso, n_proto, data_protocolo, tb_all_id }) => {
      if (!tb_all_id) {
        toast.warning('Por favor! Preencha o campo Tipo da funcionalidade');
        return;
      }

      if (!n_doc) {
        toast.warning('Por favor! Preencha o campo Nº Documento');
        return;
      }

      if (!data_protocolo) {
        toast.warning('Por favor! Preencha o campo Data limite protocolado');
        return;
      }

      if (!aviso) {
        toast.warning('Por favor! Preencha o campo Avisar em');
        return;
      }

      setLoading(true);

      try {
        const { data } = await api.post(`clientes/${idCliente}/licencas`, {
          n_doc,
          data_venc,
          aviso,
          n_proto,
          data_protocolo,
          tb_all_id,
        });

        if (data.error) {
          toast.warning(data.error);
          setLoading(false);
          return;
        }

        setLoading(false);
        toast.success('Licença cadastrado com sucesso.');

        const object = { data };

        const convert = Object.values(object); // Transforma objeto em array

        const dadosArray = convert.concat(lics);

        setLics(
          dadosArray.map(item => ({
            ...item,
            dateFormatted: format(
              parseISO(item.updated_at),
              "dd 'de' MMMM', às ' HH:mm'h'",
              {
                locale: pt,
              }
            ),
          }))
        );
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
        console.tron.log(err);
      }
    },
    [idCliente, lics]
  );

  const handleUpdate = useCallback(
    async ({ id, n_doc, data_venc, n_proto, data_protocolo, tb_all_id }) => {
      try {
        const aviso = document.querySelector(`.form_aviso${id}`).value;
        await api.put(`clientes/${idCliente}/licencas?id=${id}`, {
          n_doc,
          data_venc,
          aviso,
          n_proto,
          data_protocolo,
          tb_all_id,
        });

        toast.success('Licença atualizado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado na atualização.');
      }
    },
    [idCliente]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        const resDel = await api.delete(
          `delete/${idCliente}/licencas?id=${id}`
        );

        toast.success(resDel.data.success);
        setLics(lics.filter(item => item.id !== id));
      } catch (err) {
        toast.error('Algo deu errado no delete. Tente mais tarde.');
      }
    },
    [idCliente, lics]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="licenca"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Tipo da funcionalidade *</label>
                <Select name="tb_all_id" options={tabsLinc} />
              </span>
              <span>
                <label>Nº Documento *</label>
                <Input type="text" name="n_doc" />
              </span>
              <span>
                <label>Data Vencimento</label>
                <Input type="date" name="data_venc" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Data limite protocolado pela Secran *</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Nº Protocolo Pedido</label>
                <Input type="text" name="n_proto" />
              </span>
              <span>
                <label>Avisar em *</label>
                <Select name="aviso" options={options} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {lics.map(item => (
          <GridT>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <h3>{item.tball.title}</h3>
              </BForm>
              <BForm>
                <span>
                  <label>Nº Documento</label>
                  <Input type="text" name="n_doc" />
                </span>
                <span>
                  <label>Data Vencimento</label>
                  <Input type="date" name="data_venc" />
                </span>
                <span>
                  <label>Nº Protocolo Pedido</label>
                  <Input type="text" name="n_proto" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <select className={`form_aviso${item.id}`}>
                    <option value="">...</option>
                    {options.map(op => (
                      <option
                        key={op.id}
                        value={op.id}
                        selected={op.id === item.aviso || false}
                      >
                        {op.title}
                      </option>
                    ))}
                  </select>
                </span>
              </BForm>
              <BForm>
                <p>
                  Adicionado em {item.dateFormatted} por{' '}
                  <strong>{item.user.name}</strong>
                </p>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
