import styled from 'styled-components';
import { darken, shade } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
  }

  a:hover {
    background: ${darken(0.03, '#0FAF69')};
  }

  .grid {
    display: flex;
    flex: 1;
  }

  .bloco {
    display: flex;
    justify-content: space-between;
    background: #0059b2;
    flex: 1;
    border-radius: 5px;
    padding: 16px 10px;
    color: #fff;

    .icon {
      padding-top: 8px;
    }

    h2 {
      color: #fff;
      font-size: 36px;
    }

    & + .bloco {
      background: #ff4d4d;
      margin-left: 12px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  border-radius: 12px;
  flex-wrap: wrap;

  .bloco {
    display: flex;
    flex: 1;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex: 1;
    background: #0059b2;
    border: none;
    border-radius: 5px;
    height: 80px;
    color: #fff;
    font-size: 18px;
    transition: background-color 0.2s;

    & + button {
      margin-left: 12px;
    }

    &:hover {
      background: ${shade(0.2, '#0059b2')};
    }
  }
`;

export const GridExe = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Props => Props.bgColor};
  border-radius: 4px;
  padding: 8px 8px;
  color: #fff;
`;

export const Observacao = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 4px 8px;
  background: #999;
  color: #fff;
  margin-top: 4px;
`;

export const Retificacao = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 4px 8px;
  background: #ffbf00;
  color: #000;
  margin-top: 4px;
`;
