import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Button({ size }) {
  const classes = useStyles();
  return <CircularProgress size={size} className={classes.buttonProgress} />;
}

Button.propTypes = {
  size: PropTypes.number.isRequired,
};
