import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Select } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';

import pt from 'date-fns/locale/pt';
import { format, parseISO, addDays } from 'date-fns';

import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import api from '~/services/api';

import { Content, Bloco, Grid, GridT, BForm, ButtonView } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function Procuracoes() {
  const profile = useSelector(state => state.user.profile);
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState('');
  const [procuracoes, setProcuracoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabscert, setTabscert] = useState([]);
  const [tabsOrg, setTabsOrg] = useState([]);
  const [tabsOut, setTabsOut] = useState([]);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/procuracoes`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        DataAcrescimo: format(addDays(new Date(), item.aviso), 'yyy-MM-dd', {
          locale: pt,
        }),
      }));

      setProcuracoes(percorre);
    });

    api.get('tb/geral?tb=cert_digitais').then(response => {
      setTabscert(response.data);
    });

    api.get('tb/geral?tb=virg_orgaos').then(response => {
      setTabsOrg(response.data);
    });

    api.get('tb/geral?tb=virg_outorgados').then(response => {
      setTabsOut(response.data);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const options = [
    { id: 1, title: '1 Dias' },
    { id: 2, title: '2 Dias' },
    { id: 3, title: '3 Dias' },
    { id: 4, title: '4 Dias' },
    { id: 5, title: '5 Dias' },
    { id: 10, title: '10 Dias' },
    { id: 15, title: '15 Dias' },
    { id: 30, title: '30 Dias' },
    { id: 45, title: '45 Dias' },
    { id: 60, title: '60 Dias' },
    { id: 90, title: '90 Dias' },
  ];

  async function handleSubmit({
    orgao,
    certificado,
    titular,
    outorgado,
    vigencia,
    data_protocolo,
    aviso,
  }) {
    if (!orgao) {
      toast.warning('Por favor! Preencha o campo Órgão');
      return;
    }

    if (!certificado) {
      toast.warning('Por favor! Preencha o campo Tipo Certficado');
      return;
    }

    if (!outorgado) {
      toast.warning('Por favor! Preencha o campo Outorgado');
      return;
    }

    if (!data_protocolo) {
      toast.warning('Por favor! Preencha o campo Data limite protocolado');
      return;
    }

    if (!aviso) {
      toast.warning('Por favor! Preencha o campo Avisar');
      return;
    }

    setLoading(true);

    try {
      await api.post(`clientes/${idCliente}/procuracoes`, {
        orgao,
        certificado,
        titular,
        outorgado,
        vigencia,
        data_protocolo,
        aviso,
      });

      setLoading(false);
      toast.success('Cadastro efetuado com sucesso.');

      const percorreData = await api.get(`clientes/${idCliente}/procuracoes`);

      setProcuracoes(
        percorreData.data.data.map(item => ({
          ...item,
          dateFormatted: format(
            parseISO(item.updated_at),
            "dd 'de' MMMM', às ' HH:mm'h'",
            {
              locale: pt,
            }
          ),
        }))
      );
    } catch (err) {
      setLoading(false);
      toast.error('Algo deu errado.');
    }
  }

  async function handleUpdate({
    id,
    titular,
    vigencia,
    data_protocolo,
    aviso,
  }) {
    try {
      const orgval = document.querySelector(`.form_orgao${id}`).value;
      const certval = document.querySelector(`.form_cert${id}`).value;
      const outval = document.querySelector(`.form_out${id}`).value;

      await api.put(`clientes/${idCliente}/procuracoes?id=${id}`, {
        orgao: orgval,
        certificado: certval,
        titular,
        outorgado: outval,
        vigencia,
        data_protocolo,
        aviso,
      });

      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`delete/${idCliente}/procuracoes?id=${id}`);

      toast.success('Deletado com sucesso!');
      setProcuracoes(procuracoes.filter(item => item.id !== id));
    } catch (err) {
      toast.error('Erro no delete.');
    }
  }

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="procuracoes"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Órgão *</label>
                <Select name="orgao" options={tabsOrg} />
              </span>
              <span>
                <label>Tipo Certficado *</label>
                <Select name="certificado" options={tabscert} />
              </span>
              <span>
                <label>Titular</label>
                <Input type="text" name="titular" />
              </span>
            </BForm>
            <BForm>
              <span>
                <label>Outorgado *</label>
                <Select name="outorgado" options={tabsOut} />
              </span>
              <span>
                <label>Vigência</label>
                <Input type="date" name="vigencia" />
              </span>
              <span>
                <label>Data limite protocolado pela Secran *</label>
                <Input type="date" name="data_protocolo" />
              </span>
              <span>
                <label>Avisar em *</label>
                <Select name="aviso" options={options} />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {procuracoes.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Órgão</label>
                  <select className={`form_orgao${item.id}`}>
                    {tabsOrg.map(tab => (
                      <option
                        key={tab.id}
                        value={tab.id}
                        selected={tab.id === item.orgao}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Tipo Certficado</label>
                  <select className={`form_cert${item.id}`}>
                    <option value="">...</option>
                    {tabscert.map(tab => (
                      <option
                        key={tab.id}
                        value={tab.id}
                        selected={tab.id === item.certificado}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Titular</label>
                  <Input type="text" name="titular" />
                </span>
              </BForm>
              <BForm>
                <span>
                  <label>Outorgado</label>
                  <select className={`form_out${item.id}`}>
                    <option value="">...</option>
                    {tabsOut.map(tab => (
                      <option
                        key={tab.id}
                        value={tab.id}
                        selected={tab.id === item.outorgado}
                      >
                        {tab.title}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <label>Vigência</label>
                  <Input type="date" name="vigencia" />
                </span>
                <span>
                  <label>Data limite protocolado pela Secran</label>
                  <Input type="date" name="data_protocolo" />
                </span>
                <span>
                  <label>Avisar em</label>
                  <Select name="aviso" options={options} />
                </span>
              </BForm>
              <BForm>
                <span>
                  <p>
                    Adicionado em {item.dateFormatted} por{' '}
                    <strong>{item.user.name}</strong>
                  </p>
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    {item.vigencia <= item.DataAcrescimo ? (
                      <button type="button" className="btn-warning">
                        <MdSave size={16} color="#fff" /> Data à vencer
                      </button>
                    ) : (
                      <button type="submit" className="btn-save">
                        <MdSave size={16} color="#fff" /> Salvar
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
