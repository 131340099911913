import React from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  MdOpenInBrowser,
  MdModeEdit,
  MdEqualizer,
  MdDateRange,
  MdImportExport,
} from 'react-icons/md';

export default function Obrigacoes({ page }) {
  const profile = useSelector(state => state.user.profile);
  const cargo = profile.cargo.subtitle;

  return (
    <ul>
      {cargo === 'financeiro' || cargo === 'consultoria' ? (
        <li>
          <Link
            to="/obrigacoes-periodicas"
            className={page === 'obrigacoes-periodicas' ? 'active' : ''}
          >
            <MdEqualizer size={14} /> Obrigações Periódicas
          </Link>
        </li>
      ) : (
        <>
          <li>
            <Link
              to="/obrigacoes"
              className={page === 'obrigacoes' ? 'active' : ''}
            >
              <MdDateRange size={14} /> Obrigações Mensais
            </Link>
          </li>
          <li>
            <Link
              to="/obrigacoes-periodicas"
              className={page === 'obrigacoes-periodicas' ? 'active' : ''}
            >
              <MdEqualizer size={14} /> Obrigações Periódicas
            </Link>
          </li>
          {profile.role_id !== 4 && (
            <>
              <li>
                <Link
                  to="/edit/obrigacoes"
                  className={page === 'edit-calendar' ? 'active' : ''}
                >
                  <MdModeEdit size={14} /> Editar Data Obrigações
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to="/view/table/obrigacoes"
              className={page === 'obrigacoes-table' ? 'active' : ''}
            >
              <MdOpenInBrowser size={14} /> Visualização Completa
            </Link>
          </li>
          <li>
            <Link
              to="/obrigacoes-import-export"
              className={page === 'obrigacoes-import-export' ? 'active' : ''}
            >
              <MdImportExport size={14} /> Importar / Exportar
            </Link>
          </li>
        </>
      )}
    </ul>
  );
}

Obrigacoes.propTypes = {
  page: PropTypes.string.isRequired,
};
