import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection === 'column' ? 'column' : 'row'};
  flex-wrap: wrap;
  flex: 1;
  min-width: 1060px;

  .alerta {
    display: block;
    width: 100%;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #ff7979;
    color: #eee;

    a {
      color: #fff;
    }
  }
`;
