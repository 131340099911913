import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { toast } from 'react-toastify';

import { format } from 'date-fns';

import api from '~/services/api';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';

import Tabelas from '~/components/MaterialUi/tableScrollVertical';

import {
  Bloco,
  Content,
  Card,
  GridExe,
  Observacao,
  Retificacao,
} from './styles';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const RelatorioObrigacoes = () => {
  const getStorageFuncao = localStorage.getItem('funcao');
  const profile = useSelector(state => state.user.profile);
  const [funcao] = useState(
    getStorageFuncao && profile.role_id === 1
      ? JSON.parse(getStorageFuncao)
      : profile.cargo.subtitle
  );
  const [loading, setLoading] = useState(true);
  const [obrigacoes, setObrigacoes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const url = new URL(window.location);

  useEffect(() => {
    const params = new URLSearchParams(url.search);
    const month = params.get('month') || format(new Date(), 'M');
    const year = params.get('year') || format(new Date(), 'yyyy');
    const executor = params.get('executor') || '';
    const obr = params.get('obr') || '';

    api
      .get('executores/relatorios', {
        params: {
          funcao,
          month,
          year,
          executor,
          obr,
        },
      })
      .then(response => {
        setObrigacoes(response.data.obrigacoes);
        setClientes(response.data.clientes);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err.message);
        setLoading(false);
      });
  }, [funcao, url.search]);

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Content>
          <Title title="Relatórios / Executores / Tabelas" />
          <Bloco>
            <div>
              <h2>Relatórios / Executores / Tabelas</h2>
            </div>
          </Bloco>
        </Content>

        <Card>
          {loading ? (
            'Carregando...'
          ) : (
            <Tabelas>
              <TableHead>
                <TableRow>
                  {clientes.length > 0 && (
                    <TableCell
                      style={{ minWidth: 450, fontSize: 11, lineHeight: 1 }}
                    >
                      Clientes ({clientes.length})
                    </TableCell>
                  )}
                  {obrigacoes.map(column => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: 280, fontSize: 11, lineHeight: 1 }}
                    >
                      <div style={{ paddingBottom: 8, color: '#0059B2' }}>
                        <strong>{column.obrigacoes.title}</strong>
                      </div>
                      <div>
                        <p>
                          Data Vencimento:{' '}
                          <strong>{column.dateFormatted_vencimento}</strong>
                        </p>
                      </div>
                      <div>
                        <p>
                          Data Secran:{' '}
                          <strong>{column.dateFormatted_secran}</strong>
                        </p>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientes.map(cliente => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={cliente.id}
                    >
                      <TableCell style={{ fontSize: 11, lineHeight: 1 }}>
                        {cliente.razao} <br />
                        <p style={{ fontSize: 11, marginTop: 6 }}>
                          <strong>CNPJ: {cliente.cnpj}</strong>
                        </p>
                      </TableCell>
                      {obrigacoes.map(column => {
                        if (
                          cliente.obrigacoes.find(
                            item => item.id_obr === column.obr_id
                          )
                        ) {
                          return (
                            <TableCell
                              key={column.id}
                              align="left"
                              style={{ fontSize: 11 }}
                            >
                              <p style={{ fontSize: 9, marginBottom: 4 }}>
                                <strong>Cliente:</strong> {cliente.razao} -{' '}
                                {cliente.cnpj}
                              </p>
                              {cliente.obrigacoes.map(data => {
                                if (data.id_obr === column.obr_id) {
                                  return (
                                    <>
                                      {data.obr_feito_prazo && (
                                        <GridExe
                                          key={data.id}
                                          bgColor="#00B259"
                                        >
                                          <strong>{data.user.name}</strong>
                                          <p
                                            style={{
                                              fontSize: 11,
                                              color: '#eee',
                                            }}
                                          >
                                            Data Entregue: {data.date_entregue}
                                          </p>
                                        </GridExe>
                                      )}
                                      {data.obr_nao_prazo && (
                                        <GridExe
                                          key={data.id}
                                          bgColor="#FF4D4D"
                                        >
                                          <strong>{data.user.name}</strong>
                                          <p
                                            style={{
                                              fontSize: 11,
                                              color: '#eee',
                                            }}
                                          >
                                            Data Entregue: {data.date_entregue}
                                          </p>
                                        </GridExe>
                                      )}
                                      {data.obr_nao_cliente_prazo && (
                                        <GridExe
                                          key={data.id}
                                          bgColor="#FF8000"
                                        >
                                          <strong>{data.user.name}</strong>
                                          <p
                                            style={{
                                              fontSize: 11,
                                              color: '#eee',
                                            }}
                                          >
                                            Data Entregue: {data.date_entregue}
                                          </p>
                                        </GridExe>
                                      )}
                                      {data.obs && (
                                        <>
                                          <h4 style={{ marginTop: 4 }}>
                                            Observações:
                                          </h4>
                                          <Observacao>{data.obs}</Observacao>
                                        </>
                                      )}
                                      {data.retificate.length > 0 && (
                                        <>
                                          <h4 style={{ marginTop: 4 }}>
                                            Refificação:
                                          </h4>
                                          {data.retificate.map(item => {
                                            return (
                                              <Retificacao key={item.id}>
                                                {item.obs}
                                              </Retificacao>
                                            );
                                          })}
                                        </>
                                      )}
                                    </>
                                  );
                                }
                                return '';
                              })}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align="center"
                            style={{ fontSize: 11 }}
                          >
                            ---
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Tabelas>
          )}
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default RelatorioObrigacoes;
