import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  flex: 1;
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  div {
    display: flex;
    margin-right: 2px;
    flex: 1;
    flex-direction: column;

    h5 {
      margin-bottom: 4px;
    }

    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      background: #eee;
      height: 28px;
      border-radius: 5px;
      padding-left: 5px;
      padding-right: 5px;

      strong {
        margin-left: 4px;
      }
    }

    span + span {
      margin-top: 4px;
    }
  }

  div + div {
    margin-left: 2px;
  }
`;
