import styled, { css } from 'styled-components';
import { darken, shade } from 'polished';

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Bloco = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 25px;

  h2 {
    color: #0059b2;
    font-size: 26px;
  }

  a {
    background: #0faf69;
    padding: 10px 36px;
    color: #fff;
    align-items: center;
    border-radius: 6px;
    display: flex;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.3, '#0FAF69')};
    }
  }
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;

  td {
    .circle {
      display: flex;
      align-items: center;
      border: 1px solid #0faf69;
      border-radius: 4px;
      padding: 6px 8px;
      color: #000;
    }

    .search {
      display: flex;
      flex-direction: column;

      select {
        margin-top: 5px;
        font-size: 12px;

        option {
          font-size: 12px;
        }
      }
    }

    .date {
      display: flex;
      align-items: center;
      border: 1px solid #004080;
      border-radius: 4px;
      padding: 6px 8px;
      color: #000;
      justify-content: flex-start;
    }

    a {
      background: #0059b2;
      border: 0;
      border-radius: 4px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      justify-content: center;
      color: #fff;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.3, '#0059b2')};
      }
    }
  }

  .btn-blue {
    background: #00468c;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#00468C')};
    }
  }

  #exe {
    color: #0faf69;
  }

  .contrato {
    display: flex;
    align-items: center;
  }

  input {
    background: none;
    border: 0;
  }

  button {
    background: #c9321f;
    border: 0;
    border-radius: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: ${darken(0.06, '#c9321f')};
    }
  }

  #red {
    color: #c9321f;
  }

  #green {
    color: #0faf69;
  }

  #orange {
    color: #ff7f00;
  }
`;

export const BForm = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;

  .form-input {
    display: flex;
    flex: 1;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    input {
      width: 100%;
    }

    select {
      width: 100%;
    }
  }

  .bloco-conteudo {
    display: block;
    width: 100%;
  }

  .usuarioShow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .colunaUser {
    display: flex;
    flex-direction: row;
  }

  .usuarioSec {
    display: block;
    margin-top: 12px;

    h4 {
      font-size: 16px;
      margin-bottom: 12px;
    }

    img {
      width: 30px;
      border-radius: 15px;
    }
  }

  span {
    display: flex;
    flex: 1;
    flex-direction: column;

    h5 {
      margin-bottom: 6px;
      color: #999;
    }

    div {
      display: flex;
      padding-top: 5px;
      align-items: center;

      img {
        width: 40px;
        border-radius: 20px;
      }

      span {
        margin-left: 4px;

        p {
          font-size: 12px;
          margin-bottom: 0px;
        }

        small {
          font-size: 11px;
          color: #999;
        }
      }
    }

    p {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }

  span + span {
    border-top: 1px solid #ddd;
  }

  hr {
    height: 28px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 14px;
  }

  .form-input {
    display: flex;
    flex-direction: column;
  }

  .form-input + .form-input {
    margin-left: 8px;
  }

  .btn-save {
    border: 0;
    background: #0faf69;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darken(0.03, '#0FAF69')};
    }
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;

  select {
    height: 35px;
    border: 0;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 15px;
  }

  .btn-green {
    background: #00b259;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
  }
  .btn-red {
    background: #ff4d4d;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#ff4d4d')};
    }
  }
  .btn-warning {
    background: #ff8040;
    color: #fff;
    border-radius: 5px;
    margin-right: 22px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#FF8040')};
    }
  }
  .btn-blue {
    background: #0f73af;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    padding: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      background: ${darken(0.06, '#0f73af')};
    }
  }

  ul {
    display: flex;
    list-style: none;
  }

  ul li {
    a {
      border-radius: 6px;
      background: ${Props => (Props.cores ? '#ff7f00' : '#00468c')};
      color: ${Props => (Props.cores ? '#ff7f00' : '#00468c')};
      color: #fff;
      padding: 8px 10px;
      font-size: 12px;

      &:hover {
        background: #ff7f00;
      }
    }
  }

  .bloco-one {
    display: flex;
    flex-direction: column;

    .divbtn {
      display: flex;
      background: none;
      flex-direction: row;
      justify-content: flex-end;

      .form {
        background: none;
        padding-bottom: 22px;

        div {
          background: none;
        }
      }

      button + button {
        margin-right: none;
        margin-left: 4px;
      }
    }

    li + li {
      margin-left: 4px;
    }

    .ativo {
      background: #024486;

      &:hover {
        background: ${shade(0.3, '#024486')};
      }
    }

    .inativo {
      background: #999;

      &:hover {
        background: ${shade(0.3, '#999')};
      }
    }

    div {
      background: #fff;
      height: 35px;
      border-radius: 4px;
      padding-left: 6px;
      padding-right: 6px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    p {
      display: flex;
      font-size: 11px;
      align-items: center;
      svg {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    input {
      height: 35px;
      background: #fff;
      border-radius: 4px;
      border: none;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
`;

export const Tipo = styled.div`
  display: flex;
  align-items: center;
  background: #ff8040;
  border-radius: 4px;
  padding: 6px 8px;
  color: #fff;
  text-align: center;

  ${props =>
    props.cor === 'individual' &&
    css`
      background-color: #ff5c26;
    `}

  ${props =>
    props.cor === 'usuario' &&
    css`
      background-color: #006dd9;
    `}

  ${props =>
    props.cor === 'departamento' &&
    css`
      background-color: #006600;
    `}
`;
