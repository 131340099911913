import React from 'react';

import PropTypes from 'prop-types';

import { Grid } from './styles';

export default function Cnae({ dados }) {
  return (
    <Grid>
      <h4>CNAE Principal</h4>
      {dados.map(item => (
        <>
          {item.atividade === 'SIM' && (
            <div key={item.id} className="content">
              <div className="tabelas">
                <div>
                  {item.code} - {item.cnae}
                </div>
              </div>
            </div>
          )}
        </>
      ))}
      <h4 className="margin-top">CNAE Secundario</h4>
      {dados.map(item => (
        <>
          {item.atividade === 'NÃO' && (
            <div key={item.id} className="content">
              <div className="tabelas">
                <div>
                  {item.code} - {item.cnae}
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </Grid>
  );
}

Cnae.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
};

Cnae.defaultProps = {
  dados: [],
};
