import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export default function InfoModal({
  children,
  open,
  close,
  title,
  handleCancel,
  handleDelete,
}) {
  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Fechar
        </Button>
        {handleDelete && (
          <Button onClick={handleDelete} color="primary">
            Deletar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
