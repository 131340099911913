import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import pt from 'date-fns/locale/pt';
import { differenceInDays, parseISO, format } from 'date-fns';

import {
  MdLockOpen,
  MdLockOutline,
  MdPerson,
  MdWork,
  MdToday,
} from 'react-icons/md';

import api from '~/services/api';

import { Content, Bloco, Card, Pesquisa } from './styles';

import Container from '~/components/Container';
import Pagination from '~/components/Pagination';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
// import Carregando from '~/components/Carregando/Button';
import history from '~/services/history';

const setores = [
  { id: '', title: 'Geral' },
  { id: 'diretor', title: 'Diretoria' },
  { id: 'paralegal', title: 'Paralegal' },
  { id: 'contabil', title: 'Contábil' },
  { id: 'fiscal', title: 'Fiscal' },
  { id: 'tributos', title: 'Tributos' },
  { id: 'pessoal', title: 'Pessoal' },
  { id: 'consultoria', title: 'Consultoria' },
  { id: 'ti', title: 'Tecnologia da Informação' },
];

export default function Ticket() {
  const profile = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);

  const [limit, setLimitView] = useState(20);
  const [pageView, setPageView] = useState(1);
  const [total, setTotal] = useState(1);

  const [btnView, setBtnView] = useState(false);
  const [btnRead, setBtnRead] = useState(false);
  const [params, setParams] = useState({
    sigilo: '',
    read: false,
    nivel: '',
    setor: '',
    responsavel: '',
    prazo: '',
  });

  const url = new URL(window.location);

  useEffect(() => {
    setLoading(true);

    const urlParams = new URLSearchParams(url.search);
    const btnTroca = urlParams.get('btn') || false;
    const page = urlParams.get('page') || 1;
    const limitView = urlParams.get('limit') || 20;
    const sigilo = urlParams.get('sigilo') || '';
    const read = urlParams.get('read') || false;
    const nivel = urlParams.get('nivel') || '';
    const setor = urlParams.get('setor') || '';
    const responsavel = urlParams.get('responsavel') || '';
    const prazo = urlParams.get('prazo') || '';

    setParams({ sigilo, read, nivel, setor, responsavel, prazo });

    setBtnView(JSON.parse(btnTroca));

    setBtnRead(JSON.parse(read));

    api
      .get(
        `ticket?status=${btnTroca}&limit=${limitView}&page=${page}&sigilo=${sigilo}&read=${read}&nivel=${nivel}&setor=${setor}&responsavel=${responsavel}&prazo=${prazo}`
      )
      .then(response => {
        const ticket = response.data.data.map(item => ({
          ...item,
          date: differenceInDays(parseISO(item.date), new Date()),
          date_entregue: item.date_entregue
            ? format(parseISO(item.date_entregue), "dd 'de' MMMM 'de' yyyy", {
                locale: pt,
              })
            : null,
          dateFormatted: format(parseISO(item.date), "dd 'de' MMMM 'de' yyyy", {
            locale: pt,
          }),
        }));

        setLimitView(limit);
        setPageView(Number(page));
        setLoading(false);
        setTickets(ticket);
        setTotal(response.data.last_page);
      });
  }, [btnRead, limit, url.search]);

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = pageView === 1 ? 1 : pageView - 1;
        setPageView(ptotal);
        setLimitView(limit);
        history.push(
          `/tickets?limit=${limit}&page=${ptotal}&btn=${btnView}&sigilo=&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
        );
        return;
      }
      setLoading(true);
      const ptotal = Number(pageView) + 1;
      setPageView(ptotal);
      setLimitView(limit);
      history.push(
        `/tickets?limit=${limit}&page=${ptotal}&btn=${btnView}&sigilo=&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
      );
    },
    [
      btnRead,
      btnView,
      limit,
      pageView,
      params.nivel,
      params.prazo,
      params.responsavel,
      params.setor,
    ]
  );

  const handlePageSelect = useCallback(
    ({ page }) => {
      setLoading(true);
      setPageView(page);
      setLimitView(limit);
      history.push(
        `/tickets?limit=${limit}&page=${page}&btn=${btnView}&sigilo=&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
      );
    },
    [
      btnRead,
      btnView,
      limit,
      params.nivel,
      params.prazo,
      params.responsavel,
      params.setor,
    ]
  );

  const handleLimit = useCallback(
    e => {
      setLoading(true);
      const limitTotal = e.target.value;

      setLimitView(limitTotal);
      setPageView(1);
      history.push(
        `/tickets?limit=${limitTotal}&page=1&btn=${btnView}&sigilo=&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
      );
    },
    [
      btnRead,
      btnView,
      params.nivel,
      params.prazo,
      params.responsavel,
      params.setor,
    ]
  );

  return (
    <>
      <Nav page="tickets" />
      <Container>
        <User />

        <Content>
          <Title title="Tickets" />

          <Bloco>
            <div>
              <h2>Tickets</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <ul>
                <li>
                  <Link
                    to="/tickets?btn=&read=false"
                    className={!btnView && !btnRead && 'ativo'}
                    onClick={() => setBtnRead(false)}
                  >
                    Tickets Postados
                  </Link>
                </li>
                <li>
                  <Link
                    to="/tickets?btn=&read=true"
                    className={!btnView && btnRead && 'ativo'}
                    onClick={() => setBtnRead(true)}
                  >
                    Tickets Realizados
                  </Link>
                </li>
                <li>
                  <Link
                    to="/tickets?btn=true"
                    className={btnView && 'ativo'}
                    onClick={() => setBtnView(true)}
                  >
                    Meus Tickets
                  </Link>
                </li>
              </ul>
            </div>
            <div className="bloco-one">
              <div className="divbtn">
                <button
                  type="button"
                  className="btn-blue"
                  onClick={() => history.push('/ticket/relatorio')}
                >
                  Visualizar Relatório
                </button>
                <button
                  type="button"
                  className="btn-green"
                  onClick={() => history.push('/ticket/create')}
                >
                  Criar Ticket
                </button>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td width="4%">#ID</td>
                <td width="16%">Cliente</td>
                <td width="11.42%">Titulo</td>
                <td width="11.42%">
                  <span>
                    Data Prazo
                    <select
                      onChange={e =>
                        history.push(
                          `/tickets?limit=${limit}&page=${pageView}&sigilo=${params.sigilo}&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${e.target.value}`
                        )
                      }
                    >
                      <option value="" selected={!params.prazo}>
                        Geral
                      </option>
                      <option
                        value="dentro"
                        selected={params.prazo === 'dentro'}
                      >
                        Dentro do Prazo
                      </option>
                      <option value="fora" selected={params.prazo === 'fora'}>
                        Fora do Prazo
                      </option>
                    </select>
                  </span>
                </td>
                <td width="11.42%">
                  <span>
                    Nível
                    <select
                      onChange={e =>
                        history.push(
                          `/tickets?limit=${limit}&page=${pageView}&sigilo=${params.sigilo}&read=${btnRead}&nivel=${e.target.value}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
                        )
                      }
                    >
                      <option value="" selected={!params.nivel}>
                        Geral
                      </option>
                      <option value="baixo" selected={params.nivel === 'baixo'}>
                        Baixo
                      </option>
                      <option value="media" selected={params.nivel === 'media'}>
                        Médio
                      </option>
                      <option value="alto" selected={params.nivel === 'alto'}>
                        Alto
                      </option>
                    </select>
                  </span>
                </td>
                <td width="11.42%">
                  <span>
                    Sigilo
                    <select
                      onChange={e =>
                        history.push(
                          `/tickets?limit=${limit}&page=${pageView}&sigilo=${e.target.value}&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${params.responsavel}&prazo=${params.prazo}`
                        )
                      }
                    >
                      <option value="" selected={!params.sigilo}>
                        Geral
                      </option>
                      <option
                        value="false"
                        selected={params.sigilo === 'false'}
                      >
                        Aberto
                      </option>
                      <option value="true" selected={params.sigilo === 'true'}>
                        Fechado
                      </option>
                    </select>
                  </span>
                </td>
                <td width="11.42%">
                  <span>
                    Setor
                    <select
                      onChange={e =>
                        history.push(
                          `/tickets?limit=${limit}&page=${pageView}&sigilo=${params.sigilo}&read=${btnRead}&nivel=${params.nivel}&setor=${e.target.value}&responsavel=${params.responsavel}&prazo=${params.prazo}`
                        )
                      }
                    >
                      {setores.map(item => (
                        <option
                          value={item.id}
                          selected={params.setor === item.id}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </span>
                </td>
                <td width="11.42%">
                  <span>
                    Responsável
                    {!btnView && (
                      <select
                        onChange={e =>
                          history.push(
                            `/tickets?limit=${limit}&page=${pageView}&sigilo=${params.sigilo}&read=${btnRead}&nivel=${params.nivel}&setor=${params.setor}&responsavel=${e.target.value}&prazo=${params.prazo}`
                          )
                        }
                      >
                        <option value="geral">Geral</option>
                        <option
                          value={profile.id}
                          selected={Number(params.responsavel) === profile.id}
                        >
                          Você
                        </option>
                      </select>
                    )}
                  </span>
                </td>
                <td width="11.42%">Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {tickets.map(item => (
                    <tr>
                      <td>#{item.id}</td>
                      <td>
                        <div>
                          {item.cliente ? item.cliente.razao : item.cliente_sec}
                          {!item.cliente && (
                            <span className="btncliente">Provisório</span>
                          )}
                        </div>
                      </td>
                      <td>{item.activity.title}</td>
                      <td>
                        {item.date_entregue ? (
                          <div className="faltaDays">{item.date_entregue}</div>
                        ) : (
                          <>
                            {item.date < 0 ? (
                              <div className="expirado">
                                <MdToday size={14} color="#000" />{' '}
                                {item.dateFormatted}
                              </div>
                            ) : (
                              <div className="faltaDays">
                                {item.date === 0 ? (
                                  'é hoje'
                                ) : (
                                  <>
                                    {item.date === 1
                                      ? 'é amanhã'
                                      : `Faltam ${item.date} dias`}
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {item.nivel === 'alto' && (
                          <div className="alto">Nível Alto</div>
                        )}
                        {item.nivel === 'media' && (
                          <div className="media">Nível Média</div>
                        )}
                        {item.nivel === 'baixa' && (
                          <div className="baixa">Nível Baixo</div>
                        )}
                      </td>
                      <td>
                        {item.sigilo ? (
                          <div className="sigiloClosed">
                            <MdLockOutline size={14} color="#fff" /> Fechado
                          </div>
                        ) : (
                          <div className="sigiloOpen">
                            <MdLockOpen size={14} color="#fff" /> Aberto
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="setor">
                          <MdWork size={14} color="#fff" /> {item.setor}
                        </div>
                      </td>
                      <td>
                        {item.usuario === profile.id ? (
                          <div className="usuarioVc">
                            <MdPerson size={14} color="#fff" /> Você é
                            responsável
                          </div>
                        ) : (
                          <div className="usuario">
                            <MdPerson size={14} color="#fff" /> {item.username}
                          </div>
                        )}
                      </td>
                      <td align="right">
                        {item.read === 'finalizado' && (
                          <button
                            type="button"
                            className="btn-success"
                            onClick={() =>
                              history.push(`/show/ticket/${item.token}`)
                            }
                          >
                            Chamado Finalizado
                          </button>
                        )}
                        {item.read === 'aguardando' && (
                          <>
                            {item.sigilo && item.usuario !== profile.id ? (
                              <button type="button" className="btn-gray">
                                Aguardando Chamado
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-blue"
                                onClick={() =>
                                  history.push(`/show/ticket/${item.token}`)
                                }
                              >
                                Aguardando Chamado
                              </button>
                            )}
                          </>
                        )}
                        {item.read === 'cancelado' && (
                          <button
                            type="button"
                            className="btn-delete"
                            onClick={() =>
                              history.push(`/show/ticket/${item.token}`)
                            }
                          >
                            Cancelado
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>

        <Pagination
          total={total}
          limit={limit}
          page={pageView}
          handleLimit={handleLimit}
          handlePage={handlePageSelect}
          handlePrev={() => handlePage('prev')}
          handleNext={() => handlePage('next')}
        />

        <Footer />
      </Container>
    </>
  );
}
