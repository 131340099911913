import React, { useState, useEffect, useCallback } from 'react';

import pt from 'date-fns/locale/pt';
import { format, parseISO, isPast } from 'date-fns';

import {
  MdModeEdit,
  MdSearch,
  MdRemoveRedEye,
  MdVisibilityOff,
} from 'react-icons/md';

import { Form, Input } from '@rocketseat/unform';

import Container from '~/components/Container';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import Footer from '~/components/Footer';
import Title from '~/components/Title';
import Notify from '~/components/Modal/Notification';

import api from '~/services/api';
import history from '~/services/history';

import {
  Content,
  Bloco,
  Card,
  Pagination,
  Qtd,
  PageVer,
  Pesquisa,
  Modal,
  ContentModal,
} from './styles';

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const totalNot = localStorage.getItem('totalNot');
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [limitView, setLimitView] = useState(10);
  const [pageView, setPageView] = useState(1);

  const [id, setId] = useState(0);
  const [btnmodal, setBtnmodal] = useState(false);

  const url = new URL(window.location);
  const params = new URLSearchParams(url.search);
  const limit = params.get('limit') || 10;
  const page = params.get('page') || 1;
  const status = params.get('status') || 'all';

  useEffect(() => {
    async function loadNotifications() {
      const response = await api.get(
        `notifications?limit=${limit}&page=${page}&status=${status}`
      );

      const res = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.created_at),
          "dd 'de' MMMM' de 'yyyy', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
        procVigencia: item.procuracao
          ? format(
              parseISO(item.procuracao.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        negVigencia: item.negativa
          ? format(
              parseISO(item.negativa.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        digVigencia: item.digital
          ? format(
              parseISO(item.digital.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        licVigencia: item.licenca
          ? format(
              parseISO(item.licenca.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        fisVigencia: item.fiscais
          ? format(
              parseISO(item.fiscais.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        socVigencia: item.socios
          ? format(
              parseISO(item.socios.data_protocolo),
              "dd 'de' MMMM 'de' yyyy",
              {
                locale: pt,
              }
            )
          : null,
        verificaProcData: item.procuracao
          ? isPast(parseISO(item.procuracao.data_protocolo))
          : null,
        verificaNegData: item.negativa
          ? isPast(parseISO(item.negativa.data_protocolo))
          : null,
        verificaDigData: item.digital
          ? isPast(parseISO(item.digital.data_protocolo))
          : null,
        verificaLicData: item.licenca
          ? isPast(parseISO(item.licenca.data_protocolo))
          : null,
        verificaFisData: item.fiscais
          ? isPast(parseISO(item.fiscais.data_protocolo))
          : null,
        verificaSocData: item.socios
          ? isPast(parseISO(item.socios.data_protocolo))
          : null,
      }));

      setNotifications(res);
      setTotal(response.data.last_page);

      setLoading(false);
    }

    loadNotifications();
  }, [limit, page, status, url.search]);

  const handleLimit = useCallback(
    e => {
      setLoading(true);
      const limite = e.target.value;

      setLimitView(limit);
      setPageView(1);
      history.push(`/notifications?limit=${limite}&page=1`);
    },
    [limit]
  );

  const handlePage = useCallback(
    e => {
      if (e === 'prev') {
        setLoading(true);
        const ptotal = pageView === 1 ? 1 : pageView - 1;
        setPageView(ptotal);
        setLimitView(limitView);
        history.push(
          `/notifications?limit=${limitView}&status=${status}&page=${ptotal}`
        );
        return;
      }
      setLoading(true);
      const ptotal = Number(pageView) + 1;
      setPageView(ptotal);
      setLimitView(limitView);
      history.push(
        `/notifications?limit=${limitView}&status=${status}&page=${ptotal}`
      );
    },
    [limitView, pageView, status]
  );

  const handlePageSelect = useCallback(
    ({ pageSelect }) => {
      setLoading(true);
      setPageView(pageSelect);
      setLimitView(limitView);
      history.push(
        `/notifications?limit=${limitView}&status=${status}&page=${pageSelect}`
      );
    },
    [limitView, status]
  );

  const handleNotification = useCallback(
    async e => {
      await api.put(`notifications/${e}`, {
        confirmed: true,
      });

      setNotifications(
        notifications.map(item => ({
          ...item,
          confirmed: item.id === e || false,
        }))
      );

      const contagem = totalNot - 1;

      localStorage.setItem('totalNot', JSON.stringify(contagem));
    },
    [notifications, totalNot]
  );

  const handleModal = useCallback(e => {
    setId(e);
    setBtnmodal(true);
  }, []);

  const handleStatus = useCallback(
    e => {
      const statusAltera = e.target.value;
      setLoading(true);

      history.push(
        `/notifications?limit=${limitView}&status=${statusAltera}&page=${pageView}`
      );
    },
    [limitView, pageView]
  );

  return (
    <>
      <Nav page="notifications" />
      <Container>
        <User />

        <Content>
          <Title title="Notificações" />
          <Bloco>
            <div>
              <h2>Notificações</h2>
            </div>
          </Bloco>

          <Pesquisa>
            <div className="bloco-one">
              <select onChange={handleStatus}>
                <option value="">Todos</option>
                <option value="0">Não lidos</option>
                <option value="1">Já lidos</option>
              </select>

              <div className="search">
                <span>Pesquisar por:</span>
                <Form onSubmit={() => {}}>
                  <Input name="searchGo" placeholder="Pesquisar" />
                  <button type="submit">
                    <MdSearch size={28} color="#999" />
                  </button>
                </Form>
              </div>
            </div>
          </Pesquisa>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Notificação</td>
                <td>Título</td>
                <td>Renovação</td>
                <td>Detalhar</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                'Carregando...'
              ) : (
                <>
                  {notifications.map(item => (
                    <>
                      {item.type === 'cliente' && (
                        <tr
                          key={item.id}
                          className="table-hover"
                          id={
                            (item.verificaNegData && 'red-background') ||
                            (item.verificaProcData && 'red-background') ||
                            (item.verificaFisData && 'red-background') ||
                            (item.verificaLicData && 'red-background') ||
                            (item.verificaSocData && 'red-background')
                          }
                        >
                          <td>{item.title}</td>
                          <td>{item.comando}</td>
                          <td>
                            {(item.procVigencia && item.procVigencia) ||
                              (item.fisVigencia && item.fisVigencia) ||
                              (item.licVigencia && item.licVigencia) ||
                              (item.negVigencia && item.negVigencia) ||
                              (item.socVigencia && item.socVigencia) ||
                              (item.digVigencia && item.digVigencia)}
                          </td>
                          <td>
                            <div>
                              {item.type === 'cliente' && (
                                <button
                                  type="button"
                                  onClick={() => handleModal(item.id)}
                                  id="btn-detalhe"
                                >
                                  <MdModeEdit size={20} color="#fff" /> Ver
                                  Detalhe
                                </button>
                              )}
                              {item.confirmed ? (
                                <button type="button" id="btn-lido">
                                  <MdRemoveRedEye size={20} color="#fff" /> Já
                                  lido
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => handleNotification(item.id)}
                                  id="btn-nao-lido"
                                >
                                  <MdVisibilityOff size={20} color="#fff" />{' '}
                                  Marcar como lido
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </Card>
        <Pagination>
          <div className="pagination">
            <Qtd>
              <span>Página de:</span>
              <select onChange={handleLimit}>
                <option value="10" selected={limitView === '10' || false}>
                  10
                </option>
                <option value="20" selected={limitView === '20' || false}>
                  20
                </option>
                <option value="40" selected={limitView === '40' || false}>
                  40
                </option>
                <option value="60" selected={limitView === '60' || false}>
                  60
                </option>
                <option value="80" selected={limitView === '80' || false}>
                  80
                </option>
                <option value="100" selected={limitView === '100' || false}>
                  100
                </option>
              </select>
            </Qtd>
            <PageVer>
              {total > 1 && (
                <>
                  <Form onSubmit={handlePageSelect}>
                    <span>
                      Página de {pageView} para {total}
                    </span>
                    <Input name="page" />
                    <button type="submit" className="hidden">
                      s
                    </button>
                  </Form>
                  <button
                    type="button"
                    onClick={() => handlePage('prev')}
                    disabled={pageView === 1 || false}
                  >
                    Anterior
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePage('next')}
                    disabled={pageView === total || false}
                  >
                    Próximo
                  </button>
                </>
              )}
            </PageVer>
          </div>
        </Pagination>

        <Footer />
      </Container>

      <Modal modal={btnmodal}>
        <ContentModal>
          <Notify cliente={id} ativo={btnmodal} />
          <button
            type="button"
            className="btn-delete"
            onClick={() => setBtnmodal(false)}
          >
            Fechar
          </button>
        </ContentModal>
      </Modal>
    </>
  );
}
