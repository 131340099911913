import React from 'react';

import { Loading } from './styles';

import carregando from '~/assets/icons/loading.svg';

export default function Button() {
  return (
    <Loading>
      <img src={carregando} alt="carregando..." />
    </Loading>
  );
}
