import React from 'react';

import PropTypes from 'prop-types';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { Grid } from './styles';

export default function Info({ dados, users }) {
  const data = dados.map(item => ({
    ...item,
    dateFormatted: item.created_at
      ? format(parseISO(item.created_at), "dd 'de' MMMM' de' yyyy", {
          locale: pt,
        })
      : null,
  }));

  return (
    <Grid on>
      {data.map(item => (
        <div key={item.id} className="content">
          <p>
            Adicionado em <strong>{item.dateFormatted}</strong> por
            <strong>
              {users.map(user => user.id === item.user_id && user.name)}
            </strong>
          </p>
          <div className="tabelas">
            <div>{item.adicionais}</div>
          </div>
        </div>
      ))}
    </Grid>
  );
}

Info.propTypes = {
  dados: PropTypes.array, //eslint-disable-line
  users: PropTypes.array, //eslint-disable-line
};

Info.defaultProps = {
  dados: [],
  users: [],
};
