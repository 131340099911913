import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { MdExitToApp } from 'react-icons/md';

import { toast } from 'react-toastify';
import { Content, Card, Submenu } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import User from '~/components/User';
import MenuSub from '~/components/Navigation/Obrigacoes';

import api from '~/services/api';
import history from '~/services/history';

export default function Calendar() {
  const profile = useSelector(state => state.user.profile);
  const cargo = profile.cargo.subtitle;
  const getStorageFuncao = localStorage.getItem('funcao');
  const [post, setPost] = useState([]);
  const [postVerify, setPostVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const { admGestaoPermission } = usePermission();

  useEffect(() => {
    admGestaoPermission({ role_id: profile.role_id });

    const parseGetStorageFuncao = JSON.parse(getStorageFuncao);

    api.get(`post?funcao=${parseGetStorageFuncao || cargo}`).then(response => {
      setPost(response.data.data);
    });

    api
      .get(`verify/post?funcao=${parseGetStorageFuncao || cargo}`)
      .then(response => {
        setPostVerify(response.data);
      });
  }, [admGestaoPermission, cargo, getStorageFuncao, loadPage, profile.role_id]);

  function handleSetor(e) {
    if (e === 'open') {
      history.push(`obrigacoes`);
      return;
    }

    localStorage.setItem('funcao', JSON.stringify(e));
  }

  const handleSubmit = useCallback(async event => {
    if (event.show === 'edit') {
      history.push(`/show/obrigacoes/${event.id}`);
      return;
    }

    await api.delete(`post/${event.id}`);

    setLoadPage(true);

    toast.success('Deletado com sucesso.');
  }, []);

  const handleObrigacoes = useCallback(async () => {
    try {
      setLoading(true);
      const parseGetStorageFuncao = JSON.parse(getStorageFuncao);

      const { data } = await api.post('calendar', {
        funcao: parseGetStorageFuncao || cargo,
      });

      setLoading(false);
      setPost([data, ...post]);
      setPostVerify(true);
      toast.success('Atualizado com sucesso.');
    } catch (err) {
      toast.warning(err.message);
      setLoading(false);
    }
  }, [cargo, post, getStorageFuncao]);

  return (
    <>
      <Nav page="obrigacoes" />
      <Container>
        <User />

        <Submenu>
          <MenuSub page="edit-calendar" />
          {profile.role_id === 1 && (
            <ul>
              <li>
                <button type="button" onClick={() => handleSetor('open')}>
                  <MdExitToApp size={14} color="#fff" /> Alterar Setor
                </button>
              </li>
            </ul>
          )}
        </Submenu>

        <Content>
          <div className="conteudo">
            <Title title="Data de Obrigações" />

            <span>
              {postVerify ? (
                <button type="button" className="clearBtn">
                  ESSE MÊS JÁ FOI GERADO AS OBRIGAÇÕES
                </button>
              ) : (
                <button
                  type="button"
                  className="createBtn"
                  onClick={handleObrigacoes}
                >
                  {loading
                    ? 'CARREGANDO...'
                    : 'GERAR NOVAS OBRIGAÇÕES PARA ESSE MÊS.'}
                </button>
              )}
            </span>
          </div>
        </Content>

        <Card>
          <table className="tab table-striped">
            <thead>
              <tr>
                <td>Titulo</td>
                <td>Função</td>
                <td>Created At</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {post.map(item => (
                <tr key={item.id}>
                  <td>{item.title}</td>
                  <td>
                    <div>
                      {item.command === 'fiscal' && (
                        <div className="fiscal">Fiscal</div>
                      )}
                      {item.command === 'contabil' && (
                        <div className="contabil">Contábil</div>
                      )}
                      {item.command === 'tributos' && (
                        <div className="tributos">Tributos</div>
                      )}
                      {item.command === 'pessoal' && (
                        <div className="pessoal">Pessoal</div>
                      )}
                    </div>
                  </td>
                  <td>{item.created_at}</td>
                  <td>
                    <div className="btn-org">
                      <button
                        type="button"
                        className="btnEdit"
                        onClick={() =>
                          handleSubmit({ id: item.id, show: 'edit' })
                        }
                      >
                        Editar
                      </button>
                      <button
                        type="button"
                        className="btnDelete"
                        onClick={() =>
                          handleSubmit({ id: item.id, show: 'delete' })
                        }
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>

        <Footer />
      </Container>
    </>
  );
}
