import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Form, Input } from '@rocketseat/unform';
import { MdSave, MdDelete } from 'react-icons/md';

import { toast } from 'react-toastify';
import pt from 'date-fns/locale/pt';
import { format, parseISO } from 'date-fns';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Content, Bloco, Grid, GridT, ButtonView, BForm } from './styles';

import { usePermission } from '~/hooks/permission';

import Container from '~/components/Container';
import Carregando from '~/components/Carregando/Button';
import User from '~/components/User';
import Title from '~/components/Title';
import Footer from '~/components/Footer';
import Nav from '~/components/Navigation';
import Menu from '~/components/MenusEdit';

export default function InfoAdicionais() {
  const { idCliente } = useParams();
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState('');
  const [info, setInfor] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const { validarPermission } = usePermission();

  useEffect(() => {
    const resStorage = localStorage.getItem('cliente');
    if (resStorage) {
      const verify = JSON.parse(resStorage).ativoUser;

      validarPermission({ role_id: profile.role_id, ativo: verify });

      setCliente(JSON.parse(resStorage));
    } else {
      api.get(`clientes/${idCliente}`).then(response => {
        const { ativoUser } = response.data;

        validarPermission({ role_id: profile.role_id, ativo: ativoUser });

        localStorage.setItem('cliente', JSON.stringify(response.data));
        setCliente(response.data);
      });
    }

    api.get(`clientes/${idCliente}/adicionais`).then(response => {
      const percorre = response.data.data.map(item => ({
        ...item,
        dateFormatted: format(
          parseISO(item.updated_at),
          "dd 'de' MMMM', às ' HH:mm'h'",
          {
            locale: pt,
          }
        ),
      }));

      setInfor(percorre);
    });
  }, [idCliente, profile.role_id, validarPermission]);

  const handleSubmit = useCallback(
    async ({ adicionais }) => {
      try {
        setLoading(true);
        const { data } = await api.post(`clientes/${idCliente}/adicionais`, {
          adicionais,
        });

        toast.success('Cadastro com sucesso.');
        setLoading(false);

        const object = { data };

        const convert = Object.values(object); // Transforma objeto em array

        const dadosArray = convert.concat(info);

        setInfor(
          dadosArray.map(item => ({
            ...item,
            dateFormatted: format(
              parseISO(item.updated_at),
              "dd 'de' MMMM', às ' HH:mm'h'",
              {
                locale: pt,
              }
            ),
          }))
        );
      } catch (err) {
        setLoading(false);
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [idCliente, info]
  );

  const handleUpdate = useCallback(
    async ({ id, adicionais }) => {
      try {
        await api.put(`clientes/${idCliente}/adicionais?id=${id}`, {
          adicionais,
        });
        toast.success('Atualizado com sucesso.');
      } catch (err) {
        toast.error('Algo deu errado no cadastro.');
      }
    },
    [idCliente]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`delete/${idCliente}/adicionais?id=${id}`);

        toast.success('Deletado com sucesso.');
        setInfor(info.filter(item => item.id !== id));
      } catch (err) {
        toast.error('Algo deu errado no delete.');
      }
    },
    [idCliente, info]
  );

  return (
    <>
      <Nav page="clientes" />

      <Container>
        <User />

        <Content>
          <Title title={`Edit: ${cliente.razao || 'carregando...'}`} />

          <Bloco>
            <div>
              <h2>Editar: {cliente.razao || 'carregando...'}</h2>
            </div>
          </Bloco>
        </Content>

        <Menu
          client={idCliente}
          page="info-add"
          permission={profile.cargo.subtitle}
        />

        <Grid>
          <Form onSubmit={handleSubmit}>
            <BForm>
              <span>
                <label>Informação</label>
                <Input name="adicionais" className="textarea" multiline />
              </span>
            </BForm>
            <BForm>
              <span>
                <button type="submit" className="btn-save">
                  {loading ? (
                    <Carregando />
                  ) : (
                    <>
                      <MdSave size={16} color="#fff" /> Salvar
                    </>
                  )}
                </button>
              </span>
            </BForm>
          </Form>
        </Grid>

        {info.map(item => (
          <GridT key={item.id}>
            <Form onSubmit={handleUpdate} initialData={item}>
              <Input type="hidden" name="id" />
              <BForm>
                <span>
                  <label>Informação</label>
                  <Input name="adicionais" className="textarea" multiline />
                </span>
              </BForm>
              <BForm>
                <span>
                  <ButtonView>
                    <button type="submit" className="btn-save">
                      <MdSave size={16} color="#fff" /> Salvar
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDelete(item.id)}
                      className="btn-delete"
                    >
                      <MdDelete size={16} color="#fff" /> Deletar
                    </button>
                  </ButtonView>
                </span>
              </BForm>
            </Form>
          </GridT>
        ))}

        <Footer />
      </Container>
    </>
  );
}
